import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../../config/design/styles";
import EditComment from "../EditComment";
import {Translate} from "../../../../../config/Translate";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class EditCommentDialog extends React.Component{
    state = {
        open: true
    };
    classes = this.props.classes;
    handleClose = () => {
        this.setState({
            open:false
        });
        this.props.cancleOperation();
    };
    render(){
        let {open} = this.state;
        return(
            <div>
                <Dialog fullWidth={true} maxWidth={"md"} open={open} onClose={this.handleClose} TransitionComponent={Transition} >
                    <AppBar className={this.classes.appBarDialog}>
                        <Toolbar>
                            <Typography variant="h6" className={this.classes.titleDialog}>
                                {Translate.editComment}
                            </Typography>
                            <IconButton color="inherit" onClick={this.handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <div style={{padding:20}}>
                        <EditComment dialog id={this.props.id} handleDone={this.handleClose.bind(this)}/>
                    </div>
                </Dialog>
            </div>
        )
    }
}
export default  withStyles(Styles)(EditCommentDialog)