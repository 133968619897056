import React from 'react';
import {ListItem} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import VerticalCollapse from './VerticalCollapse';
import VerticalItem from './VerticalItem';
import useStyles from './VerticalNavGroup.style';
import {getStore} from "trim-redux";

const VerticalNavGroup = ({item, level,role}) => {
  const classes = useStyles();
  let user  = getStore('user');
  let plan = user.plan;
  let restricted = false;
  if(user.role.type !== "admin"){
    if(!!item.restrict){
      if(!plan){
        restricted = true;
      }else{
        if(plan[item.restrict] === 0){
          restricted = true;
        }
      }
    }
  }

  if(!restricted){
    return (
        <>
          <ListItem
              component='li'
              className={clsx(classes.navItem, 'nav-item nav-item-header')}>
            {item.title}
          </ListItem>

          {item.children && (
              <>
                {item.children.map((item) => (
                    <React.Fragment key={item.id}>
                      {item.type === 'group' && (
                          <NavVerticalGroup item={item} level={level} />
                      )}

                      {item.type === 'collapse' && (
                          <VerticalCollapse item={item} level={level} />
                      )}
                      {
                        !item.roles || item.roles.indexOf(role.name) !==  -1 ?
                            !item.permission || role.permission[item.permission] ?
                                item.type === 'item' && (
                                    <VerticalItem item={item} level={level} />
                                )
                                :null : null
                      }

                    </React.Fragment>
                ))}
              </>
          )}
        </>
    );
  }else{
    return null
  }

};

VerticalNavGroup.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    children: PropTypes.array,
  }),
};

VerticalNavGroup.defaultProps = {};

const NavVerticalGroup = VerticalNavGroup;

export default NavVerticalGroup;
