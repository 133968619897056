import React from 'react';
import {Styles} from '../../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import {setStore} from "trim-redux";
import Grid from "@material-ui/core/Grid/Grid";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import AppCardLoaderFields from "../../../../../components/Skeletons/AppCardLoaderFields";
import LoadingButton from "../../../../../components/elements/LoadingButton";
import {currencySymbol} from "../../../../../../config/values";
import {LoadSetting} from "../../../../../../config/Security";

class XPriceField extends React.Component {
    state = {
        price: null,
        priceR: null,
        loading:true,
        SubmitLoading:false,
    };
    classes = this.props.classes;
    componentDidMount(){
        this.loadData();
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/settings/getAll`, config)
                .then(response => {
                    this.setState({
                        price: response.data.x_price,
                        priceR: response.data.x_price,
                        loading:false
                    })
                })
                .catch(error => {
                    console.log(error.response)
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
            this.setState({
                loading:false
            })
        }
    }
    async handleSubmit(){
        await  this.setState({
            SubmitLoading:true
        })
        let loginToken = localStorage.getItem('ACT');
        let {priceR} =  this.state;
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            let data = new FormData();
            data.append('price', priceR);
            await axios.post(`${defaultVariabls.url}/settings/saveXPrice`,data, config)
                .then(async response => {
                    await LoadSetting();
                    await this.setState({
                        SubmitLoading:false
                    })

                })
                .catch(error => {
                    console.log(error.response)
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
            this.setState({
                loading:false
            })
        }
    }
    render(){
        let {loading,price,SubmitLoading} = this.state;
        return (
            <React.Fragment>
                {
                    loading ?
                        <AppCardLoaderFields/>
                        :
                        <Grid container spacing={2} className="mt-20" alignItems="center">
                            <Grid item xs={10} md={4}>
                                <CurrencyTextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    currencySymbol={currencySymbol}
                                    decimalPlaces={0}
                                    value={price}
                                    id="price"
                                    label={Translate.price}
                                    name="price"
                                    onChange={(e)=> this.setState({
                                        price : e.target.value,
                                        priceR:e.target.value.replace(/,/ig, '')
                                    })}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    onClick = {this.handleSubmit.bind(this)}
                                    loading = {SubmitLoading}
                                >
                                    {Translate.save}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                }
            </React.Fragment>

        );
    }
}

export default withSnackbar(withRouter(withStyles(Styles)(XPriceField)));