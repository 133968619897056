import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField/TextField";
import validator from "validator";
import axios from "axios";
import {defaultVariabls} from "../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import LoadingButton from "../../components/elements/LoadingButton";
import AppAnimate from "../../components/AppAnimate/index";
import AppCard from "../../components/layouts/components/AppCard";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import {connect, setStore} from "trim-redux";
import BreadCrumbs from "../../components/elements/BreadCrumbs";
import AppCardLoaderFields from "../../components/Skeletons/AppCardLoaderFields";
import Alert from "@material-ui/lab/Alert/Alert";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import Button from "@material-ui/core/Button/Button";
import {makeSlug, toggleLocale, translate} from "../../../config/lang";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import FieldCounterWithoutBar from "../../components/elements/FieldCounterWithoutBar";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Dialog from "@material-ui/core/Dialog/Dialog";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import AppBar from "@material-ui/core/AppBar/AppBar";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import CloseIcon from '@material-ui/icons/Close';
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import SubTitle from "../../components/elements/SubTitle";

import RichEditor from "../../components/editor/RichEditor";
import Hidden from "@material-ui/core/Hidden/Hidden";

import CategoryHolderPro from "../../components/fields/CategoryHolderPro";
import {truncate} from "../../../config/values";
import PageviewRoundedIcon from "@material-ui/icons/PageviewRounded";

class MakeEqualContent extends React.Component {
    constructor(props){
        super(props);
        this.ThumbChild = React.createRef();
        this.EditorChild = React.createRef();
    }
    state = {
        fields:{
            title:"",
            description:"",
            content_type:"article",
            type:"none",
        },
        content:{
            HTML: '',
            RAW: null,
            PlainText: ''
        },
        thumbnail: null,
        video_id: null,
        errors:{},
        keywords:[],
        categories:[],
        loading: true,
        SubmitLoading: false,
        publish: true,
        id:this.props.equal_id,
        editMode:false,
        slug:"",
        updated_at:"",
        created_at:"",
        businesses:[],
        gallery: [],
        item:this.props.item,
        modal:false,
        article:null,
    };
    classes = this.props.classes;
    async componentDidMount(){
        await this.setState({
            id: this.props.equal_id,
            loading:true
        })
        if(this.state.id){
            await this.setState({
                editMode:true
            });
            await this.loadData();
        }else{
            await this.setState({
                loading:false
            })
        }
    }
    componentDidUpdate(prevProps){
        if(this.state.id !== this.props.equal_id){
            this.setState({
                id: this.props.equal_id,
                loading:true
            })
            if(!!this.state.id && !!this.props.equal_id){
                this.loadData();
            }
        }
    }
    async toggleModal(){
        await toggleLocale();
        await this.setState({
            modal: !this.state.modal
        })
    }
    handleThumbImage(id){
        this.setState({
            thumbnail: id
        })
    }
    handleChangePublish(){
        this.setState({
            publish: !this.state.publish,
        })
    }
    async loadData(){
        this.setState({
            loading:true,
        })
        if(!this.state.id || !this.props.equal_id || (this.props.equal_id !== this.state.id)) {
            this.setState({
                editMode:false,
                loading:false,
                id:null
            })
        }else {
            let loginToken = localStorage.getItem('ACT');
            if (loginToken !== null) {
                let config = {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${loginToken}`
                    }
                };
                await axios.get(`${defaultVariabls.url}/content/get/${this.state.id}`, config)
                    .then(response => {
                        this.setState({
                            fields: {
                                title: response.data.title,
                                description: !!response.data.description ? response.data.description : '',
                                type: response.data.type,
                                content_type: response.data.content_type,
                            },
                            thumbnail: response.data.thumbnail,
                            video_id: !!response.data.video ? response.data.video.id : 0,
                            slug: response.data.slug,
                            updated_at: response.data.updated_at,
                            created_at: response.data.created_at,
                            businesses: !!response.data.businesses ? response.data.businesses : [],
                            content: {
                                HTML: response.data.html,
                                RAW: response.data.raw,
                            },
                        })
                        let categoriesTemp = [];
                        let keywordsTemp = [];
                        let galleryTemp = [];
                        response.data.gallery.map(item => {
                            galleryTemp.push({
                                src: `${defaultVariabls.uploads}/FileManager/${item.name}_s_thumb.${item.extension}`,
                                link: !!item.source_id ? `https://www.youtube.com/watch?v=${item.source_id}` : `${defaultVariabls.uploads}/FileManager/${item.name}.${item.extension}`,
                                id: item.id,
                                name: item.name,
                                type: item.type,
                                preview: item.preview_url ? item.preview_url : item.preview_id ? `${defaultVariabls.uploads}/FileManager/${item.preview.name}_s_thumb.${item.preview.extension}` : '',
                            })
                            return true
                        })
                        response.data.categories.map(item => {
                            categoriesTemp.push(item.id)
                            return true
                        })
                        response.data.keywords.map(item => {
                            keywordsTemp.push(item.name);
                            return true
                        });
                        this.setState({
                            categories: categoriesTemp,
                            keywords: keywordsTemp,
                            gallery: galleryTemp,
                            equal: response.data.equal,
                            article: response.data,
                            loading: false
                        })
                    }).catch(error => {
                        this.setState({
                            loading: false
                        })
                    })
            } else {
                setStore({
                    isAuth: false,
                    user: null,
                });
                this.setState({
                    loading: false
                })
            }
        }
    }
    handleChange(e){
        let {errors,fields} = this.state;
        let target = e.target;
        fields[target.name] = target.value;
        errors[target.name] = "";
        this.setState({fields});
    }

    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //title
        if(validator.isEmpty(fields.title)){
            formIsValid = false;
            errors['title'] = translate('emptyField');
        }

        //Description
        if(fields.description !== null ) {
            if (fields.description.length > 150) {
                formIsValid = false;
                errors['description'] = translate('maxLength150');
            }
        }
        if(!formIsValid){
            this.props.enqueueSnackbar(translate('someFieldsHasError'), {
                variant: 'warning',
            });
            this.setState({
                errors,
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }

    async handleRequest(){
        let {item,fields,thumbnail,content,video_id,businesses,gallery,keywords,categories,publish,id,editMode} = this.state;
        let data = new FormData();
        data.append('title', fields.title);
        data.append('type', fields.type);
        data.append('content_type', fields.content_type);
        data.append('html', content.HTML);
        data.append('raw', content.RAW);
        data.append('plainText', content.PlainText);
        data.append('id', id ? id : 0);
        data.append('description', fields.description);
        data.append('thumbnail', !!thumbnail ? thumbnail.id : 1);
        data.append('video_id', video_id);
        data.append('categories', JSON.stringify(categories));
        data.append('keywords', JSON.stringify(keywords));
        data.append('publish', publish ? 1 : 0);
        data.append('lang', this.props.fake_locale);
        data.append('equal_id', item.id);

        let ad_ids = [];
        await businesses.map((ad) => {
            ad_ids.push(ad.id)
        })
        await data.append('businesses',  JSON.stringify(ad_ids));

        if(gallery !== null){
            let galery_ids = [];
            gallery.map((row) =>{
                galery_ids.push(row.id);
                return true
            })
            gallery = JSON.stringify(galery_ids);
        }
        data.append('gallery', gallery);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        await axios.post(`${defaultVariabls.url}/content/make-equal`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(editMode ? translate('contentEdited') : translate('contentAdded'), {
                    variant: 'success',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
                this.loadData();
                this.toggleModal();
                this.props.reload();
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(editMode ?  translate('contentEditingFailed') : translate('contentAddingFailed'), {
                    variant: 'error',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
            });

    }
    handleGalleryChange(data){
        this.setState({
            gallery: data,
        })
    }
    handleClickDescription(){
        let {fields,content} = this.state;
        if(fields['description'] === ''){
            fields['description'] = content.PlainText === null ? null : content.PlainText.slice(0, 150);
            this.setState({fields})
        }
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }

    handleOutPut(code){
        this.setState({
            content:code
        })
    }
    handleCategoryHolder(categories){
        this.setState({categories})
    }

    render(){
        let {fields, errors,SubmitLoading,article,slug,loading,equal,item,video_id,categories,keywords,content,gallery,editMode,publish} = this.state;
        const {user,list} = this.props;
        return (
            <>
                {
                    !!article ?
                        <Button
                            color={!!list ? 'default' : "secondary"}
                            variant={!!list ? 'text' : "contained"}
                            disabled={loading}
                            size={!!list ? 'small' : "medium"}
                            onClick={() => this.toggleModal()}
                            startIcon={<EditRoundedIcon/>}
                            style={{whiteSpace:"nowrap"}}
                        >
                            {!!list ? truncate(article.title,8) : article.title }
                        </Button>
                        :
                        <Button
                            color={!!list ? 'default' : "secondary"}
                            variant={!!list ? 'outlined' : "contained"}
                            disabled={loading}
                            size={!!list ? 'small' : "medium"}
                            onClick={() => this.toggleModal()}
                            startIcon={<AddRoundedIcon/>}
                            style={{whiteSpace:"nowrap"}}
                        >
                            {translate('addEqual')}
                        </Button>
                }
                <Dialog
                    open={this.state.modal}
                    maxWidth={"md"}
                    fullWidth
                    onClose={() => this.toggleModal()}
                >
                    <AppBar className={this.classes.appBarDialog}>
                        <Toolbar>
                            <Typography variant="h6" className={this.classes.titleDialog}>
                                {editMode ? translate('editContent') :  translate('makeContent')}
                            </Typography>
                            <IconButton color="inherit" onClick={() => this.toggleModal()} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <DialogContent
                        className={this.classes.dialogRoot}
                    >
                        <AppAnimate animation='transition.slideUpIn' delay={200} title={editMode ? translate('editContent') :  translate('makeContent')}>
                            {
                                <Grid container spacing={2} justifyContent="center">
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} justifyContent="center">
                                            <Grid item xs={12}>
                                                <Grid container spacing={2} justifyContent="center">
                                                    <Grid item xs={12}>
                                                        <AppCard
                                                            title={editMode ? translate('editContent') :  translate('makeContent')}
                                                            action={
                                                                <BreadCrumbs singleLevel firstText={editMode ? translate('editContent') :  translate('makeContent')}/>
                                                            }>
                                                            {
                                                                this.state.loading ? <AppCardLoaderFields/> :
                                                                    <Grid container spacing={2} justify="center" alignItems="center">
                                                                        {
                                                                            (!!editMode && !this.state.loading) &&
                                                                            <Grid item xs={12}>
                                                                                <Button
                                                                                    component="a"
                                                                                    target="_blank"
                                                                                    size="small"
                                                                                    startIcon={<PageviewRoundedIcon/>}
                                                                                    href={makeSlug(`post/${slug}`,this.props.fake_locale)}
                                                                                    color="secondary"
                                                                                >
                                                                                    {translate('viewArticle')}
                                                                                </Button>
                                                                            </Grid>
                                                                        }
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                margin="normal"
                                                                                required
                                                                                fullWidth
                                                                                color="secondary"
                                                                                variant="outlined"
                                                                                name="title"
                                                                                label={translate('contentTitle')}
                                                                                type="text"
                                                                                id="title"
                                                                                size="small"
                                                                                value={fields.title}
                                                                                onChange={this.handleChange.bind(this)}
                                                                                error = {!!errors['title']}
                                                                                helperText={errors['title']}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <RichEditor ref={this.EditorChild} Output={this.handleOutPut.bind(this)} input={content.RAW}/>
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                variant="outlined"
                                                                                margin="normal"
                                                                                multiline
                                                                                color="secondary"
                                                                                fullWidth
                                                                                name="description"
                                                                                label={translate('seoDescription')}
                                                                                placeholder={translate('seoDescription')}
                                                                                type="text"
                                                                                id="description"
                                                                                rows="4"
                                                                                value={fields.description}
                                                                                onChange={this.handleChange.bind(this)}
                                                                                onClick={this.handleClickDescription.bind(this)}
                                                                                error = {!!errors['description']}
                                                                                helperText={errors['description']}
                                                                                InputProps={{
                                                                                    endAdornment: (
                                                                                        <InputAdornment position="end">
                                                                                            <FieldCounterWithoutBar length={fields.description.length} max={150}/>
                                                                                        </InputAdornment>
                                                                                    ),
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                            }
                                                        </AppCard>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <LoadingButton
                                                            variant="contained"
                                                            color="primary"
                                                            onClick = {this.handleSubmit.bind(this)}
                                                            loading = {SubmitLoading}
                                                        >
                                                            {editMode ? translate('editContent') : translate('makeContent')}
                                                        </LoadingButton>
                                                    </Grid>
                                                    {
                                                        (!!editMode && !this.state.loading) &&
                                                        <Grid item xs={12}>
                                                            <Alert severity="warning" variant="standard" style={{marginBottom:10}}>
                                                                <Typography style={{direction:this.props.fake_locale === "en" ? "ltr" : "rtl",}}>
                                                                    {translate('ifYouWantToSeeMoreInfoClick')}
                                                                    {" "}
                                                                    <Button
                                                                        size="small"
                                                                        color="secondary"
                                                                        component='a'
                                                                        href={`${defaultVariabls.React}/edit/content/${this.state.id}?lang=${this.props.fake_locale}`}
                                                                    >
                                                                        {translate('clickHere')}
                                                                    </Button>
                                                                </Typography>
                                                                <Typography style={{fontSize:11}}>
                                                                    {translate('attentionText')}
                                                                </Typography>
                                                            </Alert>
                                                        </Grid>
                                                    }
                                                </Grid>

                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                        </AppAnimate>
                    </DialogContent>

                </Dialog>
            </>
        );
    }
}

const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,
    fake_locale: state.fake_locale,
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MakeEqualContent))));