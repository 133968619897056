import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Link, withRouter} from 'react-router-dom';
import Loader from "../../../components/elements/Loader";
import TextField from "@material-ui/core/TextField/TextField";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {connect, setStore} from "trim-redux";
import { withSnackbar } from 'notistack';
import SubTitle from "../../../components/elements/SubTitle";
import LoadingButton from "../../../components/elements/LoadingButton";
import BreadCrumbs from "../../../components/elements/BreadCrumbs";
import Title from "../../../components/elements/Title";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import Permission from "../../../components/elements/Permission";
import Rating from "@material-ui/lab/Rating/Rating";
import Typography from "@material-ui/core/Typography/Typography";
import HeaderTags from "../../../components/elements/HeaderTags";
import withWidth from "@material-ui/core/withWidth/withWidth";
import Button from "@material-ui/core/Button/Button";
import ChatBubbleOutlineRoundedIcon from '@material-ui/icons/ChatBubbleOutlineRounded';
import Divider from "@material-ui/core/Divider/Divider";
import {makeSlug, translate} from "../../../../config/lang";

class EditComment extends React.Component {
    state = {
        fields:{
            comment:'',
        },
        loading: true,
        rating: 4.5,
        SubmitLoading:false,
        confirmed:0,
        user:null,
        ad:null,
        id: null,
        parent: null,
        errors:[],
    };

    classes = this.props.classes;
    async componentDidMount(){
        let {dialog,id} = this.props;
        if(dialog){
            await this.setState({
                id:id
            })
        }else{
            await this.setState({
                id:this.props.match.params.id
            })
        }
        await this.loadData();
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        let {id} = this.state;
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/comment/get/${id}`, config)
                .then(response => {
                    this.setState({
                        fields: {
                            comment: response.data.comment,
                        },
                        rating: response.data.rate,
                        confirmed: parseInt(response.data.confirmed),
                        user:response.data.user,
                        product:response.data.product,
                        business:response.data.business,
                        parent:response.data.parent,
                        loading: false

                    })
                    return true
                }).catch(error => {
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
                loading:false
            });
            this.setState({
                loading:false
            })
        }
    }
    handleChangeConfirmed(){
        this.setState({
            confirmed: this.state.confirmed === 0 ? 1 : 0,
        })
    }
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        fields[target.name] = target.value;
        errors[target.name] = "";
        this.setState({
            fields,
            PreventingToExit:true
        });

    }
    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //comment
        if(fields.comment !== null ) {
            if (fields.comment.length > 1000) {
                formIsValid = false;
                errors['comment'] = translate('maxLength1000');
            }
        }
        if(!formIsValid){
            this.setState({
                SubmitLoading:false
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        let { fields,confirmed,rating } = this.state;
        let data = new FormData();
        data.append('id', this.state.id);
        data.append('comment', fields.comment);
        data.append('rate', rating);
        data.append('confirmed', confirmed ? 1 : 0);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/comment/edit`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(translate('commentEdited'), {
                    variant: 'success',
                });
                this.setState({
                    SubmitLoading:false
                })
                if(this.props.dialog)
                this.props.handleDone()
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(translate('commentEditingFailed'), {
                    variant: 'error',
                });
                this.setState({
                    SubmitLoading:false
                })
            });

        this.setState({
            loading:false,
            SubmitLoading:false
        });
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.setState({
                    loading:true
                });
                this.handleRequest();
            }
        });
    }

    handleRemoveItem(){
        let data = new FormData();
        data.append('id', this.state.id);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/comment/delete`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(translate('commentRemoved'), {
                    variant: 'success',
                });
                this.setState({
                    PreventingToExit:false,
                    SubmitLoading:false
                })
                this.props.handleDone();
            })
            .catch(error => {
                this.props.enqueueSnackbar(translate('commentEditingFailed'), {
                    variant: 'error',
                });
                this.setState({
                    SubmitLoading:false
                })
            });

        this.setState({
            loading:false,
            SubmitLoading:false
        });
    }

    render(){
        let {fields, confirmed,errors,loading,rating,SubmitLoading,user,parent} = this.state;
        let {dialog,type} = this.props;
        return (
            <React.Fragment>
                <HeaderTags title={translate('editComment')}/>
                {
                    loading ? <Loader/> :
                        <React.Fragment>
                            {
                                !dialog ?
                                    <Grid item xs={12}>
                                        <BreadCrumbs  firstText={translate('comments')} firstLink="/setting/comments" secondText={translate('editComment')}/>
                                    </Grid>
                                    : null
                            }

                            <Grid item xs={12}>
                                <Grid container spacing={1} direction="row" className={dialog ? null :this.classes.GridAsaPaper}>
                                    {
                                        !dialog ?
                                            <Grid item xs={12} className="TitleContainer">
                                                <Title>{translate('editComment')} <ChatBubbleOutlineRoundedIcon style={{float:'left'}}/></Title>
                                            </Grid>
                                            : null
                                    }
                                    {
                                        !!parent ?
                                            <Grid item xs={12}>
                                                <Typography paragraph variant="body2" color="textSecondary"> {translate('parentCommentDesc')}</Typography>
                                                <Typography paragraph>{parent.comment}</Typography>
                                                <Divider/>
                                            </Grid>
                                            : null
                                    }
                                    <Grid item xs={12}>
                                        <Typography>
                                            {translate(type)}:
                                            <Button
                                                color="secondary"
                                                component={'a'}
                                                className="link" href={makeSlug(`${type}/${this.state[type].slug}`)}>
                                                {this.state[type].name}
                                            </Button>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {
                                            !!user ?
                                                <Typography>
                                                    {translate('simple_user')}:
                                                    <Button
                                                        color="secondary"
                                                        component={Link}
                                                        className="link"
                                                        to={`/edit/user/${user.id}`}
                                                    >
                                                        {user.first_name} {user.last_name}
                                                    </Button>
                                                </Typography>
                                                : null
                                        }

                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>{translate('rating')}:</Typography>
                                        <div className={this.classes.ratingContainer}>
                                            <Rating
                                                name="rating"
                                                precision={0.5}
                                                value={rating}
                                                onChange={(event, newValue) => {
                                                    this.setState({
                                                        rating:newValue,
                                                    })
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} direction="row">

                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            color="secondary"
                                            multiline
                                            fullWidth
                                            name="comment"
                                            label={translate('comment')}
                                            type="text"
                                            id="comment"
                                            rows="4"
                                            value={fields.comment}
                                            onChange={this.handleChange.bind(this)}
                                            error = {!!errors['comment']}
                                            helperText={errors['comment']}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container  direction="row" className={dialog ? null : this.classes.GridAsaPaper}>
                                    <Permission rule="manageSettings">
                                        <Grid item xs={5} md={2}>
                                            <SubTitle noHR>{translate('confirmationStatusFilter')} : </SubTitle>
                                        </Grid>
                                        <Grid item xs={7} md={4}>
                                            <FormControlLabel
                                                style={{marginLeft:10}}
                                                control={
                                                    <Switch
                                                        checked={confirmed}
                                                        onChange={this.handleChangeConfirmed.bind(this)}
                                                        value={confirmed}
                                                        color="secondary"
                                                    />
                                                }
                                                label={confirmed ? translate('confirmed') : translate('unConfirmed')}
                                            />
                                        </Grid>
                                    </Permission>
                                    <Grid item xs={12} md={6}>
                                        <Permission rule="remove">
                                            <Button
                                                style={{color:"#ef6b2c",float:'left'}}
                                                onClick={this.handleRemoveItem.bind(this)}
                                            >
                                                {
                                                    translate('removeComment')
                                                }
                                            </Button>
                                        </Permission>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <LoadingButton
                                            variant="contained"
                                            color="primary"
                                            onClick = {this.handleSubmit.bind(this)}
                                            loading = {SubmitLoading}
                                        >
                                            {translate('editComment')}
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                }
            </React.Fragment>

        );
    }
}
const mstp = state => ({
    user: state.user
});
export default withSnackbar(withRouter(withWidth()(withStyles(Styles)(connect(mstp)(EditComment)))));