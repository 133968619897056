import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import BreadCrumbs from "../../components/elements/BreadCrumbs";
import AppAnimate from "../../components/AppAnimate";
import AppCard from "../../components/layouts/components/AppCard";
import Advertisements from "./parts/Businesses";
import {translate} from "../../../config/lang";
import MakeBusiness from "./MakeBusiness";
import {withRouter} from "react-router";
import {connect} from "trim-redux";


class BusinessPage extends React.Component {
    classes = this.props.classes;

    render(){
        let {user,manage,create} = this.props;
        let user_id = null;
        if(!create){
            if(!!manage){
                user_id = this.props.match.params.id
            }else{
                user_id = !!user.business ? user.id : null;
            }
        }
        return (
            <>
                <MakeBusiness user_id={user_id} create={create}/>
            </>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,
    locale: state.locale,
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(BusinessPage))));
