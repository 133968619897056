import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {getDataTableLocalization} from "../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {connect} from "trim-redux";
import MaterialTable from "material-table";
import {tableIcons} from "../../../../config/design/TableIcons";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import moment from "moment-timezone";
import {TableCount} from "../../../../config/Permissions";
import Typography from "@material-ui/core/Typography/Typography";
import withWidth from "@material-ui/core/withWidth/withWidth";
import SubTitle from "../../../components/elements/SubTitle";
import {translate} from "../../../../config/lang";
import {getTimeZone} from "../../../../config/values";
class RFQs extends React.Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    state = {
        columns: [
            {
                title: translate('documentName'),
                field: 'name',
                editable: 'never',
                sorting:false,
                filterPlaceholder: translate('search'),
            },
            {
                title: translate('dateAdded'),
                editable: 'never',
                sorting:false,
                field: 'created_at',
                render: rowData =>
                    <>
                        {
                            !!rowData.created_at &&
                            <Typography className={this.classes.dateTable}>
                                {`${moment.tz(rowData.created_at,'UTC').tz(getTimeZone()).format( 'HH:mm , YYYY/M/D')}`}
                            </Typography>
                        }
                    </>
            },
            { title: translate('status'),
                sorting:false,
                field: 'status',
                filterPlaceholder: translate('choose'),
                lookup: {
                    "pending" :
                        <Typography color="textSecondary" >{translate('pending')}</Typography>
                    , "verified":
                        <Typography style={{color: "#38bf84"}}>{translate('verified')}</Typography>
                },
            },
            {
                title: translate('link'),
                editable: 'never',
                sorting:false,
                field: 'link',
                render: rowData =>
                    <a href={rowData.link} target="_blank">
                        Download
                    </a>
            },

        ],
        data: this.props.documents,
    };
    async onDelete(id,hasNotif = true) {
        let data = new FormData();
        data.append('id', id);
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        await axios.post(`${defaultVariabls.url}/document/remove`, data, config)
            .then(response => {
                this.props.reload();

                return true
            })
            .catch(error => {
                console.log(error)
                this.props.enqueueSnackbar(translate('deleteFileFailed'), {
                    variant: 'error',
                });
                return false
            });
    }
    onEdit(file) {
        console.log(file);
        let data = new FormData();
        data.append('id', file.id);
        data.append('status', file.status);
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/document/edit`, data, config)
            .then(response => {
                this.props.reload()
                return true
            })
            .catch(error => {
                console.log(error)
                console.log(error.response)
                this.props.enqueueSnackbar(translate('editFileFailed'), {
                    variant: 'error',
                });
                return false
            });
    }
    render(){
        let {data} = this.state;
        let {user} = this.props;
        return (
            <>

                <MaterialTable
                    tableRef={this.tableRef}
                    icons={tableIcons}
                    title={
                        <SubTitle align="left" noHR>
                            {translate('companyDocuments')}
                        </SubTitle>
                    }
                    localization={getDataTableLocalization()}
                    columns={this.state.columns}
                    data={query =>
                        new Promise((resolve, reject) => {
                            let data = [];
                            this.props.documents.map((row) => {
                                data.push({
                                    name: row.document.parent.name+" - "+row.document.name,
                                    id: row.id,
                                    link: `${defaultVariabls.uploads}/FileManager/${row.file.name}.${row.file.extension}`,
                                    status: row.status,
                                    created_at:row.created_at
                                })
                            });
                            resolve({
                                data: data,
                            })
                        })
                    }
                    options={{
                        draggable: false,
                        search: false,
                        paging: false,
                        pageSize: 5,
                        pageSizeOptions: TableCount,
                    }}
                    editable={this.props.width === "sm" || this.props.width === "xs" ? null :
                        user.role.type === "admin" ?
                            {
                                onRowUpdate: (newData, oldData) =>
                                    new Promise(resolve => {
                                        setTimeout(() => {
                                            resolve();
                                            this.onEdit(newData);
                                            if (oldData) {
                                                this.setState(prevState => {
                                                    const data = [...prevState.data];
                                                    data[data.indexOf(oldData)] = newData;
                                                    return { ...prevState, data };
                                                });
                                            }
                                        }, 600);
                                    }),
                                onRowDelete: oldData =>
                                    new Promise(resolve => {
                                        setTimeout(() => {
                                            resolve();
                                            this.onDelete(oldData.id);
                                            this.setState(prevState => {
                                                const data = [...prevState.data];
                                                data.splice(data.indexOf(oldData), 1);
                                                return { ...prevState, data };
                                            });
                                        }, 600);
                                    }),
                            } : null
                            /*{
                                onRowDelete: oldData =>
                                    new Promise(resolve => {
                                        setTimeout(() => {
                                            resolve();
                                            this.onDeleteFile(oldData.id);
                                            this.setState(prevState => {
                                                const data = [...prevState.data];
                                                data.splice(data.indexOf(oldData), 1);
                                                return { ...prevState, data };
                                            });
                                        }, 600);
                                    }),
                            }*/
                    }
                />
            </>
        );
    }
}

const mstp = state => ({
    user: state.user,
    features: state.features,
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(RFQs)))));