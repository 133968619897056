import React from 'react';
import {Icon, ListItem, ListItemText} from '@material-ui/core';
import {withRouter} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Badge from '../Badge';
import AppNavLink from '../AppNavLink';
import Box from '@material-ui/core/Box';
import useStyles from './VerticalItem.style';
import {connect, getStore, setStore} from "trim-redux";

const VerticalItem = ({level, item}) => {
  const classes = useStyles({level});
  let features = getStore('features');
    let user  = getStore('user');
    let restricted = false;
    if(!!user){
        if(user.role.type !== "admin"){
            let plan = user.plan;
            if(!!item.restrict){
                if(!plan){
                    restricted = true;
                }else{
                    if(plan[item.restrict] === 0){
                        restricted = true;
                    }
                }
            }
        }
    }else{
        restricted = true;
    }
  if(!item.feature || features[item.feature]){
      if(!restricted){
          return (
              <>
                  {
                      item.url_type === "external" ?
                          <ListItem
                              button
                              component="a"
                              href={item.url}
                              target="_blank"
                              onClick={()=>setStore({
                                  navCollapsed:false
                              })}
                              style={{ paddingRight: 17*(level+1)}}
                              activeClassName='active'
                              className={clsx(classes.navItem, 'nav-item')}
                              exact={item.exact}>
                              {item.icon && (
                                  <Box component='span' mr={3}>
                                      <Icon
                                          className={clsx(classes.listIcon, 'nav-item-icon')}
                                          color='action'>
                                          {item.icon}
                                      </Icon>
                                  </Box>
                              )}
                              <ListItemText
                                  primary={item.title}
                                  classes={{primary: 'nav-item-text'}}
                              />
                              {item.count && (
                                  <Box mr={1} clone>
                                      <Badge count={item.count} color={item.color} />
                                  </Box>
                              )}
                          </ListItem>
                          :
                          <ListItem
                              button
                              component={AppNavLink}
                              to={item.url}
                              onClick={()=>setStore({
                                  navCollapsed:false
                              })}
                              activeClassName='active'
                              style={{ paddingRight: 17*(level+1)}}
                              className={clsx(classes.navItem, 'nav-item')}
                              exact={item.exact}>
                              {item.icon && (
                                  <Box component='span' mr={3}>
                                      <Icon
                                          className={clsx(classes.listIcon, 'nav-item-icon','material-symbols-outlined')}
                                          color='action'>
                                          {item.icon}
                                      </Icon>
                                  </Box>
                              )}
                              <ListItemText
                                  primary={item.title}
                                  classes={{primary: 'nav-item-text'}}
                              />
                              {item.count && (
                                  <Box mr={1} clone>
                                      <Badge count={item.count} color={item.color} />
                                  </Box>
                              )}
                          </ListItem>
                  }

              </>
          )
      }else{
          return null
      }
  }else{
    return null
  }
};

VerticalItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.string,
    url: PropTypes.string,
  }),
};

VerticalItem.defaultProps = {};

const mstp = state => ({
    navCollapsed: state.navCollapsed,
});
export default connect(mstp)(withRouter(React.memo(VerticalItem)));
