import React from 'react';
import {Translate} from "../../../../../config/Translate";
import {connect} from "trim-redux";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../../config/design/styles";
import Grid from "@material-ui/core/Grid/Grid";
import IconButtonOpenner from "../../../../components/elements/IconButtonOpenner";
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import SubTitle from "../../../../components/elements/SubTitle";
import axios from "axios";
import {defaultVariabls} from "../../../../../config";
import AppCardLoaderFields from "../../../../components/Skeletons/AppCardLoaderFields";
import TemplatePartChooser from "../components/TemplatePartChooser";
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";
import Paper from "@material-ui/core/Paper/Paper";
import AppCard from "../../../../components/layouts/components/AppCard";
import Alert from "@material-ui/lab/Alert/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle/AlertTitle";
import LiveHelpRoundedIcon from '@material-ui/icons/LiveHelpRounded';
import MenuChooser from "../components/MenuChooser";
import CodeEditor from "../components/CodeEditor";

class FooterManagement extends React.Component{

    state = {
        loading: true,
        tab:"footer",
        footers:[],
    };

    loadData(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.get(`${defaultVariabls.url}/template_parts/get/footer`, config)
            .then(response => {
                this.setState({
                    footers: response.data,
                    loading:false,
                })
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(Translate.dataFetchingError, {
                    variant: 'error',
                });
            });
    }
    render(){
        let {footers,loading,tab} = this.state;
        return (
            <IconButtonOpenner
                title={Translate.footerTemplate}
                icon={<SettingsRoundedIcon/>}
                opened={() => this.loadData()}
            >
                {
                    loading ?
                        <AppCardLoaderFields/>:
                        <>
                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                            <Grid item xs={12} md={10}>
                                <Tabs
                                    value={tab}
                                    onChange={(e,val) => this.setState({tab:val})}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    centered
                                >
                                    <Tab label={Translate.manageFooter} value="footer"/>
                                    <Tab label={Translate.manageMenu} value="menu" />
                                    <Tab label={Translate.footerCertifications} value="certifications" />
                                </Tabs>
                            </Grid>
                            {
                                tab === "footer" ?
                                    <>
                                        <Grid item xs={12} md={10}>
                                            <Alert severity="info" icon={<LiveHelpRoundedIcon fontSize="inherit" />} >
                                                <AlertTitle><strong>{Translate.chooseFooter}</strong></AlertTitle>
                                                {Translate.chooseFooterText}
                                            </Alert>
                                        </Grid>
                                        {
                                            footers.map((footer,index)=> (
                                                <Grid item xs={12} md={10} key={index}>
                                                    <TemplatePartChooser type="footer" item={footer}/>
                                                </Grid>
                                            ))
                                        }
                                    </>
                                    : tab === "menu" ?

                                    <>
                                        <Grid item xs={12} md={10}>
                                            <Alert severity="info" icon={<LiveHelpRoundedIcon fontSize="inherit" />} >
                                                <AlertTitle><strong>{Translate.makeFooterMenu}</strong></AlertTitle>
                                                {Translate.makeFooterMenuText}
                                            </Alert>
                                        </Grid>
                                        <Grid item xs={12} md={10}>
                                            <MenuChooser position="footer_menu"/>
                                        </Grid>
                                    </>
                                    :
                                    <>
                                        <Grid item xs={12} md={10}>
                                            <Alert severity="info" icon={<LiveHelpRoundedIcon fontSize="inherit" />} >
                                                <AlertTitle><strong>{Translate.footerCertificationTitle}</strong></AlertTitle>
                                                {Translate.footerCertificationText}
                                            </Alert>
                                        </Grid>
                                        <Grid item xs={12} md={10}>
                                            <CodeEditor type="footer_certificates"/>
                                        </Grid>
                                    </>
                            }

                        </Grid>
                        </>
                }

            </IconButtonOpenner>
        );
    }
}
const mstp = state => ({
    user: state.user,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(FooterManagement)))));