import React from 'react';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField/TextField";
import axios from "axios";
import {defaultVariabls} from "../../../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import {setStore} from "trim-redux";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import {FieldSkeleton} from "../../../../components/Skeletons/FieldSkeleton";
import {Translate} from "../../../../../config/Translate";


class ChooseBlogComment extends React.Component {
    state = {
        contents: null,
        loading:true,
        output:null,
        input:null,
        defaults: null,
    };
    classes = this.props.classes;
    componentDidMount(){
        this.setState({
            input: this.props.inPut
        })
        this.loadData()
    }
    async componentDidUpdate(newProps){
        if(newProps !== this.props){
            await this.setState({
                input: this.props.inPut

            })
        }
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            let data = new FormData();
            data.append('id', this.props.id ? this.props.id : 0);
            await axios.post(`${defaultVariabls.url}/contents/quickViewForComments`,data, config)
                .then(response => {
                    this.setState({
                        contents: response.data,
                    })
                    this.loadDefault();
                })
                .catch(error => {
                    console.log(error.response)
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
                loading:false
            });
            this.setState({
                loading:false
            })
        }
    }
    async loadDefault(){
        let {input,contents} = this.state;
        if(input > 0){
            if(contents !== null){
                await contents.map(row =>{
                    if(row.id === input){
                        this.setState({
                            defaults:row,
                            output:row,
                            loading:false
                        })
                        this.props.outPut(row);

                    }
                    return true
                })
            }
        }else{
            this.setState({
                loading:false
            })
        }

    }
    handChange(value){
        this.setState({
            output:value,
        })
        this.props.outPut(value);
    }

    render(){
        let {contents,defaults} = this.state;
        return (
            <React.Fragment>
                {
                    this.state.loading ?
                        <FieldSkeleton/>
                        :
                        contents !== undefined ?
                            <Autocomplete
                                options={contents}
                                className="autoCompleteHolder"
                                onChange={(event, value) => this.handChange(value)}
                                defaultValue={defaults}
                                getOptionLabel={option => option.title}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label={Translate.chooseContentForComment}
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            /> :
                            Translate.noContent
                }
            </React.Fragment>

        );
    }
}

export default withSnackbar(withRouter(withStyles(Styles)(ChooseBlogComment)));