import React from 'react';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import {Translate} from "../../../../../config/Translate";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import IconButton from "@material-ui/core/IconButton/IconButton";
import CreditCardRoundedIcon from '@material-ui/icons/CreditCardRounded';
import Typography from "@material-ui/core/Typography/Typography";
import ImageViewer from "../../../../components/elements/ImageViewer";
class ViewUserBankInfo extends React.Component {

    state={
        open:false,
        bank_info:this.props.bank_info
    }
    classes = this.props.classes;
    toggleOpen (){
        this.setState({open:!this.state.open})
    }
    render(){
        let {open,bank_info} = this.state;
        return (
            <>
                <Tooltip title={Translate.viewUserBankInfo} arrow>
                <IconButton
                    color="primary"
                    onClick={this.toggleOpen.bind(this)}
                >
                    <CreditCardRoundedIcon/>
                </IconButton>
                </Tooltip>
                <Dialog maxWidth="sm" fullWidth onClose={this.toggleOpen.bind(this)} aria-labelledby="customized-dialog-title" open={open}>
                    <DialogTitle id="customized-dialog-title" onClose={this.toggleOpen.bind(this)}>
                        {Translate.viewUserBankInfo}
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography>{Translate.bank_name}: <strong>{bank_info.bank_name}</strong></Typography>
                        <br/>
                        <Typography>{Translate.sheba}: <strong>{bank_info.sheba}</strong></Typography>
                        <br/>
                        <ImageViewer id={bank_info.creditcard_image} style={{maxWidth:"70%"}}/>
                    </DialogContent>
                </Dialog>
            </>
        );
    }
}

export default withSnackbar(withRouter(withStyles(Styles)(ViewUserBankInfo)));