import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {DataTableLocalization, getDataTableLocalization, Translate} from "../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../config/index";
import {connect} from "trim-redux";
import MaterialTable from "material-table";
import {tableIcons} from "../../../config/design/TableIcons";
import AddFile from "./Components/AddFile";
import { withSnackbar } from 'notistack';
import moment from "moment-timezone";
import IconButton from "@material-ui/core/IconButton/IconButton";
import LinkIcon from '@material-ui/icons/Link';
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar/Avatar";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import GetAppIcon from '@material-ui/icons/GetApp';
import OndemandVideoRoundedIcon from '@material-ui/icons/OndemandVideoRounded';
import {TableCount, TablePage} from "../../../config/Permissions";
import withWidth from "@material-ui/core/withWidth/withWidth";
import Button from "@material-ui/core/Button/Button";
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import Grid from "@material-ui/core/Grid/Grid";
import Role from "../../components/elements/Role";
import Typography from "@material-ui/core/Typography/Typography";
import TextField from "@material-ui/core/TextField/TextField";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import {getTimeZone, truncate} from "../../../config/values";
import Accordion from "@material-ui/core/Accordion/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary/AccordionSummary";
import SubTitle from "../../components/elements/SubTitle";
import AccordionDetails from "@material-ui/core/AccordionDetails/AccordionDetails";
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import Container from "@material-ui/core/Container/Container";
import {DEMO} from "../../../config/Security";
import {translate} from "../../../config/lang";
import AddFromYoutube from "./Components/AddFromYoutube";
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';
import PlayCircleFilledOutlinedIcon from '@material-ui/icons/PlayCircleFilledOutlined';
import PlanRestriction from "../../components/elements/PlanRestriction";
class FileManager extends React.Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    state = {
        owner: 'self',
        type:  this.props.fileType !== undefined ? this.props.fileType : 'image',
        fileType : this.props.fileType !== undefined ? this.props.fileType : 'image',
        columns: [
            {
                field: 'source',
                editable: 'never',
                title: translate('image'),
                render: rowData =>(
                    rowData.type === 'image' ? <img className={this.classes.FMThumbnail} alt="avatar" src={rowData.thumbnail} /> :
                        rowData.type === 'video' ?
                            !!rowData.preview ?
                                <div className={this.classes.thumbnailWithPreview}>
                                    <img className={this.classes.FMThumbnail} alt="avatar" src={rowData.preview} />
                                    <PlayCircleFilledOutlinedIcon style={{fontSize: 30,color: "#6d6d6d",opacity:0.7}}/>
                                </div>
                                :
                            <OndemandVideoRoundedIcon style={{fontSize: 40,color: "#6d6d6d"}}/> :
                            <DescriptionRoundedIcon style={{fontSize: 40,color: "#6d6d6d"}}/>
                )
            },
            {
                title: translate('imageName'),
                field: 'ImageName' ,
                editable: 'never',
                render: rowData =>(
                    rowData.ImageName+"."+rowData.extension
                )

            },
            {
                title: translate('alt'),
                hidden: this.props.user.role.type !== "admin" || (this.props.width === "sm" || this.props.width === "xs"),
                field: 'alt' ,
                render: rowData =>(
                    <Typography className={this.classes.dateTable}>
                        {truncate(rowData.alt,20)}
                    </Typography>
                )
            },
            {
                title: translate('fileSize'),
                hidden: this.props.user.role.type !== "admin" || (this.props.width === "sm" || this.props.width === "xs"),
                field: 'size' ,
                render: rowData =>(
                    <Typography className={this.classes.dateTable} style={{whiteSpace:"nowrap"}}>
                        {rowData.size+" kb"}
                    </Typography>
                )
            },
            { title: translate('ownerMedia'),editable: 'never', field: 'owner',hidden: this.props.width === "sm" || this.props.width === "xs",},
            { title: translate('uploadedDate'),
                editable: 'never',
                field: 'lastUpdate',
                hidden: this.props.width === "sm" || this.props.width === "xs",
                render: rowData =>
                    <Typography className={this.classes.dateTable}>
                        {`${moment.tz(rowData.created_at,'UTC').tz(getTimeZone()).fromNow()}`}
                        <br/>
                        {`${moment.tz(rowData.created_at,'UTC').tz(getTimeZone()).format( 'HH:mm , YYYY/M/D')}`}
                    </Typography>
            },
            {
                title: translate('link'),editable: 'never',
                field: 'link',
                hidden : !this.props.page,
                render: rowData =>(
                    rowData.type === 'image' ? <IconButton color="secondary" onClick={() => this.OpenDialog(rowData)}><LinkIcon /></IconButton> : rowData.extension === "youtube" ? <IconButton target="_blank" component="a" href={`${defaultVariabls.youtube}${rowData.source_id}`}><OpenInNewRoundedIcon color="secondary"/></IconButton> :  <IconButton component="a" href={rowData.source}><GetAppIcon color="secondary" /></IconButton>
                )
            },
            {
                title: translate('activity'),
                editable: 'never',
                field: 'action',
                hidden: !this.props.selection,
                render: rowData =>(
                    <Button color="secondary" variant="outlined" size="small" startIcon={<GetAppIcon/>}>
                        {translate('insert')}
                    </Button>
                )
            },
        ],
        data: [],
        ChangeLoader:false,
        LinkDialog:false,
        links:null,
        open_filter:false,
    };
    componentDidMount(){
    }
    OpenDialog(data){
        let links = []
        links[0] = `${defaultVariabls.uploads}/FileManager/${data.name}_small.${data.extension}`;
        links[1] = `${defaultVariabls.uploads}/FileManager/${data.name}_medium.${data.extension}`;
        links[2] = `${defaultVariabls.uploads}/FileManager/${data.name}.${data.extension}`;
        this.setState({
            links,
            LinkDialog:true
        })
    }
    handleCloseDialog(){
        this.setState({
            LinkDialog:false
        })
    }
    handleClickLink(link){
        navigator.clipboard.writeText(link);
        this.setState({
            LinkDialog:false
        })
        this.props.enqueueSnackbar(translate('linkCopied'), {
            variant: 'info',
        });
    }
    addImage(data){
        const {handleSetImage} = this.props;
        if(handleSetImage !== undefined){
            handleSetImage(data)
        }
    }

    upload(image,preview = null){
        const {handleSetImage} = this.props;
        if(handleSetImage !== undefined){
            let res = {
                thumbnail: `${defaultVariabls.uploads}/FileManager/${image.name}_s_thumb.${image.extension}`,
                source: `${defaultVariabls.uploads}/FileManager/${image.name}.${image.extension}`,
                ImageName: truncate(image.name,20),
                name: image.name,
                extension: image.extension,
                alt: image.alt,
                type: image.type,
                preview: !!image.preview_url ? image.preview_url : !!image.preview_id ? `${defaultVariabls.uploads}/FileManager/${image.preview.name}_s_thumb.${image.preview.extension}` : null,
                source_id: image.source_id,
                owner: image.owner.first_name+" "+image.owner.last_name,
                id: image.id,
                created_at:image.updated_at
            }
            handleSetImage(res,preview)
        }
        this.loadData();
    }
    handlePreview(image){
        const {handleSetPreview} = this.props;
        if(handleSetPreview !== undefined){
            handleSetPreview(image)
        }
    }
    Changed(){
        this.loadData();
    }
    loadData(){
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }

    async onDeleteFile(id,hasNotif = true) {
        let data = new FormData();
        data.append('id', id);
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        await axios.post(`${defaultVariabls.url}/file/remove`, data, config)
            .then(response => {
                if(response.status === 403){
                    this.props.enqueueSnackbar(translate('fileInUse'), {
                        variant: 'error',
                    });
                }else{
                    if(response.data.hasError){
                        this.props.enqueueSnackbar(response.data.message, {
                            variant: 'error',
                        });
                    }else{
                        if(hasNotif){
                            this.props.enqueueSnackbar(translate('deleteFile'), {
                                variant: 'success',
                            });
                        }
                    }
                }

                this.loadData();

                return true
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(translate('deleteFileFailed'), {
                    variant: 'error',
                });
                return false
            });
    }
    async onDeleteSelectedFiles(data){
        await data.map((row) =>(
            this.onDeleteFile(row.id,false)

        ));
        await this.loadData();
        await this.props.enqueueSnackbar(translate('deleteSelectedFiles'), {
            variant: 'success',
        });

    }
    onEditFile(file) {
        let data = new FormData();
        data.append('id', file.id);
        data.append('alt', file.alt);
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/file/edit`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(translate('editFile'), {
                    variant: 'success',
                });
                return true
            })
            .catch(error => {
                this.props.enqueueSnackbar(translate('editFileFailed'), {
                    variant: 'error',
                });
                return false
            });
    }
    handleChangeLoader(){
        this.setState({
            ChangeLoader:!this.state.ChangeLoader
        })
    }
    async handleChangeOwner(e) {
        this.setState({
            ChangeLoader:true
        })
        let target = e.target;
        let owner = target.value;
        await this.setState({
            owner
        });
        this.loadData();
    }
    async handleChangeType(e) {
        this.setState({
            ChangeLoader:true
        })
        let target = e.target;
        let type = target.value;
        await this.setState({
            type
        });
        this.loadData();
    }
    onSelected(data){
        data.map((row) =>(
            this.addImage(row)
        ));
    }
    render(){
        let {LinkDialog,links,fileType,type,owner,open_filter} = this.state;
        let {user,modal} = this.props;
        let loginToken = localStorage.getItem('ACT');
        const data = new FormData();
        data.append('type', type);
        data.append('owner', owner);
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        return (
            <Container maxWidth="xl">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div style={{display:"flex",alignItems:"center"}}>
                            <div className={this.classes.uploaderHolder} >
                            <AddFile
                                preview={this.props.preview}
                                ChangeLoader={this.handleChangeLoader.bind(this)}
                                upload={this.upload.bind(this)}
                                handlePreview={this.handlePreview.bind(this)}
                                type={this.props.fileType}
                                defined_width={this.props.defined_width}
                                defined_height={this.props.defined_height}
                            />
                            </div>
                            <div className={this.classes.uploaderHolder}>
                                <PlanRestriction rule="has_youtube">
                                    {
                                        ((type === "video" || type === "all") && !this.props.onlyFile) &&
                                            <AddFromYoutube Changed={this.Changed.bind(this)}/>
                                    }
                                </PlanRestriction>
                            </div>
                        </div>
                    </Grid>
                    {
                        !modal &&
                        <Role type="admin" role="admin">
                            <Grid item xs={12}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreRoundedIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <SubTitle noHR align="left" style={{marginBottom:0}}>
                                            <IconButton size="small">
                                                <FilterListRoundedIcon/>
                                            </IconButton>
                                            {translate('filters')}
                                        </SubTitle>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={2} alignItems="center" direction="row">
                                            <Grid item xs={12} md={3}>
                                                <TextField
                                                    select
                                                    variant="outlined"
                                                    fullWidth
                                                    color="secondary"
                                                    label={translate('chooseOwnerFile')}
                                                    value={owner}
                                                    onChange={this.handleChangeOwner.bind(this)}
                                                >
                                                    <MenuItem value='self'>{translate('selfFiles')}</MenuItem>
                                                    <MenuItem value='admins'>{translate('adminsFiles')}</MenuItem>
                                                    <MenuItem value='all'>{translate('everyBodyFiles')}</MenuItem>
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <TextField
                                                    select
                                                    fullWidth
                                                    color="secondary"
                                                    variant="outlined"
                                                    value={type}
                                                    label={translate('chooseFormat')}
                                                    onChange={this.handleChangeType.bind(this)}
                                                >
                                                    {
                                                        fileType === 'image' || fileType === 'all' || fileType === 'document' ? <MenuItem value='image' >{translate('image')}</MenuItem> :null
                                                    }
                                                    {
                                                        fileType === 'video' || fileType === 'all'  ? <MenuItem value='video'>{translate('video')}</MenuItem> :null
                                                    }
                                                    {
                                                        fileType === 'document' || fileType === 'all'  ? <MenuItem value='document'>{translate('document')}</MenuItem> :null
                                                    }
                                                    {
                                                        fileType === 'all'  ? <MenuItem value='all'>{translate('allTypes')}</MenuItem> :null
                                                    }
                                                </TextField>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Role>
                    }
                    <Grid item xs={12} className="file-manager-table">
                        <MaterialTable
                            tableRef={this.tableRef}
                            icons={tableIcons}
                            onRowClick={((evt, selectedRow) =>  this.addImage(selectedRow))}
                            localization={getDataTableLocalization()}
                            title={translate('savedFiles')}
                            columns={this.state.columns}
                            data={query =>
                                new Promise((resolve, reject) => {
                                        axios.post(`${defaultVariabls.url}/file/getAll?page=${query.page + 1}&per_page=${query.pageSize}&search=${query.search}&only_file=${!!this.props.onlyFile ? "yes" : 'no'}&width=${!!this.props.defined_width ? this.props.defined_width : ''}&height=${!!this.props.defined_height ? this.props.defined_height : ''}`, data, config)
                                            .then(response => {
                                                let data = [];
                                                response.data.data.map((row) =>(
                                                    data.push({
                                                        thumbnail: `${defaultVariabls.uploads}/FileManager/${row.name}_s_thumb.${row.extension}`,
                                                        source: `${defaultVariabls.uploads}/FileManager/${row.name}.${row.extension}`,
                                                        ImageName: truncate(row.name,20),
                                                        name: row.name,
                                                        extension: row.extension,
                                                        alt: row.alt,
                                                        type: row.type,
                                                        size: row.size,
                                                        preview: !!row.preview_url ? row.preview_url : !!row.preview_id ? `${defaultVariabls.uploads}/FileManager/${row.preview.name}_s_thumb.${row.preview.extension}` : null,
                                                        source_id: row.source_id,
                                                        owner: row.owner.first_name+" "+row.owner.last_name,
                                                        id: row.id,
                                                        created_at:row.updated_at
                                                    })
                                                ));
                                                resolve({
                                                    data: data,
                                                    page: response.data.current_page - 1,
                                                    totalCount: response.data.total,
                                                })

                                            }).catch(error => {
                                            console.log(error.response)
                                        });
                                    }
                                )
                            }
                            actions={(this.props.width === "sm" || this.props.width === "xs") || this.props.selection ? null : [
                                {
                                    tooltip: translate('deleteSelectedFiles'),
                                    icon: tableIcons.Delete,
                                    onClick: (evt, data) => this.onDeleteSelectedFiles(data)
                                },
                                this.props.multiInsert ? {
                                    tooltip: translate('multiImport'),
                                    icon: tableIcons.insert ,
                                    onClick: (evt, data) => this.onSelected(data)
                                } : null
                            ]}
                            options={{
                                selection:!(this.props.width === "sm" || this.props.width === "xs") && !this.props.selection,
                                pageSize: TablePage,
                                pageSizeOptions: TableCount,
                                sorting:false,
                                search:false,
                                draggable:false,
                            }}
                            editable={this.props.width === "sm" || this.props.width === "xs" ? null :
                                user.role.type === "admin" ?
                                    {
                                        onRowUpdate: (newData, oldData) =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve();
                                                    this.onEditFile(newData);
                                                    if (oldData) {
                                                        this.setState(prevState => {
                                                            const data = [...prevState.data];
                                                            data[data.indexOf(oldData)] = newData;
                                                            return { ...prevState, data };
                                                        });
                                                    }
                                                }, 600);
                                            }),
                                        onRowDelete: oldData =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve();
                                                    this.onDeleteFile(oldData.id);
                                                    this.setState(prevState => {
                                                        const data = [...prevState.data];
                                                        data.splice(data.indexOf(oldData), 1);
                                                        return { ...prevState, data };
                                                    });
                                                }, 600);
                                            }),
                                    } :
                                    {
                                        onRowDelete: oldData =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve();
                                                    this.onDeleteFile(oldData.id);
                                                    this.setState(prevState => {
                                                        const data = [...prevState.data];
                                                        data.splice(data.indexOf(oldData), 1);
                                                        return { ...prevState, data };
                                                    });
                                                }, 600);
                                            }),
                                    }
                            }
                        />
                    </Grid>

                </Grid>
                <Dialog onClose={this.handleCloseDialog.bind(this)}  open={LinkDialog}>
                    <DialogTitle>{translate('ChooseImageLink')}</DialogTitle>
                    {
                        LinkDialog ?
                            <List>
                                <ListItem button onClick={() => {this.handleClickLink(links[0])}}>
                                    <ListItemText style={{textAlign:"left"}} primary={links[0]} />
                                    <ListItemAvatar style={{direction:"ltr"}}>
                                        <Avatar src={links[0]} className={this.classes.small}/>
                                    </ListItemAvatar>
                                </ListItem>
                                <ListItem button onClick={() => {this.handleClickLink(links[1])}}>
                                    <ListItemText style={{textAlign:"left"}}  primary={links[1]} />
                                    <ListItemAvatar style={{direction:"ltr"}}>
                                        <Avatar src={links[1]}/>
                                    </ListItemAvatar>
                                </ListItem>
                                <ListItem  button onClick={() => {this.handleClickLink(links[2])}}>
                                    <ListItemText style={{textAlign:"left"}} primary={links[2]} />
                                    <ListItemAvatar style={{direction:"ltr"}}>
                                        <Avatar src={links[2]} className={this.classes.large}/>
                                    </ListItemAvatar>
                                </ListItem>
                            </List>
                            : null
                    }
                </Dialog>
            </Container>

        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    locale: state.locale
});
export default withWidth()(withSnackbar(withStyles(Styles)(connect(mstp)(FileManager))));
