import React from 'react';
import {XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,} from 'recharts';
import Line from "recharts/es6/cartesian/Line";
import LineChart from "recharts/es6/chart/LineChart";
import axios from "axios";
import {defaultVariabls} from "../../../config";
import {connect, setStore} from "trim-redux";
import moment from "moment-jalaali";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

class SearchChart extends  React.Component{
    state = {
        data :[],
        loading:true,
    }
    componentDidMount(){
        this.loadData();
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');

        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/analytics/past-week-visitors`, config)
                .then( async response => {
                    let data = [];
                    for(let i = 6 ; i >= 0 ; i--){
                        let time = moment(new Date() - i * 24 * 3600 * 1000).locale('en').locale('en').format('M/D');
                        await data.push({
                            name: time,
                            visits: parseInt(response.data[i])
                        })
                    }
                    await console.log(data)

                    await this.setState({
                        data,
                        loading:false
                    })
                }).catch(error => {
                    console.log(error)
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
                loading:false
            });
            this.setState({
                loading:false
            })
        }
    }
    render(){
        let {data,loading} = this.state;
        return (
            <React.Fragment>
                {
                    loading ?
                        <div style={{position:"relative"}}>
                        <CircularProgress
                            style={{position: "relative", top: 150, marginLeft: "50%"}}/>
                        </div>
                        :
                        <ResponsiveContainer>
                            <LineChart data={data}
                                       margin={{top: 40, right: 50, left: 10, bottom: 0}}>
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis dataKey="name"/>
                                <YAxis allowDecimals={false}/>
                                <Tooltip/>
                                <Legend/>
                                <Line type="monotone" stroke="#ffef06" dataKey="visits" strokeWidth={3}/>
                            </LineChart>
                        </ResponsiveContainer>
                }
            </React.Fragment>
        );
    }
}
const mstp = state => ({
    Translate: state.Translate,
    primary_color: state.primary_color,
});
export default connect(mstp)(SearchChart);
