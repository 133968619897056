import React from "react";
import {getStore} from "trim-redux";
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';

export default function ChevronNextRoundIcon() {
    const locale = getStore('locale');
    return (
        <>
        {
            locale === "ar" ?
                <ChevronRightRoundedIcon/> : <ChevronRightRoundedIcon/>
        }
        </>
    );
}