import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import validator from 'validator';
import axios from 'axios';
import { defaultVariabls } from '../../../config/index';
import {setStore, getStore, connect} from "trim-redux";
import {Link, withRouter} from 'react-router-dom';
import {withSnackbar} from "notistack";
import LoadingButton from "../../components/elements/LoadingButton";
import {secret} from "../../../config/Security";
import HeaderTags from "../../components/elements/HeaderTags";
import AuthLayout from "../../components/layouts/AuthLayout";
import Box from "@material-ui/core/Box/Box";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import FormControl from "@material-ui/core/FormControl/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText/FormHelperText";
import Button from "@material-ui/core/Button/Button";
import {translate} from "../../../config/lang";
import MuiPhoneNumber from "material-ui-phone-number";
import CountryChooser from "../../components/fields/CountryChooser";
import PassField from "../../components/fields/PassField";
class Register extends React.Component {
    constructor(props){
        super(props);
        if(getStore().isAuth){
            this.handleLogout()
        }
        this.state={
            fields: {
                first_name: '',
                last_name: '',
                email:'',
                mobile:!!this.props.match.params.mobile ? this.props.match.params.mobile : '',
                role:'user',
                password:'',
                password_confirmation: '',
                company_name: ''
            },
            user_id:this.props.user_id,
            errors:{},
            SubmitLoading:false,
            policy_confirmation:false,
            success:false,
            country:'',
            has_mobile:!!this.props.match.params.mobile
        };
    }
    componentDidMount(){
        this.setState({
            user_id:this.props.user_id,
        })
    }
    componentDidUpdate(PrevProps){
        if(this.state.user_id !== this.props.user_id){
            this.setState({
                user_id:this.props.user_id
            })
        }
    }
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        errors[target.name] = "";
        fields[target.name] = target.value;
        this.setState({fields});
    }
    handleLogout(){
        localStorage.removeItem('ACT');
        localStorage.removeItem('RET');
        setStore({
            isAuth : false,
            user : null,
        });
    }

    handleValidation(callback){
        let { fields ,policy_confirmation} = this.state;
        let errors = {};
        let formIsValid = true;

        //last_name
        if(validator.isEmpty(fields.last_name)){
            formIsValid = false;
            errors['last_name'] = translate('emptyField');
        }
        //first_name
        if(validator.isEmpty(fields.first_name)){
            formIsValid = false;
            errors['first_name'] = translate('emptyField');
        }
        //company_name
        if(validator.isEmpty(fields.company_name)){
            formIsValid = false;
            errors['company_name'] = translate('emptyField');
        }
        //policy_confirmation
        if(!policy_confirmation ) {
            formIsValid = false;
            errors['policy_confirmation'] = translate('pleaseCheckTheBox');
        }

        //Email
        if(validator.isEmpty(fields.email)){
            formIsValid = false;
            errors['email'] = translate('emptyEmail');
        }else if(!validator.isEmail(fields.email)){
            formIsValid = false;
            errors['email'] = translate('wrongEmail');
        }
        //mobile
        if(!validator.isNumeric(fields.mobile) || fields.mobile.length <= 9){
            formIsValid = false;
            errors['mobile'] = translate('wrongMobile');
            console.log(fields.mobile)
        }
        //Password
        if(validator.isEmpty(fields.password)){
            formIsValid = false;
            errors['password'] = translate('emptyPassword');
        }else if(fields.password.length < 4){
            formIsValid = false;
            errors['password'] = translate('weakPassword');
        }
        //Password_confirmation
        if(validator.isEmpty(fields.password_confirmation)){
            formIsValid = false;
            errors['password_confirmation'] = translate('emptyField');
        }else if (fields.password_confirmation !== fields.password){
            formIsValid = false;
            errors['password_confirmation'] = translate('noMatchPassword');
        }

        //check Email and Mobile
        let data = new FormData();
        data.append('email', fields.email);
        data.append('mobile', fields.mobile);
        const loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        const request = async () => {
            if(errors['email'] === undefined){
                await axios.post(`${defaultVariabls.url}/register/checkEmail`, data, config)
                    .then(response => {}).catch(error => {
                        console.log(error.response)
                        formIsValid = false;
                        errors['email'] = translate('existEmail');
                    });
            }
            if(errors['mobile'] === undefined) {
                await axios.post(`${defaultVariabls.url}/register/checkMobile`, data, config)
                    .then(response => {
                        if(response.data.errors.mobile !== undefined){
                            console.log(response.data.errors)
                            formIsValid = false;
                            errors['mobile'] = translate('existMobile');
                        }
                    }).catch(error => {
                    });
            }
            await this.setState({errors},() =>{
                if(!formIsValid){
                    this.setState({
                        SubmitLoading:false
                    })
                }
                return callback(formIsValid)
            });
        }
        request();
    }
    async handleRequest(){
        const {first_name,last_name,company_name,email,mobile,role,password,password_confirmation} = this.state.fields;
        let {user_id} = this.state;
        let data = new FormData();
        data.append('secret', secret);
        data.append('first_name', first_name);
        data.append('last_name', last_name);
        data.append('company_name', company_name);
        data.append('email', email);
        data.append('mobile', mobile);
        data.append('role', role);
        data.append('user_id', user_id);
        data.append('country_id', this.state.country.id);
        data.append('password', password);
        data.append('password_confirmation', password_confirmation);
        data.append('avatar', "2");
        let config = {
            headers: {
                Accept: 'application/json',
            }
        };
        await axios.post(`${defaultVariabls.url}/register`, data, config)
            .then(response =>{
                localStorage.setItem('ACT',response.data.accessToken);
            })
            .catch(error =>{
                console.log(error.response)
                this.setState({
                    SubmitLoading:false
                })
                this.props.enqueueSnackbar(translate('registerError'), {
                    variant: 'error',
                });
            });
        const loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/checkLogin`, config)
                .then(response => {
                    setStore({
                        isAuth : true,
                        user : response.data,
                        umc : response.data.umc,
                    });
                    this.setState({
                        SubmitLoading:false,
                        success:true
                    })

                }).catch(error => {
                setStore({
                    isAuth : false,
                    user : null,
                });
                this.setState({
                    SubmitLoading:false
                })
                this.props.enqueueSnackbar(translate('registerError'), {
                    variant: 'error',
                });
            })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
            this.setState({
                SubmitLoading:false
            })
        }
        if (this.props.isAuth){
            let code = this.props.user.login_token;
            let token = localStorage.getItem('ACT');
            let lang = localStorage.getItem('locale');
            let mode = localStorage.getItem('mode');
            if(mode === null){
                mode = "light";
            }
            let {iframe} = this.props;
            if(iframe){
                await window.parent.location.replace(`${defaultVariabls.website}/loginFromPanel/${this.props.user.email}/${token}/${code}/${lang}/${mode}`);
            }else{
                await window.location.replace(`${defaultVariabls.website}/loginFromPanel/${this.props.user.email}/${token}/${code}/${lang}/${mode}`);
            }
        }
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    handleChangeMobile(mobile){
        let {fields,errors} = this.state;
        let res = mobile.replace(/\s/g, '');
        res = res.replace('(','')
        res = res.replace(')','')
        res = res.replace('-','')
        fields.mobile = res
        errors.mobile = '';
        this.setState({
            fields,
            errors
        })
    }
    render() {
        const {classes,iframe} = this.props;
        const {errors,SubmitLoading,success,policy_confirmation,country,fields} = this.state;
        return (
            <AuthLayout iframe={iframe}>
                <HeaderTags title={translate('register')}/>
                <Grid item md={11} lg={11} >
                    <Box mb={{xs: 3, xl: 8}}  fontSize={20}>
                        <strong className={classes.auth_title}>
                            {translate('register')}
                        </strong>
                        <Typography className={classes.correctText} style={{whiteSpace:"pre"}}>
                            {translate('registerHint')}
                        </Typography>
                    </Box>
                    <form className={classes.form} noValidate onSubmit={this.handleSubmit.bind(this)}>
                        <Grid container spacing={2} justifyContent="center" alignItems='center'>
                            <Grid item xs={12}>
                                <RadioGroup aria-label="role" name="role" value={this.state.fields.role}
                                            onChange={this.handleChange.bind(this)}>
                                    <Grid container>
                                        <Grid item xs={6} style={{textAlign: "right"}}>
                                            <FormControlLabel
                                                value="user"
                                                control={<Radio/>}
                                                className={classes.correctText}
                                                label={translate('buyer')}
                                            />
                                        </Grid>
                                        <Grid item xs={6} style={{textAlign: "left"}}>
                                            <FormControlLabel
                                                value="supplier"
                                                control={<Radio/>}
                                                className={classes.correctText}
                                                label={<Typography>{translate('supplier')}</Typography>}
                                            />
                                        </Grid>
                                    </Grid>
                                </RadioGroup>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="first_name"
                                    variant="outlined"
                                    size="small"
                                    color="secondary"
                                    label={translate('first_name')}
                                    placeholder={translate('first_name')}
                                    name="first_name"
                                    onChange={this.handleChange.bind(this)}
                                    autoFocus
                                    error = {!!errors['first_name']}
                                    helperText={errors['first_name']}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    required
                                    fullWidth
                                    color="secondary"
                                    id="last_name"
                                    variant="outlined"
                                    label={translate('last_name')}
                                    placeholder={translate('last_name')}
                                    name="last_name"
                                    size="small"

                                    onChange={this.handleChange.bind(this)}
                                    error = {!!errors['last_name']}
                                    helperText={errors['last_name']}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    required
                                    fullWidth
                                    variant="outlined"
                                    id="email"
                                    color="secondary"
                                    label={translate('email')}
                                    name="email"
                                    dir="ltr"
                                    autoComplete="email"
                                    size="small"

                                    onChange={this.handleChange.bind(this)}
                                    error = {!!errors['email']}
                                    helperText={errors['email']}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    required
                                    fullWidth
                                    variant="outlined"
                                    label={translate('company_name')}
                                    name="company_name"
                                    size="small"
                                    color="secondary"
                                    onChange={this.handleChange.bind(this)}
                                    error = {!!errors['company_name']}
                                    helperText={errors['company_name']}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CountryChooser
                                    error={errors['country']}
                                    size="small"
                                    handleCountry={(country) => this.setState({country})}
                                    has_default={!this.state.has_mobile}
                                />
                            </Grid>
                            <Grid item xs={12}  md={6}>
                                <MuiPhoneNumber
                                    required
                                    fullWidth
                                    id="mobile"
                                    label={translate('mobile')}
                                    name="mobile"
                                    dir="ltr"
                                    size="small"
                                    variant="outlined"
                                    color="secondary"
                                    onChange={this.handleChangeMobile.bind(this)}
                                    autoFocus
                                    disableAreaCodes
                                    autoFormat
                                    value={fields.mobile}
                                    defaultCountry={!!this.state.has_mobile ? "" : !!country ? country.iso2.toLowerCase() : ''}
                                    error = {!!errors['mobile']}
                                    helperText={errors['mobile']}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <PassField
                                    required
                                    fullWidth
                                    variant="outlined"
                                    name="password"
                                    size="small"
                                    color="secondary"
                                    label={translate('password')}
                                    type="password"
                                    id="password"
                                    onChange={this.handleChange.bind(this)}
                                    error = {!!errors['password']}
                                    helperText={errors['password']}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <PassField
                                    required
                                    variant="outlined"
                                    fullWidth
                                    color="secondary"
                                    size="small"
                                    name="password_confirmation"
                                    label={translate('passwordConfirm')}
                                    placeholder={translate('passwordConfirm')}
                                    type="password"
                                    id="password_confirmation"
                                    onChange={this.handleChange.bind(this)}
                                    error = {!!errors['password_confirmation']}
                                    helperText={errors['password_confirmation']}
                                />
                            </Grid>

                                <Grid item xs={12} className={classes.textFloat}>
                                    <FormControl required error={!!errors['policy_confirmation']} component="fieldset">
                                        <FormControlLabel
                                            control={<Checkbox checked={policy_confirmation} onChange={() => this.setState({policy_confirmation:!policy_confirmation})} />}
                                            label={
                                                <Typography className={classes.privacy_policy}>
                                                    {translate('withRegistering')}
                                                    {" "}
                                                    <a target="_blank" href={`${defaultVariabls.website}/terms-and-conditions`} >
                                                        {translate('rules')}
                                                    </a>
                                                    {" "}
                                                    {translate('privacyPolicyConfirmation')}
                                                </Typography>
                                            }
                                        />
                                        {
                                            !!errors['policy_confirmation'] &&
                                            <FormHelperText>{errors['policy_confirmation']}</FormHelperText>

                                        }
                                    </FormControl>
                                </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                color="primary"
                                style={{width:"100%"}}
                                styleForParent={{marginBottom:0}}
                                onClick = {this.handleSubmit.bind(this)}
                                loading = {SubmitLoading}
                                success = {success}
                            >
                                {translate('register')}
                            </LoadingButton>
                        </Grid>
                        <Grid item xs={12} style={{marginTop:15}} className={classes.textFloat}>
                            <Typography className={classes.correctText}>
                                    {translate('haveAccount')}
                                <Button variant="outlined" color="secondary" size={"small"} component={Link}  to={iframe ? "/iframe/token-login" : "/token-login"}>
                                        {translate('doLogin')}
                                </Button>
                            </Typography>
                        </Grid>
                    </form>
                </Grid>
            </AuthLayout>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(Register))));