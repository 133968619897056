import {makeStyles} from '@material-ui/core/styles';
import {getStore} from "trim-redux";

const useStyles = makeStyles((theme) => {
    return {
        navItem: {
            height: 40,
            marginTop: 6,
            marginBottom: 2,
            width: '100%',
            paddingLeft: 24,
            paddingRight:24,

            [theme.breakpoints.up('xl')]: {
                paddingLeft: 24,
                paddingRight: 24,
            },

            '& .nav-item-text': {
                // fontSize: 16,
                color: getStore('mode') === "dark" ? "#ddd" : "#333333",
                [theme.breakpoints.up('xl')]: {
                    marginTop: 4,
                    marginBottom: 4,
                    // fontSize: 18,
                },
            },

            '& .nav-item-icon': {
                color: getStore('mode') === "dark" ? "#ddd" : "#333333",
                fontSize: 22,
                marginTop:7,
                [theme.breakpoints.up('xl')]: {
                    fontSize: 22,
                    marginTop:7
                },
            },

            '& .nav-item-icon-arrow': {
                color: getStore('mode') === "dark" ? "#ddd" : "#333333",
            },

            '& .MuiIconButton-root': {
                marginRight: -16,
            },

            '&.open, &:hover, &:focus': {
                '& .nav-item-text': {
                    color: (props) =>
                        props.themeMode === '#313541',
                },

                '& .nav-item-icon': {
                    color: (props) =>
                        props.themeMode === '#313541',
                },

                '& .nav-item-icon-arrow': {
                    color: (props) =>
                        props.themeMode === '#313541',
                },
            },
        },
        listItemText: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        listIcon: {
            fontSize: 18,
            [theme.breakpoints.up('xl')]: {
                 fontSize: 22,
                marginTop:7
            },
        },
    };
});
export default useStyles;
