import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button/Button";
import {Translate} from "../../../config/Translate";
import { withSnackbar } from 'notistack';
import withWidth from "@material-ui/core/withWidth/withWidth";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import {currencySymbol} from "../../../config/values";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import PaymentIcon from '@material-ui/icons/Payment';
import {defaultVariabls} from "../../../config";
import {connect} from "trim-redux";
import IndeterminateCheckBoxRoundedIcon from '@material-ui/icons/IndeterminateCheckBoxRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import {Link} from "react-router-dom";
import axios from "axios";
class WithdrawMoneyDialog extends React.Component {
    state = {
        open:false,
        error:'',
        amount:this.props.user.wallet.amount,
        amountR:this.props.user.wallet.amount,
        low_money: this.props.user.wallet.amount < 100000,
    };
    handleChange(e){
        let target = e.target;
        let amountR = target.value.replace(/,/ig, '');
        let error = "";
        this.setState({
            error,
            amountR,
            amount: target.value
        });
    }
    handleRequest(){
        const {amountR} = this.state;
        let data = new FormData();
        data.append('amount', amountR);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/wallet/withdraw`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.detailsSet, {
                    variant: 'success',
                });
                this.setState({
                    open:false,
                })
                this.props.reload();
            })
            .catch(error => {
                if(error.response.status === 403){
                    if(error.response.data === "has_pending_request"){
                        this.props.enqueueSnackbar(Translate.youHavePendingWithdrawRequest, {
                            variant: 'warning',
                        });
                    }
                }else {
                    console.log(error.response)
                    this.props.enqueueSnackbar(Translate.detailsSetFailed, {
                        variant: 'error',
                    });
                }
                this.setState({
                    SubmitLoading:false
                });

            });
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        if(this.state.amountR > 1000){
            this.handleRequest();
        }else{
            this.props.enqueueSnackbar(Translate.someFieldsHasError, {
                variant: 'warning',
            });

            this.setState({
                error: Translate.wrongPrice,
                SubmitLoading:false,
            })
        }
    }
    openModal(){
        let {low_money,open} = this.state;
        if(low_money){
            this.props.enqueueSnackbar(Translate.cantWithdrawSmallerThan100000, {
                variant: 'warning',
            });
        }else{
            this.setState({
                open:!open
            })
        }
    }
    classes = this.props.classes;
    render(){
        let {error,open,amountR,low_money} = this.state;
        let verified = this.props.user.verified;
        return(
            <span style={{marginLeft:10,marginRight:10}}>
                {
                    this.props.width !== "xs" ?
                        <Button
                            variant="outlined"
                            color="primary"
                            disabled={!verified}
                            startIcon={<IndeterminateCheckBoxRoundedIcon/>}
                            onClick={() => this.openModal()}
                        >
                            {Translate.withdrawMoney}
                        </Button>
                        :
                        <IconButton disabled={!verified} color="primary" onClick={() => this.setState({open: !this.state.open})}>
                            <IndeterminateCheckBoxRoundedIcon/>
                        </IconButton>
                }
                {
                    verified ? null :
                        <>
                            <Tooltip title={Translate.pleaseVerifyFirst} arrow>
                            <IconButton color="secondary" component={Link} to="/account/verification">
                                <WarningRoundedIcon/>
                            </IconButton>
                            </Tooltip>
                        </>
                }
                <Dialog fullWidth maxWidth="sm" open={open} onClose={() => this.setState({open: !this.state.open})} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{Translate.withdrawMoney}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {Translate.withdrawMoneyDescription}
                        </DialogContentText>
                        <CurrencyTextField
                            variant="outlined"
                            required
                            fullWidth
                            margin="normal"
                            currencySymbol={currencySymbol}
                            decimalPlaces={0}
                            value={amountR}
                            id="amount"
                            maximumValue={this.props.user.wallet.amount}
                            label={Translate.amount}
                            name="amount"
                            onChange={this.handleChange.bind(this)}
                            error = {!!error}
                            helperText={error}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleSubmit.bind(this)} variant="contained" color="primary" startIcon={<PaymentIcon/>}>
                            {Translate.withdrawRequest}
                        </Button>
                    </DialogActions>
                </Dialog>
            </span>
        )
    }
}
const mstp = state => ({
    user: state.user,
});
export default withWidth()(withSnackbar(withStyles(Styles)(connect(mstp)(WithdrawMoneyDialog))));