import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import validator from 'validator';
import axios from 'axios';
import { defaultVariabls } from '../../../../config/index';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import LoadingButton from "../../../components/elements/LoadingButton";
import {connect, getStore, setStore} from "trim-redux";
import AppCardLoaderFields from "../../../components/Skeletons/AppCardLoaderFields";
import {updateRedux} from "../../../../config/Security";
import Typography from "@material-ui/core/Typography/Typography";
import {translate} from "../../../../config/lang";
import MuiPhoneNumber from "material-ui-phone-number";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import IconButton from "@material-ui/core/IconButton/IconButton";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import FieldCounterWithoutBar from "../../../components/elements/FieldCounterWithoutBar";
import Alert from "@material-ui/lab/Alert/Alert";
import AppCard from "../../../components/layouts/components/AppCard";
import AppAnimate from "../../../components/AppAnimate";
import CountryChooser from "../../../components/fields/CountryChooser";
import StateChooser from "../../../components/fields/StateChooser";
import SelectLocationFromMap from "../../../components/map/SelectLocationFromMap";
import ParentCategoryChooser from "../../admin/categories/components/ParentCategoryChooser";
import moment from "moment";
import FormControl from "@material-ui/core/FormControl/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import SubTitleLight from "../../../components/elements/SubTitleLight";
import SingleImageChooser from "../../fileManager/Components/SingleImageChooser";
import AlertTitle from "@material-ui/lab/AlertTitle/AlertTitle";
import Button from "@material-ui/core/Button/Button";
import DocumentChooser from "../../../components/fields/DocumentChooser";
import UserDocumentsTable from "./UserDocumentsTable";
import AddTicketModal from "../../tickets/add_ticket/AddTicketModal";
class details extends React.Component {
    classes = this.props.classes
    state={
        fields: {
            mobile:'',
            first_name:'',
            last_name:'',
            company_name:'',
            business_bio:'',
            phone:'',
            brand_name:'',
            license_number:'',
            license_expiration_date:moment().add(3,'y').format('YYYY-MM-DD'),
            address:'',
            faults:'',
        },
        user_documents:[],
        lat:"",
        lng:"",
        category:null,
        category_id:null,
        country:null,
        state:null,
        server_country:null,
        server_state:null,
        avatar:null,
        logo:null,
        lock:true,
        trade_license:null,
        emirates_card:null,
        errors:{},
        loading:true,
        SubmitLoading:false,
        confirmed:'',
        is_verified: false,
        checked: false,
        is_discount_provider: false,
        mobile_confirmed:false,
        melli_card:null,

    };
    componentDidMount(){
        this.loadData();
    }
    loadData(){
        let loginToken = localStorage.getItem('ACT');
        let {manage,user_id} = this.props;
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.get(`${defaultVariabls.url}/user/get/${manage ? user_id : this.props.user.id}`, config)
            .then(response => {
                this.setState({
                    fields:{
                        name: response.data.name,
                        first_name: response.data.first_name,
                        last_name: response.data.last_name,
                        email:response.data.email,
                        mobile:response.data.mobile,
                        company_name:!!response.data.company_name ? response.data.company_name : '',
                        business_bio:!!response.data.business_bio ? response.data.business_bio : '',
                        phone:!!response.data.phone ? response.data.phone : '',
                        brand_name:!!response.data.brand_name ? response.data.brand_name : '',
                        license_number:!!response.data.license_number ? response.data.license_number : '',
                        license_expiration_date:!!response.data.license_expiration_date ? response.data.license_expiration_date : moment().add(3,'y').format('YYYY-MM-DD'),
                        address:!!response.data.address ? response.data.address : '',
                        faults:!!response.data.faults ? response.data.faults : '',
                    },
                    user_documents: response.data.user_documents,
                    mobile_confirmed: parseInt(response.data.mobile_confirmed),
                    loading:false,
                    logo: response.data.logo,
                    emirates_card: response.data.emirates_card,
                    trade_license: response.data.trade_license,
                    is_discount_provider: response.data.is_discount_provider,
                    is_verified: response.data.is_verified,
                    lat:!!response.data.lat ? parseFloat(response.data.lat) : '',
                    lng:!!response.data.lng ? parseFloat(response.data.lng) : '',
                    country:!!response.data.country ? response.data.country : null,
                    state:!!response.data.state ? response.data.state : null,
                    server_country:!!response.data.country ? response.data.country : null,
                    server_state:!!response.data.state ? response.data.state : null,
                    category:!!response.data.category ? response.data.category : null,
                    category_id:!!response.data.category ? response.data.category.id : null,
                })
            })
            .catch(error => {
                this.setState({
                    loading:false
                })
            });
    }
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        errors[target.name] = "";
        fields[target.name] = target.value;
        this.setState({
            fields,
        });
    }
    handleValidation(callback){
        let { fields,country,state,lat,category,checked,trade_license,emirates_card,logo } = this.state;
        let {user} = this.props;
        let errors = {};
        let formIsValid = true;
        if(!trade_license){
            formIsValid = false;
            errors['trade_license'] = translate('pleaseChooseAFile');
        }
        if(!emirates_card){
            formIsValid = false;
            errors['emirates_card'] = translate('pleaseChooseAFile');
        }
        if(!logo){
            formIsValid = false;
            errors['logo'] = translate('pleaseChooseAFile');
        }
        //first_name
        if(validator.isEmpty(fields.first_name)){
            formIsValid = false;
            errors['first_name'] = translate('emptyField');
        }else if(!validator.isByteLength(fields.first_name,{min:0, max:255})){
            formIsValid = false;
            errors['first_name'] = translate('maxLength255');
        }
        //last_name
        if(validator.isEmpty(fields.last_name)){
            formIsValid = false;
            errors['last_name'] = translate('emptyField');
        }else if(!validator.isByteLength(fields.last_name,{min:0, max:255})){
            formIsValid = false;
            errors['last_name'] = translate('maxLength255');
        }
        if(!fields.brand_name){
            formIsValid = false;
            errors['brand_name'] = translate('emptyField');
        }
        if(!fields.company_name){
            formIsValid = false;
            errors['company_name'] = translate('emptyField');
        }
        if(!fields.address){
            formIsValid = false;
            errors['address'] = translate('emptyField');
        }
        if(!fields.license_number){
            formIsValid = false;
            errors['license_number'] = translate('emptyField');
        }
        if(!fields.license_expiration_date){
            formIsValid = false;
            errors['license_expiration_date'] = translate('emptyField');
        }
        if(!fields.phone){
            formIsValid = false;
            errors['phone'] = translate('emptyField');
        }
        if(!country){
            formIsValid = false;
            errors['country'] = translate('emptyField');
        }
        if(!state){
            formIsValid = false;
            errors['state'] = translate('emptyField');
        }
        if(!lat){
            formIsValid = false;
            errors['geocode'] = translate('pleaseChooseYourLocation');
        }
        if(!category){
            formIsValid = false;
            errors['category'] = translate('emptyField');
        }
        if(!checked){
            if(!fields.faults){
                formIsValid = false;
                errors['faults'] = translate('emptyField');
            }
        }
        if(!formIsValid){
            this.props.enqueueSnackbar(translate('someFieldsHasError'), {
                variant: 'warning',
            });
            this.setState({
                SubmitLoading:false,
                errors
            })
            console.log(errors);
        }
        return callback(formIsValid)
    }
    handleRequest(){
        const {fields,confirmed,mobile_confirmed,lock,is_verified,is_discount_provider,logo,trade_license,emirates_card,lat,lng,country,state,category} = this.state;
        let data = new FormData();
        data.append('first_name', fields.first_name);
        data.append('last_name', fields.last_name);
        data.append('company_name', fields.company_name);
        data.append('business_bio', fields.business_bio);
        data.append('brand_name', fields.brand_name);
        data.append('address', fields.address);
        data.append('phone', fields.phone);
        data.append('faults', fields.faults);
        data.append('license_number', fields.license_number);
        data.append('license_expiration_date', fields.license_expiration_date);
        data.append('is_discount_provider',is_discount_provider ? 1 : 0);
        data.append('is_verified',is_verified ? 1 : 0);
        data.append('logo', !!logo ? logo.id : 1);
        data.append('emirates_card', !!emirates_card ? emirates_card.id : '');
        data.append('trade_license', !!trade_license ? trade_license.id : '');
        data.append('confirmed', confirmed);
        data.append('mobile_confirmed', !!mobile_confirmed ?  1 : 0);
        data.append('lat', lat);
        data.append('lng', lng);
        data.append('country_id', !!country ? country.id : '');
        data.append('state_id', !!state ? state.id : '' );
        data.append('category_id', !!category ? category.id : '' );
        data.append('status', !!this.state.checked ? "confirmed" : "failed" );
        if(!lock){
            data.append('mobile', fields.mobile);
            data.append('email', fields.email);
        }
        let loginToken = localStorage.getItem('ACT');
        let {manage,user_id} = this.props;
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/user_business/set/${manage ? user_id : this.props.user.id}`, data, config)
            .then(async response => {
                this.props.enqueueSnackbar(translate('detailsSet'), {
                    variant: 'success',
                });
                this.setState({
                    SubmitLoading:false
                });
                await  updateRedux();
                await this.props.reload();
                await this.loadData();
            })
            .catch(error => {
                console.log(error.response);
                if(error.response.status === 422){
                    this.props.enqueueSnackbar(translate('anotherUserHasThisInfo'), {
                        variant: 'error',
                    });
                    this.setState({
                        loading:true,
                    })
                    this.loadData();
                }else{
                    this.props.enqueueSnackbar(translate('detailsSetFailed'), {
                        variant: 'error',
                    });
                }
                this.setState({
                    SubmitLoading:false
                });
            });
    }
    refreshStore(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            axios.get(`${defaultVariabls.url}/checkLogin`, config)
                .then(response => {
                    setStore({
                        isAuth : true,
                        user : response.data,
                    });

                }).catch(error => {
                setStore({
                    isAuth : false,
                    user : null,
                });
            })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        });
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    handleChangeConfirmed(){
        this.setState({
            confirmed: this.state.confirmed === "confirmed" ? "pending" : "confirmed",
        })
    }
    loginToUserAccount(){
        let {mobile_confirmed} = this.state;
        if(!!mobile_confirmed){
            this.setState({
                loading:true
            })
            let loginToken = localStorage.getItem('ACT');
            let {user_id} = this.props;
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            axios.get(`${defaultVariabls.url}/loginToUserAccount/${user_id}`, config)
                .then(async response => {
                    await localStorage.setItem('ADMIN_ACT',localStorage.getItem('ACT'));
                    await localStorage.setItem('ACT',response.data.accessToken);
                    await updateRedux();
                    let user = await getStore('user')
                    let username = user.email;
                    let login_token = user.login_token;
                    await window.location.replace(`${defaultVariabls.website}/loginFromPanel/${username}/${login_token}`);
                })
                .catch(error => {
                    this.setState({
                        loading:false
                    })
                });
        }else{
            this.props.enqueueSnackbar(translate('userShouldConfirmMobileFirst'), {
                variant: 'error',
            });
        }

    }
    handleChangeMobile(mobile){
        let {fields,errors} = this.state;
        let res = mobile.replace(/\s/g, '');
        res = res.replace('(','')
        res = res.replace(')','')
        res = res.replace('-','')
        fields.mobile = res
        errors.mobile = '';
        this.setState({
            fields,
            errors
        })
    }
    handleChangePhone(phone){
        let {fields,errors} = this.state;
        fields.phone = phone;
        errors.phone = '';
        this.setState({
            fields,
            errors
        })
    }
    handleLogoImage(logo){
        this.setState({
            logo
        })
    }
    handleLogoEmiratesCard(emirates_card){
        this.setState({
            emirates_card
        })
    }
    handleTradeLicense(trade_license){
        this.setState({
            trade_license
        })
    }
    handleChangeDiscountProvider(){
        this.setState({
            is_discount_provider: !this.state.is_discount_provider,
        })
    }
    handleChangeVerified(){
        this.setState({
            is_verified: !this.state.is_verified,
        })
    }
    handleChangeCat(category){
        this.setState({
            category
        })
    }
    handleAddress(address){
        let {fields} = this.state;
        fields['address'] = address;
        this.setState({
            fields
        })

    }
    render() {
        const {errors,fields,lock,user_documents,server_country,server_state,country,lat,lng,loading,checked,logo,trade_license,emirates_card} = this.state;
        let {purchase,user,manage,user_id,primary_color} = this.props;
        return (
            <>
                {
                    user.step === 6 ?
                        <AppCard title={translate('registerInfo')}>
                            {
                                loading ? <AppCardLoaderFields/> :
                                    <Grid container spacing={2} justifyContent="center">
                                        <Grid item xs={12} md={11} lg={10}>
                                            <Grid container  spacing={2} justifyContent="center" alignItems="flex-start">
                                                <CssBaseline/>
                                                <Grid item xs={12}>
                                                    <Alert
                                                        severity="info"
                                                        action={
                                                            <AddTicketModal reload={() => console.log('reload')}/>
                                                        }
                                                    >
                                                        <AlertTitle>{translate('viewRegisterInfo')}</AlertTitle>
                                                        {translate('viewRegisterInfoHint')}
                                                    </Alert>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} md={6}>
                                                            <MuiPhoneNumber
                                                                required
                                                                fullWidth
                                                                id="phone"
                                                                disabled
                                                                label={translate('phone')}
                                                                name="phone"
                                                                onChange={this.handleChangePhone.bind(this)}
                                                                variant="outlined"
                                                                value={fields.phone}
                                                                disableAreaCodes
                                                                defaultCountry={'ae'}
                                                                error = {!!errors['phone']}
                                                                helperText={errors['phone']}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                required
                                                                variant="outlined"
                                                                value={fields.company_name}
                                                                fullWidth
                                                                disabled
                                                                label={translate('companyName')}
                                                                name="company_name"
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['company_name']}
                                                                helperText={errors['company_name']}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                variant="outlined"
                                                                value={fields.license_number}
                                                                fullWidth
                                                                disabled
                                                                required
                                                                label={translate('license_number')}
                                                                name="license_number"
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['license_number']}
                                                                helperText={errors['license_number']}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                fullWidth
                                                                required
                                                                name="license_expiration_date"
                                                                variant="outlined"
                                                                label={translate('license_expiration_date')}
                                                                type="date"
                                                                onChange={this.handleChange.bind(this)}
                                                                value={fields.license_expiration_date}
                                                                disabled
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                error={!!errors['license_expiration_date']}
                                                                helperText={errors['license_expiration_date']}
                                                            />
                                                        </Grid>
                                                        {
                                                            (!!server_country && !!server_state) ?
                                                                <>
                                                                    <Grid item xs={12}>
                                                                        <Typography>
                                                                            {server_country.name} / {server_state.name}
                                                                        </Typography>
                                                                    </Grid>
                                                                </>
                                                                :
                                                                <>
                                                                    <Grid item xs={12} md={6}>
                                                                        <CountryChooser
                                                                            required
                                                                            county_id={1}
                                                                            disabled
                                                                            error={errors['country']}
                                                                            handleCountry={(country) => this.setState({country})}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} md={6}>
                                                                        <StateChooser
                                                                            required
                                                                            disabled
                                                                            country={country}
                                                                            error={errors['state']}
                                                                            handleState={(state) => this.setState({state})}
                                                                        />
                                                                    </Grid>
                                                                </>
                                                        }
                                                        <Grid item xs={12}>
                                                            <SelectLocationFromMap
                                                                disabled
                                                                init_geocode={(!!lat && !!lng) ? {lat:lat,lng:lng} : ""}
                                                                geocode={(lat,lng) => this.setState({lat,lng})}
                                                                setAddress={(address) => this.handleAddress(address)}
                                                                country={country}
                                                                state={this.state.state}
                                                            />
                                                            {
                                                                !!errors["geocode"] &&
                                                                <Alert severity="error">
                                                                    <Typography className={this.classes.alertText}>
                                                                        {errors["geocode"]}
                                                                    </Typography>
                                                                </Alert>
                                                            }
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                disabled
                                                                variant="outlined"
                                                                multiline
                                                                fullWidth
                                                                name="address"
                                                                label={translate('address')}
                                                                type="text"
                                                                minRows="2"
                                                                value={fields.address}
                                                                onChange={this.handleChange.bind(this)}
                                                                error={!!errors['address']}
                                                                helperText={errors['address']}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <FieldCounterWithoutBar length={fields.address.length} max={150}/>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid item xs={12}>
                                                                <UserDocumentsTable documents={user_documents} reload={() => this.loadData()}/>
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                            }
                        </AppCard>
                        :
                        <AppCard title={translate('registerInfo')}>

                        </AppCard>
                }

            </>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_color: state.primary_color
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(details))));