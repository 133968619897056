import React from 'react';
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import {getStore, setStore} from "trim-redux";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../../config/design/styles";
import SubTitle from "../../../../components/elements/SubTitle";
import TrendingUpRoundedIcon from '@material-ui/icons/TrendingUpRounded';
import TrendingDownRoundedIcon from '@material-ui/icons/TrendingDownRounded';
import axios from "axios";
import {defaultVariabls} from "../../../../../config/index";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import TrendingFlatRoundedIcon from '@material-ui/icons/TrendingFlatRounded';
import {translate} from "../../../../../config/lang";
class TodayAnalytics extends React.Component {
    state = {
        products: null,
        yesterdayProducts: null,
        rfqs: null,
        yesterdayRFQs: null,
        users: null,
        yesterdayUsers: null,
        visitors: null,
        yesterdayVisitors: null,
    };
    componentDidMount(){
        this.loadData();
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');

        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/analytics/today-analytics`, config)
                .then(response => {
                    this.setState({
                        products: response.data.products,
                        yesterdayProducts: response.data.yesterdayProducts,
                        rfqs: response.data.rfqs,
                        yesterdayRFQs: response.data.yesterdayRFQs,
                        users: response.data.users,
                        yesterdayUsers: response.data.yesterdayUsers,
                        visitors: response.data.visitors,
                        yesterdayVisitors:response.data.yesterdayVisitors,
                    })
                }).catch(error => {
                    console.log(error.response)
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
                loading:false
            });
            this.setState({
                loading:false
            })
        }
    }
    classes = this.props.classes;
    render(){
        let {products,yesterdayProducts,rfqs,yesterdayRFQs,users,yesterdayUsers,visitors,yesterdayVisitors} = this.state;
        let mode = getStore('mode');
        return (
            <Grid container spacing={2} direction="row" justify="center" alignItems="center">
                <Grid item xs={6} md={3}>
                    <Grid container  style={mode === "light" ? {backgroundColor:"#fff"} : {backgroundColor:"#333"} } direction="row" alignItems="center" justify="center" className={this.classes.GridAsaPaperBaby}>
                        <Grid item xs={11}>
                        <SubTitle color="secondary" align="left">{translate('newProducts')}</SubTitle>
                        </Grid>
                        <Grid item xs={5}>
                        <Typography className={this.classes.AnalyticsResult} >{products === null ? <CircularProgress size={25}/> : products}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            {
                                products === null ? null :
                                yesterdayProducts > products ?
                                    <span className={this.classes.ErrorAnalytic}><TrendingDownRoundedIcon/> <span>-{yesterdayProducts-products}</span> </span>
                                :
                                yesterdayProducts === products ?
                                    <span className={this.classes.FlatAnalytic}><TrendingFlatRoundedIcon/> <span>{products-yesterdayProducts}</span> </span>
                                :
                                    <span className={this.classes.SuccessAnalytic}><TrendingUpRoundedIcon/> +{products-yesterdayProducts} </span>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6} md={3}>
                    <Grid container  style={mode === "light" ? {backgroundColor:"#fff"} : {backgroundColor:"#333"} } direction="row" alignItems="center" justify="center" className={this.classes.GridAsaPaperBaby}>
                        <Grid item xs={11}>
                            <SubTitle color="secondary" align="left">{translate('todayRFQs')}</SubTitle>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography className={this.classes.AnalyticsResult} >{rfqs === null ? <CircularProgress size={25}/> : rfqs}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            {
                                rfqs === null ? null :
                                    yesterdayRFQs > rfqs ?
                                        <span className={this.classes.ErrorAnalytic}><TrendingDownRoundedIcon/> <span>-{yesterdayRFQs-rfqs}</span> </span>
                                        :
                                        yesterdayRFQs === rfqs ?
                                            <span className={this.classes.FlatAnalytic}><TrendingFlatRoundedIcon/> <span>{rfqs-yesterdayRFQs}</span> </span>
                                            :
                                            <span className={this.classes.SuccessAnalytic}><TrendingUpRoundedIcon/> +{rfqs-yesterdayRFQs} </span>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6} md={3}>
                    <Grid container   style={mode === "light" ? {backgroundColor:"#fff"} : {backgroundColor:"#333"} } direction="row" alignItems="center" justify="center" className={this.classes.GridAsaPaperBaby}>
                        <Grid item xs={11}>
                            <SubTitle color="secondary" align="left">{translate('newRegistrations')}</SubTitle>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography className={this.classes.AnalyticsResult} >{users === null ? <CircularProgress size={25}/> : users}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            {
                                users === null ? null :
                                    yesterdayUsers > users ?
                                        <span className={this.classes.ErrorAnalytic}><TrendingDownRoundedIcon/> <span>-{yesterdayUsers-users}</span> </span>
                                        :
                                        yesterdayUsers === users ?
                                            <span className={this.classes.FlatAnalytic}><TrendingFlatRoundedIcon/> <span>{users-yesterdayUsers}</span> </span>
                                            :
                                            <span className={this.classes.SuccessAnalytic}><TrendingUpRoundedIcon/> +{users-yesterdayUsers} </span>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6} md={3}>
                    <Grid style={mode === "light" ? {backgroundColor:"#fff"} : {backgroundColor:"#333"} } container   direction="row" alignItems="center" justify="center" className={this.classes.GridAsaPaperBaby}>
                        <Grid item xs={11}>
                            <SubTitle color="secondary" align="left">{translate('todayAllUnRegisteredUsers')}</SubTitle>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography className={this.classes.AnalyticsResult} >{visitors === null ? <CircularProgress size={25}/> : visitors}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            {
                                visitors === null ? null :
                                    yesterdayVisitors > visitors ?
                                        <span className={this.classes.ErrorAnalytic}><TrendingDownRoundedIcon/> <span>-{yesterdayVisitors-visitors}</span> </span>
                                        :
                                        yesterdayVisitors === visitors ?
                                            <span className={this.classes.FlatAnalytic}><TrendingFlatRoundedIcon/> <span>{visitors-yesterdayVisitors}</span> </span>
                                            :
                                            <span className={this.classes.SuccessAnalytic}><TrendingUpRoundedIcon/> +{visitors-yesterdayVisitors} </span>
                            }
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        );
    }
}

export default withStyles(Styles)(TodayAnalytics);