import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Translate} from "../../../../config/Translate";
import {defaultVariabls} from "../../../../config";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Typography from "@material-ui/core/Typography/Typography";
import Divider from "@material-ui/core/Divider/Divider";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import {theme} from "../../../../config/design/theme";
import ReactPlayer from 'react-player'
import Grid from "@material-ui/core/Grid/Grid";
import SubTitle from "../../../components/elements/SubTitle";

class ReferencesListButton extends React.Component{
    state = {
        open: false,
        loading:false,
        plan: this.props.plan,
    };
    async toggleOpen(){
        await this.setState({
            open: !this.state.open
        })
        if(this.state.open === true){
            await this.setState({
                loading:true
            })
        }
    }
    render(){
        let {open,plan} = this.state;
        return (
            <>
                <Button
                    onClick={() => this.toggleOpen()}
                    variant="contained"
                    size="small"
                    style={{marginTop:20,backgroundColor:"#4d8c25",color:"#fff"}}
                >
                    {Translate.viewPlan}
                </Button>
                <Dialog
                    open={open}
                    onClose={() => this.toggleOpen()}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    maxWidth="md"
                >
                    <DialogTitle id="form-dialog-title">
                        {plan.name}
                        <IconButton
                            onClick={() => this.toggleOpen()}
                            style={{position:"absolute",left:15,top:15}}
                            size="small"
                        >
                            <CloseRoundedIcon fontSize="small"/>
                        </IconButton>
                        </DialogTitle>
                    <Divider/>
                    <DialogContent>
                        <Grid container spacing={2} alignItems="center" justify="center">
                            <Grid item xs={12} md={6}>
                                {
                                    plan.file.type==="image" ?
                                        <div style={{textAlign:"center",padding:30,paddingLeft:15}}>
                                            <img
                                                style={{maxWidth:"100%",borderRadius:5}}
                                                src={`${defaultVariabls.uploads}/FileManager/${plan.file.name}.${plan.file.extension}`}
                                            />
                                        </div>
                                        :
                                        plan.file.type==="video" ?
                                            <div style={{textAlign:"center",padding:30,paddingLeft:15}}>
                                                <ReactPlayer
                                                    controls
                                                    width="100%"
                                                    height="unset"
                                                    url={`${defaultVariabls.uploads}/FileManager/${plan.file.name}.${plan.file.extension}`}
                                                />
                                            </div>
                                            : null
                                }

                            </Grid>
                            <Grid item xs={12} md={6}>
                                <DialogContentText style={{padding:30,paddingRight:15}}>
                                    <SubTitle align="left"> {Translate.planDescription}</SubTitle>
                                    <Typography style={{marginTop:10,textAlign: "justify"}}>
                                        {plan.description}
                                    </Typography>
                                    <Typography
                                        className="plan-price"
                                    >
                                        {Translate.price}:
                                        <span style={{color:theme.palette.primary.color}}>{plan.price} {Translate.currency}</span>
                                    </Typography>
                                </DialogContentText>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </>
        );
    }
}
export default ReferencesListButton;