import React from 'react';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import {getDataTableLocalization} from "../../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../../config/index";
import MaterialTable from "material-table";
import {tableIcons} from "../../../../../config/design/TableIcons";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import {TableCount, TablePage} from "../../../../../config/Permissions";
import AppCardLoaderTable from "../../../../components/Skeletons/AppCardLoaderTable";
import Container from "@material-ui/core/Container/Container";
import {translate} from "../../../../../config/lang";
import OrderCategoriesButton from "../../categories/components/OrderCategoriesButton";
import OrderBusinessTypesButton from "./OrderBusinessTypesButton";


class ManageBusinessTypes extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    fixedHeightPaper = clsx(this.classes.paper, this.classes.fixedHeight);
    state = {
        columns: [
            { title: translate('uniqueKey'), field: 'unique_key',},
            { title: translate('business_type_name'), field: 'name'},
            { title: translate('business_arabic_type_name'), field: 'arabic_name'},
        ],
        data: [],
        loading:false,
    };
    componentDidMount(){
        this.loadData();
    }
    loadData(){
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }
    onAdd(result) {
        let data = new FormData();
        data.append('unique_key', result.unique_key);
        data.append('name', result.name);
        data.append('arabic_name', result.arabic_name);
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/business_type/add`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(translate('allChangesSaved'), {
                    variant: 'success',
                });
                this.loadData();
                return true

            })
            .catch(error => {
                this.props.enqueueSnackbar(translate('allChangesSavingFailed'), {
                    variant: 'error',
                });
                this.loadData();
                return false
            });
    }
    onEdit(result) {
        let data = new FormData();
        data.append('id', result.id);
        data.append('name', result.name);
        data.append('arabic_name', result.arabic_name);
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/business_type/edit`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(translate('allChangesSaved'), {
                    variant: 'success',
                });
                return true
            })
            .catch(error => {
                this.props.enqueueSnackbar(translate('allChangesSavingFailed'), {
                    variant: 'error',
                });
                this.loadData();
                return false
            });
    }
    onDelete(id,hasNotif = true) {
        let data = new FormData();
        data.append('id', id);
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/business_type/delete`, data, config)
            .then(response => {
                if(hasNotif){
                    this.props.enqueueSnackbar(translate('allChangesSaved'), {
                        variant: 'success',
                    });
                }
                return true
            })
            .catch(error => {
                this.props.enqueueSnackbar(translate('allChangesSavingFailed'), {
                    variant: 'error',
                });
                this.loadData();

                return false
            });
    }
    render(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        return (
            <>
                {
                    this.state.loading ? <AppCardLoaderTable/> :
                        <Container maxWidth="xl">
                            <MaterialTable
                                tableRef={this.tableRef}
                                icons={tableIcons}
                                title={
                                <>
                                    <OrderBusinessTypesButton reload={this.loadData.bind(this)}/>
                                    {translate('manageBusinessTypes')}
                                </>
                            }
                                localization={getDataTableLocalization()}
                                columns={this.state.columns}
                                data={query =>
                                    new Promise((resolve, reject) => {
                                        axios.get(`${defaultVariabls.url}/business_types/quickView?page=${query.page + 1}&per_page=${query.pageSize}&search=${query.search}`, config)
                                            .then(response => {
                                                let data = [];
                                                // eslint-disable-next-line
                                                response.data.data.map((row) => {
                                                    data.push({
                                                        id:row.id,
                                                        unique_key:row.unique_key,
                                                        name:row.name,
                                                        arabic_name:row.arabic_name,
                                                        created_at:row.created_at,
                                                    })
                                                });
                                                resolve({
                                                    data: data,
                                                    page: response.data.current_page - 1,
                                                    totalCount: response.data.total,
                                                })
                                            }).catch(error => {
                                            console.log(error.response)
                                        })
                                    })
                                }
                                options={{
                                    pageSize: TablePage,
                                    pageSizeOptions: TableCount,
                                    sorting:false,
                                    draggable:false,
                                }}
                                editable={{
                                    onRowAdd: newData =>
                                        new Promise((resolve, reject) => {
                                            setTimeout(() => {
                                                this.onAdd(newData);
                                                resolve();
                                            }, 1000);
                                        }),
                                    onRowUpdate: async (newData, oldData) =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                this.onEdit(newData);
                                                if (oldData) {
                                                    this.setState(prevState => {
                                                        const data = [...prevState.data];
                                                        data[data.indexOf(oldData)] = newData;
                                                        return {...prevState, data};
                                                    });
                                                }
                                            }, 600);
                                        }),
                                    onRowDelete: oldData =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                this.onDelete(oldData.id);
                                                this.setState(prevState => {
                                                    const data = [...prevState.data];
                                                    data.splice(data.indexOf(oldData), 1);
                                                    return {...prevState, data};
                                                });
                                            }, 600);
                                        }),
                                }}

                            />
                        </Container>
                }
            </>
        );
    }
}
export default withSnackbar(withRouter(withStyles(Styles)(ManageBusinessTypes)));