import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {connect} from "trim-redux";
import {translate} from "../../../../config/lang";
import AppCard from "../../../components/layouts/components/AppCard";
import AppAnimate from "../../../components/AppAnimate";
import Typography from "@material-ui/core/Typography";
import Title from "../../../components/elements/Title";
import LoadingButton from "../../../components/elements/LoadingButton";
import axios from "axios";
import {defaultVariabls} from "../../../../config";
import AppCardLoaderFields from "../../../components/Skeletons/AppCardLoaderFields";
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import {updateRedux} from "../../../../config/Security";
class details extends React.Component {
    classes = this.props.classes
    state={
        loading:true,
        SubmitLoading:false,
        user: null,
    };
    componentDidMount() {
        this.loadData();
    }
    loadData(){
        this.setState({
            loading:true
        })
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
        let {user,manage} = this.props;
        let user_id = null;
        if(!!manage){
            let id = this.props.match.params.id
            let loginToken = localStorage.getItem('ACT');
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            axios.get(`${defaultVariabls.url}/user/get/${id}`, config)
                .then(response => {
                    this.setState({
                        user: response.data,
                        loading:false,
                    })
                })
                .catch(error => {
                    this.setState({
                        loading:false
                    })
                });
        }else{
            this.setState({
                user:user,
                loading:false,
            })
        }
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        });
        e.preventDefault();
        let id = this.state.user.id;
        let step = 2;
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.get(`${defaultVariabls.url}/user/change_step/${id}/${step}`, config)
            .then(async response => {
                await updateRedux();
                await this.props.reload();
            })
            .catch(error => {
                this.setState({
                    loading:false,
                    SubmitLoading:false,
                })
            });
    }
    render() {
        let {user,SubmitLoading,loading} = this.state;
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={translate('step5')}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} lg={11}>
                        <AppCard>
                            {
                                loading ?
                                    <AppCardLoaderFields/>
                                    :
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <div className="waiting-container">
                                                <Title color="secondary">{translate('yourInfoIsChecked')}</Title>
                                                <Typography>{translate('pleaseReadAndDo')}</Typography>
                                                <Typography className={"faults"}>
                                                    {user.faults}
                                                </Typography>
                                                <LoadingButton
                                                    variant="outlined"
                                                    color="secondary"
                                                    onClick = {this.handleSubmit.bind(this)}
                                                    loading = {SubmitLoading}
                                                    startIcon={<EditRoundedIcon/>}
                                                >
                                                    {translate('editInfo')}
                                                </LoadingButton>
                                            </div>
                                        </Grid>
                                    </Grid>
                            }
                        </AppCard>
                    </Grid>
                </Grid>
            </AppAnimate>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_color: state.primary_color
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(details))));