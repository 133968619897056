import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import Loader from "../../../components/elements/Loader";
import TextField from "@material-ui/core/TextField/TextField";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {connect} from "trim-redux";
import { withSnackbar } from 'notistack';
import SubTitle from "../../../components/elements/SubTitle";
import LoadingButton from "../../../components/elements/LoadingButton";
import BreadCrumbs from "../../../components/elements/BreadCrumbs";
import Title from "../../../components/elements/Title";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import Permission from "../../../components/elements/Permission";
import Rating from "@material-ui/lab/Rating/Rating";
import Typography from "@material-ui/core/Typography/Typography";
import HeaderTags from "../../../components/elements/HeaderTags";
import withWidth from "@material-ui/core/withWidth/withWidth";
import ChatBubbleOutlineRoundedIcon from '@material-ui/icons/ChatBubbleOutlineRounded';
import ChooseCommentReplay from "./components/ChooseCommentReplay";
import {translate} from "../../../../config/lang";
import ChooseAdComment from "./components/ChooseAdComment";

class AddComment extends React.Component {
    state = {
        fields:{
            comment:'',
        },
        loading: true,
        rating: 4.5,
        SubmitLoading:false,
        confirmed:true,
        user:null,
        replay_to:null,
        parent: null,
        item_id: null,
        type: this.props.type,
        errors:[],
    };

    classes = this.props.classes;
    async componentDidMount(){
        await this.setState({
            replay_to: this.props.replay_comment,
            loading:false
        })
    }
    handleChangeConfirmed(){
        this.setState({
            confirmed: !this.state.confirmed,
        })
    }
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        fields[target.name] = target.value;
        errors[target.name] = "";
        this.setState({
            fields,
            PreventingToExit:true
        });

    }
    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //comment
        if(fields.comment !== null ) {
            if (fields.comment.length > 1000) {
                formIsValid = false;
                errors['comment'] = translate('maxLength1000');
            }
        }
        if(!formIsValid){
            this.setState({
                SubmitLoading:false
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        let { fields,confirmed,rating,item_id,replay_to } = this.state;
        let data = new FormData();
        data.append('id', this.state.id);
        data.append('comment', fields.comment);
        data.append('item_id', item_id);
        data.append('type', this.props.type);
        data.append('rate', rating);
        data.append('replay_to', replay_to);
        data.append('confirmed', confirmed ? 1 : 0);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/comment/add`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(translate('commentAdded'), {
                    variant: 'success',
                });
                this.setState({
                    SubmitLoading:false
                })
                if(this.props.dialog)
                this.props.handleDone()
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(translate('commentAddingFailed'), {
                    variant: 'error',
                });
                this.setState({
                    SubmitLoading:false
                })
            });

        this.setState({
            loading:false,
            SubmitLoading:false
        });
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.setState({
                    loading:true
                });
                this.handleRequest();
            }
        });
    }
    handleAd(value){
        this.setState({
            item_id:!!value ? value.id : null
        })
    }
    handleReplay(value){
        this.setState({
            replay_to:value.id
        })
    }
    render(){
        let {fields, confirmed,errors,loading,rating,SubmitLoading,item_id,replay_to,type} = this.state;
        let {dialog} = this.props;
        return (
            <React.Fragment>
                <HeaderTags title={translate('editComment')}/>
                {
                    loading ? <Loader/> :
                        <React.Fragment>
                            {
                                !dialog ?
                                    <Grid item xs={12}>
                                        <BreadCrumbs  firstText={translate('comments')} firstLink="/setting/comments" secondText={translate('editComment')}/>
                                    </Grid>
                                    : null
                            }

                            <Grid item xs={12}>
                                <Grid container spacing={1} direction="row" className={dialog ? null :this.classes.GridAsaPaper}>
                                    {
                                        !dialog ?
                                            <Grid item xs={12} className="TitleContainer">
                                                <Title>{translate('editComment')} <ChatBubbleOutlineRoundedIcon style={{float:'left'}}/></Title>
                                            </Grid>
                                            : null
                                    }
                                    <Grid item xs={12}>
                                        <Typography>{translate('rating')}:</Typography>
                                        <div className={this.classes.ratingContainer}>
                                            <Rating
                                                name="rating"
                                                precision={0.5}
                                                value={rating}
                                                onChange={(event, newValue) => {
                                                    this.setState({
                                                        rating:newValue,
                                                    })
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={!!item_id ? 6 : 12}>
                                        <ChooseAdComment type={type} inPut={item_id} outPut={this.handleAd.bind(this)}/>
                                    </Grid>
                                    {
                                        !!item_id ?
                                            <Grid item xs={12} md={6}>
                                                <ChooseCommentReplay type={type} inPut={replay_to} item_id={item_id} outPut={this.handleReplay.bind(this)}/>
                                            </Grid>
                                            : null

                                    }
                                </Grid>
                                <Grid container spacing={2} direction="row">

                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            multiline
                                            fullWidth
                                            name="comment"
                                            label={translate('comment')}
                                            type="text"
                                            id="comment"
                                            rows="4"
                                            value={fields.comment}
                                            onChange={this.handleChange.bind(this)}
                                            error = {!!errors['comment']}
                                            helperText={errors['comment']}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container  direction="row" className={dialog ? null : this.classes.GridAsaPaper}>
                                    <Permission rule="make">
                                        <Grid item xs={5} md={2}>
                                            <SubTitle noHR>{translate('confirmStatus')} : </SubTitle>
                                        </Grid>
                                        <Grid item xs={7} md={4}>
                                            <FormControlLabel
                                                style={{marginRight:10}}
                                                control={
                                                    <Switch
                                                        checked={confirmed}
                                                        onChange={this.handleChangeConfirmed.bind(this)}
                                                        value={confirmed}
                                                        color="secondary"
                                                    />
                                                }
                                                label={confirmed ? translate('confirmed') : translate('unConfirmed')}
                                            />
                                        </Grid>
                                    </Permission>
                                    <Grid item xs={12}>
                                        <LoadingButton
                                            variant="contained"
                                            color="primary"
                                            onClick = {this.handleSubmit.bind(this)}
                                            loading = {SubmitLoading}
                                        >
                                            {translate('addComment')}
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                }
            </React.Fragment>

        );
    }
}
const mstp = state => ({
    user: state.user
});
export default withSnackbar(withRouter(withWidth()(withStyles(Styles)(connect(mstp)(AddComment)))));