import React from 'react';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import DragIndicatorRoundedIcon from '@material-ui/icons/DragIndicatorRounded';
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import Grid from "@material-ui/core/Grid/Grid";
import AppCardLoaderFields from "../../../../components/Skeletons/AppCardLoaderFields";
import {
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    ListItemSecondaryAction
} from "@material-ui/core";
import RootRef from "@material-ui/core/RootRef";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";
import {defaultVariabls} from "../../../../../config";
import {connect, setStore} from "trim-redux";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import withWidth from "@material-ui/core/withWidth/withWidth";
import Button from "@material-ui/core/Button/Button";
import {translate} from "../../../../../config/lang";
import LowPriorityRoundedIcon from '@material-ui/icons/LowPriorityRounded';
import Typography from "@material-ui/core/Typography/Typography";
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import IconButton from "@material-ui/core/IconButton/IconButton";
import Alert from "@material-ui/lab/Alert/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle/AlertTitle";
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,

    ...(isDragging && {
        background: "rgb(235,235,235)",

    })
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#fbfbfb' : null,
    borderRadius:10
});

class BusinessTypesOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            variables: null,
            loading: true,
            dragLoading: false,
            parent:this.props.parent
        };
        this.onDragEnd = this.onDragEnd.bind(this);
    }
    componentDidMount(){
        this.loadData();
        this.setState({
            parent:this.props.parent

        })

    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        let {parent} = this.state;
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/business_types/getAll`, config)
                .then(response => {
                    this.setState({
                        variables:response.data,
                        loading:false
                    })

                }).catch(error => {
                    console.log(error.response)
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }
    }
    async onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        await  this.setState({
            dragLoading:true
        })

        let source = this.state.variables[result.source.index];
        let destination = this.state.variables[result.destination.index];
        const variables = reorder(
            this.state.variables,
            result.source.index,
            result.destination.index
        );
        this.setState({
            variables
        });
        let loginToken = localStorage.getItem('ACT');
        let data = new FormData();
        data.append('source', source.id);
        data.append('destination', destination.id);
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.post(`${defaultVariabls.url}/business_types/changeOrder`,data, config)
                .then(response => {
                    this.setState({
                        dragLoading:false
                    });
                }).catch(error => {
                console.log(error.response)
            })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }

    }
    classes = this.props.classes;
    async handleClickOnItem(parent){
        await this.setState({
            parent:parent,
            loading:true,
        })
        await this.loadData();
    }
    async handleBack(){
        await this.setState({
            parent:null,
            loading:true,
        })
        await this.loadData();
    }
    render(){
        return (
            <>
                {
                    this.state.loading ? <AppCardLoaderFields/> :
                        <Grid container spacing={2} justify="center" alignItems="center">
                            <Grid item xs={12}>
                                {
                                    this.state.dragLoading ?
                                        <LinearProgress />
                                        : null
                                }
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    !!this.state.variables.length ?
                                        <DragDropContext onDragEnd={this.onDragEnd}>
                                            <Droppable droppableId="droppable">
                                                {(provided, snapshot) => (
                                                    <RootRef rootRef={provided.innerRef}>
                                                        <List style={getListStyle(snapshot.isDraggingOver)}>
                                                            {this.state.variables.map((item, index) => (
                                                                <Draggable key={"item-"+item.id} draggableId={"item-"+item.id} index={index}>
                                                                    {(provided, snapshot) => (
                                                                        <ListItem
                                                                            ContainerComponent="li"
                                                                            ContainerProps={{ ref: provided.innerRef }}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            style={getItemStyle(
                                                                                snapshot.isDragging,
                                                                                provided.draggableProps.style
                                                                            )}
                                                                        >
                                                                            <ListItemIcon>
                                                                                <DragIndicatorRoundedIcon color="primary"/>
                                                                            </ListItemIcon>
                                                                            <ListItemText
                                                                                primary={
                                                                                    <Typography>
                                                                                        {item.name}
                                                                                    </Typography>
                                                                                }
                                                                                secondary={item.arabic_name}
                                                                            />
                                                                            <ListItemSecondaryAction>
                                                                            </ListItemSecondaryAction>
                                                                        </ListItem>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {provided.placeholder}
                                                        </List>
                                                    </RootRef>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                        :
                                        <Typography className={this.classes.condir}>{translate('noCategoryFound')}</Typography>
                                }

                            </Grid>
                        </Grid>
                }
            </>

        );
    }
}

const mstp = state => ({
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(BusinessTypesOrder)))));
