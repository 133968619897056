import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {connect} from "trim-redux";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../config/design/styles";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import Divider from "@material-ui/core/Divider/Divider";

class IconButtonOpenner extends React.Component{
    state = {
        open: false,
    };
    toggleOpen(){
        this.setState({
            open: !this.state.open
        })
        this.props.opened();
    }
    render(){
        let {open} = this.state;
        let {title,color,size,children,icon} = this.props;
        return (
            <>
                <Tooltip arrow placement="top" title={title} aria-label={title}>
                    <IconButton
                        color={!!color ? color : 'primary'}
                        onClick={() => this.toggleOpen()}
                        size={size}
                    >
                        {
                            !!icon ?  icon : <AddBoxRoundedIcon />
                        }
                    </IconButton>
                </Tooltip>
                <Dialog
                    open={open}
                    onClose={() => this.toggleOpen()}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    maxWidth="lg"
                >
                    <DialogTitle id="form-dialog-title">
                        <IconButton
                            onClick={() => this.toggleOpen()}
                            style={{position:"absolute",left:15,top:15}}
                            size="small"
                        >
                            <CloseRoundedIcon fontSize="small"/>
                        </IconButton>
                        {title}
                    </DialogTitle>
                    <Divider/>
                    <DialogContent>
                        {open && children}
                    </DialogContent>
                </Dialog>
            </>
        );
    }
}
const mstp = state => ({
    user: state.user,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(IconButtonOpenner)))));