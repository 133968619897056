import React from 'react';
import Grid from "@material-ui/core/Grid/Grid";
import {connect, getStore, setStore} from "trim-redux";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../config/design/styles";
import SubTitle from "../../../components/elements/SubTitle";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {Translate} from "../../../../config/Translate";
import Button from "@material-ui/core/Button/Button";
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import {Link, withRouter} from "react-router-dom";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction";
import {makeSlug, translate} from "../../../../config/lang";
import Typography from "@material-ui/core/Typography/Typography";
import {hasAccess} from "../../../../config/Security";
import withWidth from "@material-ui/core/withWidth/withWidth";
import AppCard from "../../../components/layouts/components/AppCard";

class SellerAnalyticsShort extends React.Component {
    state = {
        has_business: null,
        business_vists: null,
        business: null,
        total_rfqs: null,
        total_answers: null,
        total_products: null,
        products_visits:null,
        rfqs_visits:null,
        loading:true,
    };
    componentDidMount(){
        this.loadData();
    }
    async loadData(){
        this.setState({
            loading:true,
        });
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/analytics/user-analytics/${this.props.locale}`, config)
                .then(response => {
                    console.log(response.data)
                    this.setState({
                        has_business: response.data.has_business,
                        business: response.data.business,
                        business_vists: response.data.business_visit,
                        total_rfqs: response.data.total_rfqs,
                        total_answers: response.data.total_answers,
                        total_products: response.data.total_products,
                        products_visits: response.data.products_visits,
                        rfqs_visits: response.data.rfqs_visits,
                        loading: false,
                    })
                }).catch(error => {
                    console.log(error.response)
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
                loading:false
            });
            this.setState({
                loading:false
            })
        }
    }
    classes = this.props.classes;
    render(){
        let {
            has_business,
            business,
            business_vists,
            total_rfqs,
            total_answers,
            total_products,
            products_visits,
            loading
        } = this.state;
        let mode = getStore('mode');

        return (
            <Grid container spacing={2} direction="row">
                <Grid item xs={12} md={4}>
                    <AppCard style={{height:"100%"}}>
                        <Grid container  style={{position:"relative",paddingTop:0}} direction="row" className={this.classes.GridAsaPaperBaby}>
                            <Grid item xs={11} style={{paddingTop:0}}>
                                <SubTitle  align="left">
                                    {translate('companyPage')}
                                    {
                                        !!has_business &&
                                        <Button
                                            size="small"
                                            color="secondary"
                                            style={{position:"absolute",right:25}}
                                            endIcon={<ChevronRightRoundedIcon/>}
                                            component={'a'}
                                            href={makeSlug('business/'+business.slug)}
                                        >
                                            {translate('viewPage')}
                                        </Button>
                                    }
                                </SubTitle>
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    !!has_business ?
                                        <List dense style={{paddingTop:0}}>
                                            <ListItem>
                                                <ListItemText
                                                    style={{marginTop:0}}
                                                    primary={translate('totalVisitsCount')}
                                                />
                                                <ListItemSecondaryAction>
                                                    {business_vists}
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        </List> :
                                        <Typography style={{fontSize:12,marginLeft:10}}>{translate('createBusinessPageToSee')}</Typography>
                                }
                            </Grid>

                        </Grid>
                    </AppCard>
                </Grid>
                <Grid item xs={12} md={4}>
                    <AppCard style={{height:"100%"}}>
                    <Grid container  style={{position:"relative",paddingTop:0}} direction="row"  className={this.classes.GridAsaPaperBaby}>
                        <Grid item xs={11}>
                            <SubTitle  align="left" style={{paddingTop:0}}>
                                {translate('myProducts')}
                                {
                                    !!hasAccess('make_products') &&
                                    <Button
                                        size="small"
                                        color="secondary"
                                        style={{position:"absolute",right:25}}
                                        endIcon={<ChevronRightRoundedIcon/>}
                                        component={Link}
                                        to={'/my-products'}
                                    >
                                        {translate('viewList')}
                                    </Button>
                                }
                            </SubTitle>
                        </Grid>
                        <Grid item xs={12}>
                            {
                                !!hasAccess('make_products') ?
                                    <List dense style={{paddingTop:0}}>
                                        <ListItem>
                                            <ListItemText
                                                style={{marginTop:0}}
                                                primary={translate('numberOfProducts')}
                                            />
                                            <ListItemSecondaryAction>
                                                {total_products}
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText
                                                primary={translate('totalVisitsCount')}
                                            />
                                            <ListItemSecondaryAction>
                                                {products_visits}
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List> :
                                    <Typography style={{fontSize:12,marginLeft:10}}>{translate('upgradeYourPlanToCreateProducts')}</Typography>
                            }
                        </Grid>

                    </Grid>
                    </AppCard>
                </Grid>
                <Grid item xs={12} md={4}>
                    <AppCard style={{height:"100%"}}>
                        <Grid container  style={{position:"relative",paddingTop:0}} direction="row"  className={this.classes.GridAsaPaperBaby}>
                            <Grid item xs={11} style={{paddingTop:0}}>
                                <SubTitle align="left">
                                    {translate('myRFQs')}
                                    <Button
                                        size="small"
                                        style={{position:"absolute",right:25}}
                                        color="secondary"
                                        endIcon={<ChevronRightRoundedIcon/>}
                                        component={Link}
                                        to={'/my-rfqs'}
                                    >
                                        {translate('viewList')}
                                    </Button>
                                </SubTitle>
                            </Grid>
                            <Grid item xs={12}>
                                <List dense style={{paddingTop:0}}>
                                    <ListItem>
                                        <ListItemText
                                            style={{marginTop:0}}
                                            primary={translate('numberOfRFQs')}
                                        />
                                        <ListItemSecondaryAction>
                                            {total_rfqs}
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primary={translate('totalAnswersToYourRFQs')}
                                        />
                                        <ListItemSecondaryAction>
                                            {total_answers}
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </List>
                            </Grid>

                        </Grid>
                    </AppCard>
                </Grid>
            </Grid>
        );
    }
}
const mstp = state => ({
    user: state.user,
    locale: state.locale,
});
export default  withRouter(withWidth()(withStyles(Styles)(connect(mstp)(SellerAnalyticsShort))));
