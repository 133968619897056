import React from "react";
import {withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../config/design/styles";
import {connect} from "trim-redux";
import axios from "axios";
import {defaultVariabls} from "../../../../config";
import {translate} from "../../../../config/lang";
import Grid from "@material-ui/core/Grid";
import SubTitle from "../../../components/elements/SubTitle";
import DeleteButton from "../../../components/elements/DeleteButton";
import Divider from "@material-ui/core/Divider/Divider";
import Button from "@material-ui/core/Button/Button";
import AppCardLoaderFields from "../../../components/Skeletons/AppCardLoaderFields";
import Typography from "@material-ui/core/Typography/Typography";

class MainProductsChooser extends React.Component{
    state = {
        products:[],
        main_products:[],
        loading:true,
    };
    async componentDidMount(){
        await this.loadData();
    }
    async loadData(){
        let {owner_id,locale} = this.props;
        if(owner_id === null) {
            owner_id = this.props.user.id;
        }
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.get(`${defaultVariabls.url}/products/getAllForMainChooser/${owner_id}/${locale}`, config)
            .then(response => {
                this.setState({
                    products:!!response.data.products ? response.data.products : [],
                    main_products:!!response.data.main_products ? response.data.main_products : [],
                    loading:false,
                })
            })
            .catch(error => {
                this.setState({
                    loading:false
                })
            });
    }
    async handleChange(product_id,type){
        this.setState({
            loading:true
        })
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        let data = new FormData();
        data.append('product_id', product_id);
        data.append('type', type);

        axios.post(`${defaultVariabls.url}/product/change-main-status`,data, config)
            .then(response => {
                this.loadData()
            })
            .catch(error => {
                this.setState({
                    loading:false
                })
            });
    }
    render(){
        let {products,loading,main_products} = this.state;
    return (
        <Grid container spacing={2}>
            {
                !!loading ? <AppCardLoaderFields/> :
                    <>
                    <Grid item xs={12}>
                        <SubTitle align="left">{translate('showcase_products')}</SubTitle>
                    </Grid>
                        {
                            !!main_products.length ?
                                <>
                                    {
                                        main_products.map((item,index) => (
                                            <Grid key={index} item xs={12} md={4} lg={3}>
                                                <div
                                                    className="slide_show_item"
                                                    style={{backgroundColor:"#999",}}
                                                >
                            <span className="active-badge">
                                {translate('main_product')}
                            </span>
                                                    <h3>{item.name}</h3>
                                                    <div className="controller">
                                                        <DeleteButton
                                                            size="small"
                                                            handleDelete={() => this.handleChange(item.id,'remove')}
                                                        />
                                                    </div>
                                                </div>
                                            </Grid>
                                        ))
                                    }
                                </> :
                                <Grid item xs={12}>
                                 <Typography paragraph >{translate('noProductFound')}</Typography>
                                </Grid>
                        }
                        {
                            main_products.length < 12 &&
                            <>
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                                <Grid item xs={12}>
                                    <SubTitle noHR align="left">{translate('other_products')}</SubTitle>
                                </Grid>
                                {
                                    !!products.length ?
                                        <>
                                            {
                                                products.map((item,index) => (
                                                    <Grid key={index} item xs={12} md={4} lg={3}>
                                                        <div
                                                            className="slide_show_item"
                                                            style={{backgroundColor:"#999",}}
                                                        >
                                                            <h3>{item.name}</h3>
                                                            <div className="controller">
                                                                <Button
                                                                    variant="outlined"
                                                                    color="secondary"
                                                                    onClick={() => this.handleChange(item.id,'add')}
                                                                    size={"small"}
                                                                >
                                                                    {translate('addToMain')}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                ))
                                            }
                                        </> :
                                        <Grid item xs={12}>
                                            <Typography paragraph>{translate('noProductFound')}</Typography>
                                        </Grid>
                                }
                            </>
                        }

                    </>
            }
        </Grid>

    );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    locale: state.locale,

});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MainProductsChooser))));