import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../../config/Translate";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import Grid from "@material-ui/core/Grid/Grid";
import Container from "@material-ui/core/Container/Container";
import Title from "../../../components/elements/Title";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import RootRef from "@material-ui/core/RootRef/RootRef";
import {List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText} from "@material-ui/core";
import DragIndicatorRoundedIcon from '@material-ui/icons/DragIndicatorRounded';
import VerticalAlignTopRoundedIcon from '@material-ui/icons/VerticalAlignTopRounded';
import VerticalAlignBottomRoundedIcon from '@material-ui/icons/VerticalAlignBottomRounded';
import HeaderManagement from "./parts/HeaderManagement";
import FooterManagement from "./parts/FooterManagement";
import ColorPicker from "../../../components/fields/ColorPicker";
import SiteColorPicker from "./components/SiteColorPicker";
import SlideShowsManager from "../settings/slideshows/SlideShowsManager";
import AppCard from "../../../components/layouts/components/AppCard";
const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#fbfbfb' : null,
    borderRadius:10
});
const getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,

    ...(isDragging && {
        background: "rgb(235,235,235)",

    })
});
class ManageTemplate extends React.Component {
    constructor(props){
        super(props);
        this.onDragEnd = this.onDragEnd.bind(this);

    }
    state = {
        variables:[
            {
                id: 1,
                name: Translate.headerTemplate,
                description: Translate.headerTemplateDesc,
            },
            {
                id: 2,
                name: "banner"
            },
            {
                id: 3,
                name: "footer"
            }
        ]
    };
    classes = this.props.classes;
    async onDragEnd(result) {
        // dropped outside the list
        alert("hi")

    }
    render(){
        let {} = this.state;
        return (
            <Container maxWidth="xl">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Title align="left">{Translate.manageTemplate}</Title>
                    </Grid>
                    <Grid item xs={12}>
                        <SiteColorPicker/>
                    </Grid>
                    <Grid item xs={12}>
                        <AppCard>
                            <List>
                                <ListItem
                                    ContainerComponent="li"
                                >
                                    <ListItemIcon>
                                        <VerticalAlignTopRoundedIcon/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={Translate.headerTemplate}
                                        secondary={Translate.headerTemplateDesc}
                                    />
                                    <ListItemSecondaryAction>
                                        <HeaderManagement/>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </List>
                        </AppCard>
                    </Grid>
                    <Grid item xs={12}>
                        <AppCard>
                            <List>
                                <ListItem
                                    ContainerComponent="li"
                                >

                                    <SlideShowsManager/>
                                </ListItem>
                            </List>
                        </AppCard>

                    </Grid>
                    <Grid item xs={12}>
                        <AppCard>
                            <List>
                                <ListItem
                                    ContainerComponent="li"
                                >
                                    <ListItemIcon>
                                        <VerticalAlignBottomRoundedIcon/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={Translate.footerTemplate}
                                        secondary={Translate.footerTemplateDesc}
                                    />
                                    <ListItemSecondaryAction>
                                        <FooterManagement/>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </List>
                        </AppCard>
                    </Grid>
                </Grid>
            </Container>

        );
    }
    }

    export default withSnackbar(withRouter(withStyles(Styles)(ManageTemplate)));