import React from 'react';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../../../config/Translate";
import TextField from "@material-ui/core/TextField/TextField";
import axios from "axios";
import {defaultVariabls} from "../../../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import LoadingButton from "../../../../components/elements/LoadingButton";
import Grid from "@material-ui/core/Grid/Grid";
import {connect, setStore} from "trim-redux";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import Thumbnail from "../../../fileManager/Components/Thumbnail";
import withWidth from "@material-ui/core/withWidth/withWidth";
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import FieldCounterWithoutBar from "../../../../components/elements/FieldCounterWithoutBar";
import Link from "@material-ui/core/Link/Link";
import Typography from "@material-ui/core/Typography/Typography";
import {translate} from "../../../../../config/lang";
import SingleImageChooser from "../../../fileManager/Components/SingleImageChooser";
import MetaDescription from "../../../fileManager/Components/MetaDescription";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import Alert from "@material-ui/lab/Alert/Alert";

class MakeBanner extends React.Component {

    state = {
        fields:{
            title:'',
            subtitle:'',
            link:'',
            description:'',
            primary_button_text:'',
            primary_button_icon:'',
            primary_button_link:"",
        },
        thumbnail: null,
        position: '',
        type: '',
        width: '',
        height: '',
        loading: true,
        SubmitLoading: false,
        active: true,
        id:this.props.id,
        editMode:!!this.props.id,
        errors:[]
    };
    classes = this.props.classes;
    async componentDidMount(){
        await this.setState({
            id:this.props.id,
            editMode:!!this.props.id
        })
        if(!!this.props.id) {
            await this.loadData();
        }else{
            this.setState({
                loading:false
            })
        }
    }
    handleThumbImage(thumbnail){
        this.setState({
            thumbnail
        })
    }
    async loadData(){
        let {id} = this.state;
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/banner/get/${id}`, config)
                .then(response => {
                    this.setState({
                        fields: {
                            title:response.data.title,
                            link:response.data.link,
                            subtitle:response.data.subtitle,
                            description:response.data.description,
                            primary_button_text:response.data.primary_button_text,
                            primary_button_icon:response.data.primary_button_icon,
                            primary_button_link:response.data.primary_button_link,
                        },
                        editMode:true,
                        loading:false,
                        thumbnail: response.data.thumbnail,
                        position: response.data.position,
                        type: response.data.type,
                        width: response.data.width,
                        height: response.data.height,
                        lang: response.data.lang,
                        active: response.data.active,
                    })

                }).catch(error => {
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }
    }
    handleChange(e){
        let {errors,fields} = this.state;
        let target = e.target;
        errors[target.name] = "";
        fields[target.name] = target.value;

        this.setState({
            fields,
            errors
        });    
    }

    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //Description
        if(fields.description !== null ) {
            if (fields.description.length > 500) {
                formIsValid = false;
                errors['description'] = translate('maxLength500');
            }
        }
        if(!formIsValid){
            this.props.enqueueSnackbar(translate('someFieldsHasError'), {
                variant: 'warning',
            });
            this.setState({
                errors,
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        let { id,fields,thumbnail,editMode,active} = this.state;
        let data = new FormData();
        data.append('title', fields.title);
        data.append('subtitle', fields.subtitle);
        data.append('description', fields.description);
        data.append('link', fields.link);
        data.append('primary_button_text', fields.primary_button_text);
        data.append('primary_button_icon', fields.primary_button_icon);
        data.append('primary_button_link', fields.primary_button_link);
        data.append('thumbnail', !!thumbnail ? parseInt(thumbnail.id) : 1);
        data.append('id', id);
        data.append('manage',  this.props.manage);
        data.append('lang',  this.props.lang);
        data.append('active',  !!active ? 1 : 0);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/banner/make`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(editMode ? translate('dataEdited') : translate('dataAdded'), {
                    variant: 'success',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
                this.props.reload();

            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(editMode ?  translate('dataEditingFailed') : translate('dataAddingFailed'), {
                    variant: 'error',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                    SubmitDraftLoading: false
                });
            });

    }

    handleSubmit(e){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }

    render(){
        let {fields,active, errors,SubmitLoading,thumbnail,loading,position,type,width,height} = this.state;
        return (
            <div className="modifySinglePricingHolder">
                {
                    loading ?
                        <div style={{paddingTop:100,paddingBottom:100,textAlign:"center"}}>
                            <CircularProgress size={35}/>
                        </div>
                        :
                        <Grid container spacing={2}  justifyContent="center">
                            <Grid item xs={12} md={3}>
                                <SingleImageChooser
                                    primary
                                    onChange={this.handleThumbImage.bind(this)}
                                    initial={thumbnail}
                                    defined_width={width}
                                    defined_height={height}
                                />
                                <MetaDescription
                                    width={width}
                                    height={height}
                                    size={"20MB"}
                                    primary
                                />
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <Grid container spacing={2} alignItems='center' justifyContent="center">
                                    <Grid item xs={12}>
                                        <Typography>{translate('bannerPosition')}: <strong>{translate(position)}</strong></Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Alert
                                            severity="info"
                                            action={
                                                <FormControlLabel
                                                    style={{marginRight:10}}
                                                    control={
                                                        <Switch
                                                            checked={active}
                                                            onChange={() => this.setState({active:!active})}
                                                            value={active}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={active ? translate('yes') : translate('no')}
                                                />
                                            }
                                        >
                                            {translate('isThisBannerActive')}
                                        </Alert>
                                    </Grid>
                                    {
                                        (type === "cta" || type === "icon_text") &&
                                        <Grid item xs={12}>
                                            <TextField
                                                variant="outlined"
                                                value={fields.title}
                                                fullWidth
                                                size="small"
                                                label={translate('title')}
                                                name="title"
                                                onChange={this.handleChange.bind(this)}
                                                error = {!!errors['title']}
                                                helperText={errors['title']}
                                            />
                                        </Grid>
                                    }
                                    {
                                        (type !== "cta" && type !== "icon_text") &&
                                        <Grid item xs={12}>
                                            <TextField
                                                variant="outlined"
                                                value={fields.link}
                                                fullWidth
                                                size="small"
                                                label={translate('link')}
                                                name="link"
                                                onChange={this.handleChange.bind(this)}
                                                error = {!!errors['link']}
                                                helperText={errors['link']}
                                            />
                                        </Grid>
                                    }

                                    {
                                        (type === "cta" || type === "icon_text") &&
                                        <Grid item xs={12}>
                                            <TextField
                                                variant="outlined"
                                                multiline
                                                minRows={3}
                                                value={fields.description}
                                                fullWidth
                                                size="small"
                                                label={translate('description')}
                                                name="description"
                                                onChange={this.handleChange.bind(this)}
                                                error = {!!errors['description']}
                                                helperText={errors['description']}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <FieldCounterWithoutBar length={!!fields.description ? fields.description.length : 0} max={500}/>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                    }
                                    {
                                        type === "cta" &&
                                            <>
                                                <Grid item xs={12} md={4}>
                                                    <TextField
                                                        variant="outlined"
                                                        value={fields.primary_button_icon}
                                                        fullWidth
                                                        size="small"
                                                        label={translate('primary_button_icon')}
                                                        name="primary_button_icon"
                                                        onChange={this.handleChange.bind(this)}
                                                        error = {!!errors['primary_button_icon']}
                                                        helperText={errors['primary_button_icon']}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <TextField
                                                        variant="outlined"
                                                        value={fields.primary_button_text}
                                                        fullWidth
                                                        size="small"
                                                        label={translate('primary_button_text')}
                                                        name="primary_button_text"
                                                        onChange={this.handleChange.bind(this)}
                                                        error = {!!errors['primary_button_text']}
                                                        helperText={errors['primary_button_text']}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <TextField
                                                        variant="outlined"
                                                        value={fields.primary_button_link}
                                                        fullWidth
                                                        size="small"
                                                        label={translate('primary_button_link')}
                                                        name="primary_button_link"
                                                        onChange={this.handleChange.bind(this)}
                                                        error = {!!errors['primary_button_link']}
                                                        helperText={errors['primary_button_link']}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Link target="_blank" href="https://fontawesome.com/v5/search">{translate('iconsList')}</Link>
                                                </Grid>
                                            </>
                                    }
                                    <Grid item xs={12}>
                                        <LoadingButton
                                            styleForParent={{
                                                float: "left",
                                                margin:0
                                            }}
                                            startIcon={<SaveRoundedIcon/>}
                                            variant="contained"
                                            color="primary"
                                            onClick = {this.handleSubmit.bind(this)}
                                            loading = {SubmitLoading}
                                        >
                                            {translate('save')}
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                }

            </div>

        );
    }
}

const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    locale: state.locale,
});
export default withWidth()(withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MakeBanner)))));