import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { defaultVariabls } from '../../../config/index';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {connect} from "trim-redux";
import {Link} from 'react-router-dom';
import Button from "@material-ui/core/Button/Button";
import Typography from "@material-ui/core/Typography/Typography";
import Card from "@material-ui/core/Card/Card";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Avatar from "@material-ui/core/Avatar/Avatar";
import SubTitle from "./SubTitle";
import ChevronRight from '@material-ui/icons/ChevronRight';
import CardContent from "@material-ui/core/CardContent/CardContent";
import {translate} from "../../../config/lang";
import AddTicketModalAdmin from "../../pages/tickets/add_ticket/AddTicketModalAdmin";

class details extends React.Component {
    classes = this.props.classes
    state={
        user:null,
        loading:true,
        id:this.props.id
    };
    componentDidMount(){
        this.loadData();
    }
    loadData(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.get(`${defaultVariabls.url}/user/get/${this.props.id}`, config)
            .then(response => {
                this.setState({
                   user:response.data,
                    loading:false,
                })
            })
            .catch(error => {
                this.setState({
                    loading:false
                })
            });
    }

    render() {
        const {user,loading} = this.state;
        let {primary_color,title} = this.props;
        if (loading) {
            return (
                <Card className="user_card">
                    <CardContent>
                        <CircularProgress style={{marginTop:10}}/>
                    </CardContent>
                </Card>
            )
        } else {
            return (
                <Card className="user_card">
                    <div className="user_card_content">
                        <div className="user_card_text">
                            <Avatar style={{width:75,height:75,margin:"auto"}} src={`${defaultVariabls.uploads}/FileManager/${user.avatar.name}_small.${user.avatar.extension}`}/>

                            <div className="user_card_info">
                                <SubTitle noHR>{title} <span className={this.classes.shadowText}>{user.first_name} {user.last_name}</span></SubTitle>
                                <Typography dir="ltr"><a href={`tel:${user.mobile}`}>{user.mobile}</a></Typography>
                            </div>
                        </div>
                        <div>
                            <Button
                                style={{marginBottom:10}}
                                color="primary"
                                variant="contained"
                                size="small"
                                component={Link}
                                to={`/manage/user/${user.id}`}
                                endIcon={<ChevronRight/>}
                            >
                                {translate('moreInfo')}
                            </Button>
                            <br/>
                            {
                                (this.props.user.id !== user.id) &&
                                <AddTicketModalAdmin
                                    only_big
                                    receiver={user}
                                    reload={() => console.log('reloaded')}
                                />
                            }
                        </div>

                    </div>
                </Card>
            );
        }
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_color: state.primary_color
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(details))));