import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import BreadCrumbs from "../../components/elements/BreadCrumbs";
import validator from "validator";
import axios from "axios";
import {defaultVariabls} from "../../../config";
import LoadingButton from "../../components/elements/LoadingButton";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {passwordRegx, updateRedux} from '../../../config/Security'
import AppAnimate from "../../components/AppAnimate";
import AppCard from "../../components/layouts/components/AppCard";
import PassField from "../../components/fields/PassField";
import {translate} from "../../../config/lang";
import {connect} from "trim-redux";
class ChangePassword extends React.Component {
    state={
        fields: {
            currentPassword:'',
            newPassword: '',
            newPasswordConfirm: '',
        },
        errors:{},
        SubmitLoading:false,
    };
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        errors[target.name] = "";
        fields[target.name] = target.value;
        this.setState({fields});
    }
    handleValidation(callback){
        let { fields } = this.state;
        let { user } = this.props;
        let errors = {};
        let formIsValid = true;

        if(!!user.has_password){
            //old Password
            if(validator.isEmpty(fields.currentPassword)){
                formIsValid = false;
                errors['currentPassword'] = translate('emptyPassword');
            }
        }

        //Password

        if(validator.isEmpty(fields.newPassword)){
            formIsValid = false;
            errors['newPassword'] = translate('emptyPassword');
        }else if(!passwordRegx.test(fields.newPassword)){
            formIsValid = false;
            errors['newPassword'] = translate('weakPassword');
        }


        //Password_confirmation
        if(validator.isEmpty(fields.newPasswordConfirm)){
            formIsValid = false;
            errors['newPasswordConfirm'] = translate('emptyField');
        }else if (fields.newPasswordConfirm !== fields.newPassword){
            formIsValid = false;
            errors['newPasswordConfirm'] = translate('noMatchPassword');
        }
        this.setState({errors},() =>{
            if(!formIsValid){
                this.setState({
                    SubmitLoading:false
                })
            }
            return callback(formIsValid)
        });
    }
    handleRequest(){
        const {currentPassword,newPassword,newPasswordConfirm} = this.state.fields;
        let data = new FormData();
        data.append('currentPassword', currentPassword);
        data.append('password', newPassword);
        data.append('password_confirmation', newPasswordConfirm);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/user/changePassword`, data, config)
            .then(response =>{
                this.props.enqueueSnackbar(translate('passwordChanged'), {
                    variant: 'success',
                });
                updateRedux();
                this.setState({
                    SubmitLoading:false,
                    fields: {
                        currentPassword:'',
                        newPassword: '',
                        newPasswordConfirm: '',
                    },
                    errors:{},
                })
                this.props.history.push('/dashboard');

            })
            .catch(error =>{
                this.setState({
                    SubmitLoading:false
                })
                this.props.enqueueSnackbar(translate('currentPasswordError'), {
                    variant: 'error',
                });
            });
    }
    handleSubmit(e){
        e.preventDefault();
        this.setState({
            SubmitLoading:true
        })
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    render() {
        let {fields,errors,SubmitLoading} = this.state;
        let {user} = this.props;
        return(
            <AppAnimate animation='transition.slideUpIn' delay={200} title={translate('managePassword')}>
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12} lg={11}>
                        <AppCard
                            title={translate('managePassword')}
                            action={
                                <BreadCrumbs  firstText={translate('myAccount')} firstLink="/account/details" secondText={translate('managePassword')}/>
                            }>
                <Grid container spacing={2} direction="row" justify="center" alignItems="center" style={{marginTop:15}}>
                    <Grid item xs={12} md={8} lg={6}>
                        <form noValidate onSubmit={this.handleSubmit.bind(this)}>
                        <Grid container spacing={2} direction="row" justify="center" alignItems="center">
                            {
                                !!user.has_password &&
                                <Grid item xs={12}>
                                    <PassField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        value={fields.currentPassword}
                                        name="currentPassword"
                                        label={translate('currentPassword')}
                                        id="currentPassword"
                                        onChange={this.handleChange.bind(this)}
                                        error={!!errors['currentPassword']}
                                        helperText={errors['currentPassword']}
                                    />
                                </Grid>
                            }


                            <Grid item xs={12}>
                                <PassField
                                    variant="outlined"
                                    required
                                    color="secondary"
                                    fullWidth
                                    value={fields.newPassword}
                                    name="newPassword"
                                    label={translate('newPassword')}
                                    id="newPassword"
                                    onChange={this.handleChange.bind(this)}
                                    error={!!errors['newPassword']}
                                    helperText={!!errors['newPassword'] ? errors['newPassword'] : translate('passwordStrength')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <PassField
                                    variant="outlined"
                                    required
                                    color="secondary"
                                    fullWidth
                                    value={fields.newPasswordConfirm}
                                    name="newPasswordConfirm"
                                    label={translate('newPasswordConfirm')}
                                    id="newPasswordConfirm"
                                    onChange={this.handleChange.bind(this)}
                                    error={!!errors['newPasswordConfirm']}
                                    helperText={errors['newPasswordConfirm']}
                                />
                            </Grid>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick = {this.handleSubmit.bind(this)}
                                loading = {SubmitLoading}
                            >
                                {!!user.has_password ? translate('changePassword') : translate('makePassword')}
                            </LoadingButton>
                        </Grid>
                        </form>

                    </Grid>
                </Grid>
                        </AppCard>
                    </Grid>
                </Grid>
            </AppAnimate>
        )
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_color: state.primary_color
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(ChangePassword))));
