import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import BreadCrumbs from "../../../components/elements/BreadCrumbs";
import AppAnimate from "../../../components/AppAnimate";
import AppCard from "../../../components/layouts/components/AppCard";
import {translate} from "../../../../config/lang";
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";
import Paper from "@material-ui/core/Paper/Paper";
import BusinessFields from "./BusinessFields";
import ProductFields from "./ProductFields";
import RFQFields from "./RFQFields";


class FieldsPage extends React.Component {
    classes = this.props.classes;
    state = {
        tab: "business"
    }
    handleChange = (event, newValue) => {
        this.setState({
            tab:newValue
        })
    };
    render(){
        let {tab} = this.state;
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={translate('manageFields')}>
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12} lg={11}>
                        <AppCard
                            title={translate('manageFields')}
                            action={
                                <BreadCrumbs singleLevel firstText={translate('manageFields')}/>
                            }>
                            <Paper>
                                <Tabs
                                    value={tab}
                                    onChange={this.handleChange.bind(this)}
                                    indicatorColor="secondary"
                                    textColor="secondary"
                                    centered
                                >
                                    <Tab label={translate('businessFields')} value="business"/>
                                    <Tab label={translate('productFields')} value="product"/>
                                    <Tab label={translate('faqFields')} value="faq"/>
                                </Tabs>
                            </Paper>
                            <div style={{marginTop:20}}>
                                {
                                    tab === "business" ?
                                        <BusinessFields/>
                                        : tab === "product" ?
                                        <ProductFields/>
                                        : <RFQFields/>
                                }
                            </div>


                        </AppCard>
                    </Grid>
                </Grid>
            </AppAnimate>
        );
    }
}
export default withSnackbar(withStyles(Styles)(FieldsPage));
