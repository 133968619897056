import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {connect} from "trim-redux";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Typography from "@material-ui/core/Typography/Typography";
import CardActions from "@material-ui/core/CardActions/CardActions";
import DeleteButton from "../../../components/elements/DeleteButton";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {Translate} from "../../../../config/Translate";
import EditAddressDialog from "./EditAddressDialog";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import Radio from "@material-ui/core/Radio/Radio";

class SingleAddress extends React.Component {
    classes = this.props.classes
    state={
        address:this.props.data,
    };
    handleDelete(){
        let {address} = this.state;
        let data = new FormData();
        data.append('id', address.id);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/address/remove`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.dataRemoved, {
                    variant: 'success',
                });
                this.props.reload();
            })
            .catch(error => {
                this.props.enqueueSnackbar(Translate.dataRemovingFailed, {
                    variant: 'error',
                });

            });

        this.setState({
            loading:false,
            SubmitLoading:false
        });
    }
    render() {
        let {address} = this.state;
        let {choose,chosen} = this.props;
        return (
            <Card variant="outlined" style={{boxShadow:'none',height:"100%",position:"relative",backgroundColor: chosen === address.id ?"#eeffd5" : ''}}>
                <CardContent style={{marginBottom:30}}>
                    {
                        choose &&
                            <Radio
                                checked={chosen === address.id}
                                onChange={() => this.props.handleChoose(address.id)}
                                value={address.id}
                            />
                    }
                    <Typography variant="subtitle2"  color="textPrimary" gutterBottom>
                        {address.province} / {address.city}
                    </Typography>
                    <Typography  color="textSecondary" gutterBottom>
                    </Typography>
                    <Typography  color="textSecondary" gutterBottom>
                        {Translate.postal_code}: {address.postal_code}
                    </Typography>
                    <Typography color="textSecondary" className="only-two-line">
                        {address.address}
                    </Typography>
                </CardContent>
                <CardActions className="card_resume_action">
                        <EditAddressDialog id={address.id}  reload={() => this.props.reload()}/>
                        <DeleteButton handleDelete={this.handleDelete.bind(this)}/>
                </CardActions>
            </Card>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(SingleAddress))));