import {makeStyles} from '@material-ui/core';
import {theme} from "../../../../config/design/theme";
import {getStore} from "trim-redux";

const useStyles = makeStyles(appTheme => {
  return {
    container: {
      maxHeight: '100vh',
      paddingLeft: 30,
      paddingTop: 30,
      paddingBottom: 30,
      width: '16rem',
      [appTheme.breakpoints.up('xl')]: {
        width:  '22.8rem',
      },
    },
      drawerContainer: {
          maxHeight: '100vh',
          position:"relative",
          paddingLeft: "0 !important",
          paddingTop: "0 !important",
          paddingBottom: "0 !important",
          width: '75vw',
          [appTheme.breakpoints.up('lg')]: {
              width: "18vw",
          },
          [appTheme.breakpoints.down('md')]: {
              width: "35vw",
          },
          [appTheme.breakpoints.down('sm')]: {
              width: "50vw",
          },
          [appTheme.breakpoints.down('xs')]: {
              width: "70vw",
          },
      },
    sidebarBg: {
      overflow: 'hidden',
        height: '100vh',
        position:"fixed",
        top:0,
        backgroundColor: getStore('mode') === "dark" ? "#353535" : "#ebebed",
        boxShadow:"0 2px 10px rgba(17, 17, 17,.2)",
        width: '75vw',
        [appTheme.breakpoints.up('lg')]: {
            width: "18vw",
            top:100,
            borderRadius:6,
            height: 'calc(100vh - 120px)',
        },
        [appTheme.breakpoints.down('md')]: {
            width: "35vw",
        },
        [appTheme.breakpoints.down('sm')]: {
            width: "50vw",
        },
        [appTheme.breakpoints.down('xs')]: {
            width: "70vw",
        },
    },
      horizontalHR:{
          "&::before":{
              content: `''`,
              width: '100%',
              height:1,
              display: "block",
              position: "absolute",
              boxShadow: "0 0 6px 1px rgba(255, 239, 6 ,.8)",
            backgroundColor:"#595757",
          },
          "& hr":{
              opacity: 1,
              color:"#bdbec0",
              borderTop: "3px solid",
              marginTop:0,
          }
      },
    scrollAppSidebar: {
      paddingBottom: 25,
      paddingLeft:0,
      height: '100vh !important',
      [appTheme.breakpoints.up('xl')]: {
        height: 'calc(100vh - 130px) !important' ,
      },
    },
    drawerScrollAppSidebar: {
      paddingTop: 8,
        paddingLeft:10,
      paddingBottom: 25,
        backgroundColor:"#3D518C",
        height: '100vh !important',
    },

    sidebarModern: {
      height: '100%',
      width: '100%',
      color: 'white',
      overflow: 'hidden',
      borderRadius: 30,
    },

  };
});
export default useStyles;
