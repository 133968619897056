import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField/TextField";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import LoadingButton from "../../../components/elements/LoadingButton";
import AppAnimate from "../../../components/AppAnimate";
import AppCard from "../../../components/layouts/components/AppCard";
import Grid from "@material-ui/core/Grid/Grid";
import {connect, setStore} from "trim-redux";
import BreadCrumbs from "../../../components/elements/BreadCrumbs";
import AppCardLoaderFields from "../../../components/Skeletons/AppCardLoaderFields";
import Hidden from "@material-ui/core/Hidden/Hidden";
import {translate} from "../../../../config/lang";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import FormControl from "@material-ui/core/FormControl/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import Typography from "@material-ui/core/Typography/Typography";
import Alert from "@material-ui/lab/Alert/Alert";
import Switch from "@material-ui/core/Switch/Switch";
import {currencySymbol} from "../../../../config/values";

class MakePlan extends React.Component {

    constructor(props){
        super(props);
        this.ThumbChild = React.createRef();
        this.EditorChild = React.createRef();
    }
    state = {
        fields:{
            price:0,
            priceR:0,
            discount_price:null,
            discount_priceR:null,
            duration:0,
            make_products:0,
            answer_to_rfq:0,
        },
        verify_badge:0,
        has_products_top_list:0,
        show_in_popular:0,
        has_commercial_banner:0,
        has_business_page:0,
        has_banner_design:0,
        has_products_showcase:0,
        has_business_catalogue:0,
        has_business_video:0,
        has_business_image:0,
        has_certificates:0,
        has_send_rfq:0,
        has_receive_public_rfq:0,
        has_receive_private_rfq:0,
        has_backlink:0,
        has_social_media_marketing:0,
        has_support:0,
        has_youtube:0,
        errors:{},
        loading: true,
        SubmitLoading: false,
        id:this.props.match.params.id,
        editMode:false,
        confirmed:false,
        parents:[],
        defaultParent:null,
        parentId:null,
    };
    classes = this.props.classes;
    async componentDidMount(){
        if(!!this.state.id){
            this.setState({
                editMode:true
            })
            await this.loadData();
        }
        await this.loadParents();

    }

    componentDidUpdate(prevProps){
        if(this.state.id !== this.props.match.params.id){
            this.setState({
                id: this.props.match.params.id
            })
        }
    }
    async loadParents(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/plan_parents/quickViewForChooser`, config)
                .then(response => {
                    let categories = response.data;
                    categories.map((item,index) =>{
                        return item.id === this.state.parentId ? this.setState({
                            defaultParent: index,
                        }) : null
                    })
                    this.setState({
                        parents: response.data,
                        loading:false,
                    })
                })
                .catch(error => {
                    this.setState({
                        loading:false,
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
                loading:false,
                SubmitLoading:false
            });
            this.setState({
                loading:false,
                SubmitLoading:false
            })
        }
    }
    handleThumbImage(thumbnail){
        this.setState({
            thumbnail
        })
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/plan/get/${this.state.id}`, config)
                .then(response => {
                    this.setState({
                        fields: {
                            price:!!response.data.price ? response.data.price : 0,
                            priceR:!!response.data.price ? response.data.price : 0,
                            discount_price:response.data.discount_price,
                            discount_priceR:response.data.discount_price,
                            duration:!!response.data.duration ? response.data.duration : 0,
                            make_products:!!response.data.make_products ? response.data.make_products : 0,
                            answer_to_rfq:!!response.data.answer_to_rfq ? response.data.answer_to_rfq : 0,
                        },
                        verify_badge:response.data.verify_badge,
                        has_products_top_list:response.data.has_products_top_list,
                        show_in_popular:response.data.show_in_popular,
                        has_commercial_banner:response.data.has_commercial_banner,
                        has_business_page:response.data.has_business_page,
                        has_banner_design:response.data.has_banner_design,
                        has_products_showcase:response.data.has_products_showcase,
                        has_business_catalogue:response.data.has_business_catalogue,
                        has_business_video:response.data.has_business_video,
                        has_business_image:response.data.has_business_image,
                        has_certificates:response.data.has_certificates,
                        has_send_rfq:response.data.has_send_rfq,
                        has_receive_public_rfq:response.data.has_receive_public_rfq,
                        has_receive_private_rfq:response.data.has_receive_private_rfq,
                        has_backlink:response.data.has_backlink,
                        has_social_media_marketing:response.data.has_social_media_marketing,
                        has_support:response.data.has_support,
                        has_youtube:response.data.has_youtube,
                        parent: response.data.parent,
                        confirmed: response.data.confirmed,
                        parentId: response.data.parent_id,
                    })

                }).catch(error => {
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }
    }
    handleChange(e){
        let {errors,fields} = this.state;
        let target = e.target;
        if(target.name === "price"){
            fields.priceR = target.value.replace(/,/ig, '');
        }else if (target.name === "discount_price") {
            fields.discount_priceR = target.value.replace(/,/ig, '');
        }else if(target.name === "duration" || target.name === "make_products" || target.name === "answer_to_rfq") {
            if (parseInt(target.value) > 0) {
                fields[target.name] = Math.abs(parseInt(target.value));
            } else {
                fields[target.name] = '';
            }
        }else{
            fields[target.name] = target.value;
            errors[target.name] = "";
        }
        this.setState({fields});
    }

    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;
        if(!!fields.priceR){
            if(fields.discount_priceR > fields.priceR){
                formIsValid = false;
                errors['price'] = translate('discountMoreThanPrice')
            }
        }

        if(!formIsValid){
            this.props.enqueueSnackbar(translate('someFieldsHasError'), {
                variant: 'warning',
            });
            this.setState({
                errors,
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        let { fields,parentId,id,editMode,
            verify_badge, has_products_top_list,show_in_popular, has_commercial_banner, has_business_page, has_banner_design,
            has_products_showcase, has_business_catalogue, has_business_video, has_business_image,
            has_certificates, has_send_rfq, has_receive_public_rfq, has_receive_private_rfq,
            has_backlink, has_social_media_marketing, has_support,has_youtube,confirmed
        } = this.state;
        let data = new FormData();
        data.append('id', id ? id : 0);
        data.append('parent_id',parentId);
        data.append('price',fields.priceR);
        data.append('discount_price',fields.discount_priceR);
        data.append('duration',fields.duration);
        data.append('make_products',fields.make_products);
        data.append('answer_to_rfq',fields.answer_to_rfq);
        data.append('confirmed',!!confirmed ? 1 : 0);
        data.append('verify_badge',!!verify_badge ? 1 : 0);
        data.append('has_products_top_list',!!has_products_top_list ? 1 : 0);
        data.append('show_in_popular',!!show_in_popular ? 1 : 0);
        data.append('has_commercial_banner',!!has_commercial_banner ? 1 : 0);
        data.append('has_business_page',!!has_business_page ? 1 : 0);
        data.append('has_banner_design',!!has_banner_design ? 1 : 0);
        data.append('has_products_showcase',!!has_products_showcase ? 1 : 0);
        data.append('has_business_catalogue',!!has_business_catalogue ? 1 : 0);
        data.append('has_business_video',!!has_business_video ? 1 : 0);
        data.append('has_business_image',!!has_business_image ? 1 : 0);
        data.append('has_certificates',!!has_certificates ? 1 : 0);
        data.append('has_send_rfq',!!has_send_rfq ? 1 : 0);
        data.append('has_receive_public_rfq',!!has_receive_public_rfq ? 1 : 0);
        data.append('has_receive_private_rfq',!!has_receive_private_rfq ? 1 : 0);
        data.append('has_backlink',!!has_backlink ? 1 : 0);
        data.append('has_social_media_marketing',!!has_social_media_marketing ? 1 : 0);
        data.append('has_support',!!has_support ? 1 : 0);
        data.append('has_youtube',!!has_youtube ? 1 : 0);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/plan/make`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(editMode ? translate('dataEdited') : translate('dataAdded'), {
                    variant: 'success',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                    SubmitDraftLoading: false
                });
                if(!this.state.editMode){
                    this.props.history.push(`/manage/plans`);
                }
            })
            .catch(error => {
                console.log(error.response);
                if(error.response.status === 403){
                    this.props.enqueueSnackbar(translate('categoryPublishedBefore'), {
                        variant: 'error',
                    });
                }else{
                    this.props.enqueueSnackbar(editMode ?  translate('dataEditingFailed') : translate('dataAddingFailed'), {
                        variant: 'error',
                    });
                }
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                    SubmitDraftLoading: false
                });
            });

    }

    handleSubmit(e){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    handleOutPut(code){
        this.setState({
            content:code
        })
    }
    handleChangeConfirmed(){
        this.setState({
            confirmed: !this.state.confirmed,
        })
    }
    handleChangeHideProducts(){
        this.setState({
            hide_products: !this.state.hide_products,
        })
    }
    handleParent(value){
        if(value === null){
            this.setState({
                parentId:''
            })
        }else{
            this.setState({
                parentId:value.id
            })
        }
    }
    async handleAddChip(item){
        let {features} = this.state;
        if(features.length < 3){
            features.push(item);
            this.setState({
                features
            })
        }else{
            this.setState({
                features
            })
        }

    }
    async handleDeleteChip(chip, index){
        let {features} = this.state;
        features.splice(index, 1);
        this.setState({
            features
        })
        this.setState({
            features
        })
    }
    async handleAddChipArabic(item){
        let {arabic_features} = this.state;
        if(arabic_features.length < 3){
            arabic_features.push(item);
            this.setState({
                arabic_features
            })
        }else{
            this.setState({
                arabic_features
            })
        }

    }
    async handleDeleteChipArabic(chip, index){
        let {arabic_features} = this.state;
        arabic_features.splice(index, 1);
        this.setState({
            arabic_features
        })
        this.setState({
            arabic_features
        })
    }

    render(){
        let {fields, errors,SubmitLoading,thumbnail,parents,editMode,parentId,
            verify_badge, has_products_top_list,show_in_popular, has_commercial_banner, has_business_page, has_banner_design,
            has_products_showcase, has_business_catalogue, has_business_video, has_business_image,
            has_certificates, has_send_rfq, has_receive_public_rfq, has_receive_private_rfq,
            has_backlink, has_social_media_marketing, has_support,has_youtube,confirmed
        } = this.state;

        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={editMode ? translate('editPlan') : translate('makePlan')}>
            {
                        <Grid container spacing={2} justify="center">
                            <Grid item xs={12} lg={11}>
                                <AppCard
                                    title={editMode ? translate('editPlan') : translate('makePlan')}
                                    action={
                                        <BreadCrumbs singleLevel firstText={editMode ? translate('editPlan') : translate('makePlan')}/>
                                    }>
                                    {
                                        this.state.loading ? <AppCardLoaderFields/> :
                                            <Grid container spacing={2} >
                                                <Grid item xs={12}>
                                                    <Alert
                                                        severity="info"
                                                        action={
                                                            <FormControlLabel
                                                                style={{marginRight:10}}
                                                                control={
                                                                    <Switch
                                                                        checked={confirmed}
                                                                        onChange={this.handleChangeConfirmed.bind(this)}
                                                                        value={confirmed}
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label={confirmed ? translate('confirmed') : translate('unConfirmed')}
                                                            />
                                                        }
                                                    >
                                                        {translate('confirmationStatusDescription')}
                                                    </Alert>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Autocomplete
                                                        options={parents}
                                                        defaultValue={parents[this.state.defaultParent]}
                                                        onChange={(event, value) => this.handleParent(value)}
                                                        getOptionLabel={option => this.props.locale === "en" ? option.name : option.arabic_name}
                                                        renderInput={params => (
                                                            <TextField
                                                                {...params}
                                                                label={translate('planParent')}
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        variant="outlined"
                                                        name="duration"
                                                        label={translate('durationPlan')}
                                                        type="number"
                                                        value={fields.duration}
                                                        onChange={this.handleChange.bind(this)}
                                                        error = {!!errors['duration']}
                                                        helperText={errors['duration']}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    {translate('days')}
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <CurrencyTextField
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        currencySymbol={currencySymbol}
                                                        decimalPlaces={2}
                                                        minimumValue="0"
                                                        value={fields.price}
                                                        id="price"
                                                        label={translate('price')}
                                                        name="price"
                                                        onChange={this.handleChange.bind(this)}
                                                        error={!!errors['price']}
                                                        helperText={errors['price']}
                                                        className={this.classes.currencyTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <CurrencyTextField
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        currencySymbol={currencySymbol}
                                                        decimalPlaces={2}
                                                        minimumValue={0}
                                                        value={fields.discount_price}
                                                        id="discount_price"
                                                        label={translate('discountPrice')}
                                                        name="discount_price"
                                                        onChange={this.handleChange.bind(this)}
                                                        error={!!errors['discount_price']}
                                                        helperText={errors['discount_price']}
                                                        className={this.classes.currencyTextField}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        variant="outlined"
                                                        name="make_products"
                                                        label={translate('make_products')}
                                                        type="number"
                                                        value={fields.make_products}
                                                        onChange={this.handleChange.bind(this)}
                                                        error = {!!errors['make_products']}
                                                        helperText={errors['make_products']}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        variant="outlined"
                                                        name="answer_to_rfq"
                                                        label={translate('answer_to_rfq')}
                                                        type="number"
                                                        value={fields.answer_to_rfq}
                                                        onChange={this.handleChange.bind(this)}
                                                        error = {!!errors['answer_to_rfq']}
                                                        helperText={errors['answer_to_rfq']}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6} lg={4}>
                                                    <FormControl required  component="fieldset">
                                                        <FormControlLabel
                                                            control={<Checkbox checked={verify_badge} onChange={() => this.setState({verify_badge:!verify_badge})} />}
                                                            label={
                                                                <Typography className={this.classes.privacy_policy}>
                                                                    {translate('verify_badge')}
                                                                </Typography>
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={6} lg={4}>
                                                    <FormControl required  component="fieldset">
                                                        <FormControlLabel
                                                            control={<Checkbox checked={has_products_top_list} onChange={() => this.setState({has_products_top_list:!has_products_top_list})} />}
                                                            label={
                                                                <Typography className={this.classes.privacy_policy}>
                                                                    {translate('has_products_top_list')}
                                                                </Typography>
                                                            }
                                                        />
                                                    </FormControl>
                                                    </Grid>
                                                <Grid item xs={12} md={6} lg={4}>
                                                    <FormControl required  component="fieldset">
                                                        <FormControlLabel
                                                            control={<Checkbox checked={show_in_popular} onChange={() => this.setState({show_in_popular:!show_in_popular})} />}
                                                            label={
                                                                <Typography className={this.classes.privacy_policy}>
                                                                    {translate('show_in_popular')}
                                                                </Typography>
                                                            }
                                                        />
                                                    </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <FormControl required  component="fieldset">
                                                            <FormControlLabel
                                                                control={<Checkbox checked={has_commercial_banner} onChange={() => this.setState({has_commercial_banner:!has_commercial_banner})} />}
                                                                label={
                                                                    <Typography className={this.classes.privacy_policy}>
                                                                        {translate('has_commercial_banner')}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <FormControl required  component="fieldset">
                                                            <FormControlLabel
                                                                control={<Checkbox checked={has_business_page} onChange={() => this.setState({has_business_page:!has_business_page})} />}
                                                                label={
                                                                    <Typography className={this.classes.privacy_policy}>
                                                                        {translate('has_business_page')}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <FormControl required  component="fieldset">
                                                            <FormControlLabel
                                                                control={<Checkbox checked={has_banner_design} onChange={() => this.setState({has_banner_design:!has_banner_design})} />}
                                                                label={
                                                                    <Typography className={this.classes.privacy_policy}>
                                                                        {translate('has_banner_design')}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <FormControl required  component="fieldset">
                                                            <FormControlLabel
                                                                control={<Checkbox checked={has_products_showcase} onChange={() => this.setState({has_products_showcase:!has_products_showcase})} />}
                                                                label={
                                                                    <Typography className={this.classes.privacy_policy}>
                                                                        {translate('has_products_showcase')}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <FormControl required  component="fieldset">
                                                            <FormControlLabel
                                                                control={<Checkbox checked={has_business_catalogue} onChange={() => this.setState({has_business_catalogue:!has_business_catalogue})} />}
                                                                label={
                                                                    <Typography className={this.classes.privacy_policy}>
                                                                        {translate('has_business_catalogue')}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <FormControl required  component="fieldset">
                                                            <FormControlLabel
                                                                control={<Checkbox checked={has_business_video} onChange={() => this.setState({has_business_video:!has_business_video})} />}
                                                                label={
                                                                    <Typography className={this.classes.privacy_policy}>
                                                                        {translate('has_business_video')}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <FormControl required  component="fieldset">
                                                            <FormControlLabel
                                                                control={<Checkbox checked={has_business_image} onChange={() => this.setState({has_business_image:!has_business_image})} />}
                                                                label={
                                                                    <Typography className={this.classes.privacy_policy}>
                                                                        {translate('has_business_image')}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <FormControl required  component="fieldset">
                                                            <FormControlLabel
                                                                control={<Checkbox checked={has_certificates} onChange={() => this.setState({has_certificates:!has_certificates})} />}
                                                                label={
                                                                    <Typography className={this.classes.privacy_policy}>
                                                                        {translate('has_certificates')}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <FormControl required  component="fieldset">
                                                            <FormControlLabel
                                                                control={<Checkbox checked={has_send_rfq} onChange={() => this.setState({has_send_rfq:!has_send_rfq})} />}
                                                                label={
                                                                    <Typography className={this.classes.privacy_policy}>
                                                                        {translate('has_send_rfq')}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <FormControl required  component="fieldset">
                                                            <FormControlLabel
                                                                control={<Checkbox checked={has_receive_public_rfq} onChange={() => this.setState({has_receive_public_rfq:!has_receive_public_rfq})} />}
                                                                label={
                                                                    <Typography className={this.classes.privacy_policy}>
                                                                        {translate('has_receive_public_rfq')}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <FormControl required  component="fieldset">
                                                            <FormControlLabel
                                                                control={<Checkbox checked={has_receive_private_rfq} onChange={() => this.setState({has_receive_private_rfq:!has_receive_private_rfq})} />}
                                                                label={
                                                                    <Typography className={this.classes.privacy_policy}>
                                                                        {translate('has_receive_private_rfq')}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <FormControl required  component="fieldset">
                                                            <FormControlLabel
                                                                control={<Checkbox checked={has_backlink} onChange={() => this.setState({has_backlink:!has_backlink})} />}
                                                                label={
                                                                    <Typography className={this.classes.privacy_policy}>
                                                                        {translate('has_backlink')}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <FormControl required  component="fieldset">
                                                            <FormControlLabel
                                                                control={<Checkbox checked={has_social_media_marketing} onChange={() => this.setState({has_social_media_marketing:!has_social_media_marketing})} />}
                                                                label={
                                                                    <Typography className={this.classes.privacy_policy}>
                                                                        {translate('has_social_media_marketing')}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <FormControl required  component="fieldset">
                                                            <FormControlLabel
                                                                control={<Checkbox checked={has_support} onChange={() => this.setState({has_support:!has_support})} />}
                                                                label={
                                                                    <Typography className={this.classes.privacy_policy}>
                                                                        {translate('has_support')}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <FormControl required  component="fieldset">
                                                            <FormControlLabel
                                                                control={<Checkbox checked={has_youtube} onChange={() => this.setState({has_youtube:!has_youtube})} />}
                                                                label={
                                                                    <Typography className={this.classes.privacy_policy}>
                                                                        {translate('has_youtube')}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </FormControl>
                                                    </Grid>


                                                <Hidden mdDown>
                                                    <Grid item xs={12}>
                                                        <LoadingButton
                                                            variant="contained"
                                                            color="primary"
                                                            onClick = {this.handleSubmit.bind(this)}
                                                            loading = {SubmitLoading}
                                                        >
                                                            {editMode ?translate('editPlan') : translate('makePlan')}
                                                        </LoadingButton>
                                                    </Grid>
                                                </Hidden>
                                            </Grid>
                                    }
                                </AppCard>
                            </Grid>

                        </Grid>
                }
            </AppAnimate>

        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,
    fake_locale: state.fake_locale,
    locale: state.locale,

});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MakePlan))));