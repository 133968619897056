import React from "react";
import {withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../config/design/styles";
import {connect} from "trim-redux";
import axios from "axios";
import {defaultVariabls} from "../../../../config";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField/TextField";
import {translate} from "../../../../config/lang";

class BusinessTypeChooser extends React.Component{
    state = {
        types:[],
        selected:!!this.props.selected ? this.props.selected : null,
        loading:true,
        open:false,
    };
    async componentDidMount(){
        await this.loadData();
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.get(`${defaultVariabls.url}/business_types/getAll`, config)
            .then(response => {
                if(this.props.selected){
                    this.setState({
                        types:response.data,
                        loading:false,
                    })
                }else{
                    this.setState({
                        types:response.data,
                        loading:false,
                    })
                }


            })
            .catch(error => {
                this.setState({
                    loading:false
                })
            });
    }
    handleType(data){
        this.props.handleType(data);
        this.setState({
            selected:data
        })
    }
    render(){
        let {types,loading,selected,open} = this.state;
        let{required,error,fake_locale,size} = this.props;
    return (
        <>
            <Autocomplete
                fullWidth
                open={open}
                onOpen={() => {
                    this.setState({open:true})
                }}
                onClose={() => {
                    this.setState({open:false})
                }}
                required={required}
                getOptionLabel={(option) => fake_locale === "ar" ? option.arabic_name : option.name}
                onChange={(event, value) => this.handleType(value)}
                options={types}
                value={selected}
                loading={loading}
                color="secondary"
                size={!!size ? size : ''}
                disabled={this.props.disabled}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={translate('business_type')}
                        variant="outlined"
                        required={required}
                        color="secondary"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                        error={error}
                        helperText={!!error && error}
                    />
                )}
            />
        </>
    );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    fake_locale: state.fake_locale,

});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(BusinessTypeChooser))));