import React from 'react';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {DataTableLocalization} from "../../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../../config/index";
import {connect} from "trim-redux";
import MaterialTable from "material-table";
import {tableIcons} from "../../../../../config/design/TableIcons";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import {TableCount, TablePage} from "../../../../../config/Permissions";
import Container from "@material-ui/core/Container/Container";
import {Translate} from "../../../../../config/Translate";
import AppCardLoaderTable from "../../../../components/Skeletons/AppCardLoaderTable";
import withWidth from "@material-ui/core/withWidth/withWidth";
import MakeBannerButton from "./Make‌BannerButton";

class Banners extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    state = {
        columns: [
            {
                field: 'thumbnail',
                editable: 'never',
                filtering: false,
                title: Translate.thumbnail,
                render: rowData => <img alt="avatar" src={rowData.thumbnail} className={this.classes.FMThumbnail}/>
            },
            {
                title: Translate.bannerPosition,
                field: 'position',
                filterPlaceholder: Translate.search,
                render: rowData => Translate[rowData.position]

            },
            {
                title: Translate.bannerTitle,
                field: 'title',
                filterPlaceholder: Translate.search,
            },
            { title: Translate.activity,
                editable: 'never',
                field: 'activity',
                render: rowData =>
                    <MakeBannerButton id={rowData.id} reload={() => this.loadData()}/>
            },
        ],
        data: [],
        loading:false,
        ChangeLoader:false
    };
    loadData(){
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }

    onDelete(id){
        let data = new FormData();
        data.append('id',  id);
        this.setState({
            loading: false
        });
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/banner/delete`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.deleteData, {
                    variant: 'success',
                });
                return true
            })
            .catch(error => {
                console.log(error.response);
                this.loadData();
                this.props.enqueueSnackbar(Translate.deleteDataFailed, {
                    variant: 'error',
                });

                return false
            });
    }

    render(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        return (
            <>
                {
                    this.state.loading ? <AppCardLoaderTable/> :
                        <Container maxWidth="xl">
                            <MaterialTable
                                tableRef={this.tableRef}
                                icons={tableIcons}
                                title={" "}
                                localization={DataTableLocalization}
                                columns={this.state.columns}
                                data={query =>
                                    new Promise((resolve, reject) => {
                                        axios.get(`${defaultVariabls.url}/banners/quickView?page=${query.page + 1}&per_page=${query.pageSize}&search=${query.search}`, config)
                                            .then(response => {
                                                let data = [];
                                                // eslint-disable-next-line
                                                response.data.data.map((row) => {
                                                    data.push({
                                                        thumbnail:`${defaultVariabls.uploads}/FileManager/${row.thumbnail.name}_small.${row.thumbnail.extension}`,
                                                        position: row.position,
                                                        title: row.title,
                                                        id: row.id,
                                                    })
                                                });
                                                resolve({
                                                    data: data,
                                                    page: response.data.current_page - 1,
                                                    totalCount: response.data.total,
                                                })
                                            }).catch(error => {
                                            console.log(error)
                                        })
                                    })
                                }
                                options={{
                                    pageSize: TablePage,
                                    pageSizeOptions: TableCount,
                                }}
                                editable={{
                                    onRowDelete: oldData =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                this.onDelete(oldData.id);
                                                this.setState(prevState => {
                                                    const data = [...prevState.data];
                                                    data.splice(data.indexOf(oldData), 1);
                                                    return { ...prevState, data };
                                                });
                                            }, 600);
                                        }),
                                }}
                            />
                        </Container>
                }
            </>
        );
    }
}

const mstp = state => ({
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(Banners)))));