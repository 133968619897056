import React, { PureComponent } from 'react';
import {AreaChart, Area, ResponsiveContainer} from 'recharts';

const data = [
    {
        name: 'Page A', uv: 1000, pv: 1400, amt: 1400,
    },
    {
        name: 'Page B', uv: 3000, pv: 1398, amt: 2210,
    },
    {
        name: 'Page C', uv: 2000, pv: 9800, amt: 2290,
    },
    {
        name: 'Page D', uv: 2780, pv: 3908, amt: 2000,
    },
    {
        name: 'Page E', uv: 1890, pv: 4800, amt: 2181,
    },
    {
        name: 'Page F', uv: 2390, pv: 3800, amt: 2500,
    },
    {
        name: 'Page G', uv: 3490, pv: 4300, amt: 2100,
    },
];

export default class TinyAreaChart extends PureComponent {
    render() {
        return (
            <ResponsiveContainer>
            <AreaChart
                height={60}
                data={data}
                margin={{
                     right: 0, left: 0,
                }}
            >
                <Area type="monotone" dataKey="uv" stroke="#ffef06" fill="#ffef06" />
            </AreaChart>
            </ResponsiveContainer>
        );
    }
}
