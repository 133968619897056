import React from 'react';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {Link} from 'react-router-dom';
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../config/design/styles";
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import {connect} from "trim-redux";
import {translate} from "../../../config/lang";

class BreadCrumbs extends React.Component {
    classes = this.props.classes;
    render(){
        let {singleLevel,firstText,firstLink,secondText,locale} = this.props;
        return (
            <Breadcrumbs separator={locale ==="ar" ? <ChevronRightRoundedIcon/> : <ChevronRightRoundedIcon/>  } aria-label="breadcrumb" className={this.classes.breadCrumb}>
                <Link
                    to="/dashboard"
                    className={this.classes.BreadLink}
                >
                    {translate('userPanel')}
                </Link>
                {
                    singleLevel ?
                        <Typography color="secondary" className={this.classes.BreadLink}>
                            {firstText}
                        </Typography> :
                        <Link
                            to={firstLink}
                            className={this.classes.BreadLink}
                        >
                            {firstText}
                        </Link>
                }
                {
                    !singleLevel ?
                        <Typography color="secondary" className={this.classes.BreadLink}>
                            {secondText}
                        </Typography> : null

                }

            </Breadcrumbs>
        );
    }
}
const mstp = state => ({
    locale: state.locale,
    Translate: state.Translate,
});
export default connect(mstp)(withStyles(Styles)(BreadCrumbs));
