import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {DataTableLocalization, getDataTableLocalization, Translate} from "../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import MaterialTable from "material-table";
import {tableIcons} from "../../../../config/design/TableIcons";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import moment from "moment-timezone";
import BreadCrumbs from "../../../components/elements/BreadCrumbs";
import {TableCount, TablePage} from "../../../../config/Permissions";
import Container from "@material-ui/core/Container/Container";
import SubTitle from "../../../components/elements/SubTitle";
import Button from "@material-ui/core/Button/Button";
import AppCardLoaderTable from "../../../components/Skeletons/AppCardLoaderTable";
import AppCard from "../../../components/layouts/components/AppCard";
import AppAnimate from "../../../components/AppAnimate";
import Typography from "@material-ui/core/Typography/Typography";
import {makeSlug, translate} from "../../../../config/lang";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {connect} from "trim-redux";
import {getTimeZone} from "../../../../config/values";

class ManagePage extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    state = {
        columns: [
            { title: translate('userName'), field: 'name'},
            { title: translate('country'), field: 'country'},
            { title: translate('company_name'), field: 'company_name'},
            { title: translate('mobile'), field: 'mobile'},
            { title: translate('email'), field: 'email'},
            { title: translate('simple_user'), field: 'user_id'},
            {
                title: translate('created_at'),
                editable: 'never',
                field: 'created_at',
                render: rowData =>
                    <Typography className={this.classes.dateTable}>
                        {`${moment.tz(rowData.created_at,'UTC').tz(getTimeZone()).fromNow()}`}
                        <br/>
                        {`${moment.tz(rowData.created_at,'UTC').tz(getTimeZone()).format( 'HH:mm , YYYY/M/D')}`}
                    </Typography>
            },
        ],
        data: [],
        loading:false,
        ChangeLoader:false
    };
    componentDidMount(){
        this.loadData();
    }
    loadData(){
        this.tableRef.current && this.tableRef.current.onQueryChange()

    }
    onDelete(id,hasNotif = true) {
        let data = new FormData();
        data.append('id', id);
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/pages/contact/delete`, data, config)
            .then(response => {
                if(hasNotif){
                    this.props.enqueueSnackbar(translate('deleteData'), {
                        variant: 'success',
                    });
                }
                return true
            })
            .catch(error => {
                this.props.enqueueSnackbar(translate('deleteDataFailed'), {
                    variant: 'error',
                });
                return false
            });
    }
    render(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        return (
                <AppAnimate animation='transition.slideUpIn' delay={200} title={translate('manageContact')}>
                    <Grid container spacing={2} justify="center">
                        <Grid item xs={12} lg={11}>
                            <AppCard
                                title={ translate('manageContact')}
                                action={
                                    <BreadCrumbs singleLevel firstText={translate('manageContact')}/>
                                }>
                                {
                                    this.state.loading ? <AppCardLoaderTable/> :
                                        <Container maxWidth="xl">
                                            <MaterialTable
                                                tableRef={this.tableRef}
                                                icons={tableIcons}
                                                onRowClick={(event, rowData, togglePanel) => togglePanel()}
                                                title={
                                                    <a href={makeSlug('contact')} target="_blank"  rel="noopener noreferrer" >
                                                        <Button color="primary" variant="contained">
                                                            {translate('manageContact')}
                                                        </Button>
                                                    </a>
                                                }
                                                localization={getDataTableLocalization()}
                                                columns={this.state.columns}
                                                data={query =>
                                                    new Promise((resolve, reject) => {
                                                        axios.get(`${defaultVariabls.url}/pages/contact/quickView?page=${query.page + 1}&per_page=${query.pageSize}&search=${query.search}`, config)
                                                            .then(response => {
                                                                let data = [];
                                                                // eslint-disable-next-line
                                                                response.data.data.map((row) => {
                                                                    data.push({
                                                                        name: row.name,
                                                                        mobile: row.mobile,
                                                                        country: row.country,
                                                                        company_name: row.company_name,
                                                                        id: row.id,
                                                                        user_id: row.user_id,
                                                                        description: row.description,
                                                                        email: row.email,
                                                                        created_at: row.created_at,
                                                                    })
                                                                });
                                                                resolve({
                                                                    data: data,
                                                                    page: response.data.current_page - 1,
                                                                    totalCount: response.data.total,
                                                                })
                                                            }).catch(error => {
                                                            console.log(error.response)
                                                        })
                                                    })
                                                }
                                                options={{
                                                    pageSize: TablePage,
                                                    pageSizeOptions: TableCount,
                                                }}
                                                detailPanel={rowData => {
                                                    return (
                                                        <Grid container spacing={2}   direction="row" justify="center">
                                                            <Grid item xs={12} md={6}>
                                                                <SubTitle align="left">{translate('comment')}</SubTitle>
                                                                <p>{rowData.description}</p>
                                                            </Grid>
                                                            <Grid item xs={12} md={4}>
                                                                <SubTitle align="left">{translate('email')}</SubTitle>
                                                                <p dir={"ltr"}>{rowData.email}</p>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                }}
                                                editable={{
                                                    onRowDelete: oldData =>
                                                        new Promise(resolve => {
                                                            setTimeout(() => {
                                                                resolve();
                                                                this.onDelete(oldData.id);
                                                                this.setState(prevState => {
                                                                    const data = [...prevState.data];
                                                                    data.splice(data.indexOf(oldData), 1);
                                                                    return { ...prevState, data };
                                                                });
                                                            }, 600);
                                                        }),
                                                }}

                                            />
                                        </Container>
                                }
                            </AppCard>
                        </Grid>
                    </Grid>
                </AppAnimate>
        );
    }
}

const mstp = state => ({
    user: state.user,
    features: state.features,
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(ManagePage)))));
