import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import {connect, getStore, setStore} from "trim-redux";
import {Translate} from "../../../../../config/Translate";
import {Link} from 'react-router-dom';
import {defaultVariabls} from "../../../../../config";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import IconButton from "@material-ui/core/IconButton/IconButton";
import SettingsApplicationsRoundedIcon from '@material-ui/icons/SettingsApplicationsRounded';
import PowerSettingsNewRoundedIcon from '@material-ui/icons/PowerSettingsNewRounded';
import GetConfirmationButton from "../../../elements/GetConfirmationButton";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {makeSlug, translate} from "../../../../../config/lang";
import Button from "@material-ui/core/Button/Button";
import Icon from "@material-ui/core/Icon/Icon";
import PermContactCalendarTwoToneIcon from '@material-ui/icons/PermContactCalendarTwoTone';
import ToggleDarkMode from "../../../elements/ToggleDarkMode";
import withTheme from "@material-ui/core/styles/withTheme";
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
const useStyles = makeStyles((theme) => {
    return {
        crUserInfo: {
            paddingTop: 9,
            paddingBottom: 9,
            minHeight: 75,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            [theme.breakpoints.up('sm')]: {
                paddingTop: 10,
                paddingBottom: 10,
            },
        },
        shadowText:{
            textShadow:getStore('mode') === "dark" ? "none"  : "0 0 3px #ffef06",
            color: getStore('mode') === "dark" ? "#ddd" : "#333",
        },
        profilePic: {
            fontSize: 24,
        },
        userInfo: {
            width: 'calc(100% - 75px)',
        },
        userName: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontSize: 16,
            color: '#fff',
        },
        designation: {
            marginTop: -2,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            color: theme.palette.text.secondary,
        },
        pointer: {
            cursor: 'pointer',
        },
        link:{
            cursor:"pointer"
        }
    };
});

const UserInfo = (props) => {
    const handleLogout = () => {
        localStorage.removeItem('ACT');
        localStorage.removeItem('RET');
        window.location.replace(`${defaultVariabls.website}/logOutFromPanel`);
    };
    const classes = useStyles();
    let {user,primary_color} = props;
    return (
        <Box
            px={{xs: 2, xl: 4}}
            className={clsx(classes.crUserInfo, 'cr-user-info')}>
            <Grid container spacing={2} alignItems="center" justifyContent="between">
                <Grid item xs={10}>
                    <Grid container spacing={2} alignItems="center" justify="center">
                        <Grid item xs={4} style={{textAlign:'center'}}>
                            <Link to="/account/details">
                                <Avatar style={{width:55,height:55,transform:"translateX(50%)",right:"50%"}} className={classes.profilePic} src={`${defaultVariabls.uploads}/FileManager/${user.avatar.name}_small.${user.avatar.extension}`}  alt={user.name} />
                            </Link>
                        </Grid>
                        <Grid item xs={8} style={{padding:0,paddingLeft:8}}>
                            <Typography  className={clsx("only-one-line",classes.shadowText)} >
                                {user.first_name} {user.last_name}
                            </Typography>
                            <Typography varaint="body2"  style={{fontSize:13,color:"#fff"}}>
                                {
                                    user.role.type === "admin" ?
                                        <Button
                                            className={classes.shadowText}
                                            size="small"
                                            startIcon={<PermContactCalendarTwoToneIcon/>}
                                        >
                                            {translate(user.role.name)}
                                        </Button> :
                                        user.plan.parent.type === "premium" ?
                                        <Button
                                            color="secondary"
                                            size="small"
                                            component={Link}
                                            to={'/my-plan'}
                                            style={{whiteSpace:"nowrap"}}
                                            startIcon={<img style={{width:20}} src={`${defaultVariabls.uploads}/FileManager/${user.plan.parent.thumbnail.name}_small.${user.plan.parent.thumbnail.extension}`}  />}
                                        >
                                            {user.plan.parent.name}
                                        </Button> :
                                            <Button
                                                color="secondary"
                                                size="small"
                                                component="a"
                                                style={{whiteSpace:"nowrap"}}
                                                startIcon={!!user.plan ? <img style={{width:20}} src={`${defaultVariabls.uploads}/FileManager/${user.plan.parent.thumbnail.name}_small.${user.plan.parent.thumbnail.extension}`}  /> : ''}
                                                href={makeSlug('plans')}
                                            >
                                                {!!user.plan ? user.plan.parent.name : translate('becomePremium')}
                                            </Button>
                                }

                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2} style={{paddingTop:0}}>
                    <Grid container alignItems="start" justify="end">
                        {/* <Grid item xs={12}>
                          <ToggleDarkMode/>
                      </Grid>*/}
                        <Grid item xs={12}>
                            <GetConfirmationButton
                                handleAction={handleLogout}
                                question={translate('doYouWanaExit')}
                                icon={<ExitToAppRoundedIcon/>}
                                color={props.theme.palette.secondary.main}
                                tooltip={translate('logOut')}
                                size="small"
                            >
                                <PowerSettingsNewRoundedIcon style={{color: getStore('mode') === 'dark' ? "#ddd" : "#333"}} fontSize="small"/>
                            </GetConfirmationButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_color: state.primary_color,
});
export default withTheme(connect(mstp)(UserInfo));
