import React from 'react';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker from "react-modern-calendar-datepicker";
import TextField from "@material-ui/core/TextField/TextField";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import moment from "jalali-moment";
import {Translate} from "../../../config/Translate";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import EventNoteRoundedIcon from '@material-ui/icons/EventNoteRounded';

class ChooseDate extends React.Component {
    classes = this.props.classes;
    constructor(props){
        super(props);
        let date = this.props.input;
        if(date){
            date = moment(date)
                .locale('en')
                .format('jYYYY-jM-jD');
            date = date.split("-");
            this.state= {
                date: {
                    year: parseInt(date[0]),
                    month: parseInt(date[1]),
                    day: parseInt(date[2])
                },
                input:this.props.input
            }
        }else{
            this.state = {
                date:null,
                input:this.props.input

            }
        }

    }
    componentDidUpdate(oldProps){
        if(this.state.input !== this.props.input){
            let date = this.props.input;
            if(date){
                date = moment(date)
                    .locale('en')
                    .format('jYYYY-jM-jD');
                date = date.split("-");
                this.setState({
                    date: {
                        year: parseInt(date[0]),
                        month: parseInt(date[1]),
                        day: parseInt(date[2])
                    },
                    input:this.props.input
                })
            }else{
                this.setState({
                    date:null,
                    input:this.props.input

                })
            }
        }
    }
    handleDate(date) {
        this.setState({
            date
        })
        date = date.year+"-"+date.month+"-"+date.day;
        date = moment.from(date, 'fa', 'YYYY-M-D').locale('en')
            .format('YYYY-M-D');
        this.props.handleDate(date);
    }

    renderCustomInput = ({ref}) => (
        <TextField
            readOnly
            fullWidth
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <EventNoteRoundedIcon />
                    </InputAdornment>
                ),
            }}
            size={this.props.size ? this.props.size : 'small'}
            margin={this.props.noMargin ? "" : "normal"}
            ref={ref} // necessary
            label={this.props.label ? this.props.label : Translate.chooseDate}
            value={this.state.date !== null ? `${this.state.date.year}/${this.state.date.month}/${this.state.date.day}` : ''}
        />
    )

    render() {
        let {date} = this.state;
        return (
            <Grid container direction="row" alignItems="center" justify="center">
                <DatePicker
                    value={date}
                    onChange={this.handleDate.bind(this)}
                    shouldHighlightWeekends
                    renderInput={this.renderCustomInput.bind(this)}
                    locale={"ar"}
                />
                {
                    this.props.error ?
                        <Typography className="errorText" color="error" variant="body2">
                            {this.props.error}
                        </Typography>
                        : null
                }
            </Grid>
        );
    }
}

export default ChooseDate;