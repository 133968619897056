import SettingsIcon from '@material-ui/icons/Settings';
import React from 'react';
import {getStore} from "trim-redux";
import {translate} from "./lang";

export const Translate = getStore('locale') === "ar" ? {
    /********Specific*******/
    app_name: "نام وب سایت",
    appName: "پنل کاربری",
    sendMessageToUser: "ارسال پیام به این کاربر",
    sentTicket: "ارسال شده",
    read: "خوانده شده",
    blog: "وبلاگ",
    fa: "فارسی",
    en: "English",
    saveCode: "ذخیره کد",
    myDetails: "اطلاعات من",
    todaySales: "فروش امروز",
    writeYourCodehere: "کد خود را اینجا بنویسید",
    pageCover: "تصویر کاور صفحه",
    siteInformation: "اطلاعات اصلی سایت",
    contactInformation: "اطلاعات تماس سایت",
    changePrimaryColor: "تغییر رنگ اصلی",
    changeSecondaryColor: "تغییر رنگ ثانویه",
    viewPage: "مشاهده صفحه",
    changeColor: "تغییر رنگ",
    editMenu: "ویرایش آیتم منو",
    saveChanges: "ذخیره تغییرات",
    site_logo: "لوگو سایت",
    favicon: "نمادک سایت (FavIcon)",
    square_logo: "لوگو مربع",
    page_header: "هدر صفحات داخلی",
    youCantDeletePermenantpages: "امکان حذف صفحات اصلی وجود ندارد!",
    addMenu: "افزودن آیتم منو",
    content: "نوشته",
    submit: "ثبت",
    custom_css: "کد CSS اضافی",
    custom_js: "کد Java Script اضافی",
    custom_head: "کد اضافه در <head> سایت",
    custom_body: "کد اضافه در <body> سایت",
    custom_code: "کد اضافی",
    main_phone: "شماره تلفن سایت",
    main_email: "ایمیل سایت",
    main_mobile: "شماره موبایل سایت",
    main_address: "آدرس",
    customCodeTitle: "کد‌های مورد نیاز خود را اضافه کنید!",
    customCodeText: "در این قسمت می‌توانید کد‌های مورد نیاز خود را در بخش‌های مختلف سایتتان اضافه و آن‌ها را سفارشی‌سازی کنید!",
    facebook: "لینک Facebook",
    linkedin: "لینک LinkedIn",
    pinterest: "لینک Pinterest",
    instagram: "لینک Instagram",
    twitter: "لینک Twitter",
    whatsapp: "لینک Whatsapp",
    telegram: "لینک Telegram",
    headerAndFooter: "مدیریت هدر و فوتر",
    short_description: "توضیح کوتاه سایت",
    footer_text: "متن توضیح فوتر",
    footer_certificates: "کد نماد‌های فوتر",
    map_script: "کد نقشه صفحه تماس",
    bg_image: "تصویر پس‌زمینه",
    gradientBg: "پس‌زمینه گردینت",
    solidBg: "پس‌زمینه رنگ ساده",
    imageBg: "پس‌زمینه با تصویر",
    gradientBgFirstColor: "رنگ اول گردینت",
    gradientBgSecondColor: "رنگ دوم گردینت",
    bg_type: "نوع تصویر پس زمینه",
    slideShowImages: "المان‌های تصویری",
    SlideShowContent: "محتوای اسلایدشو",
    nextStep: "بخش بعدی",
    mainImageSlide: "تصویر اصلی اسلاید",
    chooseColor: "انتخاب رنگ",
    chooseFormat: "انتخاب فرمت",
    chooseOwnerFile: "انتخاب منتشر کننده",
    verify_product: "تایید محصول",
    factorConfirmedToPurchase: "وضعیت سفارش ثبت شد!",
    templateUpdated: "قالب سایت آپدیت شد!",
    reject_product: "رد محصول",
    manageTemplate: "مدیریت قالب",
    exportUsersList: "دانلود لیست کاربران",
    weightChoosingFailed: "بازه وزنی ایراد دارد!",
    welcomeToAgentPanel: "به پنل نمایندگی خوش آمدید!",
    agentTerms: "قوانین و مقررات پنل نمایندگی",
    globalNeedVerification: "تایید سفارشات قبل از پرداخت",
    noGlobalNeedVerification: "خیر",
    noContent: "نوشته‌ای یافت نشد!",
    yesGlobalNeedVerification: "همه سفارشات قبل از پرداخت نیاز به تایید دارند!",
    pleaseConfirmAgentTerms: "لطفا قوانین و مقررات مربوط به نمایندگان را مطالعه نموده و تیک تایید را بزنید.",
    changeXPrice: "تغییر عدد x",
    changeProvinceAndCity: "تغییر استان و شهر",
    unread: "در انتظار بررسی",
    need_verification: "در انتظار تایید سفارش",
    need_verification_hint: "نیاز به تایید قبل از سفارش",
    need_verification_hint_description: "امکان سفارش این محصول را رد و یا تایید نمایید.",
    rejectVerificationStatus: "رد امکان سفارش محصول",
    confirmVerificationStatus: "تایید امکان سفارش محصول",
    chooseVerificationStatus: "آیا امکان سفارش این محصول وجود دارد؟",
    need_verification_description: "آیا این محصول، قبل از انجام سفارش، نیاز به بررسی و تایید شما دارد؟",
    need_verification_description_user: "این محصول قبل از انجام سفارش نیاز به بررسی و تایید فروشنده دارد!",
    all_products: "همه محصولات",
    confirmed_products: "محصولات تایید شده",
    unconfirmed_products: "در انتظار تایید",
    all_pricings: "مشاهده همه",
    confirmed_pricings: "قیمت‌گذاری تایید شده",
    unconfirmed_pricings: "در انتظار تایید",
    equalsTo: "معادل",
    gram: "گرم",
    kilogram: "کیلوگرم",
    weightHelper: "جهت محاسبه هزینه ارسال",
    inventoryHelperText: "در صورت عدم نیاز به مدیریت موجودی خالی بگذارید!",
    min_weight: "حداقل وزن",
    max_weight: "حداکثر وزن",
    your_commissions: "لیست کمیسیون‌ها",
    agent_cities: "شهر‌های نماینده",
    sell_agent: "نماینده فروش",
    buy_agent: "نماینده خرید",
    weight: "وزن",
    agent_panel: "پنل نمایندگی",
    agent_panel_desc: "به پنل نمایندگان وب سایت خوش آمدید!",
    yourCities: "شهر‌های تحت نمایندگی شما",
    agent_commission: "پورسانت نماینده",
    viewAll: "مشاهده همه",
    your_related_orders: "سفارش‌های شهر شما",
    addCity: "افزودن شهر",
    agentOrdersTable: "لیست سفارشات نماینده",
    noCityFound: "شهر‌ی یافت نشد!",
    subLine: "جهت بهره‌مندی از ویژگی‌های سایت وارد شوید!",
    viewInvoice: "مشاهده فاکتور",
    viewSellerInvoice: "مشاهده فاکتور فروشنده",
    submitReturnMoney: "ثبت بازگشت مبلغ",
    productWShop: "محصول مربوطه",
    agentsManagement: "مدیریت نمایندگان",
    referrer_code: "کد ",
    sell_commission: "٪ پورسانت فروش ",
    sell_commission_amount: "پورسانت فروش ",
    buy_commission: "٪ پورسانت خرید ",
    buy_commission_amount: "پورسانت خرید ",
    ManageAgent: "مدیریت نماینده",
    Submit: "ثبت",
    report: "گزارش",
    admin_note: "یادداشت ادمین",
    priority: "اولویت",
    low: "کم",
    medium: "متوسط",
    high: "زیاد",
    subject: "موضوع",
    message: "پیام",
    submitReturnMoneyFor: "ثبت بازگشت تراکنش برای فاکتور شماره",
    TransactionRecordSaved: "تراکنش ثبت شد",
    TransactionRecordSavedError: "مشکلی در ثبت تراکنش وجود دارد!",
    bestSellingProducts: "پرفروش ترین محصولات",
    youHaveNoAddress: "هیچ آدرسی ندارید",
    checkProducts: "محصولات مختلف را بررسی کنید",
    firstAddAnAddress: "ابتدا یک آدرس اصافه کنید",
    allOrders: "همه سفارش‌ها",
    addYourAddress: "آدرس خود را وارد کنید!",
    viewDetails: "مشاهده",
    viewTicket: "مشاهده تیکت",
    systemSupportRequest: "درخواست پشتیبانی سیستم",
    productSupportRequest: "درخواست پشتیبانی محصول",
    home: "خانه",
    manageViolations: "مدیریت گزارش‌های تخلف",
    supplier: "فروشنده",
    old_url: "URL قدیمی",
    new_url: "URL جدید",
    related_admin: "ادمین مربوطه",
    department: "دپارتمان",
    departments: "دپارتمان‌ها",
    editRedirect:"ریدایرکت ویرایش شد.",
    editRedirectFailed:"ویرایش ریدایرکت با مشکل رو به رو شد.",
    deleteRedirect:"ریدایرکت حذف شد.",
    deleteRedirectFailed:"حذف ریدایرکت با مشکل رو به رو شد.",
    deleteSelectedRedirects:"حذف ریدایرکت‌های انتخاب شده با مشکل رو به رو شد.",
    addRedirectFailed:"ایجاد ریدایرکت با مشکل رو به رو شد.",
    addRedirect:"ریدایرکت ایجاد شد.",
    redirects : "ریدایرکت‌ها",
    manageRedirects : "مدیریت ریدایرکت‌ها",
    manageTransferPricing : "مدیریت هزینه ارسال",
    finished_inventory: "محصولات ناموجود",
    finishing_inventory: "محصولات رو به اتمام ",
    pending_orders: "سفارشات در جریان",
    done_orders: "سفارش های تکمیل شده  ",
    total_products: "تعداد کل محصولات ",
    total_pending_products: "محصولات درحال بررسی ",
    manageInventory: "مدیریت موجودی",
    addNewProduct: "محصول تازه ایجاد کنید",
    shopIsPending: "در انتظار بررسی!",
    firstCreateShop: "ابتدا فروشگاه بسازید",
    sellerGuidLine: "راهنمای مسیر",
    sellerGuidLineDescription: "گام به گام پیش بروید و با ساخت یک فروشگاه حرفه‌ای، محصولات خود را به مشتریان بیشتری ارائه دهید!",
    userGuidLineDescription: "گام به گام پیش بروید و اطلاعات خود را تکمیل کنید تا ساده تر محصولات مورد نظر خود را بیابید!",
    OrdersChart: "نمودار سفارش‌ها",
    ordersAnalytics: "گزارش سفارشات",
    doneOrders: "سفارش‌های تکمیل شده",
    totalSells: "جمع فروش‌ها",
    thisWeek: "هفته اخیر",
    thisMonth: "ماه اخیر",
    thisYear: "سال اخیر",
    thanks: "با تشکر",
    completeYorDetails: "تکمیل اطلاعات شخصی",
    doCompleteDetails: "اطلاعات خود را تکمیل کنید!",
    inCheckingDetails: "اطلاعات درحال بررسی می‌باشند!",
    compeltedDetails: "حساب کاربری تایید شده!",
    completeYorDetailsDesc: "لطفا اطلاعات شخصی حود را تکمیل نمایید!",
    shopsSells: "حجم فروش فروشندگان",
    totalCommissions: "جمع پورسانت‌ها",
    buyCommissions: "جمع پورسانت‌های خرید",
    payedOrders: "سفارش‌های تسویه شده",
    sellCommissions: "جمع پورسانت‌های فروش",
    viewOtherSellers: "مشاهده سایر فروشندگان",
    deleteProductFromCart: "حذف محصول از سبد خرید",
    firstConfirmUser: "ابتدا اکانت کاربر را تایید کنید!",
    firstConfirmShop: "ابتدا فروشگاه این کاربر را تایید کنید!",
    doYouWantToHideProducts: "آیا می‌خواهید محصولات این دسته را از دید کاربران غیر عضو پنهان کنید؟",
    discountsAttention: "توجه! مبلغ کد تخفیف از پورسانت سایت کسر می‌شود.",
    suppliers: "فروشندگان",
    belowProductsHasAddressError: "محصولات زیر امکان ارسال به آدرس انتخابی شما را ندارند!",
    youHavePendingWithdrawRequest: "شما یک درخواست در انتظار بررسی دارید!",
    cantWithdrawSmallerThan100000: "امکان برداشت کمتر از ۱۰۰ هزار تومان وجود ندارد!",
    fillYourDetailsFirstDescription: "لطفا ابتدا اطلاعات حساب کاربری خود را تکمیل کنید",
    fillYourDetailsFirst: "تکمیل حساب کاربری",
    yourAccountInfoIsPending: "در انتظار بررسی",
    yourAccountInfoIsPendingDesc: "حساب کاربری شما در دست بررسی است! پس از تایید می‌توانید سفارش خود را ثبت کنید.",
    deleteIt: "پاک شود",
    backToPrevPage: "مرحله قبل",
    payToShop: "تسویه با فروشنده",
    payed_to_shop: "تسویه شده با فروشنده",
    purchaseDone: "پرداخت انجام شد!",
    purchaseRejected: "در انجام پرداخت مشکلی پیش آمد!",
    payToShopAreYouSure: "آیا قصد تسویه این سفارش را دارید (با فروشنده و نمایندگان)؟ ",
    changeFactorStatus: "تغییر وضعیت فاکتور",
    changeStatus: "تغییر وضعیت سفارش",
    receivingConfirmation: "تغییر وضعیت",
    isOrderReceivedToYou: "محصولات فروشنده آماده ارسال به خریدار است؟",
    changeStatusHelper: "با احتیاط و در صورت اطمینان تغییر دهید!",
    edit: "ویرایش",
    view: "مشاهده",
    payableMoney: "مبلغ قابل پرداخت",
    no_discount: "کد تخفیف وارد شده معتبر نیست!",
    editBanner: "ویرایش بنر",
    pleaseChooseYourAddress: "لطفا آدرس تحویل خود را انتخاب کنید!",
    PleaseChooseAnAddressToContinue: "لطفا آدرس تحویل خود را انتخاب کنید!",
    assignedToYou: "برای شما اعمال شد.",
    save: "ذخیره",
    makeOrder: "ثبت نهایی سفارش",
    makeSimpleOrder: "ثبت سفارش",
    makeSimpleOrderDesc: "محصولات مورد نظر خود را سفارش دهید!",
    commission: "پورسانت",
    site_commission: "پورسانت سیستم",
    total_commission: "کل پورسانت",
    number: "عدد",
    icon: "آیکون",
    link: "لینک ",
    checkout: "تکمیل سفارش",
    productsInCart: "محصولات موجود در سبد خرید",
    discount_onlyForThisCategory: "کد تخفیف فقط برای دسته‌بندی ",
    discount_onlyForThisShop: "کد تخفیف فقط برای فروشگاه ",
    discount_end_sentence: "لحاظ می‌شود.",
    isShopGlobal: "آیا فروشگاه سراسری است؟",
    iconsList: "لیست آیکون‌ها",
    bg_color: "رنگ پس‌زمینه",
    addSlideShow: "افزودن اسلایدشو",
    primary_button_text:'متن دکمه اصلی',
    primary_button_icon:'آیکون دکمه اصلی',
    primary_button_link:"لینک دکمه اصلی",
    secondary_button_text:'متن دکمه دوم',
    secondary_button_icon:'آیکون دکمه دوم',
    secondary_button_link:"لینک دکمه دوم",
    featuredProduct: "محصول ویژه؟",
    managementSetting: "تنظیمات مدیریتی",
    shopTotalRating: "امتیاز کلی فروشگاه",
    productTotalRating: "امتیاز کلی محصول",
    productTotalRatingDesc: "این امتیاز به صورت اتوماتیک از میانگین امتیاز کاربران به دست می‌آید.",
    wishlist: "لیست علاقه‌مندی‌‌ها",
    noWishlist: "محصولی در لیست علاقه‌مند‌ی‌ها نیست!",
    changeCategories: "تغییر دسته‌بندی‌ها",
    chooseCategoryIcon: "آیکون دسته‌بندی",
    siteSettings: "تنظیمات سایت",
    manageGlobalCommission: "پورسانت کلی",
    servicesPrice: "هزینه خدمات",
    hidePrices: "مخفی سازی قیمت از کاربران غیر عضو",
    pricesAreShown: "نمایش قیمت‌ها",
    pricesAreHidden: "مخفی سازی قیمت‌ها",
    youAreInSomeOneAccount: "شما در حساب کاربری فرد دیگری هستید!",
    backToMyAccount: "بازگشت",
    loginToUserAccount: "ورود به اکانت این کاربر",
    userShouldConfirmMobileFirst: "ابتدا باید شماره موبایل کاربر تایید شود!",
    requestToDeleteProduct: "درخواست حذف محصول",
    removeProduct: "حذف محصول",
    requestedToDelete: "درخواست حذف محصول ارسال شد!",
    pricingThumbnailDesc: "اگر می‌خواهید تصویر خودتان بجای تصویر محصول نمایش داده شود آن را آپلود کنید!",
    backToLogin: "بازگشت به صفحه ورود",
    doLogin: "وارد شوید!",
    nonOfThem: "هیچکدام",
    uploadedDate: "زمان آپلود",
    editMobile: "تغییر شماره موبایل",
    editMobileDesc: "در صورتی که قصد تغییر شماره موبایل خود (",
    editMobileDesc2: ") را دارید، آن را وارد کنید.",
    pricing: "قیمت‌گذاری محصولات",
    pricingOnly: "قیمت‌گذاری",
    doPricing: "برای محصولات خود و دیگران قیمت گذاری کنید!",
    pricingFor: "قیمت‌گذاری برای",
    changeRole: "تغییر سطح دسترسی",
    changeRoleToProvider: "آیا می‌خواهید سطح دسترسی خود را تغییر دهید؟",
    editAddress: "ویرایش آدرس",
    customHeadDescription: "در این قسمت می‌توانید کد‌های مورد نیاز خود مانند انواع تگ meta و Open Graph را به تگ <head> سایت  اضافه کنید. ",
    customBodyDescription: "در این قسمت می‌توانید کد‌های مورد نیاز خود مانند انواع کد Java Script و... را به انتهای تگ <body> سایت  اضافه کنید. ",
    shopOwner: "مالک فروشگاه",
    myShops: "فروشگاه‌های من",
    shopsList: "لیست فروشگاه‌ها",
    myShop: "فروشگاه من",
    makeYourShop: "فروشگاه خود را بسازید!",
    addresses: "آدرس‌های من",
    addAdressDetailsLong: "آدرس‌های خود را در این بخش اضافه کنید و در انتخاب محل تحویل سفارش‌های خود از آن استفاده کنید.",
    addAddressDetails: "آدرس‌های خود را اضافه کنید!",
    addAddress: "افزودن آدرس",
    addAddressDescription: "آدرس‌های خود را در این بخش مدیریت کنید!",
    moreInfo: "اطلاعات بیشتر",
    beSupplier: "فروشنده شوید!",
    viewShop: "مشاهده فروشگاه",
    viewShops: "مشاهده فروشگاه‌ها",
    relatedShop: "فروشگاه مربوطه",
    shopName: "نام فروشگاه",
    shop: "فروشگاه",
    menu_title: "نام آیتم",
    url: "آدرس اینترنتی",
    numberOfSells: "تعداد فروش",
    buyer: "خریدار",
    makeAShopFirst: "ابتدا فروشگاه خود را بسازید!",
    makeAShopFirstDescription: "لطفا قبل از ایجاد محصول یک فروشگاه بسازید!",
    chooseShop: "انتخاب فروشگاه",
    shopDiscountHelp: "اگر می‌خواهید کد تخفیف فقط برای یک فروشگاه اعمال شود.",
    categoryDiscountHelp: "اگر می‌خواهید کد تخفیف فقط برای یک دسته بندی اعمال شود.",
    chooseShopHelperText: "فروشگاه ارائه دهنده محصول",

    productInsertionDescription: "شما می توانید محصول خود را در این بخش بسازید و یا اطلاعات آن را از  پست اینستاگرام خود درج کنید ",
    welcomeToSite: "به مارکت اینجازنونس خوش آمدید!",
    bio: "توضیح کوتاه",
    shopDescription: "معرفی فروشگاه",
    description: "توضیح کوتاه",
    manageShops: "مدیریت فروشگاه‌ها",
    management: "مدیریت",
    makeShop: "ایجاد فروشگاه جدید",
    editShop: "ویرایش اطلاعات فروشگاه",
    yourShopIsActive: "تبریک! فروشگاه شما تایید شده و فعال است.",
    yourShopIsInchecking: "فروشگاه شما در دست بررسی است!",
    yourAccountIsActive: "تبریک! حساب کاربری شما تایید شده و فعال است.",
    yourAccountIsInChecking: "حساب کاربری شما در دست بررسی است!",
    yourProductIsActive: "تبریک! محصول شما تایید شده و فعال است.",
    yourProductIsInchecking: "محصول شما در دست بررسی است!",
    yourPricingIsActive: "قیمت‌‌گذاری شما تایید شده و درحال نمایش به کاربران است",
    yourPricingIsInchecking: "قیمت‌‌گذاری شما در دست بررسی است!",
    welcomeShopCreatorTitle: "فروشنده گرامی خوش‌آمدید!",
    WelcomeShopCreatorText: "شما می‌توانید از این بخش فروشگاه خود را ایجاد کنید، پس از تایید فروشگاه امکان انتشار محصول برای شما فراهم خواهد شد.",
    deleteShop: "فروشگاه حذف شد.",
    deleteShopFailed: "در حذف فروشگاه مشکلی به وجود آمده است.",
    deleteProduct: "محصول حذف شد.",
    deleteProductFailed: "در حذف محصول مشکلی به وجود آمده است.",
    leaderboard: "جدول امتیازات",
    searchPlaceholder:'جستجوی متخصص‌ها...',
    resume: "رزومه",
    info: "اطلاعات پایه",
    portfo: "نمونه کارها",
    services: "خدمت‌ها",
    projects: "پروژه‌ها",
    pendingProjects: "پروژه‌های در انتظار تایید",
    doneProjects: "پروژه‌های انچام شده",
    teaching: "تدریس خصوصی",
    courses: "لیست دوس تدریسی",
    jobrequest: "استخدام",
    teamUp: "تیم سازی",
    consultation: "مشاوره",
    moneyManagement: "مدیریت مالی",
    studentsList: "لیست شاگردان",
    contents: "نوشته‌ها",
    manageContents: "مدیریت نوشته‌ها",
    addContent: "ایجاد نوشته جدید",
    viewContent: "مشاهده نوشته‌های قبلی",
    makeContent: "انتشار نوشته",
    editContent: "ویرایش نوشته",
    contentTitle: "تیتر مقاله",
    pageTitle: "تیتر صفحه",
    addPage: "افزودن صفحه",
    contentAdded: "نوشته‌ی شما ایجاد شد.",
    contentAddingFailed: "در ایجاد نوشته مشکلی به وجود آمده است.",
    contentEdited: "نوشته‌ی شما ویرایش شد.",
    contentEditingFailed: "در ویرایش نوشته مشکلی به وجود آمده است.",
    deleteContent: "نوشته‌ حذف شد.",
    deleteContentFailed: "در حذف نوشته مشکلی به وجود آمده است.",
    howToSell: "نحوه فروش",
    sellOnline: "آنلاین",
    sellOffline: "آفلاین",
    myPoints: "امتیازات من",
    ifYouWantSellOnline: "در صورتی که قصد فروش آنلاین دارید، ابتدا اطلاعات بانکی خود را تکمیل کنید!",
    province: "استان",
    city: "شهر",
    editPage: "ویرایش صفحه",
    makePage: "ایجاد صفحه",

    /*********Global**********/
    account: "حساب کاربری",
    manageProducts: "مدیریت محصولات",
    headLineMakeYourFirstProduct: "محصولات خود را ایجاد کنید، محتوای آن‌ها را بهبود دهید و از ویژگی‌های های مارکت اینجازنونس لذت ببرید!",
    productDetails: "اطلاعات محصول",
    myProducts: "محصولات من",
    editProduct: "ویرایش محصول",
    makeProduct: "ایجاد محصول جدید",
    purchaseProduct: "سفارش محصول",
    resumeOrder: "ادامه سفارش",
    productName: "نام محصول",
    productEnglishName: "نام انگلیسی محصول",
    productDescription: "توضیحات",
    product: "محصول",
    viewProduct: "مشاهده محصول",
    viewOrders: "مشاهده سفارش‌ها",
    viewOrdersDetails: "همه سفارش‌های گذشته خود را از این بخش مشاهده نمایید!",
    viewOrder: "مشاهده سفارش‌",
    created_at_factor: "تاریخ ثبت سفارش",
    user_delivery: "تحویل گیرنده",
    mobileNumber: "شماره موبایل",
    orderDetails: "جزئیات سفارش‌",
    viewUserDetails: "اطلاعات کاربر",
    manageOrders: "مدیریت سفارش‌ها",
    manageOrder: "مدیریت سفارش‌",
    manageDiscounts: "مدیریت کدهای تخفیف",
    discounts: "تخفیف‌ها",
    discount_code: "کد تخفیف",
    input_your_discount_code: "در صورتی که کد تخفیف دارید، آن را وارد کنید!",
    discount_type: "نوع تخفیف",
    discount_value: "مقدار",
    percent: "درصد",
    fixed: "مقدار ثابت",
    discount_total_count: "تعداد قابل استفاده",
    total_price_to_get: "مبلغ تسویه",
    discount_count: "تعداد استفاده شده",
    discount_expired_at: "تاریخ انقضا",
    makeDiscount: "ایجاد کد تخفیف",
    editDiscount: "ویرایش کد تخفیف",
    shopOrders: "لیست فروش‌ها",
    viewShopOrders: "مشاهده فروش‌ها",
    products: "محصولات",
    productsList: "لیست محصولات",
    productOwner: "مالک محصول",
    manageComments: "مدیریت کامنت‌ها",
    manageShopComments: "مدیریت کامنت‌ فروشگاه‌ها",
    comments: "کامنت‌ها",
    commentForReplayDescription : "اگر قصد پاسخ به کامنتی را دارید از اینجا انتخاب کنید",
    noProduct:"محصولی وجود ندارد",
    chooseProductForComment : "انتخاب محصول",
    chooseShopForComment : "انتخاب فروشگاه",
    chooseContentForComment : "انتخاب نوشته",
    commentEdited:"کامنت ویرایش شد",
    commentAdded:"کامنت ایجاد شد",
    commentAddingFailed:"افزودن کامنت با مشکل مواجه شد.",
    commentEditingFailed:"ویرایش کامنت با مشکل مواجه شد.",
    commentRemoved: "کامنت با موفقیت حذف شد",
    dataRemoved: "اطلاعات با موفقیت حذف شد",
    dataRemovingFailed: "حذف اطلاعات با مشکل رو به رو شد.",
    commentRemovingFailed: "حذف کامنت با مشکل رو به رو شد.",
    deleteSelectedComments: "کامنت‌های انتخاب شده حذف شدند.",
    deleteSelectedUsers: "کاربران انتخاب شده حذف شدند.",
    role: "سطح دسترسی",
    allRoles: "تمام سطوح دسترسی",
    allUserTypes: "تمام انواع کاربران",
    editComment: "ویرایش کامنت",
    addComment: "افزودن کامنت",
    parentCommentDesc : "شما درحال پاسخ دادن به کامنت زیر هستید:",
    productRating:"امتیاز محصول",
    shopRating:"امتیاز فروشگاه",
    contentRating:"امتیاز نوشته",
    inserProduct:"درج محصول",
    inserProductFromInstagram:"درج محصول از اینستاگرام",
    confirmStatus:"وضعیت تایید",
    removeComment: "حذف کامنت",
    translateFront: "ترجمه فرانت",
    manageSlideShows: "مدیریت اسلایدشو‌",
    manageBanners: "مدیریت بنر‌ها",
    editSlideShow: "ویرایش اسلایدشو‌",
    translateFrontDesc: "در صورتی که تغییرات را با زدن دکمه ثبت اطلاعات انجام داده‌اید و تغییرات در این قسمت مشاهده نشد، از قسمت فرانت بررسی نمایید.",

    thankYou: "با تشکر!",
    settings: "تنظیمات",
    exit: "خروج از سایت",
    myAccount: "حساب کاربری",
    myInfo: "اطلاعات من",
    makeDraft: "ذخیره پیش‌نویس",
    userPanel: "پنل کاربری",
    draft: "پیش‌نویس",
    published: "منتشر شده",
    someFieldsHasError: "یک یا چند فیلد دارای خطا هستند، لطفا بررسی کنید",
    search: "جستجو",
    author: "نویسنده",
    creator: "ناشر",
    choose: "انتخاب",
    updateTime: "بروزرسانی",
    created_at: "تاریخ ایجاد",
    updated_at: "تاریخ بروزرسانی",
    makeBrand: "ایجاد برند",
    editBrand: "ویرایش برند",
    filters: "فیلتر‌ها",
    sliderTitle: "تیتر اسلایدر",
    bannerTitle: "تیتر بنر",
    subtitleBanner: "متن کناری بنر",
    bannerPosition: "محل بنر",
    activityStatus: "وضعیت",

    thumbnail: "تصویر",
    publishStatus: "وضعیت انتشار",
    keywordsLabel: "عبارات کلیدی",
    allKeywordsAreTyped: "امکان افزودن کلمات کلیدی بیشتر ندارید. ",
    keywordsDescription: "تگ‌های مرتبط را بنویسید. (برای مثال، ست هدیه، سلامت فردی و...)",
    seoDescription: "توضیح کوتاه",
    linkPlaceholder:"یک آدرس اینترنتی وارد کنید",
    addImage: "افزودن تصویر",
    imageName: "نام فایل",
    ownerMedia:"ناشر",
    insertMedia:"درج فایل",
    insert:"درج",
    linkCopied:"لینک کپی شد.",
    uploadSuccess: "آپلود با موفقیت انجام شد",
    editFile:"فایل با موفقیت ویرایش شد",
    editFileFailed:"ویرایش فایل با مشکل رو به رو شد.",
    tableAdded:"جدول ایجاد شد",
    tableAddingFailed:"افزودن جدول با مشکل مواجه شد.",
    deleteTable:"جدول با موفقیت حذف شد",
    deleteTableFailed:"حذف جدول با مشکل رو به رو شد.",
    tableEdited:"جدول ویرایش شد.",
    tableEditingFailed:"ویرایش جدول با مشکل مواجه شد.",
    selfFiles: "فایل‌های من",
    adminsFiles: "فایل‌های مدیران سایت",
    everyBodyFiles: "فایل‌های همه",
    uploading: "درحال آپلود...",
    uploadDone: "فایل آپلود شد.",
    allTypes: "همه فرمت‌ها",
    image: "تصویر",
    video: "ویدیو",
    document: "سند",
    chooseVideo:"انتخاب ویدیو",
    multiImport:"انتخاب رسانه‌ها",
    filesTable: "فایل‌های ذخیره شده",
    fileManager: "رسانه‌ها",
    lastUpdate: "آخرین بروزرسانی",
    userDetail: "اطلاعات کاربر",
    usersManagement: "مدیریت کاربران",
    allUsers: "مشاهده کاربران",
    usersActivity: "فعالیت‌ کاربران",
    paymentManagement: "اطلاعات مالی کاربران",
    addUser: "افزودن کاربر",
    editUser: "ویرایش کاربر",
    editDetails: "ویرایش پروفایل",
    logoSize600: "سایز مناسب 600x600px می‌باشد.",
    logo: "لوگو فروشگاه",
    logo_brand: "لوگو",
    cover: "کاور فروشگاه",
    chooseMedia: "انتخاب رسانه",
    submitErrorFile:"فایل ارسالی مشکل دارد.",
    imageSizeWidth700: "عرض مناسب تصویر 700px می‌باشد.",
    thumbnailSize: " حداقل عرض مناسب تصویر 600px می‌باشد.",
    userAvatarSet:"تصویر پروفایل شما بروز شد.",
    profileImage: "تصویر پروفایل",
    userAvatarSetFailed:"در تغییر تصویر پروفایل مشکلی به وجود آمده است.",
    loadingThumbnailFailed: "در بارگذاری تصویر مشکلی پیش آمده",
    ChooseImageLink:" برای کپی لینک، سایز مورد نظر را انتخاب کنید.",
    deleteFile:"فایل با موفقیت حذف شد",
    deleteFileFailed:"حذف فایل با مشکل رو به رو شد.",
    deleteSelectedFiles:"فایل ها با موفقیت حذف شدند",
    alt: "متن جایگزین (alt)",
    chooseImage: "انتخاب تصویر",
    emptyImage: "تصویری انتخاب کنید!",
    descriptionPlaceHolder: "توضیحات مربوطه را بنویسید",
    words:"کلمه",
    characters:"کاراکتر",
    uploadImage: "آپلود رسانه",
    ThumbnailTitle: "تصویر شاخص",
    loading: "درحال بارگذاری...",
    login: "ورود",
    homePage: "صفحه اصلی",
    contactPage: "تماس با ما",
    shopPage: "همه محصولات",
    rulesPage: "قوانین استفاده",
    aboutPage: "درباره ما‌",
    shops: "فروشگاه‌ها",
    orLoginWith: "ورود با",
    backToHomePage: "بازگشت به صفحه اصلی",
    emailOrMobile: "ایمیل یا موبایل",
    first_name: "نام",
    last_name: "نام‌خانوادگی",
    username: "نام کاربری",
    userName: "نام و نام‌خانوادگی",
    phone: "شماره تماس",
    melli_code_compnay: "شناسه ملی",
    financial_code: "کد اقتصادی",
    mobile: "موبایل",
    mobileSample: "مثل 09123456789",
    email: "ایمیل",
    sms: "پیام کوتاه",
    verifyEmail: "تایید ایمیل",
    password: "رمزعبور",
    comment: "کامنت",
    passwordConfirm: "تکرار رمزعبور",
    passwordStrength:"حداقل ۶ کاراکتر، شامل عدد و حروف",
    passwordChanged:"رمزعبور با موفقیت تغییر کرد.",
    currentPasswordError:"رمزعبور فعلی صحیح نیست.",
    currentPassword:"رمزعبور فعلی",
    register: "ثبت‌نام",
    doRegister: "ثبت‌نام کنید",
    dontHaveAccount: "حساب کاربری ندارید؟",
    haveAccount: "حساب کاربری دارید؟",
    loginError:"ایمیل یا رمزعبور اشتباه است.",
    forgetPass:"فراموشی رمز عبور",
    loginWithToken:"ورود با رمز یکبار مصرف",
    dashboard:"داشبورد",
    emptyUsername: "نام کاربری خود را وارد کنید.",
    emptyTableCells: "جدول خالی است.",
    wrongUsername: "نام کاربری فقط میتواند شامل حروف و اعداد انگلیسی باشد.",
    NotFreeUsername: "این نام کاربری قبلا انتخاب شده.",
    existEmail: "این ایمیل قبلا ثبت شده.",
    existMobile: "این شماره موبایل قبلا ثبت شده.",
    emptyEmail: "ایمیل خود را وارد کنید.",
    emptyMobile: "شماره موبایل خود را وارد کنید.",
    wrongEmail: "فرمت ایمیل اشتباه است.",
    wrongMobile: "فرمت شماره موبایل اشتباه است.",
    wrongPrice: "مبلغ اشتباه است",
    transferPrice: "هزینه ارسال",
    transfer_type: "نحوه ارسال",
    wrongSheba: "شماره شبا اشتباه است. (۲۴ عدد)",
    wrongCreditNumber: "شماره کارت اشتباه است. (۱۶ عدد)",
    emptyPassword: "رمز عبور را وارد کنید.",
    weakPassword: "رمز عبور ضعیف است.",
    notEqualPassword: "رمز عبور و تکرار آن یکسان نیست.",
    lessPost: "نوشته‌ی پست کم است",
    maxLength: "نوشته طولانی است",
    SelectFromSelect:"انتخاب کنید",
    emptyField:'فیلد خالی است.',
    fileIsEmpty:'انتخاب فایل ضروری است!',
    fillPrevFieldFirst:'ابتدا فیلد قبل را پر کنید.',
    emptyName: "نام ضروری است",
    emptyLastName: "نام خانوادگی ضروری است",
    noMatchPassword: "تاییده با رمز عبور یکسان نیست",
    emptyTagName:"نام برچسب ضروری است",
    emptyBrandName:"نام برند ضروری است",
    cantBeMoreThan100:'نمی‌تواند بیشتر از 100 باشد',
    maxLength60:"طول این فیلد نباید بیش از ۶۰ کاراکتر باشد.",
    maxLength70:"طول این فیلد نباید بیش از ۷۰ کاراکتر باشد.",
    maxLength150:"طول این فیلد نباید بیش از ۱۵۰ کاراکتر باشد.",
    maxLength50:"طول این فیلد نباید بیش از ۵۰ کاراکتر باشد.",
    maxLength255:"طول این فیلد نباید بیش از ۲۵۵ کاراکتر باشد.",
    maxLength500:"طول این فیلد نباید بیش از ۵۰۰ کاراکتر باشد.",
    wrongMelliCode: "کد ملی اشتباه است.",
    maxLength1000:"طول این فیلد نباید بیش از ۱۰۰۰ کاراکتر باشد.",
    maxLength4000:"طول این فیلد نباید بیش از ۴۰۰۰ کاراکتر باشد.",
    between0to100:"درصد باید در بازه ۰ تا ۱۰۰ باشد",
    pleaseCheckTheBox:"لطفا قوانین را تایید کنید",
    emptyEmailOrMobile:"ایمیل یا شماره موبایل خود را وارد کنید.",
    dear:"عزیز",
    errorHappens:"در بارگذاری سایت خطایی رخ داده است!",
    allChangesSaved:"تمام تغییرات ذخیره شد.",
    allChangesSavingFailed:"ذخیره سازی تغییرات با مشکل مواجه شد!",
    registerSuccess: "کاربر با موفقیت ثبت شد.",
    registerError: "خطا در ایجاد کاربر",
    cantLoadData:"در بارگیری مطالب مشکلی است.",
    fileSize500kb:"حجم تصویر باید کمتر از ۵۰۰kb باشد.",
    fileSize100mb:"حجم تصویر باید کمتر از ۱۰۰mb باشد.",
    fileSize1mb:"حجم تصویر باید کمتر از ۱mb باشد.",
    makingTokenError:"در ایجاد کد تایید مشکلی به وجود آمده است. ",
    youLoggedOff:"شما از پنل خارج شدید",
    mobileVerified:"شماره موبایل شما تایید شد.",
    wrongToken:"کد تایید اشتباه است. ",
    mobileVerify: "تایید موبایل",
    verifyMobile: "تایید شماره موبایل",
    sendTokenText1:"رمز یکبارمصرف شما به شماره",
    sendTokenText2:"ارسال شد.",
    sendTokenTextResend:"رمز یکبار مصرف مجددا برای شما ارسال شد.",
    sendTokenText3:"زمان باقی مانده تا ارسال مجدد پیامک",
    logOut: "خروج از سایت",
    confirm: "تایید",
    token:"کد تایید",
    forgetPassword: "فراموشی رمزعبور",
    invalidMobileNumber:"این شماره در سیستم وجود ندارد",
    recoverPassword:"بازیابی رمزعبور",
    recoverPasswordText:"برای بازیابی رمز عبور، شماره موبایل خود را وارد کنید.",
    sendAgain:"ارسال دوباره",
    changePassword: "تغییر رمزعبور",
    newPassword:"رمزعبور جدید",
    newPasswordConfirm:"تکرار رمزعبور جدید",
    loginWithUserPass:"ورود با اطلاعات کاربری",
    addYourNumberFirst:"برای ورود به سایت، شماره موبایل خود را وارد کنید.",
    connectionError:'اینترنت شما قطع است.',
    copyright: " تمام حقوق محفوظ است © ",
    copyright2: " طراحی و توسعه توسط ",
    developer: " رایانیتا® ",
    unreadNotifications: "اعلان‌های خوانده نشده",
    readNotifications: "اعلان‌های خوانده شده",
    noNotifications: "اعلانی وجود ندارد!",
    nothingFound: "صفحه وجود ندارد!",
    notFoundPage: "صفحه وجود ندارد!",
    setDetails: "ثبت اطلاعات",
    detailsSet:"اطلاعات ثبت شد.",
    detailsSetFailed:"ثبت اطلاعات با مشکل مواجه شد.",
    detailsGetFailed:"دریافت اطلاعات با مشکل مواجه شد.",
    mobilSample:"نمونه: 09121234567",
    confirmationStatus:"تایید اطلاعات",
    confirmationStatusDescription:"وضعیت تایید اطلاعات",
    confirmationStatusFilter:"وضعیت تایید",
    userConfirmationStatus:"وضعیت تایید اطلاعات کاربر",
    confirmed:"تایید شده",
    unConfirmed:"در انتظار تایید",
    all:"همه",
    addNewBrand:"افزودن برند جدید",
    admin: "مدیر سایت",
    supportUser: "پشتیبانی",
    product_manager: "ادمین محصولات",
    support: "پشتیبانی",
    adminType: "مدیران سایت",
    normalType: "سایر کاربران",
    usersLog: "فعالیت‌های کاربران",
    someFieldsHasErrorPleaseCheckThem: "یک یا چند فیلد دارای خطا هستند، لطفا بررسی نمایید",
    duplicateName: 'این برند متعلق به شخص دیگری است.',
    dataAdded: "اطلاعات شما ایجاد شد.",
    dataAddingFailed: "در ایجاد اطلاعات مشکلی به وجود آمده است.",
    addressError: "فروشنده امکان ارسال محصول به آدرس انتخابی شما را ندارد!",
    onlyUsersCanPurchase: "فقط کاربران عادی امکان ثبت سفارش دارند!",
    dataEdited: "اطلاعات شما ویرایش شد.",
    categoryPublishedBefore: "دسته بندی تکراری است!",
    viewCategory: "مشاهده صفحه دسته‌بندی",
    dataEditingFailed: "در ویرایش اطلاعات مشکلی به وجود آمده است.",
    firstCheckOrdersStatus: "ابتدا وضعیت سفارش هر فروشگاه را تایید کنید!",
    deleteData: "اطلاعات حذف شد.",
    deleteDataFailed: "در حذف این اطلاعات مشکلی به وجود آمده است.",
    makeCategory: "ایجاد دسته‌بندی",
    editCategory: "ویرایش دسته‌بندی",
    makeBlogCategory: "ایجاد دسته‌بندی وبلاگ",
    editBlogCategory: "ویرایش دسته‌بندی وبلاگ",
    categoryName: "نام دسته",
    categoryParentName: "دسته والد",
    categories: "دسته‌بندی‌ها",
    pleaseChooseCategoriesFirst: "لطفا ابتدا دسته‌بندی را انتخاب کنید!",
    category: "دسته‌بندی‌",
    chooseCategory: "انتخاب دسته‌بندی‌",
    chooseSubCategory: "انتخاب زیر دسته‌",
    chooseMainCategory: "انتخاب دسته‌ اصلی",
    manageCategories: "مدیریت دسته‌بندی‌ها",
    manageBlogCategories: "دسته‌بندی‌ وبلاگ",
    manageBlogComments: "نظرات وبلاگ",
    manageBrands: "مدیریت برندها",
    brandName: "نام برند",
    brand: "برند",
    manageVariables: "مدیریت مشخصات محصول‌",
    manageAttributes: "مدیریت ویژگی‌ها‌",
    chooseAttribute: "انتخاب ویژگی‌‌",
    attributes: "ویژگی‌ها‌",
    managePages: "مدیریت صفحات",
    manageDepartments: "مدیریت دپارتمان‌ها",
    manageMessages: "مدیریت پیام‌ها",
    manageAbout: "صفحه درباره‌ما",
    manageTerms: "صفحه قوانین مقررات",
    managePrivacyPolicy: "صفحه حریم خصوصی",
    manageSellerTerms: "صفحه قوانین فروشندگان",
    manageAgentTerms: "صفحه قوانین نمایندگان",
    manageContact: "فرم تماس‌ با‌ ما",
    pageDetails: "اطلاعات صفحه",
    variableIsNotConfirmed: "در انتظار تایید",
    youCantAddMoreVariablesUntileConfirmation: "امکان افزودن مشخصات محصول بیشتر قبل از تایید موارد قبلی وجود ندارد!",
    manageVariablesOrder: "مدیریت  ترتیب مشخصات محصول‌",
    chooseVariables: "انتخاب مشخصات محصول‌",
    makeVariable: "ایجاد مشخصات محصول",
    makeAttribute: "ایجاد ویژگی",
    editVariable: "ویرایش مشخصات محصول",
    variableName: "نام مشخصه",
    attributeName: "نام ویژگی",
    attributeType: "نوع ویژگی",
    valueName: "نام مقدار",
    manageValues: "مدیریت مقدار‌ها",
    makeValue: "ایحاد مقدار",
    values: "مقدارها",
    valueColor: "رنگ مقدار",
    variables: "مشخصات محصول‌",
    variable: "مشخصات محصول",
    addNewVariable: "افزودن مشخصات محصول",
    addNewVariableDescription: "برای افزودن مشخصات محصول دکمهEnter را بزنید!",
    addYourvariableIfYouCatFind: "اگر مشخصات محصول مد نظر خود را نمی‌یابید آن را ایجاد کنید.",
    addVariable: "افزودن مشخصات محصول",
    user: "کاربر",
    agent: "نماینده",
    duplicateInstagram: 'این اکانت متعلق به شخص دیگری است.',
    markAsRead: 'خوانده شد!',
    dataFetchingError: 'اطلاعات مورد نظر یافت نشد!',
    activity: 'فعالیت',
    details: 'جزئیات',
    gallery: 'گالری محصول',
    removeImage: 'حذف تصویر',
    remove: 'حذف',
    tickets: "درخواست پشتیبانی",
    manageTickets: "مدیریت تیکت‌ها",
    requestType: "نوع درخواست",
    supportRequestFromDepartment: "پشتیبانی سیستم",
    supportRequestForProduct: "پشتیبانی محصول",
    sendFile: "ارسال فایل",
    ticket_sender: "ارسال کننده",
    ticket_receiver: "دریافت کننده",
    ticket_title: "موضوع",
    ticket_status: "وضعیت",
    ticket_edited_at: " آخرین ارسال",
    sendMessage: "ارسال پیام",
    backToTickets: "بازگشت به تیکت‌ها",
    deleteTicket: "تیکت حذف شد!",
    deleteTicketFailed: "حذف تیکت با مشکل مواجه شد!",
    ticket_details: "اطلاعات تیکت",
    ticket_sent: "تیکت ارسال شد!",
    sendFastTicket: "ارسال سریع پیام",
    sendMessageTo: "ارسال پیام به",
    addTicket: "ارسال تیکت",
    send: "ارسال",
    messageText: "متن پیام",
    gotToTicketPage: "صفحه پیام‌ها",
    removeRequest: 'حذف درخواست',
    discountMoreThanPrice:"میزان تخفیف از کل قیمت بیشتر است",
    discount_percent:"درصد تخفیف",
    discount_price:"قیمت با تخفیف",
    discount:"تخفیف",
    factor:"فاکتور",
    factor_number:"شماره فاکتور",
    factor_status:"وضعیت فاکتور",
    bill:"صورت حساب",
    price:"قیمت",
    x_price: "قیمت عدد X",
    priceName:"مبلغ",
    create_order:"ثبت سفارش",
    totalPrice:"قیمت کالا‌ها",
    totalDiscount:"قیمت کالا‌ها",
    totalCartPrice:"جمع سبد خرید",
    totalPriceFull:"جمع مبالغ",
    totalUserDiscounts:"جمع تخفیف‌ها",
    totalDiscounts:"سود شما از خرید",
    cancel:"لغو!",
    youDontHavePermission:"شما دسترسی مجاز ندارید!",
    pageNotFound:"این صفحه در دسترس نیست!",
    pressEnterAfterEachKeyword:"پس از نوشتن هر عبارت دکمه Enter را بزنید!",
    chooseBeginDate:"تاریخ شروع",
    chooseEndDate:"تاریخ پایان",
    chooseDate:"انتخاب تاریخ",
    doFilter:"اعمال فیلتر",
    removeFilter:"حذف فیلتر",
    noLogFound:"فعالیتی یافت نشد!",
    roleHelper:"انتخاب سطح دسترسی کاربر",
    goBackToPanel:"بازگشت به پنل",
    emailVerified:"ایمیل تایید شد!",
    emailNotVerified:"ایمیل تایید نشد!",
    visitsChart:"نمودار بازدید‌ها",
    visits:"بازدید‌ها",
    products_visits:"بازدید محصولات",
    shop_visits:"بازدید فروشگاه",
    googleAnalytics:"گوگل آنالیتیکس",
    liveUsers:"کاربران آنلاین",
    newProducts:"محصولات جدید",
    newShops:"فروشگاه‌های جدید",
    newRegistrations:"ثبت‌نام‌های جدید",
    todayAllUnRegisteredUsers:"بازدید‌های امروز",
    transactionType:"نوع تراکنش",
    depositMoney:"شارژ کیف پول",
    depositMoneyDescription:"مقدار مورد نظر برای شارژ کیف پول خود را در این قسمت وارد کنید.",
    withdrawMoney:"برداشت از کیف پول",
    withdrawMoneyDescription:"مقدار مورد نظر برای برداشت از کیف پول خود را در این قسمت وارد کنید.",
    deposit:"شارژ",
    withdraw:"برداشت",
    amount:"مبلغ",
    status:"وضعیت",
    order_status:"وضعیت سفارش",
    orderPayed:"مبلغ سفارش پرداخت شد.",
    orderPayingFailed:"در پرداخت مبلغ مشکلی وجود دارد!",
    pending:"در انتظار تایید",
    not_payed:"در انتظار پرداخت",
    commission_payed:"تسویه شده",
    commission_not_payed:"درحال بررسی ",
    commission_status:"تسویه ",
    gathering:"تایید اولیه",
    confirmToPay:"تایید جهت پرداخت",
    removeFactorWithMessage:"لغو سفارش و اعلام به کاربر",
    ready:"آماده تحویل به خریدار",
    sending:"درحال ارسال به خریدار",
    done:"تکمیل سفارش",
    rejected:"لغو سفارش",
    myWallet: "کیف پول",
    orders: "خرید‌ها",
    ordersList: "لیست سفارشات",
    payOrder: "پرداخت سفارش‌",
    payWithWallet: "پرداخت از کیف پول",
    purchase: "پرداخت",
    withdrawRequest: "درخواست واریز",
    transactionTrackingCode: "کد تراکنش",
    postal_code: "کد پستی",
    postalInfo: "اطلاعات پستی",
    pleaseCompleteYourPostalInfo: "لطفا اطلاعات پستی خود را تکمیل کنید!",
    postalInfoDescription: "لطفا اطلاعات پستی خود را در این قسمت وارد کنید، دکمه ثبت اطلاعات را بزنید و سپس اقدام به پرداخت کنید.",
    address: "آدرس",
    melli_code: "کد ملی",
    doctors_code: "کد نظام پزشکی",
    addRefrenceIdFirst: "ابتدا کد تراکنش را وارد کنید!",
    myWalletBalance: "موجودی کیف پول",
    balance: "موجودی",
    pleaseVerifyFirst: "لطفا ابتدا اطلاعات حساب خود را تکمیل کنید!",
    verificationDescription: "برای برداشت از کیف پول خود در سایت نیاز به تایید اطلاعات بانکی دارید!",
    verificationDetails: "اطلاعات بانکی",
    verificationDataSets: "اطلاعات بانکی ثبت شد!",
    manageVerifications: "مدیریت اطلاعات بانکی",
    ManageUserVerification: "مدیریت اطلاعات بانکی کاربر",
    bank_name: "نام بانک",
    viewUserBankInfo: "مشاهده اطلاعات بانکی کاربر",
    sheba: "شماره شبا",
    credit_number: "شماره کارت",
    melli_card_image: "تصویر کارت ملی",
    credit_card_image: "تصویر کارت بانکی",
    activeStatus: "وضعیت",
    active: "فعال",
    inactive: "غیر فعال",
    manageWallets: "مدیریت کیف پول‌ها",
    manageUserWallet: "مدیریت تراکنش‌های کاربر",
    yourBankInfoIsActive: "اطلاعات بانکی شما تایید شده و امکان برداشت از کیف پول سایت برای شما فراهم است.",
    yourBankInfoIsInchecking: "اطلاعات بانکی شما در دست بررسی است.",
    payed: "پرداخت شد",
    is_payed: "پرداخت شده",
    readMore: "بیشتر بخوانید!",
    areYouSure: "آیا از انجام این کار مطمئنید؟",
    yes: "بله",
    no: "خیر",
    doYouWanaExit: "آیا قصد خروج دارید؟",
    certificationFile: "فایل جواز",
    chooseFile: "انتخاب فایل",
    chooseAFile: "لطفا یک فایل انتخاب کنید!",
    welcomeUserTitle: "کاربر گرامی خوش آمدید!",
    WelcomeUserText: "لطفا اطلاعات حساب کاربری خود را تکمیل نمایید.",
    with: "با ثبت نام در وب‌سایت ",
    withCreateShop: "با ایجاد فروشگاه در وب‌سایت ",
    createShop: "ایجاد فروشگاه",
    manageYourShop: "فروشگاه خود را مدیریت کنید",
    shopRules: "قوانین و مقررات ثبت فروشگاه",
    rules: "قوانین و مقررات",
    and: "و",
    privacyPolicy: "قوانین حریم خصوصی",
    privacyPolicyConfirmation: "آن را می‌پذیرم.",
    agentRulesConfirmation: "موافقم و آن را می‌پذیرم.",
    privacyPolicyConfirmationShop: "را می‌پذیرم.",
    registerDate: "تاریخ ثبت‌نام",
    uncompleted: "تکمیل نشده",
    userMobileIsConfirmed: "شماره موبایل کاربر تایید شده است.",
    userMobileIsNotConfirmed: "شماره موبایل کاربر تایید نشده است.",
    doYouWanaConfirm: "شماره موبایل کاربر را تایید می‌کنید؟",
    ProductIsNotOriginal: "محصول غیر اصل می‌باشد؟",
    advantages_disadvantages: "مزایا و معایب",
    advantages: "مزایا",
    productDisadvantages: "معایب محصول",
    productAdvantages: "مزایای محصول",
    disadvantages: "معایب",
    advantage: "مزیت",
    disadvantage: "عیب",
    color: "رنگ",
    string: "متن",
    variablePricing: "قیمت‌گذاری متغیر",
    simplePricing: "قیمت‌گذاری ساده",
    simplePricingDescTitle: "قیمت ثابت",
    variablePricingDescTitle: "قیمت بر اساس ویژگی محصول",
    attributeChooserTitle: "های مورد نظر خود را انتخاب نمایید.",
    doYouWantToChangePricingType: "آیا می‌خواهید نوع قیمت‌گذاری خود را تغییر دهید؟",
    help: "راهنما",
    understood: "متوجه شدم!",
    pricingDiferences: "تفاوت انواع قیمت‌گذاری",
    simplePricingDesc: "اگر یک نوع از این محصول دارید و تفاوتی در تعداد موجودی و قیمت ندارد از این گزینه استفاده کنید.",
    variablePricingDesc: "اگر چند نوع از این محصول دارید و در تعداد موجودی و قیمت تفاوت وجود دارد از این گزینه استفاده کنید.",
    warranty: "گارانتی",
    inventory: "موجودی",
    inventoryDesc: "تعداد موجودی کالا",
    transfer_time: "زمان ارسال کالا",
    modifyPricing: "ویرایش قیمت‌گذاری",
    managePricing: "مدیریت قیمت‌گذاری",
    headerTemplate: "هدر و منو سایت",
    headerTemplateDesc: "تنظیمات مربوط به هدر و منو‌‌های سایت",
    chooseHeader: "انتخاب هدر",
    chooseHeaderText: "در این قسمت می‌توانید طراحی هدر سایت خود را به طور کامل تغییر دهید.",
    chooseFooter: "انتخاب فوتر",
    chooseFooterText: "در این قسمت می‌توانید طراحی فوتر سایت خود را به طور کامل تغییر دهید.",
    manageHeader: "مدیریت هدر",
    manageFooter: "مدیریت فوتر",
    manageMenu: "مدیریت منو",
    footerCertifications: "نماد‌های فوتر",
    makeHeaderMenu: "تنظیمات منو اصلی سایت",
    makeHeaderMenuText: "در این قسمت می‌توانید المان‌های منوی اصلی سایت را به طور کامل تغییر دهید. (این منو در هدر سایت و هدر پنل نمایش داده می‌شود).",
    makeFooterMenu: "تنظیمات منو پایینی سایت",
    makeFooterMenuText: "در این قسمت می‌توانید المان‌های منوی پایینی سایت را به طور کامل تغییر دهید.",
    footerTemplate: "فوتر و منو پایین",
    footerTemplateDesc: "تنظیمات مربوط به فوتر و منو‌‌ پایینی سایت",
    footerCertificationTitle: "کد مربوط به نماد ها",
    footerCertificationText: "در این قسمت می‌توانید کد‌های دریافتی از سازمان های مختلف مانند نماد اعتماد، رسانه‌های دیجیتال و... را قرار دهید.",
    mapScriptTitle: "کد نقشه",
    mapScriptText: "در این قسمت می‌توانید کد‌های دریافتی از پلتفرم های مختلف نمایش نقشه مانند Google Map را قرار دهید، تا در صفحه تماس نشان داده شوند.",
    home_page_top_banner: "بنر بالا صفحه اصلی",
    home_page_cta: "دکمه فراخوان صفحه اصلی",
    home_page_twin_right: "بنر راست دوتایی صفحه اصلی ",
    home_page_twin_left: "بنر چپ دوتایی صفحه اصلی",
    categories_banner: "بنر دسته بندی‌ها",
    footer_icon_text_1: "متن و آیکون فوتر ۱",
    footer_icon_text_2: "متن و آیکون فوتر ۲",
    footer_icon_text_3: "متن و آیکون فوتر ۳",
    footer_icon_text_4: "متن و آیکون فوتر ۴",
} :  {
    /********Specific*******/
    app_name: "نام وب سایت",
    appName: "پنل کاربری",
    sendMessageToUser: "ارسال پیام به این کاربر",
    sentTicket: "ارسال شده",
    read: "خوانده شده",
    blog: "وبلاگ",
    fa: "فارسی",
    en: "English",
    saveCode: "ذخیره کد",
    myDetails: "اطلاعات من",
    todaySales: "فروش امروز",
    writeYourCodehere: "کد خود را اینجا بنویسید",
    pageCover: "تصویر کاور صفحه",
    siteInformation: "اطلاعات اصلی سایت",
    contactInformation: "اطلاعات تماس سایت",
    changePrimaryColor: "تغییر رنگ اصلی",
    changeSecondaryColor: "تغییر رنگ ثانویه",
    viewPage: "مشاهده صفحه",
    changeColor: "تغییر رنگ",
    editMenu: "ویرایش آیتم منو",
    saveChanges: "ذخیره تغییرات",
    site_logo: "لوگو سایت",
    favicon: "نمادک سایت (FavIcon)",
    square_logo: "لوگو مربع",
    page_header: "هدر صفحات داخلی",
    youCantDeletePermenantpages: "امکان حذف صفحات اصلی وجود ندارد!",
    addMenu: "افزودن آیتم منو",
    content: "نوشته",
    submit: "ثبت",
    custom_css: "کد CSS اضافی",
    custom_js: "کد Java Script اضافی",
    custom_head: "کد اضافه در <head> سایت",
    custom_body: "کد اضافه در <body> سایت",
    custom_code: "کد اضافی",
    main_phone: "شماره تلفن سایت",
    main_email: "ایمیل سایت",
    main_mobile: "شماره موبایل سایت",
    main_address: "آدرس",
    customCodeTitle: "کد‌های مورد نیاز خود را اضافه کنید!",
    customCodeText: "در این قسمت می‌توانید کد‌های مورد نیاز خود را در بخش‌های مختلف سایتتان اضافه و آن‌ها را سفارشی‌سازی کنید!",
    facebook: "لینک Facebook",
    linkedin: "لینک LinkedIn",
    pinterest: "لینک Pinterest",
    instagram: "لینک Instagram",
    twitter: "لینک Twitter",
    whatsapp: "لینک Whatsapp",
    telegram: "لینک Telegram",
    headerAndFooter: "مدیریت هدر و فوتر",
    short_description: "توضیح کوتاه سایت",
    footer_text: "متن توضیح فوتر",
    footer_certificates: "کد نماد‌های فوتر",
    map_script: "کد نقشه صفحه تماس",
    bg_image: "تصویر پس‌زمینه",
    gradientBg: "پس‌زمینه گردینت",
    solidBg: "پس‌زمینه رنگ ساده",
    imageBg: "پس‌زمینه با تصویر",
    gradientBgFirstColor: "رنگ اول گردینت",
    gradientBgSecondColor: "رنگ دوم گردینت",
    bg_type: "نوع تصویر پس زمینه",
    slideShowImages: "المان‌های تصویری",
    SlideShowContent: "محتوای اسلایدشو",
    nextStep: "بخش بعدی",
    mainImageSlide: "تصویر اصلی اسلاید",
    chooseColor: "انتخاب رنگ",
    chooseFormat: "انتخاب فرمت",
    chooseOwnerFile: "انتخاب منتشر کننده",
    verify_product: "تایید محصول",
    factorConfirmedToPurchase: "وضعیت سفارش ثبت شد!",
    templateUpdated: "قالب سایت آپدیت شد!",
    reject_product: "رد محصول",
    manageTemplate: "مدیریت قالب",
    exportUsersList: "دانلود لیست کاربران",
    weightChoosingFailed: "بازه وزنی ایراد دارد!",
    welcomeToAgentPanel: "به پنل نمایندگی خوش آمدید!",
    agentTerms: "قوانین و مقررات پنل نمایندگی",
    globalNeedVerification: "تایید سفارشات قبل از پرداخت",
    noGlobalNeedVerification: "خیر",
    noContent: "نوشته‌ای یافت نشد!",
    yesGlobalNeedVerification: "همه سفارشات قبل از پرداخت نیاز به تایید دارند!",
    pleaseConfirmAgentTerms: "لطفا قوانین و مقررات مربوط به نمایندگان را مطالعه نموده و تیک تایید را بزنید.",
    changeXPrice: "تغییر عدد x",
    changeProvinceAndCity: "تغییر استان و شهر",
    unread: "در انتظار بررسی",
    need_verification: "در انتظار تایید سفارش",
    need_verification_hint: "نیاز به تایید قبل از سفارش",
    need_verification_hint_description: "امکان سفارش این محصول را رد و یا تایید نمایید.",
    rejectVerificationStatus: "رد امکان سفارش محصول",
    confirmVerificationStatus: "تایید امکان سفارش محصول",
    chooseVerificationStatus: "آیا امکان سفارش این محصول وجود دارد؟",
    need_verification_description: "آیا این محصول، قبل از انجام سفارش، نیاز به بررسی و تایید شما دارد؟",
    need_verification_description_user: "این محصول قبل از انجام سفارش نیاز به بررسی و تایید فروشنده دارد!",
    all_products: "همه محصولات",
    confirmed_products: "محصولات تایید شده",
    unconfirmed_products: "در انتظار تایید",
    all_pricings: "مشاهده همه",
    confirmed_pricings: "قیمت‌گذاری تایید شده",
    unconfirmed_pricings: "در انتظار تایید",
    equalsTo: "معادل",
    gram: "گرم",
    kilogram: "کیلوگرم",
    weightHelper: "جهت محاسبه هزینه ارسال",
    inventoryHelperText: "در صورت عدم نیاز به مدیریت موجودی خالی بگذارید!",
    min_weight: "حداقل وزن",
    max_weight: "حداکثر وزن",
    your_commissions: "لیست کمیسیون‌ها",
    agent_cities: "شهر‌های نماینده",
    sell_agent: "نماینده فروش",
    buy_agent: "نماینده خرید",
    weight: "وزن",
    agent_panel: "پنل نمایندگی",
    agent_panel_desc: "به پنل نمایندگان وب سایت خوش آمدید!",
    yourCities: "شهر‌های تحت نمایندگی شما",
    agent_commission: "پورسانت نماینده",
    viewAll: "مشاهده همه",
    your_related_orders: "سفارش‌های شهر شما",
    addCity: "افزودن شهر",
    agentOrdersTable: "لیست سفارشات نماینده",
    noCityFound: "شهر‌ی یافت نشد!",
    subLine: "جهت بهره‌مندی از ویژگی‌های سایت وارد شوید!",
    viewInvoice: "مشاهده فاکتور",
    viewSellerInvoice: "مشاهده فاکتور فروشنده",
    submitReturnMoney: "ثبت بازگشت مبلغ",
    productWShop: "محصول مربوطه",
    agentsManagement: "مدیریت نمایندگان",
    referrer_code: "کد ",
    sell_commission: "٪ پورسانت فروش ",
    sell_commission_amount: "پورسانت فروش ",
    buy_commission: "٪ پورسانت خرید ",
    buy_commission_amount: "پورسانت خرید ",
    ManageAgent: "مدیریت نماینده",
    Submit: "ثبت",
    report: "گزارش",
    admin_note: "یادداشت ادمین",
    priority: "اولویت",
    low: "کم",
    medium: "متوسط",
    high: "زیاد",
    subject: "موضوع",
    message: "پیام",
    submitReturnMoneyFor: "ثبت بازگشت تراکنش برای فاکتور شماره",
    TransactionRecordSaved: "تراکنش ثبت شد",
    TransactionRecordSavedError: "مشکلی در ثبت تراکنش وجود دارد!",
    bestSellingProducts: "پرفروش ترین محصولات",
    youHaveNoAddress: "هیچ آدرسی ندارید",
    checkProducts: "محصولات مختلف را بررسی کنید",
    firstAddAnAddress: "ابتدا یک آدرس اصافه کنید",
    allOrders: "همه سفارش‌ها",
    addYourAddress: "آدرس خود را وارد کنید!",
    viewDetails: "مشاهده",
    viewTicket: "مشاهده تیکت",
    systemSupportRequest: "درخواست پشتیبانی سیستم",
    productSupportRequest: "درخواست پشتیبانی محصول",
    home: "خانه",
    manageViolations: "مدیریت گزارش‌های تخلف",
    supplier: "فروشنده",
    old_url: "URL قدیمی",
    new_url: "URL جدید",
    related_admin: "ادمین مربوطه",
    department: "دپارتمان",
    departments: "دپارتمان‌ها",
    editRedirect:"ریدایرکت ویرایش شد.",
    editRedirectFailed:"ویرایش ریدایرکت با مشکل رو به رو شد.",
    deleteRedirect:"ریدایرکت حذف شد.",
    deleteRedirectFailed:"حذف ریدایرکت با مشکل رو به رو شد.",
    deleteSelectedRedirects:"حذف ریدایرکت‌های انتخاب شده با مشکل رو به رو شد.",
    addRedirectFailed:"ایجاد ریدایرکت با مشکل رو به رو شد.",
    addRedirect:"ریدایرکت ایجاد شد.",
    redirects : "ریدایرکت‌ها",
    manageRedirects : "مدیریت ریدایرکت‌ها",
    manageTransferPricing : "مدیریت هزینه ارسال",
    finished_inventory: "محصولات ناموجود",
    finishing_inventory: "محصولات رو به اتمام ",
    pending_orders: "سفارشات در جریان",
    done_orders: "سفارش های تکمیل شده  ",
    total_products: "تعداد کل محصولات ",
    total_pending_products: "محصولات درحال بررسی ",
    manageInventory: "مدیریت موجودی",
    addNewProduct: "محصول تازه ایجاد کنید",
    shopIsPending: "در انتظار بررسی!",
    firstCreateShop: "ابتدا فروشگاه بسازید",
    sellerGuidLine: "راهنمای مسیر",
    sellerGuidLineDescription: "گام به گام پیش بروید و با ساخت یک فروشگاه حرفه‌ای، محصولات خود را به مشتریان بیشتری ارائه دهید!",
    userGuidLineDescription: "گام به گام پیش بروید و اطلاعات خود را تکمیل کنید تا ساده تر محصولات مورد نظر خود را بیابید!",
    OrdersChart: "نمودار سفارش‌ها",
    ordersAnalytics: "گزارش سفارشات",
    doneOrders: "سفارش‌های تکمیل شده",
    totalSells: "جمع فروش‌ها",
    thisWeek: "هفته اخیر",
    thisMonth: "ماه اخیر",
    thisYear: "سال اخیر",
    thanks: "با تشکر",
    completeYorDetails: "تکمیل اطلاعات شخصی",
    doCompleteDetails: "اطلاعات خود را تکمیل کنید!",
    inCheckingDetails: "اطلاعات درحال بررسی می‌باشند!",
    compeltedDetails: "حساب کاربری تایید شده!",
    completeYorDetailsDesc: "لطفا اطلاعات شخصی حود را تکمیل نمایید!",
    shopsSells: "حجم فروش فروشندگان",
    totalCommissions: "جمع پورسانت‌ها",
    buyCommissions: "جمع پورسانت‌های خرید",
    payedOrders: "سفارش‌های تسویه شده",
    sellCommissions: "جمع پورسانت‌های فروش",
    viewOtherSellers: "مشاهده سایر فروشندگان",
    deleteProductFromCart: "حذف محصول از سبد خرید",
    firstConfirmUser: "ابتدا اکانت کاربر را تایید کنید!",
    firstConfirmShop: "ابتدا فروشگاه این کاربر را تایید کنید!",
    doYouWantToHideProducts: "آیا می‌خواهید محصولات این دسته را از دید کاربران غیر عضو پنهان کنید؟",
    discountsAttention: "توجه! مبلغ کد تخفیف از پورسانت سایت کسر می‌شود.",
    suppliers: "فروشندگان",
    belowProductsHasAddressError: "محصولات زیر امکان ارسال به آدرس انتخابی شما را ندارند!",
    youHavePendingWithdrawRequest: "شما یک درخواست در انتظار بررسی دارید!",
    cantWithdrawSmallerThan100000: "امکان برداشت کمتر از ۱۰۰ هزار تومان وجود ندارد!",
    fillYourDetailsFirstDescription: "لطفا ابتدا اطلاعات حساب کاربری خود را تکمیل کنید",
    fillYourDetailsFirst: "تکمیل حساب کاربری",
    yourAccountInfoIsPending: "در انتظار بررسی",
    yourAccountInfoIsPendingDesc: "حساب کاربری شما در دست بررسی است! پس از تایید می‌توانید سفارش خود را ثبت کنید.",
    deleteIt: "پاک شود",
    backToPrevPage: "مرحله قبل",
    payToShop: "تسویه با فروشنده",
    payed_to_shop: "تسویه شده با فروشنده",
    purchaseDone: "پرداخت انجام شد!",
    purchaseRejected: "در انجام پرداخت مشکلی پیش آمد!",
    payToShopAreYouSure: "آیا قصد تسویه این سفارش را دارید (با فروشنده و نمایندگان)؟ ",
    changeFactorStatus: "تغییر وضعیت فاکتور",
    changeStatus: "تغییر وضعیت سفارش",
    receivingConfirmation: "تغییر وضعیت",
    isOrderReceivedToYou: "محصولات فروشنده آماده ارسال به خریدار است؟",
    changeStatusHelper: "با احتیاط و در صورت اطمینان تغییر دهید!",
    edit: "ویرایش",
    view: "مشاهده",
    payableMoney: "مبلغ قابل پرداخت",
    no_discount: "کد تخفیف وارد شده معتبر نیست!",
    editBanner: "ویرایش بنر",
    pleaseChooseYourAddress: "لطفا آدرس تحویل خود را انتخاب کنید!",
    PleaseChooseAnAddressToContinue: "لطفا آدرس تحویل خود را انتخاب کنید!",
    assignedToYou: "برای شما اعمال شد.",
    save: "ذخیره",
    makeOrder: "ثبت نهایی سفارش",
    makeSimpleOrder: "ثبت سفارش",
    makeSimpleOrderDesc: "محصولات مورد نظر خود را سفارش دهید!",
    commission: "پورسانت",
    site_commission: "پورسانت سیستم",
    total_commission: "کل پورسانت",
    number: "عدد",
    icon: "آیکون",
    link: "لینک ",
    checkout: "تکمیل سفارش",
    productsInCart: "محصولات موجود در سبد خرید",
    discount_onlyForThisCategory: "کد تخفیف فقط برای دسته‌بندی ",
    discount_onlyForThisShop: "کد تخفیف فقط برای فروشگاه ",
    discount_end_sentence: "لحاظ می‌شود.",
    isShopGlobal: "آیا فروشگاه سراسری است؟",
    iconsList: "لیست آیکون‌ها",
    bg_color: "رنگ پس‌زمینه",
    addSlideShow: "افزودن اسلایدشو",
    primary_button_text:'متن دکمه اصلی',
    primary_button_icon:'آیکون دکمه اصلی',
    primary_button_link:"لینک دکمه اصلی",
    secondary_button_text:'متن دکمه دوم',
    secondary_button_icon:'آیکون دکمه دوم',
    secondary_button_link:"لینک دکمه دوم",
    featuredProduct: "محصول ویژه؟",
    managementSetting: "تنظیمات مدیریتی",
    shopTotalRating: "امتیاز کلی فروشگاه",
    productTotalRating: "امتیاز کلی محصول",
    productTotalRatingDesc: "این امتیاز به صورت اتوماتیک از میانگین امتیاز کاربران به دست می‌آید.",
    wishlist: "لیست علاقه‌مندی‌‌ها",
    noWishlist: "محصولی در لیست علاقه‌مند‌ی‌ها نیست!",
    changeCategories: "تغییر دسته‌بندی‌ها",
    chooseCategoryIcon: "آیکون دسته‌بندی",
    siteSettings: "تنظیمات سایت",
    manageGlobalCommission: "پورسانت کلی",
    servicesPrice: "هزینه خدمات",
    hidePrices: "مخفی سازی قیمت از کاربران غیر عضو",
    pricesAreShown: "نمایش قیمت‌ها",
    pricesAreHidden: "مخفی سازی قیمت‌ها",
    youAreInSomeOneAccount: "شما در حساب کاربری فرد دیگری هستید!",
    backToMyAccount: "بازگشت",
    loginToUserAccount: "ورود به اکانت این کاربر",
    userShouldConfirmMobileFirst: "ابتدا باید شماره موبایل کاربر تایید شود!",
    requestToDeleteProduct: "درخواست حذف محصول",
    removeProduct: "حذف محصول",
    requestedToDelete: "درخواست حذف محصول ارسال شد!",
    pricingThumbnailDesc: "اگر می‌خواهید تصویر خودتان بجای تصویر محصول نمایش داده شود آن را آپلود کنید!",
    backToLogin: "بازگشت به صفحه ورود",
    doLogin: "وارد شوید!",
    nonOfThem: "هیچکدام",
    uploadedDate: "زمان آپلود",
    editMobile: "تغییر شماره موبایل",
    editMobileDesc: "در صورتی که قصد تغییر شماره موبایل خود (",
    editMobileDesc2: ") را دارید، آن را وارد کنید.",
    pricing: "قیمت‌گذاری محصولات",
    pricingOnly: "قیمت‌گذاری",
    doPricing: "برای محصولات خود و دیگران قیمت گذاری کنید!",
    pricingFor: "قیمت‌گذاری برای",
    changeRole: "تغییر سطح دسترسی",
    changeRoleToProvider: "آیا می‌خواهید سطح دسترسی خود را تغییر دهید؟",
    editAddress: "ویرایش آدرس",
    customHeadDescription: "در این قسمت می‌توانید کد‌های مورد نیاز خود مانند انواع تگ meta و Open Graph را به تگ <head> سایت  اضافه کنید. ",
    customBodyDescription: "در این قسمت می‌توانید کد‌های مورد نیاز خود مانند انواع کد Java Script و... را به انتهای تگ <body> سایت  اضافه کنید. ",
    shopOwner: "مالک فروشگاه",
    myShops: "فروشگاه‌های من",
    shopsList: "لیست فروشگاه‌ها",
    myShop: "فروشگاه من",
    makeYourShop: "فروشگاه خود را بسازید!",
    addresses: "آدرس‌های من",
    addAdressDetailsLong: "آدرس‌های خود را در این بخش اضافه کنید و در انتخاب محل تحویل سفارش‌های خود از آن استفاده کنید.",
    addAddressDetails: "آدرس‌های خود را اضافه کنید!",
    addAddress: "افزودن آدرس",
    addAddressDescription: "آدرس‌های خود را در این بخش مدیریت کنید!",
    moreInfo: "اطلاعات بیشتر",
    beSupplier: "فروشنده شوید!",
    viewShop: "مشاهده فروشگاه",
    viewShops: "مشاهده فروشگاه‌ها",
    relatedShop: "فروشگاه مربوطه",
    shopName: "نام فروشگاه",
    shop: "فروشگاه",
    menu_title: "نام آیتم",
    url: "آدرس اینترنتی",
    numberOfSells: "تعداد فروش",
    buyer: "خریدار",
    makeAShopFirst: "ابتدا فروشگاه خود را بسازید!",
    makeAShopFirstDescription: "لطفا قبل از ایجاد محصول یک فروشگاه بسازید!",
    chooseShop: "انتخاب فروشگاه",
    shopDiscountHelp: "اگر می‌خواهید کد تخفیف فقط برای یک فروشگاه اعمال شود.",
    categoryDiscountHelp: "اگر می‌خواهید کد تخفیف فقط برای یک دسته بندی اعمال شود.",
    chooseShopHelperText: "فروشگاه ارائه دهنده محصول",

    productInsertionDescription: "شما می توانید محصول خود را در این بخش بسازید و یا اطلاعات آن را از  پست اینستاگرام خود درج کنید ",
    welcomeToSite: "به مارکت اینجازنونس خوش آمدید!",
    bio: "توضیح کوتاه",
    shopDescription: "معرفی فروشگاه",
    description: "توضیح کوتاه",
    manageShops: "مدیریت فروشگاه‌ها",
    management: "مدیریت",
    makeShop: "ایجاد فروشگاه جدید",
    editShop: "ویرایش اطلاعات فروشگاه",
    yourShopIsActive: "تبریک! فروشگاه شما تایید شده و فعال است.",
    yourShopIsInchecking: "فروشگاه شما در دست بررسی است!",
    yourAccountIsActive: "تبریک! حساب کاربری شما تایید شده و فعال است.",
    yourAccountIsInChecking: "حساب کاربری شما در دست بررسی است!",
    yourProductIsActive: "تبریک! محصول شما تایید شده و فعال است.",
    yourProductIsInchecking: "محصول شما در دست بررسی است!",
    yourPricingIsActive: "قیمت‌‌گذاری شما تایید شده و درحال نمایش به کاربران است",
    yourPricingIsInchecking: "قیمت‌‌گذاری شما در دست بررسی است!",
    welcomeShopCreatorTitle: "فروشنده گرامی خوش‌آمدید!",
    WelcomeShopCreatorText: "شما می‌توانید از این بخش فروشگاه خود را ایجاد کنید، پس از تایید فروشگاه امکان انتشار محصول برای شما فراهم خواهد شد.",
    deleteShop: "فروشگاه حذف شد.",
    deleteShopFailed: "در حذف فروشگاه مشکلی به وجود آمده است.",
    deleteProduct: "محصول حذف شد.",
    deleteProductFailed: "در حذف محصول مشکلی به وجود آمده است.",
    leaderboard: "جدول امتیازات",
    searchPlaceholder:'جستجوی متخصص‌ها...',
    resume: "رزومه",
    info: "اطلاعات پایه",
    portfo: "نمونه کارها",
    services: "خدمت‌ها",
    projects: "پروژه‌ها",
    pendingProjects: "پروژه‌های در انتظار تایید",
    doneProjects: "پروژه‌های انچام شده",
    teaching: "تدریس خصوصی",
    courses: "لیست دوس تدریسی",
    jobrequest: "استخدام",
    teamUp: "تیم سازی",
    consultation: "مشاوره",
    moneyManagement: "مدیریت مالی",
    studentsList: "لیست شاگردان",
    contents: "نوشته‌ها",
    manageContents: "مدیریت نوشته‌ها",
    addContent: "ایجاد نوشته جدید",
    viewContent: "مشاهده نوشته‌های قبلی",
    makeContent: "انتشار نوشته",
    editContent: "ویرایش نوشته",
    contentTitle: "تیتر مقاله",
    pageTitle: "تیتر صفحه",
    addPage: "افزودن صفحه",
    contentAdded: "نوشته‌ی شما ایجاد شد.",
    contentAddingFailed: "در ایجاد نوشته مشکلی به وجود آمده است.",
    contentEdited: "نوشته‌ی شما ویرایش شد.",
    contentEditingFailed: "در ویرایش نوشته مشکلی به وجود آمده است.",
    deleteContent: "نوشته‌ حذف شد.",
    deleteContentFailed: "در حذف نوشته مشکلی به وجود آمده است.",
    howToSell: "نحوه فروش",
    sellOnline: "آنلاین",
    sellOffline: "آفلاین",
    myPoints: "امتیازات من",
    ifYouWantSellOnline: "در صورتی که قصد فروش آنلاین دارید، ابتدا اطلاعات بانکی خود را تکمیل کنید!",
    province: "استان",
    city: "شهر",
    editPage: "ویرایش صفحه",
    makePage: "ایجاد صفحه",

    /*********Global**********/
    account: "حساب کاربری",
    manageProducts: "مدیریت محصولات",
    headLineMakeYourFirstProduct: "محصولات خود را ایجاد کنید، محتوای آن‌ها را بهبود دهید و از ویژگی‌های های مارکت اینجازنونس لذت ببرید!",
    productDetails: "اطلاعات محصول",
    myProducts: "محصولات من",
    editProduct: "ویرایش محصول",
    makeProduct: "ایجاد محصول جدید",
    purchaseProduct: "سفارش محصول",
    resumeOrder: "ادامه سفارش",
    productName: "نام محصول",
    productEnglishName: "نام انگلیسی محصول",
    productDescription: "توضیحات",
    product: "محصول",
    viewProduct: "مشاهده محصول",
    viewOrders: "مشاهده سفارش‌ها",
    viewOrdersDetails: "همه سفارش‌های گذشته خود را از این بخش مشاهده نمایید!",
    viewOrder: "مشاهده سفارش‌",
    created_at_factor: "تاریخ ثبت سفارش",
    user_delivery: "تحویل گیرنده",
    mobileNumber: "شماره موبایل",
    orderDetails: "جزئیات سفارش‌",
    viewUserDetails: "اطلاعات کاربر",
    manageOrders: "مدیریت سفارش‌ها",
    manageOrder: "مدیریت سفارش‌",
    manageDiscounts: "مدیریت کدهای تخفیف",
    discounts: "تخفیف‌ها",
    discount_code: "کد تخفیف",
    input_your_discount_code: "در صورتی که کد تخفیف دارید، آن را وارد کنید!",
    discount_type: "نوع تخفیف",
    discount_value: "مقدار",
    percent: "درصد",
    fixed: "مقدار ثابت",
    discount_total_count: "تعداد قابل استفاده",
    total_price_to_get: "مبلغ تسویه",
    discount_count: "تعداد استفاده شده",
    discount_expired_at: "تاریخ انقضا",
    makeDiscount: "ایجاد کد تخفیف",
    editDiscount: "ویرایش کد تخفیف",
    shopOrders: "لیست فروش‌ها",
    viewShopOrders: "مشاهده فروش‌ها",
    products: "محصولات",
    productsList: "لیست محصولات",
    productOwner: "مالک محصول",
    manageComments: "مدیریت کامنت‌ها",
    manageShopComments: "مدیریت کامنت‌ فروشگاه‌ها",
    comments: "کامنت‌ها",
    commentForReplayDescription : "اگر قصد پاسخ به کامنتی را دارید از اینجا انتخاب کنید",
    noProduct:"محصولی وجود ندارد",
    chooseProductForComment : "انتخاب محصول",
    chooseShopForComment : "انتخاب فروشگاه",
    chooseContentForComment : "انتخاب نوشته",
    commentEdited:"کامنت ویرایش شد",
    commentAdded:"کامنت ایجاد شد",
    commentAddingFailed:"افزودن کامنت با مشکل مواجه شد.",
    commentEditingFailed:"ویرایش کامنت با مشکل مواجه شد.",
    commentRemoved: "کامنت با موفقیت حذف شد",
    dataRemoved: "اطلاعات با موفقیت حذف شد",
    dataRemovingFailed: "حذف اطلاعات با مشکل رو به رو شد.",
    commentRemovingFailed: "حذف کامنت با مشکل رو به رو شد.",
    deleteSelectedComments: "کامنت‌های انتخاب شده حذف شدند.",
    deleteSelectedUsers: "کاربران انتخاب شده حذف شدند.",
    role: "سطح دسترسی",
    allRoles: "تمام سطوح دسترسی",
    allUserTypes: "تمام انواع کاربران",
    editComment: "ویرایش کامنت",
    addComment: "افزودن کامنت",
    parentCommentDesc : "شما درحال پاسخ دادن به کامنت زیر هستید:",
    productRating:"امتیاز محصول",
    shopRating:"امتیاز فروشگاه",
    contentRating:"امتیاز نوشته",
    inserProduct:"درج محصول",
    inserProductFromInstagram:"درج محصول از اینستاگرام",
    confirmStatus:"وضعیت تایید",
    removeComment: "حذف کامنت",
    translateFront: "ترجمه فرانت",
    manageSlideShows: "مدیریت اسلایدشو‌",
    manageBanners: "مدیریت بنر‌ها",
    editSlideShow: "ویرایش اسلایدشو‌",
    translateFrontDesc: "در صورتی که تغییرات را با زدن دکمه ثبت اطلاعات انجام داده‌اید و تغییرات در این قسمت مشاهده نشد، از قسمت فرانت بررسی نمایید.",

    thankYou: "با تشکر!",
    settings: "تنظیمات",
    exit: "خروج از سایت",
    myAccount: "حساب کاربری",
    myInfo: "اطلاعات من",
    makeDraft: "ذخیره پیش‌نویس",
    userPanel: "پنل کاربری",
    draft: "پیش‌نویس",
    published: "منتشر شده",
    someFieldsHasError: "یک یا چند فیلد دارای خطا هستند، لطفا بررسی کنید",
    search: "جستجو",
    author: "نویسنده",
    creator: "ناشر",
    choose: "انتخاب",
    updateTime: "بروزرسانی",
    created_at: "تاریخ ایجاد",
    updated_at: "تاریخ بروزرسانی",
    makeBrand: "ایجاد برند",
    editBrand: "ویرایش برند",
    filters: "فیلتر‌ها",
    sliderTitle: "تیتر اسلایدر",
    bannerTitle: "تیتر بنر",
    subtitleBanner: "متن کناری بنر",
    bannerPosition: "محل بنر",
    activityStatus: "وضعیت",

    thumbnail: "تصویر",
    publishStatus: "وضعیت انتشار",
    keywordsLabel: "عبارات کلیدی",
    allKeywordsAreTyped: "امکان افزودن کلمات کلیدی بیشتر ندارید. ",
    keywordsDescription: "تگ‌های مرتبط را بنویسید. (برای مثال، ست هدیه، سلامت فردی و...)",
    seoDescription: "توضیح کوتاه",
    linkPlaceholder:"یک آدرس اینترنتی وارد کنید",
    addImage: "افزودن تصویر",
    imageName: "نام فایل",
    ownerMedia:"ناشر",
    insertMedia:"درج فایل",
    insert:"درج",
    linkCopied:"لینک کپی شد.",
    uploadSuccess: "آپلود با موفقیت انجام شد",
    editFile:"فایل با موفقیت ویرایش شد",
    editFileFailed:"ویرایش فایل با مشکل رو به رو شد.",
    tableAdded:"جدول ایجاد شد",
    tableAddingFailed:"افزودن جدول با مشکل مواجه شد.",
    deleteTable:"جدول با موفقیت حذف شد",
    deleteTableFailed:"حذف جدول با مشکل رو به رو شد.",
    tableEdited:"جدول ویرایش شد.",
    tableEditingFailed:"ویرایش جدول با مشکل مواجه شد.",
    selfFiles: "فایل‌های من",
    adminsFiles: "فایل‌های مدیران سایت",
    everyBodyFiles: "فایل‌های همه",
    uploading: "درحال آپلود...",
    uploadDone: "فایل آپلود شد.",
    allTypes: "همه فرمت‌ها",
    image: "تصویر",
    video: "ویدیو",
    document: "سند",
    chooseVideo:"انتخاب ویدیو",
    multiImport:"انتخاب رسانه‌ها",
    filesTable: "فایل‌های ذخیره شده",
    fileManager: "رسانه‌ها",
    lastUpdate: "آخرین بروزرسانی",
    userDetail: "اطلاعات کاربر",
    usersManagement: "مدیریت کاربران",
    allUsers: "مشاهده کاربران",
    usersActivity: "فعالیت‌ کاربران",
    paymentManagement: "اطلاعات مالی کاربران",
    addUser: "افزودن کاربر",
    editUser: "ویرایش کاربر",
    editDetails: "ویرایش پروفایل",
    logoSize600: "سایز مناسب 600x600px می‌باشد.",
    logo: "لوگو فروشگاه",
    logo_brand: "لوگو",
    cover: "کاور فروشگاه",
    chooseMedia: "انتخاب رسانه",
    submitErrorFile:"فایل ارسالی مشکل دارد.",
    imageSizeWidth700: "عرض مناسب تصویر 700px می‌باشد.",
    thumbnailSize: " حداقل عرض مناسب تصویر 600px می‌باشد.",
    userAvatarSet:"تصویر پروفایل شما بروز شد.",
    profileImage: "تصویر پروفایل",
    userAvatarSetFailed:"در تغییر تصویر پروفایل مشکلی به وجود آمده است.",
    loadingThumbnailFailed: "در بارگذاری تصویر مشکلی پیش آمده",
    ChooseImageLink:" برای کپی لینک، سایز مورد نظر را انتخاب کنید.",
    deleteFile:"فایل با موفقیت حذف شد",
    deleteFileFailed:"حذف فایل با مشکل رو به رو شد.",
    deleteSelectedFiles:"فایل ها با موفقیت حذف شدند",
    alt: "متن جایگزین (alt)",
    chooseImage: "انتخاب تصویر",
    emptyImage: "تصویری انتخاب کنید!",
    descriptionPlaceHolder: "توضیحات مربوطه را بنویسید",
    words:"کلمه",
    characters:"کاراکتر",
    uploadImage: "آپلود رسانه",
    ThumbnailTitle: "تصویر شاخص",
    loading: "درحال بارگذاری...",
    login: "Login",
    homePage: "صفحه اصلی",
    contactPage: "تماس با ما",
    shopPage: "همه محصولات",
    rulesPage: "قوانین استفاده",
    aboutPage: "درباره ما‌",
    shops: "فروشگاه‌ها",
    orLoginWith: "ورود با",
    backToHomePage: "بازگشت به صفحه اصلی",
    emailOrMobile: "ایمیل یا موبایل",
    first_name: "نام",
    last_name: "نام‌خانوادگی",
    username: "نام کاربری",
    userName: "نام و نام‌خانوادگی",
    phone: "شماره تماس",
    melli_code_compnay: "شناسه ملی",
    financial_code: "کد اقتصادی",
    mobile: "موبایل",
    mobileSample: "مثل 09123456789",
    email: "ایمیل",
    sms: "پیام کوتاه",
    verifyEmail: "تایید ایمیل",
    password: "رمزعبور",
    comment: "کامنت",
    passwordConfirm: "تکرار رمزعبور",
    passwordStrength:"حداقل ۶ کاراکتر، شامل عدد و حروف",
    passwordChanged:"رمزعبور با موفقیت تغییر کرد.",
    currentPasswordError:"رمزعبور فعلی صحیح نیست.",
    currentPassword:"رمزعبور فعلی",
    register: "ثبت‌نام",
    doRegister: "ثبت‌نام کنید",
    dontHaveAccount: "حساب کاربری ندارید؟",
    haveAccount: "حساب کاربری دارید؟",
    loginError:"ایمیل یا رمزعبور اشتباه است.",
    forgetPass:"فراموشی رمز عبور",
    loginWithToken:"ورود با رمز یکبار مصرف",
    dashboard:"داشبورد",
    emptyUsername: "نام کاربری خود را وارد کنید.",
    emptyTableCells: "جدول خالی است.",
    wrongUsername: "نام کاربری فقط میتواند شامل حروف و اعداد انگلیسی باشد.",
    NotFreeUsername: "این نام کاربری قبلا انتخاب شده.",
    existEmail: "این ایمیل قبلا ثبت شده.",
    existMobile: "این شماره موبایل قبلا ثبت شده.",
    emptyEmail: "ایمیل خود را وارد کنید.",
    emptyMobile: "شماره موبایل خود را وارد کنید.",
    wrongEmail: "فرمت ایمیل اشتباه است.",
    wrongMobile: "فرمت شماره موبایل اشتباه است.",
    wrongPrice: "مبلغ اشتباه است",
    transferPrice: "هزینه ارسال",
    transfer_type: "نحوه ارسال",
    wrongSheba: "شماره شبا اشتباه است. (۲۴ عدد)",
    wrongCreditNumber: "شماره کارت اشتباه است. (۱۶ عدد)",
    emptyPassword: "رمز عبور را وارد کنید.",
    weakPassword: "رمز عبور ضعیف است.",
    notEqualPassword: "رمز عبور و تکرار آن یکسان نیست.",
    lessPost: "نوشته‌ی پست کم است",
    maxLength: "نوشته طولانی است",
    SelectFromSelect:"انتخاب کنید",
    emptyField:'فیلد خالی است.',
    fileIsEmpty:'انتخاب فایل ضروری است!',
    fillPrevFieldFirst:'ابتدا فیلد قبل را پر کنید.',
    emptyName: "نام ضروری است",
    emptyLastName: "نام خانوادگی ضروری است",
    noMatchPassword: "تاییده با رمز عبور یکسان نیست",
    emptyTagName:"نام برچسب ضروری است",
    emptyBrandName:"نام برند ضروری است",
    cantBeMoreThan100:'نمی‌تواند بیشتر از 100 باشد',
    maxLength60:"طول این فیلد نباید بیش از ۶۰ کاراکتر باشد.",
    maxLength70:"طول این فیلد نباید بیش از ۷۰ کاراکتر باشد.",
    maxLength150:"طول این فیلد نباید بیش از ۱۵۰ کاراکتر باشد.",
    maxLength50:"طول این فیلد نباید بیش از ۵۰ کاراکتر باشد.",
    maxLength255:"طول این فیلد نباید بیش از ۲۵۵ کاراکتر باشد.",
    maxLength500:"طول این فیلد نباید بیش از ۵۰۰ کاراکتر باشد.",
    wrongMelliCode: "کد ملی اشتباه است.",
    maxLength1000:"طول این فیلد نباید بیش از ۱۰۰۰ کاراکتر باشد.",
    maxLength4000:"طول این فیلد نباید بیش از ۴۰۰۰ کاراکتر باشد.",
    between0to100:"درصد باید در بازه ۰ تا ۱۰۰ باشد",
    pleaseCheckTheBox:"لطفا قوانین را تایید کنید",
    emptyEmailOrMobile:"ایمیل یا شماره موبایل خود را وارد کنید.",
    dear:"عزیز",
    errorHappens:"در بارگذاری سایت خطایی رخ داده است!",
    allChangesSaved:"تمام تغییرات ذخیره شد.",
    allChangesSavingFailed:"ذخیره سازی تغییرات با مشکل مواجه شد!",
    registerSuccess: "کاربر با موفقیت ثبت شد.",
    registerError: "خطا در ایجاد کاربر",
    cantLoadData:"در بارگیری مطالب مشکلی است.",
    fileSize500kb:"حجم تصویر باید کمتر از ۵۰۰kb باشد.",
    fileSize100mb:"حجم تصویر باید کمتر از ۱۰۰mb باشد.",
    fileSize1mb:"حجم تصویر باید کمتر از ۱mb باشد.",
    makingTokenError:"در ایجاد کد تایید مشکلی به وجود آمده است. ",
    youLoggedOff:"شما از پنل خارج شدید",
    mobileVerified:"شماره موبایل شما تایید شد.",
    wrongToken:"کد تایید اشتباه است. ",
    mobileVerify: "تایید موبایل",
    verifyMobile: "تایید شماره موبایل",
    sendTokenText1:"رمز یکبارمصرف شما به شماره",
    sendTokenText2:"ارسال شد.",
    sendTokenTextResend:"رمز یکبار مصرف مجددا برای شما ارسال شد.",
    sendTokenText3:"زمان باقی مانده تا ارسال مجدد پیامک",
    logOut: "خروج از سایت",
    confirm: "تایید",
    token:"کد تایید",
    forgetPassword: "فراموشی رمزعبور",
    invalidMobileNumber:"این شماره در سیستم وجود ندارد",
    recoverPassword:"بازیابی رمزعبور",
    recoverPasswordText:"برای بازیابی رمز عبور، شماره موبایل خود را وارد کنید.",
    sendAgain:"ارسال دوباره",
    changePassword: "تغییر رمزعبور",
    newPassword:"رمزعبور جدید",
    newPasswordConfirm:"تکرار رمزعبور جدید",
    loginWithUserPass:"ورود با اطلاعات کاربری",
    addYourNumberFirst:"برای ورود به سایت، شماره موبایل خود را وارد کنید.",
    connectionError:'اینترنت شما قطع است.',
    copyright: " تمام حقوق محفوظ است © ",
    copyright2: " طراحی و توسعه توسط ",
    developer: " رایانیتا® ",
    unreadNotifications: "اعلان‌های خوانده نشده",
    readNotifications: "اعلان‌های خوانده شده",
    noNotifications: "اعلانی وجود ندارد!",
    nothingFound: "صفحه وجود ندارد!",
    notFoundPage: "صفحه وجود ندارد!",
    setDetails: "ثبت اطلاعات",
    detailsSet:"اطلاعات ثبت شد.",
    detailsSetFailed:"ثبت اطلاعات با مشکل مواجه شد.",
    detailsGetFailed:"دریافت اطلاعات با مشکل مواجه شد.",
    mobilSample:"نمونه: 09121234567",
    confirmationStatus:"تایید اطلاعات",
    confirmationStatusDescription:"وضعیت تایید اطلاعات",
    confirmationStatusFilter:"وضعیت تایید",
    userConfirmationStatus:"وضعیت تایید اطلاعات کاربر",
    confirmed:"تایید شده",
    unConfirmed:"در انتظار تایید",
    all:"همه",
    addNewBrand:"افزودن برند جدید",
    admin: "مدیر سایت",
    supportUser: "پشتیبانی",
    product_manager: "ادمین محصولات",
    support: "پشتیبانی",
    adminType: "مدیران سایت",
    normalType: "سایر کاربران",
    usersLog: "فعالیت‌های کاربران",
    someFieldsHasErrorPleaseCheckThem: "یک یا چند فیلد دارای خطا هستند، لطفا بررسی نمایید",
    duplicateName: 'این برند متعلق به شخص دیگری است.',
    dataAdded: "اطلاعات شما ایجاد شد.",
    dataAddingFailed: "در ایجاد اطلاعات مشکلی به وجود آمده است.",
    addressError: "فروشنده امکان ارسال محصول به آدرس انتخابی شما را ندارد!",
    onlyUsersCanPurchase: "فقط کاربران عادی امکان ثبت سفارش دارند!",
    dataEdited: "اطلاعات شما ویرایش شد.",
    categoryPublishedBefore: "دسته بندی تکراری است!",
    viewCategory: "مشاهده صفحه دسته‌بندی",
    dataEditingFailed: "در ویرایش اطلاعات مشکلی به وجود آمده است.",
    firstCheckOrdersStatus: "ابتدا وضعیت سفارش هر فروشگاه را تایید کنید!",
    deleteData: "اطلاعات حذف شد.",
    deleteDataFailed: "در حذف این اطلاعات مشکلی به وجود آمده است.",
    makeCategory: "ایجاد دسته‌بندی",
    editCategory: "ویرایش دسته‌بندی",
    makeBlogCategory: "ایجاد دسته‌بندی وبلاگ",
    editBlogCategory: "ویرایش دسته‌بندی وبلاگ",
    categoryName: "نام دسته",
    categoryParentName: "دسته والد",
    categories: "دسته‌بندی‌ها",
    pleaseChooseCategoriesFirst: "لطفا ابتدا دسته‌بندی را انتخاب کنید!",
    category: "دسته‌بندی‌",
    chooseCategory: "انتخاب دسته‌بندی‌",
    chooseSubCategory: "انتخاب زیر دسته‌",
    chooseMainCategory: "انتخاب دسته‌ اصلی",
    manageCategories: "مدیریت دسته‌بندی‌ها",
    manageBlogCategories: "دسته‌بندی‌ وبلاگ",
    manageBlogComments: "نظرات وبلاگ",
    manageBrands: "مدیریت برندها",
    brandName: "نام برند",
    brand: "برند",
    manageVariables: "مدیریت مشخصات محصول‌",
    manageAttributes: "مدیریت ویژگی‌ها‌",
    chooseAttribute: "انتخاب ویژگی‌‌",
    attributes: "ویژگی‌ها‌",
    managePages: "مدیریت صفحات",
    manageDepartments: "مدیریت دپارتمان‌ها",
    manageMessages: "مدیریت پیام‌ها",
    manageAbout: "صفحه درباره‌ما",
    manageTerms: "صفحه قوانین مقررات",
    managePrivacyPolicy: "صفحه حریم خصوصی",
    manageSellerTerms: "صفحه قوانین فروشندگان",
    manageAgentTerms: "صفحه قوانین نمایندگان",
    manageContact: "فرم تماس‌ با‌ ما",
    pageDetails: "اطلاعات صفحه",
    variableIsNotConfirmed: "در انتظار تایید",
    youCantAddMoreVariablesUntileConfirmation: "امکان افزودن مشخصات محصول بیشتر قبل از تایید موارد قبلی وجود ندارد!",
    manageVariablesOrder: "مدیریت  ترتیب مشخصات محصول‌",
    chooseVariables: "انتخاب مشخصات محصول‌",
    makeVariable: "ایجاد مشخصات محصول",
    makeAttribute: "ایجاد ویژگی",
    editVariable: "ویرایش مشخصات محصول",
    variableName: "نام مشخصه",
    attributeName: "نام ویژگی",
    attributeType: "نوع ویژگی",
    valueName: "نام مقدار",
    manageValues: "مدیریت مقدار‌ها",
    makeValue: "ایحاد مقدار",
    values: "مقدارها",
    valueColor: "رنگ مقدار",
    variables: "مشخصات محصول‌",
    variable: "مشخصات محصول",
    addNewVariable: "افزودن مشخصات محصول",
    addNewVariableDescription: "برای افزودن مشخصات محصول دکمهEnter را بزنید!",
    addYourvariableIfYouCatFind: "اگر مشخصات محصول مد نظر خود را نمی‌یابید آن را ایجاد کنید.",
    addVariable: "افزودن مشخصات محصول",
    user: "کاربر",
    agent: "نماینده",
    duplicateInstagram: 'این اکانت متعلق به شخص دیگری است.',
    markAsRead: 'خوانده شد!',
    dataFetchingError: 'اطلاعات مورد نظر یافت نشد!',
    activity: 'فعالیت',
    details: 'جزئیات',
    gallery: 'گالری محصول',
    removeImage: 'حذف تصویر',
    remove: 'حذف',
    tickets: "درخواست پشتیبانی",
    manageTickets: "مدیریت تیکت‌ها",
    requestType: "نوع درخواست",
    supportRequestFromDepartment: "پشتیبانی سیستم",
    supportRequestForProduct: "پشتیبانی محصول",
    sendFile: "ارسال فایل",
    ticket_sender: "ارسال کننده",
    ticket_receiver: "دریافت کننده",
    ticket_title: "موضوع",
    ticket_status: "وضعیت",
    ticket_edited_at: " آخرین ارسال",
    sendMessage: "ارسال پیام",
    backToTickets: "بازگشت به تیکت‌ها",
    deleteTicket: "تیکت حذف شد!",
    deleteTicketFailed: "حذف تیکت با مشکل مواجه شد!",
    ticket_details: "اطلاعات تیکت",
    ticket_sent: "تیکت ارسال شد!",
    sendFastTicket: "ارسال سریع پیام",
    sendMessageTo: "ارسال پیام به",
    addTicket: "ارسال تیکت",
    send: "ارسال",
    messageText: "متن پیام",
    gotToTicketPage: "صفحه پیام‌ها",
    removeRequest: 'حذف درخواست',
    discountMoreThanPrice:"میزان تخفیف از کل قیمت بیشتر است",
    discount_percent:"درصد تخفیف",
    discount_price:"قیمت با تخفیف",
    discount:"تخفیف",
    factor:"فاکتور",
    factor_number:"شماره فاکتور",
    factor_status:"وضعیت فاکتور",
    bill:"صورت حساب",
    price:"قیمت",
    x_price: "قیمت عدد X",
    priceName:"مبلغ",
    create_order:"ثبت سفارش",
    totalPrice:"قیمت کالا‌ها",
    totalDiscount:"قیمت کالا‌ها",
    totalCartPrice:"جمع سبد خرید",
    totalPriceFull:"جمع مبالغ",
    totalUserDiscounts:"جمع تخفیف‌ها",
    totalDiscounts:"سود شما از خرید",
    cancel:"لغو!",
    youDontHavePermission:"شما دسترسی مجاز ندارید!",
    pageNotFound:"این صفحه در دسترس نیست!",
    pressEnterAfterEachKeyword:"پس از نوشتن هر عبارت دکمه Enter را بزنید!",
    chooseBeginDate:"تاریخ شروع",
    chooseEndDate:"تاریخ پایان",
    chooseDate:"انتخاب تاریخ",
    doFilter:"اعمال فیلتر",
    removeFilter:"حذف فیلتر",
    noLogFound:"فعالیتی یافت نشد!",
    roleHelper:"انتخاب سطح دسترسی کاربر",
    goBackToPanel:"بازگشت به پنل",
    emailVerified:"ایمیل تایید شد!",
    emailNotVerified:"ایمیل تایید نشد!",
    visitsChart:"نمودار بازدید‌ها",
    visits:"بازدید‌ها",
    products_visits:"بازدید محصولات",
    shop_visits:"بازدید فروشگاه",
    googleAnalytics:"گوگل آنالیتیکس",
    liveUsers:"کاربران آنلاین",
    newProducts:"محصولات جدید",
    newShops:"فروشگاه‌های جدید",
    newRegistrations:"ثبت‌نام‌های جدید",
    todayAllUnRegisteredUsers:"بازدید‌های امروز",
    transactionType:"نوع تراکنش",
    depositMoney:"شارژ کیف پول",
    depositMoneyDescription:"مقدار مورد نظر برای شارژ کیف پول خود را در این قسمت وارد کنید.",
    withdrawMoney:"برداشت از کیف پول",
    withdrawMoneyDescription:"مقدار مورد نظر برای برداشت از کیف پول خود را در این قسمت وارد کنید.",
    deposit:"شارژ",
    withdraw:"برداشت",
    amount:"مبلغ",
    status:"وضعیت",
    order_status:"وضعیت سفارش",
    orderPayed:"مبلغ سفارش پرداخت شد.",
    orderPayingFailed:"در پرداخت مبلغ مشکلی وجود دارد!",
    pending:"در انتظار تایید",
    not_payed:"در انتظار پرداخت",
    commission_payed:"تسویه شده",
    commission_not_payed:"درحال بررسی ",
    commission_status:"تسویه ",
    gathering:"تایید اولیه",
    confirmToPay:"تایید جهت پرداخت",
    removeFactorWithMessage:"لغو سفارش و اعلام به کاربر",
    ready:"آماده تحویل به خریدار",
    sending:"درحال ارسال به خریدار",
    done:"تکمیل سفارش",
    rejected:"لغو سفارش",
    myWallet: "کیف پول",
    orders: "خرید‌ها",
    ordersList: "لیست سفارشات",
    payOrder: "پرداخت سفارش‌",
    payWithWallet: "پرداخت از کیف پول",
    purchase: "پرداخت",
    withdrawRequest: "درخواست واریز",
    transactionTrackingCode: "کد تراکنش",
    postal_code: "کد پستی",
    postalInfo: "اطلاعات پستی",
    pleaseCompleteYourPostalInfo: "لطفا اطلاعات پستی خود را تکمیل کنید!",
    postalInfoDescription: "لطفا اطلاعات پستی خود را در این قسمت وارد کنید، دکمه ثبت اطلاعات را بزنید و سپس اقدام به پرداخت کنید.",
    address: "آدرس",
    melli_code: "کد ملی",
    doctors_code: "کد نظام پزشکی",
    addRefrenceIdFirst: "ابتدا کد تراکنش را وارد کنید!",
    myWalletBalance: "موجودی کیف پول",
    balance: "موجودی",
    pleaseVerifyFirst: "لطفا ابتدا اطلاعات حساب خود را تکمیل کنید!",
    verificationDescription: "برای برداشت از کیف پول خود در سایت نیاز به تایید اطلاعات بانکی دارید!",
    verificationDetails: "اطلاعات بانکی",
    verificationDataSets: "اطلاعات بانکی ثبت شد!",
    manageVerifications: "مدیریت اطلاعات بانکی",
    ManageUserVerification: "مدیریت اطلاعات بانکی کاربر",
    bank_name: "نام بانک",
    viewUserBankInfo: "مشاهده اطلاعات بانکی کاربر",
    sheba: "شماره شبا",
    credit_number: "شماره کارت",
    melli_card_image: "تصویر کارت ملی",
    credit_card_image: "تصویر کارت بانکی",
    activeStatus: "وضعیت",
    active: "فعال",
    inactive: "غیر فعال",
    manageWallets: "مدیریت کیف پول‌ها",
    manageUserWallet: "مدیریت تراکنش‌های کاربر",
    yourBankInfoIsActive: "اطلاعات بانکی شما تایید شده و امکان برداشت از کیف پول سایت برای شما فراهم است.",
    yourBankInfoIsInchecking: "اطلاعات بانکی شما در دست بررسی است.",
    payed: "پرداخت شد",
    is_payed: "پرداخت شده",
    readMore: "بیشتر بخوانید!",
    areYouSure: "آیا از انجام این کار مطمئنید؟",
    yes: "بله",
    no: "خیر",
    doYouWanaExit: "آیا قصد خروج دارید؟",
    certificationFile: "فایل جواز",
    chooseFile: "انتخاب فایل",
    chooseAFile: "لطفا یک فایل انتخاب کنید!",
    welcomeUserTitle: "کاربر گرامی خوش آمدید!",
    WelcomeUserText: "لطفا اطلاعات حساب کاربری خود را تکمیل نمایید.",
    with: "با ثبت نام در وب‌سایت ",
    withCreateShop: "با ایجاد فروشگاه در وب‌سایت ",
    createShop: "ایجاد فروشگاه",
    manageYourShop: "فروشگاه خود را مدیریت کنید",
    shopRules: "قوانین و مقررات ثبت فروشگاه",
    rules: "قوانین و مقررات",
    and: "و",
    privacyPolicy: "قوانین حریم خصوصی",
    privacyPolicyConfirmation: "آن را می‌پذیرم.",
    agentRulesConfirmation: "موافقم و آن را می‌پذیرم.",
    privacyPolicyConfirmationShop: "را می‌پذیرم.",
    registerDate: "تاریخ ثبت‌نام",
    uncompleted: "تکمیل نشده",
    userMobileIsConfirmed: "شماره موبایل کاربر تایید شده است.",
    userMobileIsNotConfirmed: "شماره موبایل کاربر تایید نشده است.",
    doYouWanaConfirm: "شماره موبایل کاربر را تایید می‌کنید؟",
    ProductIsNotOriginal: "محصول غیر اصل می‌باشد؟",
    advantages_disadvantages: "مزایا و معایب",
    advantages: "مزایا",
    productDisadvantages: "معایب محصول",
    productAdvantages: "مزایای محصول",
    disadvantages: "معایب",
    advantage: "مزیت",
    disadvantage: "عیب",
    color: "رنگ",
    string: "متن",
    variablePricing: "قیمت‌گذاری متغیر",
    simplePricing: "قیمت‌گذاری ساده",
    simplePricingDescTitle: "قیمت ثابت",
    variablePricingDescTitle: "قیمت بر اساس ویژگی محصول",
    attributeChooserTitle: "های مورد نظر خود را انتخاب نمایید.",
    doYouWantToChangePricingType: "آیا می‌خواهید نوع قیمت‌گذاری خود را تغییر دهید؟",
    help: "راهنما",
    understood: "متوجه شدم!",
    pricingDiferences: "تفاوت انواع قیمت‌گذاری",
    simplePricingDesc: "اگر یک نوع از این محصول دارید و تفاوتی در تعداد موجودی و قیمت ندارد از این گزینه استفاده کنید.",
    variablePricingDesc: "اگر چند نوع از این محصول دارید و در تعداد موجودی و قیمت تفاوت وجود دارد از این گزینه استفاده کنید.",
    warranty: "گارانتی",
    inventory: "موجودی",
    inventoryDesc: "تعداد موجودی کالا",
    transfer_time: "زمان ارسال کالا",
    modifyPricing: "ویرایش قیمت‌گذاری",
    managePricing: "مدیریت قیمت‌گذاری",
    headerTemplate: "هدر و منو سایت",
    headerTemplateDesc: "تنظیمات مربوط به هدر و منو‌‌های سایت",
    chooseHeader: "انتخاب هدر",
    chooseHeaderText: "در این قسمت می‌توانید طراحی هدر سایت خود را به طور کامل تغییر دهید.",
    chooseFooter: "انتخاب فوتر",
    chooseFooterText: "در این قسمت می‌توانید طراحی فوتر سایت خود را به طور کامل تغییر دهید.",
    manageHeader: "مدیریت هدر",
    manageFooter: "مدیریت فوتر",
    manageMenu: "مدیریت منو",
    footerCertifications: "نماد‌های فوتر",
    makeHeaderMenu: "تنظیمات منو اصلی سایت",
    makeHeaderMenuText: "در این قسمت می‌توانید المان‌های منوی اصلی سایت را به طور کامل تغییر دهید. (این منو در هدر سایت و هدر پنل نمایش داده می‌شود).",
    makeFooterMenu: "تنظیمات منو پایینی سایت",
    makeFooterMenuText: "در این قسمت می‌توانید المان‌های منوی پایینی سایت را به طور کامل تغییر دهید.",
    footerTemplate: "فوتر و منو پایین",
    footerTemplateDesc: "تنظیمات مربوط به فوتر و منو‌‌ پایینی سایت",
    footerCertificationTitle: "کد مربوط به نماد ها",
    footerCertificationText: "در این قسمت می‌توانید کد‌های دریافتی از سازمان های مختلف مانند نماد اعتماد، رسانه‌های دیجیتال و... را قرار دهید.",
    mapScriptTitle: "کد نقشه",
    mapScriptText: "در این قسمت می‌توانید کد‌های دریافتی از پلتفرم های مختلف نمایش نقشه مانند Google Map را قرار دهید، تا در صفحه تماس نشان داده شوند.",
    home_page_top_banner: "بنر بالا صفحه اصلی",
    home_page_cta: "دکمه فراخوان صفحه اصلی",
    home_page_twin_right: "بنر راست دوتایی صفحه اصلی ",
    home_page_twin_left: "بنر چپ دوتایی صفحه اصلی",
    categories_banner: "بنر دسته بندی‌ها",
    footer_icon_text_1: "متن و آیکون فوتر ۱",
    footer_icon_text_2: "متن و آیکون فوتر ۲",
    footer_icon_text_3: "متن و آیکون فوتر ۳",
    footer_icon_text_4: "متن و آیکون فوتر ۴",
};
export const DataTableLocalization = {};
export const getDataTableLocalization = function () {
    return {
        pagination: {
            labelDisplayedRows: `{from}-{to} ${translate('from')} {count}`,
            labelRowsSelect: translate('labelRowsSelect'),
            labelRowsPerPage: translate('labelRowsPerPage'),
            firstAriaLabel: translate('firstAriaLabel'),
            firstTooltip: translate('firstTooltip'),
            previousAriaLabel: translate('previousAriaLabel'),
            previousTooltip: translate('previousTooltip'),
            nextAriaLabel:translate('nextAriaLabel'),
            nextTooltip: translate('nextTooltip'),
            lastAriaLabel: translate('lastAriaLabel'),
            lastTooltip: translate('lastTooltip'),
        },
        toolbar: {
            nRowsSelected: `{0} ${translate('rowsSelected')}`,
            searchTooltip: translate('search'),
            searchPlaceholder: translate('search'),
            exportTitle: translate('exportTitle'),
            exportAriaLabel: translate('exportCSVName'),
            exportCSVName: translate('exportCSVName'),
            exportPDFName: "",

        },
        header: {
            actions: <SettingsIcon/>
        },
        body: {
            emptyDataSourceMessage: translate('emptyDataSourceMessage'),
            addTooltip: translate('addTooltip'),
            deleteTooltip:translate('deleteTooltip'),
            editTooltip: translate('editTooltip'),

            filterRow: {
                filterTooltip:translate('filterTooltip'),
            },
            editRow:{
                deleteText: translate('deleteText'),
                cancelTooltip: translate('cancelTooltip'),
                saveTooltip: translate('saveTooltip'),
            }
        }
    };
}
