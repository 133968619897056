import React from 'react';
import {connect} from "trim-redux";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../config/design/styles";
import {ChromePicker } from 'react-color';
import Typography from "@material-ui/core/Typography/Typography";
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import Popover from "@material-ui/core/Popover/Popover";
import ColorLensRoundedIcon from '@material-ui/icons/ColorLensRounded';
import Button from "@material-ui/core/Button/Button";
import {translate} from "../../../config/lang";
class ColorPicker extends React.Component{
    state = {
        color: !!this.props.color ? this.props.color : this.props.primary_color ,
        title:this.props.title,
        anchorEl:null
    };

    handleClick = (event) => {
        this.setState({
            anchorEl:event.currentTarget
        });
    };

    handleClose = () => {
        this.setState({
            anchorEl:null
        });
    };

    handleChangeColor(){
        this.props.onChange(this.state.color)
        this.setState({
            anchorEl:null
        });
    }
    render(){
        let {color,title,anchorEl} = this.state;
        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;
        return (
            <>
                <Button
                    onClick={(event) => this.handleClick(event)}
                    aria-describedby={id}
                    endIcon={<ExpandMoreRoundedIcon style={{color:"#fff"}}/>}
                    className="color-picker-container"
                >
                    <span className="color-picker-badge" style={{backgroundColor:color}}> </span>
                    <Typography>{title}</Typography>
                </Button>
                <Popover
                    style={{marginTop:10}}
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={() => this.handleClose()}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <div style={{padding:10}} >
                        <ChromePicker
                            color={ color }
                            onChangeComplete={ (color) => this.setState({color: color.hex}) }
                        />
                        <Button
                            onClick={() => this.handleChangeColor()}
                            size="large"
                            style={{width:"100%",marginTop:10}}
                            variant="contained"
                            color="primary"
                            startIcon={<ColorLensRoundedIcon/>}
                        >
                            {translate('changeColor')}
                        </Button>
                    </div>
                </Popover>
            </>
        );
    }
}
const mstp = state => ({
    about: state.about,
    primary_color: state.primary_color,

});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(ColorPicker)))));