import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {store} from "./redux/store";
import { Provider } from 'trim-redux'
import 'react-perfect-scrollbar/dist/css/styles.css';
import Definer from "./Definer";

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
        <Definer />
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
