import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField/TextField";
import validator from "validator";
import axios from "axios";
import {defaultVariabls} from "../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import LoadingButton from "../../components/elements/LoadingButton";
import SubTitle from "../../components/elements/SubTitle";
import AppAnimate from "../../components/AppAnimate/index";
import AppCard from "../../components/layouts/components/AppCard";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import {connect, setStore} from "trim-redux";
import BreadCrumbs from "../../components/elements/BreadCrumbs";
import AppCardLoaderFields from "../../components/Skeletons/AppCardLoaderFields";
import Alert from "@material-ui/lab/Alert/Alert";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import LinearCategoryHolder from "../../components/fields/LinearCategoryHolder/index";
import Button from "@material-ui/core/Button/Button";
import PageviewRoundedIcon from '@material-ui/icons/PageviewRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import GetConfirmationText from "../../components/elements/GetConfirmationText";
import moment from "moment-timezone";
import {makeSlug, translate} from "../../../config/lang";
import OptionUnitChooser from "../../components/fields/OptionUnitChooser";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import FieldCounterWithoutBar from "../../components/elements/FieldCounterWithoutBar";
import UserCard from "../../components/elements/UserCard";
import CountryChooser from "../../components/fields/CountryChooser";
import Divider from "@material-ui/core/Divider/Divider";
import {updateRedux} from "../../../config/Security";
import CustomFields from "../advertisements/components/CustomFields";
import FilesChooser from "../fileManager/Components/FilesChooser";
import MakeEqualRFQ from "./MakeEqualRFQ";
import RFQUserChooser from "./components/RFQUserChooser";
import TotalResponses from "./components/TotalResponses";
import {getTimeZone} from "../../../config/values";
import {InputLabel, ListItemText, MenuItem} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import FormControl from "@material-ui/core/FormControl/FormControl";

class MakeRFQ extends React.Component {
    constructor(props){
        super(props);
        this.ThumbChild = React.createRef();
        this.EditorChild = React.createRef();
    }
    state = {
        fields:{
            name:"",
            description:"",
            moq:'',
            payment_terms:[],
            shipping_terms:[],
            expired_at:moment().add(3,'month').format( 'YYYY-MM-DDTHH:mm'),

        },
        content:{
            HTML: '',
            RAW: null,
            PlainText: ''
        },
        thumbnail: null,
        file_id: null,
        errors:{},
        categories:[],
        categoriesName:[],
        files: [],
        loading: true,
        currency: '',
        moq_unit: '',
        has_depot_address:false,
        SubmitLoading: false,
        publish: true,
        confirmed: false,
        real_confirmed: false,
        slug: null,
        id:this.props.match.params.id,
        editMode:false,
        owner_id:null,
        related_user:null,
        owner:null,
        requested_to_delete:0,
        editor_input_text:'',
        updated_at:null,
        created_at:null,
        expired_at:null,
        expired:0,
        expired_this_week:0,
        activePhoneNumbers:1,
        phoneNumberFilled:false,
        activeEmail:1,
        emailFilled:false,
        origin:null,
        server_origin:null,
        destination:null,
        server_destination:null,
        visits:0,
        today_visits:0,
        lang: this.props.locale,
        parent_category:null,
        item:null,
        equal:null,
        custom_fields:null,
    };
    classes = this.props.classes;
    componentDidMount(){
        if(this.state.id){
            this.setState({
                editMode:true
            });
            this.loadData();
        }else{
            this.setState({
                loading:false
            })
        }
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }
    componentDidUpdate(prevProps){
        if(this.state.id !== this.props.match.params.id){
            this.setState({
                id: this.props.match.params.id
            })
        }
    }
    handleThumbImage(thumbnail){
        this.setState({
            thumbnail
        })
    }
    async loadData(){
        this.setState({
            loading:true
        })
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/rfq/get/${this.state.id}`, config)
                .then(response => {
                    this.setState({
                        fields: {
                            name: response.data.name,
                            description:!!response.data.description ? response.data.description : '',
                            price:!!response.data.price ? response.data.price : '',
                            moq:!!response.data.moq ? response.data.moq : '',
                            payment_terms:!!response.data.payment_terms ? response.data.payment_terms.split(',') : [],
                            shipping_terms:!!response.data.shipping_terms ? response.data.shipping_terms.split(',') : [],
                            expired_at: !!response.data.expired_at ? response.data.expired_at : moment().add(3,'month').format( 'YYYY-MM-DDTHH:mm'),
                        },
                        attachment: response.data.attachment,
                        content: {
                            HTML: response.data.html,
                            RAW: response.data.raw,
                        },
                        confirmed: response.data.confirmed,
                        real_confirmed: response.data.confirmed,
                        slug: response.data.slug,
                        currency: response.data.currency,
                        moq_unit: response.data.moq_unit,
                        owner_id: response.data.user_id,
                        owner: response.data.owner,
                        updated_at: response.data.updated_at,
                        created_at: response.data.created_at,
                        expired_at: response.data.expired_at,
                        expired: response.data.expired,
                        has_depot_address:!!response.data.depot_lng,
                        origin:!!response.data.origin ? response.data.origin : null,
                        server_origin:!!response.data.origin ? response.data.origin : null,
                        destination:!!response.data.destination ? response.data.destination : null,
                        server_destination:!!response.data.destination ? response.data.destination : null,
                        visits:parseInt(response.data.visits_count),
                        today_visits:parseInt(response.data.today_visits_count),
                        lang:response.data.lang,
                        custom_fields:response.data.custom_fields,
                    })
                    let categoriesTemp=[];
                    let categoriesNameTemp=[];
                    let filesTemp=[];
                    let parent_category = null;
                    response.data.categories.map(item => {
                        if(!parent_category){
                            parent_category = item.id;
                        }
                        categoriesTemp.push(item.id)
                        categoriesNameTemp.push(item.name)
                        return true
                    })
                    response.data.attachments.map(item =>{
                        filesTemp.push({
                            id: item.id,
                            name: item.name,
                            type: item.type,
                        })
                        return true
                    })

                    this.setState({
                        categories: categoriesTemp,
                        categoriesName: categoriesNameTemp,
                        files: filesTemp,
                        parent_category,
                        item:response.data,
                        equal:response.data.equal,
                        loading: false
                    })
                }).catch(error => {
                    console.log(error.response)
                    /*this.props.history.push('/');
                    this.props.enqueueSnackbar(translate('pageNotFound'), {
                        variant: 'error',
                    });*/
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
            this.setState({
                loading:false
            })
        }
    }
    handleChange(e){
        let {errors,fields} = this.state;
        let target = e.target;
        if(target.name === "price"){
            fields.priceR = target.value.replace(/,/ig, '');
        }else if(target.name === "discount_price"){
            fields.discount_priceR = target.value.replace(/,/ig, '');
        }else{
            fields[target.name] = target.value;
        }
        errors[target.name] = "";
        this.setState({
            fields,
            errors
        });
    }

    handleValidation(callback){
        let { fields,company_lat,depot_lat,categories,destination } = this.state;
        let errors = {};
        let formIsValid = true;

        //name
        if(validator.isEmpty(fields.name)){
            formIsValid = false;
            errors['name'] = translate('emptyField');
        }
        if(categories.length === 0){
            formIsValid = false;
            errors['category'] = translate('emptyField');
        }
        //Description
        if(fields.description !== null ) {
            if (fields.description.length > 1000) {
                formIsValid = false;
                errors['description'] = translate('maxLength1000');
            }
        }
        if(!fields.description){
            formIsValid = false;
            errors['description'] = translate('emptyField');
        }
        if(!fields.moq){
            formIsValid = false;
            errors['moq'] = translate('emptyField');
        }
        if(!destination){
            formIsValid = false;
            errors['destination'] = translate('emptyField');
        }
        let expire_at = moment(fields.expired_at,'YYYY-MM-DDTHH:mm');
        if(moment().isAfter(expire_at)){
            formIsValid = false;
            errors['expired_at'] = translate('dateShouldBeAfterToday');
        }
        if(!formIsValid){
            this.props.enqueueSnackbar(translate('someFieldsHasError'), {
                variant: 'warning',
            });
            this.setState({
                errors,
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        let { id,fields,content,thumbnail,files,moq_unit,editMode,related_user,categories,confirmed,currency,destination,company_lng,depot_lat,depot_lng,origin} = this.state;
        let data = new FormData();
        data.append('name', fields.name);
        data.append('moq', fields.moq);
        data.append('payment_terms', fields.payment_terms);
        data.append('shipping_terms', fields.shipping_terms);
        data.append('moq_unit', moq_unit);
        data.append('lang', this.props.locale);
        data.append('html', content.HTML);
        data.append('raw', content.RAW);
        data.append('plainText', content.PlainText);
        data.append('id', id ? id : 0);
        data.append('description', fields.description);
        data.append('expired_at', fields.expired_at);
        data.append('related_user', related_user ? related_user.id : 0);
        data.append('thumbnail', !!thumbnail ? thumbnail.id : 1);
        data.append('confirmed',confirmed ? 1 : 0);
        data.append('origin_id', !!origin ? origin.id : '');
        data.append('destination_id', !!destination ? destination.id : '');
        data.append('custom_fields', JSON.stringify(this.state.custom_fields));

        data.append('categories', JSON.stringify(categories));

        if(files !== null){
            let files_ids = [];
            files.map((row) =>{
                files_ids.push(row.id);
                return true
            })
            files = JSON.stringify(files_ids);
        }
        data.append('attachments', files);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        let {user} = this.props;
        axios.post(`${defaultVariabls.url}/rfq/make`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(editMode ? translate('rfqEdited') : translate('rfqAdded'), {
                    variant: 'success',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
                updateRedux();
                if(!!user.role.permission.manageAdvertisements){
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    })
                    this.props.history.push(`/manage/rfqs`);
                }else{
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    })
                    this.props.history.push(`/my-rfqs`);
                }
                if(!this.state.editMode){

                }else{
                    this.loadData();
                }
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(editMode ?  translate('rfqEditingFailed') : translate('rfqAddingFailed'), {
                    variant: 'error',
                });

                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
            });

    }
    handleClickDescription(){
        let {fields,content} = this.state;
        if(fields['description'] === ''){
            fields['description'] = content.PlainText === null ? null : content.PlainText.slice(0, 150);
            this.setState({fields})
        }
    }
    handleSubmit(e,draft = false){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.setState({
                    publish:draft
                })
                this.handleRequest();
            }
        });
    }

    handleOutPut(code){
        this.setState({
            content:code
        })
    }
    handleCategoryHolder(categories){
        let {parent_category,errors} = this.state;
        if(parent_category === null){
            this.setState({
                parent_category: categories[0]
            })
        }else if(parent_category !== categories[0]){
            this.setState({
                parent_category: categories[0]
            })
        }
        errors['category'] ='';
        this.setState({
            categories,
            errors
        })
    }
    handleGalleryChange(data){
        this.setState({
            gallery: data,
        })
    }
    handleChangeConfirmed(){
        this.setState({
            confirmed: !this.state.confirmed,
        })
    }
    async handleRequestToDelete(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        await axios.get(`${defaultVariabls.url}/rfq/request_delete/${this.state.id}`, config)
            .then(response => {
                this.props.enqueueSnackbar(translate('requestedToDelete'), {
                    variant: 'success',
                });
                this.setState({
                    requested_to_delete:1,
                })
            }).catch(error => {
                console.log(error.response)
            })
    }
    handleDeleteItem(){
        let data = new FormData();
        data.append('id', this.state.id);
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/delete/rfq`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(translate('deleteRFQ'), {
                    variant: 'success',
                });
                this.props.history.push('/manage/rfqs')
                return true
            })

            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(translate('deleteRFQFailed'), {
                    variant: 'error',
                });
                return false
            });
    }
    async changeCategories(){
        await this.setState({
            categoriesName:[],
            categories:[],
            variables:[],
        })
    }
    handleChangeCurrency(currency){
        this.setState({
            currency
        })
    }
    handleChangeUnit(unit){
        this.setState({
            moq_unit:unit
        })
    }

    handleCompanyAddress(address){
        let {fields} = this.state;
        fields['company_address'] = address;
        this.setState({
            fields
        })

    }
    handleDepotAddress(address){
        let {fields} = this.state;
        fields['depot_address'] = address;
        this.setState({
            fields
        })

    }
    handleFilesChange(data){
        this.setState({
            files: data,
        })
    }
    handleCHangeDestination(destination){
        let {errors} = this.state;
        errors['destination'] = ''
        this.setState({
            destination,
            errors
        })
    }
    render(){
        let {fields,currency,moq_unit,item,equal,files,parent_category,loading,server_country,server_state,phoneNumberFilled,activeEmail,emailFilled, errors,gallery,slug,SubmitLoading,owner,thumbnail,editor_input_text,categoriesName,content,editMode,real_confirmed,confirmed,company_lat,company_lng,depot_lat,depot_lng} = this.state;
        const {user} = this.props;
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={editMode ? translate('editRFQ') :  translate('makeRFQ')}>
                {
                    <Grid container spacing={2} justify="center">
                        <Grid item xs={12} lg={11}>
                            <Grid container spacing={2} justify="center">
                                {
                                    (!!editMode && !loading) &&
                                    <>
                                        <Grid item xs={12}>
                                            {
                                                !! user.role.permission.manageAdvertisements ?
                                                    <>
                                                        <Alert
                                                            severity="info"
                                                            action={
                                                                <FormControlLabel
                                                                    style={{marginRight:10}}
                                                                    control={
                                                                        <Switch
                                                                            checked={confirmed}
                                                                            onChange={this.handleChangeConfirmed.bind(this)}
                                                                            value={confirmed}
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                    label={confirmed ? translate('confirmed') : translate('unConfirmed')}
                                                                />
                                                            }
                                                        >
                                                            {translate('confirmationStatusDescription')}
                                                        </Alert>
                                                    </>
                                                    :
                                                    confirmed ?
                                                        <Alert severity="success" variant="filled">
                                                            <Typography  className={this.classes.alertText}>{translate('yourRFQIsActive')}</Typography>
                                                        </Alert>
                                                        :
                                                        <Alert severity="warning">
                                                            <Typography className={this.classes.alertText}>{translate('yourRFQIsInchecking')}</Typography>
                                                        </Alert>
                                            }
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <AppCard style={{height:"100%"}}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        {
                                                            !!real_confirmed ?
                                                                <>
                                                                    <Button
                                                                        component="a"
                                                                        target="_blank"
                                                                        size="small"
                                                                        startIcon={<PageviewRoundedIcon/>}
                                                                        href={makeSlug(`rfq/${slug}`,this.state.lang)}
                                                                        color="secondary"
                                                                    >
                                                                        {translate('viewRFQ')}
                                                                    </Button>
                                                                </> :
                                                                <>
                                                                    <Button
                                                                        component="a"
                                                                        target="_blank"
                                                                        size="small"
                                                                        variant="contained"
                                                                        startIcon={<PageviewRoundedIcon/>}
                                                                        href={makeSlug(`rfq/${slug}`,this.state.lang)}
                                                                        color="secondary"
                                                                    >
                                                                        {translate('viewPreview')}
                                                                    </Button>

                                                                </>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography  className='date-holder'>
                                                            <span>{translate('todayVisitsCount')}</span>
                                                            <strong>
                                                                {this.state.today_visits}
                                                            </strong>
                                                        </Typography>
                                                        <Divider style={{marginTop:10,marginBottom:10}}/>
                                                        <Typography  className='date-holder'>
                                                            <span>{translate('totalVisitsCount')}</span>
                                                            <strong>
                                                                {this.state.visits}
                                                            </strong>
                                                        </Typography>
                                                        <Divider style={{marginTop:10,marginBottom:10}}/>
                                                        <Typography  className='date-holder'>
                                                            <span>{translate('totalResponses')}</span>
                                                            <strong>
                                                                <TotalResponses  id={this.state.id} manage={!!user.role.permission.manageAdvertisements}/>
                                                            </strong>
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </AppCard>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <AppCard style={{height:"100%"}}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <SubTitle align="left" style={{position:"relative"}}>
                                                            {translate('rfqInfo')}
                                                            {
                                                                user.role.type !== "admin" &&
                                                                <Button
                                                                    color="secondary"
                                                                    size="small"
                                                                    style={{position:'absolute',top:-5,right:0}}
                                                                    variant="contained"
                                                                    startIcon={<img style={{width:20}} src={`${defaultVariabls.uploads}/FileManager/${user.plan.parent.thumbnail.name}.${user.plan.parent.thumbnail.extension}`}  />}
                                                                >
                                                                    {user.plan.parent.name}
                                                                </Button>
                                                            }
                                                        </SubTitle>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography paragraph className='date-holder' style={{marginTop: "0px !important"}}>
                                                            <span>{translate('rfq_expired_at')}</span>
                                                            <strong className={this.classes.alertText}>
                                                                {
                                                                    (this.state.expired === 0) ?
                                                                        `${moment.tz(this.state.fields.expired_at,'UTC').tz(getTimeZone()).fromNow(true)}` :
                                                                        <span className="expired">{translate('expired')}</span>
                                                                }
                                                            </strong>
                                                        </Typography>
                                                        <Divider style={{marginTop:10,marginBottom:10}}/>
                                                        <Typography  className='date-holder'>
                                                                <span>
                                                                {translate('updated_at')}
                                                            </span>
                                                            <strong>
                                                                {`${moment.tz(this.state.updated_at,'UTC').tz(getTimeZone()).format( 'HH:mm , YYYY/M/D')}`}
                                                            </strong>
                                                        </Typography>
                                                        <Divider style={{marginTop:10,marginBottom:10}}/>
                                                        <Typography  className='date-holder'>
                                                            <span>{translate('created_at')}</span>
                                                            <strong>
                                                                {`${moment.tz(this.state.created_at,'UTC').tz(getTimeZone()).format( 'HH:mm , YYYY/M/D')}`}
                                                            </strong>
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </AppCard>
                                        </Grid>
                                    </>

                                }
                                {
                                    !!editMode &&
                                    <>
                                        {
                                            this.state.loading ? null :
                                                <Grid item xs={12}>
                                                    <AppCard
                                                        className={this.classes.equalAppCard}
                                                    >
                                                       <div
                                                           className={this.classes.equalHolder}
                                                       >
                                                           <SubTitle noHR align="left">{translate('equalItem')}</SubTitle>

                                                           <MakeEqualRFQ
                                                               equal_id={!!equal ? equal.id : null}
                                                               item={item}
                                                               reload={() => this.loadData()}
                                                           />
                                                       </div>
                                                    </AppCard>
                                                </Grid>
                                        }
                                    </>
                                }
                                <Grid item xs={12}>
                                    <AppCard
                                        title={editMode ? translate('editRFQ') :  translate('makeRFQ')}
                                        action={
                                            <BreadCrumbs singleLevel firstText={editMode ? translate('editRFQ') :  translate('makeRFQ')}/>
                                        }>
                                        {
                                            this.state.loading ? <AppCardLoaderFields/> :
                                                <Grid container spacing={2} justify="center" alignItems="center" style={{marginBottom:10}}>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            color="secondary"
                                                            name="name"
                                                            variant="outlined"
                                                            label={translate('rfqName')}
                                                            placeholder={translate('rfqNameDesc')}
                                                            type="text"
                                                            id="name"
                                                            value={fields.name}
                                                            onChange={this.handleChange.bind(this)}
                                                            error={!!errors['name']}
                                                            helperText={errors['name']}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            fullWidth
                                                            name="moq"
                                                            required
                                                            variant="outlined"
                                                            label={translate('quantityRequirement')}
                                                            type="number"
                                                            color="secondary"
                                                            id="moq"
                                                            value={fields.moq}
                                                            onChange={this.handleChange.bind(this)}
                                                            error={!!errors['moq']}
                                                            helperText={errors['moq']}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <OptionUnitChooser
                                                                            current={moq_unit}
                                                                            onChange={(unit) => this.handleChangeUnit(unit)}/>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Grid>
                                                    {/*<Grid item xs={12} md={6}>
                                                        {
                                                            !!this.state.server_origin ?
                                                                <TextField
                                                                    required
                                                                    disabled
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    label={translate('buyerFrom')}
                                                                    type="text"
                                                                    color="secondary"
                                                                    value={this.state.server_origin.name}
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                <img src={`https://flagsapi.com/${this.state.server_origin.iso2}/flat/16.png`} style={{marginRight:5}}/>
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                /> :
                                                                <CountryChooser
                                                                    label={translate('buyerFrom')}
                                                                    error={errors['origin']}
                                                                    handleCountry={(country) => this.setState({origin:country})}
                                                                />
                                                        }

                                                    </Grid>*/}
                                                    <Grid item xs={12} md={6}>
                                                        {
                                                            !!this.state.server_destination ?
                                                                <TextField
                                                                    required
                                                                    color="secondary"
                                                                    disabled
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    label={translate('destinationCountry')}
                                                                    type="text"
                                                                    value={this.state.server_destination.name}
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                <img src={`https://flagsapi.com/${this.state.server_destination.iso2}/flat/16.png`} style={{marginRight:5}}/>
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                /> :
                                                                <CountryChooser
                                                                    required
                                                                    label={translate('destinationCountry')}
                                                                    error={errors['destination']}
                                                                    handleCountry={(country) => this.handleCHangeDestination(country)}
                                                                />
                                                        }

                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <FormControl
                                                            fullwidth
                                                            variant="outlined"
                                                            style={{width:"100%"}}
                                                        >
                                                            <InputLabel style={{marginBottom:4}}>{translate('payment_terms')}</InputLabel>
                                                            <Select
                                                                variant="outlined"
                                                                value={fields.payment_terms}
                                                                multiple="multi_select"
                                                                name="payment_terms"
                                                                onChange={this.handleChange.bind(this)}
                                                                label={translate('payment_terms')}
                                                                renderValue={(selected) => selected.join(', ')}

                                                            >
                                                                {
                                                                    this.props.setting.payment_terms.map((option,i)=>(
                                                                        <MenuItem key={i} value={option}>
                                                                            <Checkbox checked={fields.payment_terms.indexOf(option) > -1}/>
                                                                            <ListItemText primary={option} />
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <FormControl
                                                            fullwidth
                                                            variant="outlined"
                                                            style={{width:"100%"}}
                                                        >
                                                            <InputLabel style={{marginBottom:4}}>{translate('shipping_terms')}</InputLabel>
                                                            <Select
                                                                variant="outlined"
                                                                value={fields.shipping_terms}
                                                                multiple="multi_select"
                                                                name="shipping_terms"
                                                                onChange={this.handleChange.bind(this)}
                                                                label={translate('shipping_terms')}
                                                                renderValue={(selected) => selected.join(', ')}

                                                            >
                                                                {
                                                                    this.props.setting.shipping_terms.map((option,i)=>(
                                                                        <MenuItem key={i} value={option}>
                                                                            <Checkbox checked={fields.shipping_terms.indexOf(option) > -1}/>
                                                                            <ListItemText primary={option} />
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            variant="outlined"
                                                            multiline
                                                            fullWidth
                                                            required
                                                            name="description"
                                                            color="secondary"
                                                            label={translate('rfqDescription')}
                                                            type="text"
                                                            id="description"
                                                            minRows="5"
                                                            value={fields.description}
                                                            onChange={this.handleChange.bind(this)}
                                                            error={!!errors['description']}
                                                            helperText={errors['description']}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <FieldCounterWithoutBar length={fields.description.length} max={1000}/>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Grid>
                                                    {
                                                        user.role.type === "admin" &&
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                name="expired_at"
                                                                variant="outlined"
                                                                required
                                                                color="secondary"
                                                                label={translate('rfq_expire_time')}
                                                                id="datetime-local"
                                                                type="datetime-local"
                                                                onChange={this.handleChange.bind(this)}
                                                                value={fields.expired_at}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                error={!!errors['expired_at']}
                                                                helperText={!!errors['expired_at'] ? errors['expired_at'] :  moment.tz(fields.expired_at,'UTC').tz(getTimeZone()).fromNow()}
                                                            />
                                                        </Grid>
                                                    }
                                                </Grid>
                                        }
                                    </AppCard>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <AppCard style={{height:"100%"}}>
                                        {
                                            this.state.loading ? <AppCardLoaderFields/> :
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <LinearCategoryHolder
                                                            outPut={this.handleCategoryHolder.bind(this)}
                                                            inPut={categoriesName}
                                                            editMode={categoriesName.length === 0 ? false : editMode}
                                                            required
                                                            error={!!errors['category']}
                                                        />
                                                        {
                                                            !!errors['category'] ?
                                                                <Typography className="errorText" color="error" variant="body2">
                                                                    {errors['category']}
                                                                </Typography>
                                                                : null
                                                        }
                                                    </Grid>
                                                    {
                                                        (!!editMode && !!categoriesName.length && !!user.role.permission.manageAdvertisements) &&
                                                        <Grid item xs={12}>
                                                            <Button
                                                                size="small"
                                                                variant="outlined"
                                                                onClick={() => this.changeCategories()}
                                                            >
                                                                {translate('changeCategories')}
                                                            </Button>
                                                        </Grid>
                                                    }

                                                </Grid>
                                        }
                                    </AppCard>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <AppCard style={{height:'100%'}}>

                                        {
                                            this.state.loading ? <AppCardLoaderFields/> :
                                                <>
                                                    <CustomFields
                                                        type={"rfq"}
                                                        position_type="custom_fields"
                                                        input={this.state.custom_fields}
                                                        category_id={this.state.parent_category}
                                                        handleCustomFields={(custom_fields) => this.setState({custom_fields})}
                                                    />
                                                </>
                                        }
                                    </AppCard>
                                </Grid>
                                <Grid item xs={12}>
                                    <AppCard>
                                        {
                                            this.state.loading ? <AppCardLoaderFields/> :
                                                <>
                                                    <SubTitle align="left">{translate('attachments')}</SubTitle>
                                                    <FilesChooser
                                                        onlyFile
                                                        onChange={this.handleFilesChange.bind(this)}
                                                        initial={files}
                                                    />
                                                </>
                                        }
                                    </AppCard>
                                </Grid>
                                {
                                    (!! user.role.permission.manageShops && !!editMode && !this.state.loading) &&
                                    <Grid item xs={12}>
                                        <UserCard title={translate('creator')} id={owner.id}/>
                                    </Grid>
                                }
                                {
                                    (!!user.role.permission.manageShops  && !editMode) &&
                                    <Grid item xs={12}>
                                        <AppCard style={{height:'100%'}}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <SubTitle align="left">{translate('relatedUser')}</SubTitle>
                                                </Grid>
                                                {
                                                    this.state.loading ? <AppCardLoaderFields/> :
                                                        <Grid item xs={12}>
                                                            <RFQUserChooser
                                                                outPut={(res) => this.setState({
                                                                    related_user:res
                                                                })
                                                                }
                                                            />
                                                        </Grid>
                                                }
                                            </Grid>
                                        </AppCard>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <LoadingButton
                                        variant="contained"
                                        color="primary"
                                        style={{minWidth:200}}
                                        onClick = {this.handleSubmit.bind(this)}
                                        loading = {SubmitLoading}
                                    >
                                        {editMode ? translate('editRFQ') :  translate('makeRFQ')}
                                    </LoadingButton>
                                </Grid>
                                <Grid item xs={12}>
                                    {
                                        !!editMode ?
                                            user.id === this.state.owner_id && !user.role.permission.manageAdvertisements && !this.state.requested_to_delete &&
                                            <Button
                                                startIcon={<DeleteRoundedIcon/>}
                                                style={{color:"#ff5451"}}
                                                onClick={() => this.handleRequestToDelete()}
                                            >
                                                {translate('requestToDeleteRFQ')}
                                            </Button>
                                            : null
                                    }
                                    {
                                        !!editMode ?
                                            !!user.role.permission.manageAdvertisements &&
                                            <GetConfirmationText
                                                variant="outlined"
                                                style={{color: "#f44336",backgroundColor:"#fff"}}
                                                tooltip={translate('removeRFQ')}
                                                handleAction={() => this.handleDeleteItem()}
                                            >
                                                {translate('removeRFQ')}
                                            </GetConfirmationText>
                                            : null
                                    }
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>

                }
            </AppAnimate>

        );
    }
}

const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,
    locale: state.locale,
    setting: state.setting,
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MakeRFQ))));