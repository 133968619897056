import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import BackupIcon from '@material-ui/icons/Backup';
import Button from "@material-ui/core/Button/Button";
import {Translate} from "../../../../config/Translate";
import axios from "axios/index";
import {defaultVariabls} from "../../../../config/index";
import CircularProgress from '@material-ui/core/CircularProgress';
import { withSnackbar } from 'notistack';
import withWidth from "@material-ui/core/withWidth/withWidth";
import IconButton from "@material-ui/core/IconButton/IconButton";
import {translate} from "../../../../config/lang";
import CloudUploadTwoToneIcon from '@material-ui/icons/CloudUploadTwoTone';
import VideoThumbnailChooser from "./VideoThumbnailChooser";
class SingleImageUploader extends React.Component {
    state = {
        loading:false,
        percentCompleted:0,
        uploadText:!!this.props.title ? this.props.title : translate('addImage'),
        disable:false,
        openVideoThumbnailChooser: false,
        file: null,
    }
    classes = this.props.classes;
    handleuploadSuccess(){
        this.setState({
            uploadText:translate('uploadDone')
        })
        setTimeout(() => {
            this.setState({
                percentCompleted:0,
                uploadText:translate('uploadImage'),
                disable:false
            })
        }, 3000);
    }
    onUploadImage = (event) => {
        event.preventDefault();

        this.setState({
            loading:true,
            disable:true
        })
        let canUpload = true;
        const length = event.target.files.length;
        for (let i = 0 ; i < length ; i++){
            const file = event.target.files[i];
            let FileSize = file.size / 1024;
            let name = file.name;
            let lastDot = name.lastIndexOf('.');
            let extension = name.substring(lastDot + 1);
            if(extension === "png" || extension === "jpg" || extension === 'jpeg' || extension === 'webp'){
                if (FileSize > 20480) {
                    this.props.enqueueSnackbar(translate('fileSize20mb'), {
                        variant: 'error',
                    });
                    this.setState({
                        loading: false,
                        disable: false,
                    })
                    canUpload = false;
                }
            }else {
                canUpload = false;
            }
            if(canUpload){
                const data = new FormData();
                data.append('image', file);
                data.append('is_preview', 1);
                if(!!this.props.defined_width && !!this.props.defined_height){
                    data.append('defined_width', this.props.defined_width);
                    data.append('defined_height', this.props.defined_height);
                }
                let loginToken = localStorage.getItem('ACT');
                let config = {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${loginToken}`
                    },
                    onUploadProgress: (progressEvent) => {
                        this.setState({
                            uploadText:translate('uploading')
                        })
                        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        if(percentCompleted === 100){
                            this.handleuploadSuccess();
                        }
                        this.setState({
                            percentCompleted: percentCompleted,
                        })
                    }
                };
                axios.post(`${defaultVariabls.url}/file/add`, data, config)
                    .then(response => {
                        this.props.handlePreview(response.data)
                    })
                    .catch(error => {
                        if(error.response.status === 403){
                            this.props.enqueueSnackbar(translate('storageIsFull'), {
                                variant: 'warning',
                            });
                        }else{
                            console.log(error.response)
                            this.props.enqueueSnackbar(translate('submitErrorFile'), {
                                variant: 'error',
                            });
                        }

                    });

            }
        }

        this.setState({
            loading:false
        })
    };
    render(){
        let {percentCompleted,uploadText,openVideoThumbnailChooser,file} = this.state;
        let {simple} = this.props;
        return(
            <React.Fragment>
                <div className={this.classes.uploadProgress}>
                    <input
                        className={this.classes.FileManagerInput}
                        id="FileManager_Input_uploader"
                        type="file"
                        name="image"
                        multiple
                        onChange={this.onUploadImage.bind(this)}
                    />
                    <label htmlFor="FileManager_Input_uploader">
                        <Button
                            variant="outlined"
                            color="secondary"
                            component="span"
                            className={!simple ? this.classes.addImageBtn : ''}
                            startIcon={!!percentCompleted ? <CircularProgress style={{marginBottom:15}} size={35} thickness={4} variant="static" value={percentCompleted}/> :  <CloudUploadTwoToneIcon />}
                        >
                            {uploadText}
                        </Button>
                    </label>
                </div>
            </React.Fragment>
        )
    }
}

export default withWidth()(withSnackbar(withStyles(Styles)(SingleImageUploader)));