import Typography from "@material-ui/core/Typography/Typography";
import Link from "@material-ui/core/Link/Link";
import React from "react";
import {defaultVariabls} from "../../../config/index";
import {getStore} from "trim-redux";
import {translate} from "../../../config/lang";
import ToggleDarkMode from "./ToggleDarkMode";

export default function Copyright(props) {
    const app_name = getStore('app_name');
    const locale = getStore('locale');
    return (
        <Typography className={props.className} variant="body2" color={props.light ? "secondary" : "textSecondary" } align="center">
            © The
            {' '}<Link color="inherit" href={defaultVariabls.website}>
                B2B Mineral
            </Link>{' '}
            All rights reserved!
{/*
            <ToggleDarkMode/>
*/}
        </Typography>
    );
}