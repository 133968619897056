import React from "react";
import Button from "@material-ui/core/Button/Button";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import {withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../config/design/styles";
import {connect} from "trim-redux";
import axios from "axios";
import {defaultVariabls} from "../../../config";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField/TextField";
import {translate} from "../../../config/lang";

class CurrencyChooser extends React.Component{
    state = {
        states:[],
        selected:null,
        loading:true,
        open:false,
        country:null,
    };
    async componentDidMount(){
        await this.loadData();
    }
    async componentDidUpdate(prevProps){
        if(this.state.country !== this.props.country){
            await this.setState({
                country: this.props.country
            })
            await this.loadData()
            await this.setState({
                loading:true
            })
        }
    }
    async loadData(){
        let {country} = this.state;
        if(!!country){
            let loginToken = localStorage.getItem('ACT');
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            axios.get(`${defaultVariabls.url}/states/getAll/${country.id}`, config)
                .then(response => {
                    this.setState({
                        states:response.data,
                        loading:false,
                    })
                })
                .catch(error => {
                    this.setState({
                        loading:false
                    })
                });
        }else{
            this.setState({
                states:[],
                loading:false,
            })
        }

    }
    handleState(data){
        this.props.handleState(data);
    }
    render(){
        let {states,loading,open,country} = this.state;
        let{required,error} = this.props;

        return (
        <>
            <Autocomplete
                fullWidth
                open={open}
                onOpen={() => {
                    this.setState({open:true})
                }}
                onClose={() => {
                    this.setState({open:false})
                }}
                disabled={!country}
                defaultValue={states[1]}
                getOptionLabel={(option) => option.name}
                onChange={(event, value) => this.handleState(value)}
                options={states}
                loading={loading}
                required={required}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        required={required}
                        label={translate('state')}
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                        error={error}
                        helperText={!!error && error}
                    />
                )}
            />
        </>
    );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,

});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(CurrencyChooser))));