import React from 'react';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {defaultVariabls} from "../../../../../config/index";
import {connect, setStore} from "trim-redux";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import withWidth from "@material-ui/core/withWidth/withWidth";
import {translate} from "../../../../../config/lang";
import MakeCertificateButton from "./MakeCertificateButton";
import DeleteButton from "../../../../components/elements/DeleteButton";
import axios from "axios";
import Button from "@material-ui/core/Button/Button";

class SlideShowItem extends React.Component {
    async handleDelete(id){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/certificate/delete/${id}`, config)
                .then(response => {
                    this.props.reload();

                }).catch(error => {
                    console.log(error.response)
                    this.props.reload();
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }
    }
    render(){
        let {item} = this.props;
        return (
            <div
                className="slide_show_item"
                style={{backgroundImage: `url("${defaultVariabls.uploads}/FileManager/${item.thumbnail.name}_thumbnail.${item.thumbnail.extension}")`,}}
            >
                <h3>{item.name}</h3>
                <div className="controller">
                    <MakeCertificateButton id={item.id} reload={() => this.props.reload()}/>
                    <DeleteButton handleDelete={() => this.handleDelete(item.id)}/>
                </div>
            </div>
        );
    }
}

const mstp = state => ({
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(SlideShowItem)))));