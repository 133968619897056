import React from 'react';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {DataTableLocalization} from "../../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../../config/index";
import {connect} from "trim-redux";
import MaterialTable from "material-table";
import {tableIcons} from "../../../../../config/design/TableIcons";
import {Link, withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import Container from "@material-ui/core/Container/Container";
import {Translate} from "../../../../../config/Translate";
import AppCardLoaderTable from "../../../../components/Skeletons/AppCardLoaderTable";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {currencySymbol, price} from "../../../../../config/values";
import Button from "@material-ui/core/Button/Button";
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';

class Orders extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    state = {
        columns: [
            {
                field: 'shop',
                editable: 'never',
                filtering: false,
                title: Translate.shop,
            },
            {
                field: 'total_sells',
                editable: 'never',
                filtering: false,
                title: Translate.numberOfSells,
            },
            { title: Translate.totalSells,
                field: 'total_price_payed',
                filterPlaceholder: Translate.search,
            },
            { title: Translate.totalCommissions,
                field: 'total_commission',
                filterPlaceholder: Translate.search,
            },
            { title: Translate.activity,
                editable: 'never',
                field: 'activity',
                render: rowData =>
                    <Button
                        variant="outlined"
                        color="primary"
                        component={Link}
                        to={`/edit/shop/${rowData.id}`}
                        endIcon={<ChevronLeftRoundedIcon/>}
                        size={"small"}
                        style={{marginBottom:5,marginRight:5}}
                    >
                        {Translate.viewShop}
                    </Button>
            },

        ],
        data: [],
        loading:false,
        ChangeLoader:false,
        beginDate: '',
        endDate: '',
    };
    async componentDidUpdate(newProps){
        if(this.state.beginDate !== this.props.beginDate){
            await this.setState({
                beginDate: this.props.beginDate
            })
            await this.loadData();
        }
        if(this.state.endDate !== this.props.endDate){
            await this.setState({
                endDate: this.props.endDate
            })
            await this.loadData();
        }
    }
    loadData(){
        let {beginDate,endDate} = this.state;
        if((!!beginDate && !!endDate) || (!beginDate && !endDate)) {
            this.tableRef.current && this.tableRef.current.onQueryChange()
        }
    }
    render(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        let {beginDate,endDate} = this.state;
        return (
            <>
                {
                    this.state.loading ? <AppCardLoaderTable/> :
                        <Container maxWidth="xl" className={!!this.props.widget ? "thin-table" : null}>
                            <MaterialTable
                                tableRef={this.tableRef}
                                icons={tableIcons}
                                title={" "}
                                localization={DataTableLocalization}
                                columns={this.state.columns}
                                data={query =>
                                    new Promise((resolve, reject) => {
                                        axios.get(`${defaultVariabls.url}/ordersByShops/quickView?begin=${beginDate}&end=${endDate}`, config)
                                            .then(response => {
                                                let data = [];
                                                // eslint-disable-next-line
                                                response.data.map((row) => {
                                                    data.push({
                                                        total_price_payed: price(row.total_price_payed) +" "+currencySymbol,
                                                        total_commission: price(row.total_commission) +" "+currencySymbol,
                                                        shop:row.name,
                                                        id:row.id,
                                                        total_sells:row.total_sells
                                                    })
                                                });
                                                resolve({
                                                    data: data,

                                                })
                                            }).catch(error => {
                                            console.log(error.response)
                                        })
                                    })
                                }
                                options={{
                                    search:false,
                                    paging:false
                                }}
                            />
                        </Container>
                }
            </>

        );
    }
}

const mstp = state => ({
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(Orders)))));