import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../../config/design/styles";
import {translate} from "../../../../../config/lang";
import AddBox from "@material-ui/icons/AddBox";
import Button from "@material-ui/core/Button/Button";
import MakeFAQ from "../MakeFAQ";
import EditRoundedIcon from '@material-ui/icons/EditRounded';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class AddFAQButton extends React.Component{
    state = {
        open: false,
        ad_id:null,
        replay_to:null
    };
    classes = this.props.classes;

    toggleOpen(){
        this.setState({
            open: !this.state.open
        })
    }
    handleClose = () => {
        this.setState({
            open:false
        });
    };
    reload(){
        this.setState({
            open:false,
        })
        this.props.reload();
    }
    render(){
        let {open} = this.state;
        let {id} = this.props;
        return(
            <div>
                <Button
                    color="secondary"
                    variant={!!id ? "outlined" : "contained"}
                    size={!!id ? "small" : ''}
                    onClick={this.toggleOpen.bind(this)}
                    startIcon={!!id ? <EditRoundedIcon/> : <AddBox />}
                >
                    {!!id ? translate('edit') :  translate('addFAQ')}
                </Button>
                <Dialog fullWidth={true} maxWidth="sm" open={open} onClose={this.handleClose} TransitionComponent={Transition} >
                    <AppBar className={this.classes.appBarDialog}>
                        <Toolbar>
                            <Typography variant="h6" className={this.classes.titleDialog}>
                                {!!id ? translate('editFAQ') :  translate('addFAQ')}
                            </Typography>
                            <IconButton color="inherit" onClick={this.handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <div style={{padding:20}}>
                        <MakeFAQ id={!!id ? id : 0} reload={() => this.reload()}/>
                    </div>
                </Dialog>
            </div>
        )
    }
}
export default  withStyles(Styles)(AddFAQButton)