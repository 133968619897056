import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../../../config/Translate";
import { withSnackbar } from 'notistack';
import BreadCrumbs from "../../../../components/elements/BreadCrumbs";
import AppAnimate from "../../../../components/AppAnimate";
import AppCard from "../../../../components/layouts/components/AppCard";
import SlideShowsManager from "./SlideShowsManager";
import {translate} from "../../../../../config/lang";
import ImageGallery from "../../../fileManager/Components/ImageGallery";
import axios from "axios";
import {defaultVariabls} from "../../../../../config";
import {connect, setStore} from "trim-redux";
import {withRouter} from "react-router";
import {updateRedux} from "../../../../../config/Security";
import LoadingButton from "../../../../components/elements/LoadingButton";
import HomeBannersManager from "../home_banners/HomeBannersManager";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button/Button";
import AccessAlarmsTwoToneIcon from '@material-ui/icons/AccessAlarmsTwoTone';

class SlideShowsPage extends React.Component {
    classes = this.props.classes;
    render(){
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={translate('manageSlideShows')}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} lg={11}>
                        <AppCard
                            title={translate('homeTopSlideShow')}
                            action={
                                <Button
                                    color="secondary"
                                    size="small"
                                    component={Link}
                                    to={'/setting/information'}
                                    startIcon={<AccessAlarmsTwoToneIcon/>}

                                >
                                    {translate('changeDelayTime')}
                                </Button>
                            }>
                            <SlideShowsManager type={"home"} defined_width={700} defined_height={700}/>
                        </AppCard>
                    </Grid>

                    <Grid item xs={12} lg={11}>
                        <AppCard
                            title={translate('homeMiddleSlideShow')}
                            action={
                                <Button
                                    color="secondary"
                                    size="small"
                                    component={Link}
                                    to={'/setting/information'}
                                    startIcon={<AccessAlarmsTwoToneIcon/>}
                                >
                                    {translate('changeDelayTime')}
                                </Button>
                            }
                        >
                            <SlideShowsManager type={"home_middle"} defined_width={1325} defined_height={285}/>
                        </AppCard>
                    </Grid>

                </Grid>
            </AppAnimate>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,
    locale: state.locale,
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(SlideShowsPage))));
