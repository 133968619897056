import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField/TextField";
import validator from "validator";
import axios from "axios";
import {defaultVariabls} from "../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import LoadingButton from "../../components/elements/LoadingButton";
import AppAnimate from "../../components/AppAnimate/index";
import AppCard from "../../components/layouts/components/AppCard";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import {connect, setStore} from "trim-redux";
import BreadCrumbs from "../../components/elements/BreadCrumbs";
import AppCardLoaderFields from "../../components/Skeletons/AppCardLoaderFields";
import Alert from "@material-ui/lab/Alert/Alert";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import LinearCategoryHolder from "../../components/fields/LinearCategoryHolder/index";
import Button from "@material-ui/core/Button/Button";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import {makeSlug, toggleLocale, translate} from "../../../config/lang";
import CurrencyChooser from "../../components/fields/CurrencyChooser";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import FieldCounterWithoutBar from "../../components/elements/FieldCounterWithoutBar";
import IconButton from "@material-ui/core/IconButton/IconButton";
import {hasAccess, updateRedux} from "../../../config/Security";
import YourPlanDoesntSupportThis from "../Dashboard/components/YourPlanDoesntSupportThis";
import Dialog from "@material-ui/core/Dialog/Dialog";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import AppBar from "@material-ui/core/AppBar/AppBar";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import CloseIcon from '@material-ui/icons/Close';
import CheckUserCanAdBusiness from "./components/CheckUserCanAdBusiness";
import SubTitle from "../../components/elements/SubTitle";
import CustomFields from "../advertisements/components/CustomFields";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import {truncate} from "../../../config/values";
import PageviewRoundedIcon from "@material-ui/icons/PageviewRounded";

class MakeEqualBusiness extends React.Component {
    constructor(props){
        super(props);
        this.ThumbChild = React.createRef();
        this.EditorChild = React.createRef();
    }
    state = {
        fields:{
            name:"",
            description:'',
            total_employees:'',
            total_annual_revenue:'',
            year:'',
            main_products:'',
            working_days_times:'',
        },
        logo: null,
        thumbnail: null,
        errors:{},
        banners: [],
        videos: [],
        loading: true,
        SubmitLoading: false,
        confirmed: false,
        real_confirmed: false,
        slug: null,
        id:this.props.equal_id,
        editMode:false,
        owner_id:null,
        owner:null,
        updated_at:null,
        created_at:null,
        lat:"",
        lng:"",
        country:null,
        state:null,
        server_country:null,
        server_state:null,
        visits:0,
        today_visits:0,
        comments:0,
        lang: this.props.locale,
        allowed_comment:0,
        item:this.props.item,
        modal:false,
        business:null,
        trade_capacity_fields:null,
        product_capacity_fields:null,
        years:[],
        type:null,
    };
    classes = this.props.classes;
    async componentDidMount(){
        await this.setState({
            id: this.props.equal_id,
            loading:true
        })
        if(this.state.id){
            await this.setState({
                editMode:true
            });
            await this.loadData();
        }else{
            await this.setState({
                loading:false,
                type:this.props.item.type,
            })
        }
        let years = [];
        for(let i = 1900 ;  i < 2101 ; i++ ){
            years.push(i);
        }
        this.setState({
            years
        })
    }
    componentDidUpdate(prevProps){
        if(this.state.id !== this.props.equal_id){
            this.setState({
                id: this.props.equal_id,
                loading:true
            })
            if(!!this.state.id && !!this.props.equal_id){
                this.loadData();
            }
        }
    }
    handleLogoImage(logo){
        this.setState({
            logo
        })
    }
    handleThumbImage(thumbnail){
        this.setState({
            thumbnail
        })
    }
    async loadData(){
        this.setState({
            loading:true,
        })
        if(!this.state.id || !this.props.equal_id || (this.props.equal_id !== this.state.id)) {
            this.setState({
                editMode:false,
                loading:false,
                id:null
            })
        }else {
            let loginToken = localStorage.getItem('ACT');
            if (loginToken !== null) {
                let config = {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${loginToken}`
                    }
                };
                await axios.get(`${defaultVariabls.url}/business/getById/${this.state.id}`, config)
                    .then(response => {
                        this.setState({
                            fields: {
                                name: response.data.name,
                                year: !!response.data.year ? response.data.year : '',
                                main_products: !!response.data.main_products ? response.data.main_products : '',
                                description: !!response.data.description ? response.data.description : '',
                                total_employees: !!response.data.total_employees ? response.data.total_employees : '',
                                total_annual_revenue: !!response.data.total_annual_revenue ? response.data.total_annual_revenue : '',
                            },
                            logo: response.data.logo,
                            confirmed: response.data.confirmed,
                            real_confirmed: response.data.confirmed,
                            slug: response.data.slug,
                            owner_id: response.data.user_id,
                            owner: response.data.owner,
                            updated_at: response.data.updated_at,
                            created_at: response.data.created_at,
                            lat: !!response.data.lat ? parseFloat(response.data.lat) : '',
                            lng: !!response.data.lng ? parseFloat(response.data.lng) : '',
                            country: !!response.data.country ? response.data.country : null,
                            state: !!response.data.state ? response.data.state : null,
                            server_country: !!response.data.country ? response.data.country : null,
                            server_state: !!response.data.state ? response.data.state : null,
                            visits: parseInt(response.data.visits_count),
                            today_visits: parseInt(response.data.today_visits_count),
                            lang: response.data.lang,
                            custom_fields: response.data.custom_fields,
                            product_capacity_fields: response.data.product_capacity_fields,
                            trade_capacity_fields: response.data.trade_capacity_fields,
                            type: response.data.type,
                        })
                        let bannerTemp = [];
                        response.data.banners.map(item => {
                            bannerTemp.push({
                                src: `${defaultVariabls.uploads}/FileManager/${item.name}_s_thumb.${item.extension}`,
                                link: !!item.source_id ? `https://www.youtube.com/watch?v=${item.source_id}` : `${defaultVariabls.uploads}/FileManager/${item.name}.${item.extension}`,
                                id: item.id,
                                name: item.name,
                                type: item.type,
                                preview: item.preview_url ? item.preview_url : item.preview_id ? `${defaultVariabls.uploads}/FileManager/${item.preview.name}_s_thumb.${item.preview.extension}` : '',
                            })
                            return true
                        })

                        this.setState({
                            banners: bannerTemp,
                            business: response.data,
                            equal: response.data.equal,
                            loading: false
                        })
                    }).catch(error => {
                        console.log(error.response)
                        /*this.props.history.push('/');
                        this.props.enqueueSnackbar(translate('pageNotFound'), {
                            variant: 'error',
                        });*/
                        this.setState({
                            loading: false
                        })
                    })
            } else {
                setStore({
                    isAuth: false,
                    user: null,
                });
                this.setState({
                    loading: false
                })
            }
        }
    }
    handleChange(e){
        let {errors,fields} = this.state;
        let target = e.target;
        if(target.name === "total_employees"){
            fields[target.name] = Math.abs(parseInt(target.value));
        }else if(target.name === "total_annual_revenue"){
            fields.total_annual_revenueR = Math.abs(parseInt(target.value.replace(/,/ig, '')));
        } else{
            fields[target.name] = target.value;
        }
        errors[target.name] = "";
        this.setState({
            fields,
        });
    }

    handleValidation(callback){
        let { fields,country,state,lat } = this.state;
        let errors = {};
        let formIsValid = true;

        //name
        if(validator.isEmpty(fields.name)){
            formIsValid = false;
            errors['name'] = translate('emptyField');
        }


        if(!formIsValid){
            this.props.enqueueSnackbar(translate('someFieldsHasError'), {
                variant: 'warning',
            });
            this.setState({
                errors,
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }
    async toggleModal(){
        await toggleLocale();
        await this.setState({
            modal: !this.state.modal
        })
    }
    handleRequest(){
        let { id,fields,editMode,confirmed,item,type,product_capacity_fields,trade_capacity_fields} = this.state;
        let data = new FormData();
        data.append('name', fields.name);
        data.append('lang', this.props.fake_locale);
        data.append('year', fields.year);
        data.append('working_days_times', fields.working_days_times);
        data.append('description', fields.description);
        data.append('total_annual_revenue', fields.total_annual_revenue);
        data.append('total_employees', fields.total_employees);
        data.append('main_products', fields.main_products);

        data.append('id', id ? id : 0);
        data.append('confirmed',confirmed ? 1 : 0);
        data.append('equal_id', item.id);
        data.append('type_id', type.id);
        data.append('product_capacity_fields', JSON.stringify(product_capacity_fields));
        data.append('trade_capacity_fields', JSON.stringify(trade_capacity_fields));
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        let {user} = this.props;
        axios.post(`${defaultVariabls.url}/business/make-equal`, data, config)
            .then(async response => {
                await this.toggleModal();

                await this.props.enqueueSnackbar(editMode ? translate('businessEdited') : translate('businessAdded'), {
                    variant: 'success',
                });
                await this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
                await this.loadData();
                await this.props.reload();
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(editMode ?  translate('businessEditingFailed') : translate('businessAddingFailed'), {
                    variant: 'error',
                });

                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
            });

    }
    handleSubmit(e,draft = false){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.setState({
                    publish:draft
                })
                this.handleRequest();
            }
        });
    }
    handleChangeConfirmed(){
        this.setState({
            confirmed: !this.state.confirmed,
        })
    }

    handleChangePhone(phone,name){
        let {fields,errors} = this.state;
        fields[name] = phone;
        errors[name] = '';
        this.setState({
            fields,
            errors
        })
    }
    handleAddress(address){
        let {fields} = this.state;
        fields['address'] = address;
        this.setState({
            fields
        })
    }
    render(){
        let {fields,currency,activePhoneNumbers,item,equal,business,modal,country,parent_category,loading,dislikes_count,likes_count,server_country,server_state,phoneNumberFilled,activeEmail,emailFilled, errors,gallery,slug,SubmitLoading,owner,thumbnail,editor_input_text,categoriesName,content,editMode,real_confirmed,confirmed,lat,lng} = this.state;
        const {user,list} = this.props;
        return (
            <>
                {
                    !!business ?
                        <Button
                            color={!!list ? 'default' : "secondary"}
                            variant={!!list ? 'text' : "outlined"}
                            disabled={loading}
                            size={!!list ? 'small' : "medium"}
                            onClick={() => this.toggleModal()}
                            startIcon={<EditRoundedIcon/>}
                            style={{whiteSpace:"nowrap"}}

                        >
                            {!!list ? truncate(business.name,8) : business.name }
                        </Button> :
                        <Button
                            color={!!list ? 'default' : "secondary"}
                            variant={!!list ? 'outlined' : "contained"}
                            disabled={loading}
                            size={!!list ? 'small' : "medium"}
                            onClick={() => this.toggleModal()}
                            startIcon={<AddRoundedIcon/>}
                            style={{whiteSpace:"nowrap"}}
                        >
                            {translate('addEqual')}
                        </Button>
                }
                <Dialog
                    open={this.state.modal}
                    maxWidth={"md"}
                    fullWidth
                    onClose={() => this.toggleModal()}
                >
                    <AppBar className={this.classes.appBarDialog}>
                        <Toolbar>
                            <Typography variant="h6" className={this.classes.titleDialog}>
                                {editMode ? translate('editBusiness') :  translate('makeBusiness')}
                            </Typography>
                            <IconButton color="inherit" onClick={() => this.toggleModal()} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <DialogContent
                        className={this.classes.dialogRoot}
                    >
                        <AppAnimate animation='transition.slideUpIn' delay={200} title={editMode ? translate('editBusiness') :  translate('makeBusiness')}>
                            {
                                <Grid container spacing={2} justify="center">
                                    <Grid item xs={12} lg={11}>
                                        <Grid container spacing={2} justify="center">
                                            {
                                                (user.role.type !== "admin" && !loading) &&
                                                <CheckUserCanAdBusiness edit={!!editMode}/>
                                            }
                                            {
                                                (!!editMode && !loading) &&
                                                <>
                                                    <Grid item xs={12}>
                                                        {
                                                            !!real_confirmed ?
                                                                <>
                                                                    <Button
                                                                        component="a"
                                                                        target="_blank"
                                                                        size="small"
                                                                        startIcon={<PageviewRoundedIcon/>}
                                                                        href={makeSlug(`business/${slug}`,this.state.lang)}
                                                                        color="secondary"
                                                                    >
                                                                        {translate('viewBusiness')}
                                                                    </Button>
                                                                </> :
                                                                <>
                                                                    <Button
                                                                        component="a"
                                                                        target="_blank"
                                                                        size="small"
                                                                        variant="contained"
                                                                        startIcon={<PageviewRoundedIcon/>}
                                                                        href={makeSlug(`business/${slug}`,this.state.lang)}
                                                                        color="secondary"
                                                                    >
                                                                        {translate('viewPreview')}
                                                                    </Button>

                                                                </>
                                                        }
                                                    </Grid>
                                                </>
                                            }
                                            <Grid item xs={12}>
                                                <AppCard
                                                    title={editMode ? translate('editBusiness') :  translate('makeBusiness')}
                                                    action={
                                                        <BreadCrumbs singleLevel firstText={editMode ? translate('editBusiness') :  translate('makeBusiness')}/>
                                                    }>
                                                    {
                                                        this.state.loading ? <AppCardLoaderFields/> :
                                                            <Grid container spacing={2} justifyContent="center" alignItems="center" style={{marginBottom:10}}>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        required
                                                                        fullWidth
                                                                        color="secondary"
                                                                        name="name"
                                                                        variant="outlined"
                                                                        label={translate('businessName')}
                                                                        type="text"
                                                                        id="name"
                                                                        value={fields.name}
                                                                        onChange={this.handleChange.bind(this)}
                                                                        error={!!errors['name']}
                                                                        helperText={errors['name']}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        fullWidth
                                                                        name="main_products"
                                                                        variant="outlined"
                                                                        label={translate('main_products')}
                                                                        type="text"
                                                                        color="secondary"
                                                                        value={fields.main_products}
                                                                        onChange={this.handleChange.bind(this)}
                                                                        error={!!errors['main_products']}
                                                                        helperText={errors['main_products']}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        variant="outlined"
                                                                        multiline
                                                                        fullWidth
                                                                        color="secondary"
                                                                        name="description"
                                                                        label={translate('businessDescription')}
                                                                        type="text"
                                                                        id="description"
                                                                        minRows="4"
                                                                        value={fields.description}
                                                                        onChange={this.handleChange.bind(this)}
                                                                        error={!!errors['description']}
                                                                        helperText={errors['description']}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    <FieldCounterWithoutBar length={fields.description.length} max={150}/>
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                    }
                                                </AppCard>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <AppCard style={{height:"100%"}}>
                                                    <SubTitle align="left" style={{marginBottom:20}}>{translate('trade_capacity')}</SubTitle>
                                                    {
                                                        this.state.loading ? <AppCardLoaderFields/> :
                                                            <>
                                                                <CustomFields
                                                                    type={"business"}
                                                                    position_type="trade_capacity"
                                                                    input={this.state.trade_capacity_fields}
                                                                    business_type={this.state.type}
                                                                    handleCustomFields={(trade_capacity_fields) => this.setState({trade_capacity_fields})}
                                                                />
                                                            </>
                                                    }
                                                </AppCard>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <AppCard style={{height:"100%"}}>
                                                    <SubTitle align="left" style={{marginBottom:20}}>{translate('product_capacity')}</SubTitle>
                                                    {
                                                        this.state.loading ? <AppCardLoaderFields/> :
                                                            <>
                                                                <CustomFields
                                                                    type={"business"}
                                                                    position_type="product_capacity"
                                                                    input={this.state.product_capacity_fields}
                                                                    business_type={this.state.type}
                                                                    handleCustomFields={(product_capacity_fields) => this.setState({product_capacity_fields})}
                                                                />
                                                            </>
                                                    }
                                                </AppCard>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <LoadingButton
                                                    variant="contained"
                                                    color="primary"
                                                    style={{minWidth:200}}
                                                    onClick = {this.handleSubmit.bind(this)}
                                                    loading = {SubmitLoading}
                                                >
                                                    {editMode ? translate('editBusiness') :  translate('makeBusiness')}
                                                </LoadingButton>
                                            </Grid>
                                            {
                                                (!!editMode && !this.state.loading) &&
                                                <Grid item xs={12}>
                                                    <Alert severity="warning" variant="standard" style={{marginBottom:10}}>
                                                        <Typography style={{direction:this.props.fake_locale === "en" ? "ltr" : "rtl",}}>
                                                            {translate('ifYouWantToSeeMoreInfoClick')}
                                                            {" "}
                                                            <Button
                                                                size="small"
                                                                color="primary"
                                                                component='a'
                                                                href={
                                                                    this.props.user.role.type === "admin" ?
                                                                        `${defaultVariabls.React}/edit/business/${this.state.id}?lang=${this.props.fake_locale}`
                                                                        :
                                                                        `${defaultVariabls.React}/business?lang=${this.props.fake_locale}`
                                                                }
                                                            >
                                                                {translate('clickHere')}
                                                            </Button>
                                                        </Typography>
                                                        <Typography style={{fontSize:11}}>
                                                            {translate('attentionText')}
                                                        </Typography>
                                                    </Alert>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>

                                </Grid>

                            }
                        </AppAnimate>
                    </DialogContent>

                </Dialog>
            </>
        );
    }
}

const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,
    fake_locale: state.fake_locale,
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MakeEqualBusiness))));