import React from 'react';
import {Translate} from "../../../../../config/Translate";
import {connect} from "trim-redux";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../../config/design/styles";
import axios from "axios";
import {defaultVariabls} from "../../../../../config";
import Grid from "@material-ui/core/Grid/Grid";
import LoadingButton from "../../../../components/elements/LoadingButton";
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css';
import {LoadSetting} from "../../../../../config/Security"; //Example style, you can use another
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import Typography from "@material-ui/core/Typography/Typography";
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import {translate} from "../../../../../config/lang";

class CodeEditor extends React.Component{
    state = {
        open: false,
        type:this.props.type,
        code:null,
        SubmitLoading: false,
        loading: true
    };
    componentDidMount(){
        this.setState({
            code:this.props.about[this.props.type],
            loading:false,
        })
    }

    handleRequest(){
        this.setState({
            SubmitLoading: true,
        })
        let { code,type} = this.state;
        let data = new FormData();
        data.append('val', code);
        data.append('type', type);
        data.append('lang', this.props.locale);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/about/change`, data, config)
            .then(async response => {
                await LoadSetting();
                await this.setState({
                    loading:false,
                    SubmitLoading: false,
                    SubmitDraftLoading: false,
                    open:false,
                });
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(translate('dataEditingFailed'), {
                    variant: 'error',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                    SubmitDraftLoading: false
                });
            });

    }
    render(){
        let {code,SubmitLoading,loading} = this.state;
        return (
            <Grid container spacing={2} justify="center" alignItems="center">
                <Grid item xs={12}>
                    <div dir="ltr" style={{direction:"ltr",textAlign:"left"}}>
                        {
                            !loading &&
                            <label>
                                <Typography style={{marginBottom:10,display:"flex",alignItems:"center",justifyContent:"center"}}>
                                    {translate('writeYourCodehere')} <ExpandMoreRoundedIcon/>
                                </Typography>
                                <Editor
                                    value={!!code ? code : " "}
                                    onValueChange={code => this.setState({code})}
                                    highlight={code => highlight(code, languages.js)}
                                    padding={20}
                                    style={{
                                        fontFamily: '"Fira code", "Fira Mono", monospace',
                                        fontSize: 14,
                                        backgroundColor:"#faf8fa",
                                        borderRadius:8,
                                        borderColor:"#fff",
                                    }}
                                />
                            </label>
                        }
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        startIcon={<SaveRoundedIcon/>}
                        onClick = {this.handleRequest.bind(this)}
                        loading = {SubmitLoading}
                    >
                        {translate('saveCode')}
                    </LoadingButton>
                </Grid>
            </Grid>
        );
    }
}
const mstp = state => ({
    about: state.about,
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(CodeEditor)))));