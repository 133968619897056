import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {getDataTableLocalization} from "../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import MaterialTable from "material-table";
import {tableIcons} from "../../../../config/design/TableIcons";
import {Link, withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import Container from "@material-ui/core/Container/Container";
import {TableCount, TablePage} from "../../../../config/Permissions";
import AddFAQButton from "./components/AddFAQButton";
import AppCardLoaderTable from "../../../components/Skeletons/AppCardLoaderTable";
import {translate} from "../../../../config/lang";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {connect} from "trim-redux";
import {truncate} from "../../../../config/values";
import Button from "@material-ui/core/Button/Button";
import ChevronRightRoundedIcon from "@material-ui/core/SvgIcon/SvgIcon";
import UpdateButton from "../../advertisements/components/UpdateButton";

class FAQ extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    state = {
        columns: [
            {
                field: 'question',
                title: translate('question'),
            },
            {
                field: 'answer',
                title: translate('answer'),
            },
            { title: translate('activity'),
                editable: 'never',
                sorting:false,
                field: 'activity',
                render: rowData =>
                    <AddFAQButton id={rowData.id} reload={() => this.loadData()}/>
            },
        ],
        data: [],
        loading:false,
        ChangeLoader:false,
        id:null,
        replay_comment:null,
        replay_ad:null,
        openDialog:false,
        openDialogAdd:false,
        replaying:false,

    };
    componentDidMount(){
        this.loadData();
    }
    loadData(){
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }

    onDelete(id,hasNotif = true) {
        let data = new FormData();
        data.append('id', id);
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/faq/delete`, data, config)
            .then(response => {

                return true
            })
            .catch(error => {
                this.props.enqueueSnackbar(translate('deleteDataFailed'), {
                    variant: 'error',
                });
                return false
            });
        this.loadData();
    }
    async onDeleteSelected(data){
        await data.map((row) =>(
            this.onDelete(row.id,false)

        ));
        await this.loadData();
    }
    handleRowClick(data){
        this.setState({
            id:data.id,
            openDialog:true,
        })
    }
    changePageSize(data){
        setTimeout(function() {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }, 100);

    }
    async toggleDialog(){
        await this.setState({
            openDialog: !this.state.openDialog,
            id:null,
            ChangeLoader:true,
        })
        await this.loadData();
    }
    async toggleDialogAdd(){
        await this.setState({
            openDialogAdd: !this.state.openDialogAdd,
            ChangeLoader:true,
        })
        await this.loadData();
    }
    render(){
        let{openDialog,id,openDialogAdd}= this.state;
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        return (
            <>
                    {
                        this.state.loading ? <AppCardLoaderTable/> :
                            <Container maxWidth="xl">
                            <MaterialTable
                                    tableRef={this.tableRef}
                                    icons={tableIcons}
                                    onChangeRowsPerPage={(data) => this.changePageSize(data)}
                                    title={
                                        <AddFAQButton reload={() => this.loadData()}/>
                                    }
                                    localization={getDataTableLocalization()}
                                    columns={this.state.columns}
                                    data={query =>
                                        new Promise((resolve, reject) => {
                                            axios.get(`${defaultVariabls.url}/faq/quickView?page=${query.page + 1}&per_page=${query.pageSize}&search=${query.search}&lang=${this.props.locale}`, config)
                                                .then(response => {
                                                    let data = [];
                                                    response.data.data.map((row) =>(
                                                        data.push({
                                                            question: truncate(row.question,50),
                                                            answer: truncate(row.answer,50),
                                                            id:row.id,
                                                            created_at:row.created_at,
                                                        })
                                                    ));
                                                    resolve({
                                                        data: data,
                                                        page: response.data.current_page - 1,
                                                        totalCount: response.data.total,
                                                    })
                                                })
                                                .catch(error => {
                                                    console.log(error.response)
                                                    this.props.enqueueSnackbar(translate('connectionError'), {
                                                        variant: 'error',
                                                    });
                                                });
                                        })
                                    }
                                    options={{
                                        selection: false,
                                        pageSize: TablePage,
                                        pageSizeOptions: TableCount,
                                        draggable: false,
                                        sorting:false,
                                    }}
                                    editable={{
                                        onRowDelete: oldData =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve();
                                                    this.onDelete(oldData.id);
                                                    this.setState(prevState => {
                                                        const data = [...prevState.data];
                                                        data.splice(data.indexOf(oldData), 1);
                                                        return { ...prevState, data };
                                                    });
                                                }, 600);
                                            }),
                                    }}
                                />
                            </Container>

                    }
                    {

                    }

            </>

        );
    }
}
const mstp = state => ({
    user: state.user,
    features: state.features,
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(FAQ)))));
