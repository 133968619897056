import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Styles} from "../../../../config/design/styles";
import {withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import Alert from "@material-ui/lab/Alert/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle/AlertTitle";
import {defaultVariabls} from "../../../../config";
import axios from "axios";
import {Translate} from "../../../../config/Translate";
import InfiniteScroll from 'react-infinite-scroller';
import Typography from "@material-ui/core/Typography/Typography";
import SingleWishlistItem from "./SingleWishlistItem";
import Grid from "@material-ui/core/Grid/Grid";
import AppCard from "../../../components/layouts/components/AppCard";
import BreadCrumbs from "../../../components/elements/BreadCrumbs";
import AppAnimate from "../../../components/AppAnimate";
import AppCardLoaderFields from "../../../components/Skeletons/AppCardLoaderFields";

class Wishlist extends React.Component {
    state = {
        wishlist: [],
        nextPage:1,
        hasMore: true,
        check: false,

    }
    classes = this.props.classes;
    async handleLoadMore(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null) {
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/my-wishlist?page=${this.state.nextPage}`, config)
                .then(response => {
                    const {current_page, last_page, data} = response.data;
                    this.setState(prevState => ({
                        wishlist: [...prevState.wishlist, ...data],
                        hasMore: current_page  !== last_page,
                        nextPage: current_page + 1,
                        check: true
                    }));
                })
                .catch(error => {
                    console.log(error.response)
                });
        }
    }
    async reload(){
        await this.setState({
            wishlist: [],
            nextPage:1,
            check: false,
        })
        await this.handleLoadMore();
    }
    render(){
        let {wishlist,hasMore,check} = this.state;
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={Translate.wishlist}>
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12}>
                        <AppCard
                            title={Translate.wishlist}
                            action={
                                <BreadCrumbs singleLevel firstText={Translate.wishlist}/>
                            }>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <InfiniteScroll
                                        pageStart={1}
                                        loadMore={this.handleLoadMore.bind(this)}
                                        hasMore={hasMore}
                                        loader={<AppCardLoaderFields/>}
                                        useWindow={false}
                                        element={Grid}
                                        container
                                        spacing={2}
                                    >
                                        {
                                            wishlist.map((item,index) => (

                                                <SingleWishlistItem reload={() => this.reload()} key={index} product={item.product}/>
                                            ))
                                        }
                                    </InfiniteScroll>
                                </Grid>
                                <Grid item xs={12}>
                                    {
                                        check && wishlist.length === 0 ?
                                            <div className="no_wishlist_container">
                                            <Typography align="center" color="textSecondary" variant="body2">{Translate.noWishlist}</Typography>
                                            </div>
                                            : null
                                    }
                                </Grid>
                            </Grid>
                        </AppCard>
                    </Grid>
                </Grid>
            </AppAnimate>
        );
    }

}
export default withSnackbar(withRouter(withStyles(Styles)(Wishlist)));