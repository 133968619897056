import React from 'react';
import Box from '@material-ui/core/Box';
import {makeStyles, withStyles} from '@material-ui/core';
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import {Styles} from "../../../../config/design/styles";
import {connect} from "trim-redux";
import Avatar from "@material-ui/core/Avatar/Avatar";
import clsx from "clsx";
import {defaultVariabls} from "../../../../config";
import Button from "@material-ui/core/Button/Button";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Grid from "@material-ui/core/Grid/Grid";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import Title from "../../../components/elements/Title";
import Typography from "@material-ui/core/Typography/Typography";
import {makeSlug, translate} from "../../../../config/lang";
import Hidden from "@material-ui/core/Hidden/Hidden";
import OpenInNewRoundedIcon from "@material-ui/icons/OpenInNewRounded";
import {truncate} from "../../../../config/values";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .user-info': {
            width: '100%',
        },
    },
    pointer: {
        cursor: 'pointer',
    },
    profilePic: {
        height: 40,
        width: 40,
        fontSize: 24,
        [theme.breakpoints.up('xl')]: {
            height: 45,
            width: 45,
        },
    },
    userInfo: {
        width: 'calc(100% - 75px)',
    },
    userName: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        fontSize: 13,
        fontWeight: 700,
        marginBottom: 2,
    },
    adminRoot: {
        color:"#fff",
        fontSize: 14,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    avatarRoot: {
        position: 'relative',
    },
    userStatus: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        zIndex: 1,
        width: 14,
        height: 14,
        borderRadius: '50%',
        border: `1px solid white`,
        [theme.breakpoints.up('xl')]: {
            width: 16,
            height: 16,
        },
    },
}));

const Header = ({
                    selectedUser,
                    deleteConversation,
                    locale,
                    loading,
                    contact,
                    manage,
                    department,
                    type,
                    user,
                    ticket,
                    history
}) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    console.log(contact);
    return (
        <Box pr={2} pl={2} width={1} display='flex' alignItems='center' className={classes.root}>
            <Box display='flex' alignItems='center'>
                <Box className={clsx(classes.userInfo, 'user-info')}>
                    <Box display='flex' alignItems='center' justifyContent='space-between'>
                        <Button
                            className={clsx(classes.userName)}
                            onClick={handleClickOpen}
                            size="small"
                            startIcon={
                                <Avatar
                                    className={classes.profilePic}
                                    src={!loading ? `${defaultVariabls.uploads}/FileManager/${contact.avatar.name}_small.${contact.avatar.extension}` : null}
                                />
                            }
                        >
                            {
                                !loading ?
                                    <Hidden mdDown>
                                        <Typography style={{display:'flex',flexDirection:"column",alignItems: "flex-start"}}>
                                            <span>{contact.first_name+" "+contact.last_name}</span>
                                            {
                                                !!department &&
                                                <span style={{fontSize:11}}>{" ("+translate('department')+" "+department.name+") "}</span>

                                            }
                                        </Typography>
                                    </Hidden>
                                    : null
                            }
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Box ml='auto' display='flex' alignItems='center'>
                <Box ml={2} component='span' color='text.disabled'>
                    {
                        ticket.type === "system" ?
                            <>
                                <Typography>
                                    {ticket.subject}
                                </Typography>
                                <Typography style={{fontSize:11}}>
                                    <span>{translate('priority')}:</span>
                                    <strong>{" "}{translate(ticket.priority)}</strong>
                                </Typography>
                            </>
                            :
                            ticket.type === "rfq" ?
                                <Typography>
                                    <span className={"sub-btn"}>
                                    {translate('rfq')}
                                    </span>
                                    <Button
                                        size="small"
                                        startIcon={<OpenInNewRoundedIcon color="primary"/>}
                                        component="a"
                                        variant="outlined"
                                        color="secondary"
                                        href={makeSlug(`rfq/${ticket.rfq.slug}`,ticket.rfq.lang)}
                                        target="_blank"
                                    >
                                        {ticket.rfq.name}
                                    </Button>
                                </Typography>
                            :
                            ticket.type === "product" ?
                                <Typography>
                                    <span className={"sub-btn"}>
                                    {translate('product')}
                                    </span>
                                    <Button
                                        size="small"
                                        startIcon={<OpenInNewRoundedIcon color="primary"/>}
                                        component="a"
                                        variant="outlined"
                                        color="secondary"
                                        href={makeSlug(`product/${ticket.product.slug}`,ticket.product.lang)}
                                        target="_blank"
                                    >
                                        {ticket.product.name}
                                    </Button>
                                </Typography>
                            :
                            ticket.type === "sample" ?
                                <Typography>
                                    <span className={"sub-btn"}>
                                    {translate('sample_request')}
                                    </span>
                                    <Button
                                        size="small"
                                        startIcon={<OpenInNewRoundedIcon color="primary"/>}
                                        component="a"
                                        variant="outlined"
                                        color="secondary"
                                        href={makeSlug(`product/${ticket.product.slug}`,ticket.product.lang)}
                                        target="_blank"
                                    >
                                        {ticket.product.name}
                                    </Button>
                                </Typography>
                            :
                            ticket.type === "business" ?
                                <Typography>
                                    <span className={"sub-btn"}>
                                    {translate('provider')}
                                    </span>
                                    <Button
                                        size="small"
                                        startIcon={<OpenInNewRoundedIcon color="primary"/>}
                                        component="a"
                                        variant="outlined"
                                        color="secondary"
                                        href={makeSlug(`product/${ticket.business.slug}`,ticket.business.lang)}
                                        target="_blank"
                                    >
                                        {ticket.business.name}
                                    </Button>
                                </Typography>
                            :
                            null
                    }
                </Box>
            </Box>
            <Dialog
                maxWidth="xs"
                fullWidth
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    <IconButton style={{position: "absolute",top: 0,left:0}} onClick={handleClose}>
                        <CloseRoundedIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {
                        !loading ?
                            <DialogContentText>
                                <Grid container spacing={2} alignItems="center" justify="center">
                                    <Grid item xs={12} style={{textAlign:"center"}}>
                                        <Avatar
                                            style={{textAlign:"center",width:90,height:90,transform:"translateX(-50%)",position:"absolute",left:"50%"}}
                                            src={!loading ? `${defaultVariabls.uploads}/FileManager/${contact.avatar.name}_small.${contact.avatar.extension}` : null}
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{marginTop:90}}>
                                        <Title color="secondary">
                                            <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                                                {
                                                    contact.role.type !== "admin" &&
                                                    <Tooltip title={contact.country.name}>
                                                        <img src={`https://flagsapi.com/${contact.country.iso2}/flat/16.png`} style={{marginRight:5}}/>
                                                    </Tooltip>
                                                }
                                                <span>{contact.first_name+" "+contact.last_name}</span>
                                            </div>
                                            {
                                                contact.role.type === "admin" &&
                                                <Typography>({translate(contact.role.name)})</Typography>
                                            }
                                        </Title>
                                    </Grid>
                                    {
                                        (contact.role.type !== "admin" && !!contact.business) &&
                                            <>
                                            <Grid item xs={12}>
                                                <Typography align="center">
                                                    {translate('company_name')}:{" "}
                                                    <strong>
                                                        {contact.company_name}
                                                    </strong>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography align="center">
                                                    <a href={`tel:${contact.business.phone}`}>
                                                        {contact.business.phone}
                                                    </a>
                                                </Typography>
                                            </Grid>
                                                <Grid item xs={12}>
                                                    <Typography align="center">
                                                        <a href={`mailto:${contact.business.email}`}>
                                                            {contact.business.email}
                                                        </a>
                                                    </Typography>
                                                </Grid>
                                        </>
                                    }
                                </Grid>
                            </DialogContentText>
                            : null
                    }

                </DialogContent>
            </Dialog>
        </Box>
    );
};

const mstp = state => ({
    locale: state.locale,
    user: state.user,
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(Header))));