import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import AppAnimate from "../../../components/AppAnimate";
import Grid from "@material-ui/core/Grid/Grid";
import AppCard from "../../../components/layouts/components/AppCard";
import {translate} from "../../../../config/lang";
import VisitsChart from "../../../components/charts/VisitsChart";
import Title from "../../../components/elements/Title";
import Clock from "../../../components/elements/Clock";
import LiveUsers from "./components/LiveUsers";
import TodayAnalytics from "./components/TodayAnalytics";
import PeopleAltRoundedIcon from "@material-ui/icons/PeopleAltRounded";
import OrdersAnalyticsShort from "./components/OrdersAnalyticsShort";

class AdminDashboard extends React.Component {
    classes = this.props.classes;
    render(){
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={translate('dashboard')}>
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12} lg={11}>
                        <Grid container spacing={2} justify="center">
                            <Grid item xs={12}>
                                <TodayAnalytics/>
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <AppCard
                                    title={translate('visitsChart')}
                                >
                                    <div style={{height:300,direction:"ltr"}}>
                                        <VisitsChart/>
                                    </div>
                                </AppCard>
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <AppCard
                                    style={{height:"100%"}}>
                                    <Title color="secondary"><PeopleAltRoundedIcon fontSize="large"/> <br/> {translate('liveUsers')} </Title>
                                    <Grid item xs={12} >
                                        <Clock/>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <LiveUsers/>
                                    </Grid>
                                </AppCard>
                            </Grid>
                            <Grid item xs={12}>
                                <OrdersAnalyticsShort/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </AppAnimate>
        );
    }
}
export default withStyles(Styles)(AdminDashboard);