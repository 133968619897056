import React from 'react';
import List from '@material-ui/core/List';
import VerticalCollapse from './VerticalCollapse';
import VerticalItem from './VerticalItem';
import VerticalNavGroup from './VerticalNavGroup';
import {connect, getStore} from "trim-redux";
import {getRoutes} from "../../../../../config/routes/routesConfig";

const Navigation = (props) => {
  const {role} = props.user;
  let features = getStore('features');
  let routesConfig = getRoutes();
  return (
    <List>
      {routesConfig.map((item) => (
          <>
          {
              !item.roles || item.roles.indexOf(role.name) !==  -1 ?
                  !item.permission || role.permission[item.permission] ?
                      !item.feature || features[item.feature] ?
                    <React.Fragment key={item.id}>
                        {item.type === 'group' && <VerticalNavGroup item={item} level={0} role={role}/>}

                        {item.type === 'collapse' && (
                            <VerticalCollapse item={item} level={0} />
                        )}

                        {item.type === 'item' && <VerticalItem item={item} level={0} />}
                    </React.Fragment>
                : null : null : null
          }
          </>

      ))}
    </List>
  );
};
const mstp = state => ({
    user: state.user,
});
export default connect(mstp)(Navigation);
