import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {defaultVariabls} from "../../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import {connect} from "trim-redux";
import Typography from "@material-ui/core/Typography/Typography";
import IconButton from "@material-ui/core/IconButton/IconButton";
import {currencySymbol, getMonths, price} from "../../../../config/values";
import Link from "@material-ui/core/Link/Link";
import FeaturedPlayListOutlinedIcon from '@material-ui/icons/FeaturedPlayListOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import {makeSlug, translate} from "../../../../config/lang";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import axios from "axios";

class SingleCartItem extends React.Component {

    state = {
        cart:this.props.cart,
        action_loading:false,

    };
    classes = this.props.classes;
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.cart !== this.props.cart){
            this.setState({
                cart:this.props.cart,
            })
        }
    }
    handleAction(action){
        this.setState({
            action_loading:true,
        })
        let {cart} = this.state;
        let data = new FormData();
        data.append('action',action);
        data.append('id',cart.id);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/cart/action`, data, config)
            .then(async response => {
                await this.props.reload();
                await this.setState({
                    action_loading:false,
                })
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(translate('dataAddingFailed'), {
                    variant: 'error',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                    SubmitDraftLoading: false
                });
            });
    }
    render(){
        let {plan} = this.state.cart;
        let {cart,action_loading} = this.state;
        return (
            <div style={{border:"1px solid #eee",marginBottom:15,borderRadius:4,paddingLeft:15,paddingRight:15}}>
                <div style={{display:"flex",alignItems:"center"}}>
                    <IconButton
                        style={{marginRight:10}}
                        size="small"
                        disabled={action_loading}
                        onClick={() => this.handleAction('delete')}
                    >
                        <CloseRoundedIcon fontSize="small"/>
                    </IconButton>
                    <Link href={makeSlug(`buy-plan`)} target="_blank">
                    <img
                        src={`${defaultVariabls.uploads}/FileManager/${plan.parent.thumbnail.name}.${plan.parent.thumbnail.extension}`}
                        alt="img"
                        className="single-order-items-img"
                    />
                    </Link>
                    <div  className="single_order_items">
                        <Typography style={{marginBottom:10}}>
                            <IconButton size="small"><FeaturedPlayListOutlinedIcon/></IconButton><strong>{plan.parent.name} ({getMonths(plan.duration)} {translate('month')})</strong></Typography>
                        <Typography  style={{marginBottom:10}}>
                            <IconButton size="small">
                                <MonetizationOnOutlinedIcon/>
                            </IconButton>
                            {
                                plan.discount_price  !== null ?
                                    <>
                                        <del style={{color:"#f82e4e"}}>
                                            {price(plan.price)}
                                        </del>
                                        <strong>
                                            {" "+price(plan.discount_price)+" "+currencySymbol}
                                        </strong>
                                    </>
                                    :
                                    <>
                                        <strong>
                                            {price(plan.price)+" "+currencySymbol}
                                        </strong>
                                    </>
                            }

                        </Typography>
                    </div>
                </div>
            </div>
        );
    }
}

const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_color: state.primary_color,
    global_need_verification: state.global_need_verification
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(SingleCartItem))));