import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {translate} from "../../../config/lang";
import {withRouter} from "react-router-dom";
import {connect} from "trim-redux";
import Paper from "@material-ui/core/Paper/Paper";
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";
import TicketsPage from "../tickets/TicketsPage";
import MessagesListPage from "./MessagesListPage";
import withWidth from "@material-ui/core/withWidth/withWidth";

class MessagesPage extends React.Component {
    classes = this.props.classes;
    state = {
        section:this.props.match.params.section,
    }
    componentDidMount() {
        this.setState({
            section:this.props.match.params.section,
        })
    }
    componentDidUpdate(prevProps){
        if(this.state.section !== this.props.match.params.section){
            this.setState({
                section: this.props.match.params.section
            })
        }
    }
    handleChangeSection(section){
        this.props.history.push(`/messages/${section}`)
    }
    render(){
        let {section} = this.state;
        let {user,umc} = this.props;
        return (
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} lg={11}>
                    <Paper style={{flexGrow: 1}}>
                        <Tabs
                            value={section}
                            onChange={(event, newValue) => this.handleChangeSection(newValue)}
                            indicatorColor="secondary"
                            textColor="secondary"
                            variant={(this.props.width === "xs" || this.props.width === "sm") ? "scrollable" : "standard"}
                            scrollButtons="auto"
                            centered
                        >
                            <Tab
                                label={
                                    <div>
                                        {translate('tickets')}
                                        {
                                            umc.ticket > 0 &&
                                            <span className="inline-badge">{umc.ticket}</span>
                                        }

                                    </div>
                                }
                                value="ticket"
                            />
                            <Tab
                                label={
                                    <div>
                                        {translate('rfqs')}
                                        {
                                            umc.rfq > 0 &&
                                            <span className="inline-badge">{umc.rfq}</span>
                                        }
                                    </div>
                                }
                                value="rfq"
                            />
                            <Tab
                                label={
                                    <div>
                                        {translate('products')}
                                        {
                                            umc.product > 0 &&
                                            <span className="inline-badge">{umc.product}</span>
                                        }
                                    </div>
                                }
                                value="product"
                            />
                            <Tab
                                label={
                                    <div>
                                        {translate('sampleRequests')}
                                        {
                                            umc.sample > 0 &&
                                            <span className="inline-badge">{umc.sample}</span>
                                        }
                                    </div>
                                }
                                value="sample"
                            />
                            <Tab
                                label={
                                    <div>
                                        {translate('businesses')}
                                        {
                                            umc.business > 0 &&
                                            <span className="inline-badge">{umc.business}</span>
                                        }
                                    </div>
                                }
                                value="business"
                            />
                        </Tabs>
                    </Paper>
                </Grid>
                {
                    <Grid item xs={12}>
                        {
                            section === "ticket" ?
                                <TicketsPage/>
                                :
                            section === "rfq" ?
                                <MessagesListPage title={translate('rfqsMessages')} type="rfq"/>
                                :
                            section === "product" ?
                                <MessagesListPage title={translate('productsMessages')} type="product"/>
                                :
                            section === "sample" ?
                                <MessagesListPage title={translate('sampleRequests')} type="sample"/>
                                :
                            section === "business" ?
                                <MessagesListPage title={translate('businessesMessages')} type="business"/>
                                :
                                null
                        }
                    </Grid>
                }
            </Grid>
        );
    }

}const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    umc: state.umc,
    primary_shop: state.primary_shop,
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(MessagesPage)))));