import React from 'react';
import Editor,{ composeDecorators } from "draft-js-plugins-editor";
import {EditorState,ContentState, RichUtils,convertToRaw,AtomicBlockUtils,Modifier,convertFromRaw} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import createLinkPlugin from 'draft-js-anchor-plugin';
import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin';
import createFocusPlugin from 'draft-js-focus-plugin';
import createBlockDndPlugin from 'draft-js-drag-n-drop-plugin';
import createLinkifyPlugin from 'draft-js-linkify-plugin';
import createImagePlugin from 'draft-js-image-plugin';
import { ItalicButton, BoldButton, UnderlineButton } from 'draft-js-buttons';
import 'draft-js/dist/Draft.css';
import "draft-js-focus-plugin/lib/plugin.css";
import "draft-js-anchor-plugin/lib/plugin.css";
import "draft-js-inline-toolbar-plugin/lib/plugin.css";
import "draft-js-image-plugin/lib/plugin.css";
import 'draft-js-linkify-plugin/lib/plugin.css';
import 'draft-js-alignment-plugin/lib/plugin.css';
import {BlockStyleControls,InlineStyleControls,getBlockStyle,styleMap} from './Toolbar'
import {BlockStyleControlsShort,InlineStyleControlsShort} from './ShortToolbar'
import FileManagerDialog from "../../pages/fileManager/Components/FileManagerDialog";
import createAlignmentPlugin from 'draft-js-alignment-plugin';
import createCounterPlugin from 'draft-js-counter-plugin';
import Typography from "@material-ui/core/Typography/Typography";
import PaletteRoundedIcon from '@material-ui/icons/PaletteRounded';
import ColorPicker, { colorPickerPlugin } from 'draft-js-color-picker';
import TableBuilderDialog from "./tableBuilder/TableBuilderDialog";
import Link from "@material-ui/core/Link/Link";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import {translate} from "../../../config/lang";
import {getStore} from "trim-redux";
import withTheme from "@material-ui/core/styles/withTheme";
import htmlToDraft from 'html-to-draftjs';
import Button from "@material-ui/core/Button/Button";
import TextFormatIcon from '@material-ui/icons/TextFormat';
const focusPlugin = createFocusPlugin();
const alignmentPlugin = createAlignmentPlugin();
/*
const { AlignmentTool } = alignmentPlugin;
*/
const blockDndPlugin = createBlockDndPlugin();
const linkifyPlugin =  createLinkifyPlugin({
    target: '_blank',

});
const decorator = composeDecorators(
    alignmentPlugin.decorator,
    focusPlugin.decorator,
    blockDndPlugin.decorator
);
const imagePlugin = createImagePlugin({ decorator });
const linkPlugin = createLinkPlugin({
    placeholder:translate('linkPlaceholder'),
    Link: props => {
        const {contentState, ...rest } = props;
        let {url} = props.contentState.getEntity(props.entityKey).getData();
        return <Tooltip leaveDelay={100} interactive title={<Typography variant="body2" style={{direction:"ltr"}}>{decodeURIComponent(url)}</Typography>} arrow><Link {...rest}  /></Tooltip>;
    }
});
const counterPlugin = createCounterPlugin();
const { CharCounter, WordCounter } = counterPlugin;

const inlineToolbarPlugin = createInlineToolbarPlugin();

const presetColors = [
    '#4A4A4A',
    '#000000',
    '#ff4111',
    '#00ca4b',
    '#2196f3',
    '#ffc107',
    '#e91e8e',
    '#3f51b5',
    '#459d49',
    '#9b9b9b',
    '#ae6b07',
    '#b60808',
    '#cf5bff',
    '#ff52ba',
    '#46dfc0',
    '#cddc39',
];

const { InlineToolbar } = inlineToolbarPlugin;
const plugins = [
    inlineToolbarPlugin,
    linkPlugin,
    blockDndPlugin,
    focusPlugin,
    counterPlugin,
    alignmentPlugin,
    imagePlugin,
    linkifyPlugin];

class RichEditor extends React.Component {
    constructor(props) {
        super(props);
        let input = this.props.input;
        if(input !== undefined && input !== null && input !== "null"){
            const content  = convertFromRaw(JSON.parse(input));
            this.state= {
                editorState: EditorState.createWithContent(content),
                FileManagerPending: false,
                TableBuilderPending: false,
                imageData:{
                    src:"",
                    alt:""
                },
                noToolbar:true,
            }
            this.onSave();
        }else if(!!this.props.inputText){
            this.state = {
                editorState: EditorState.createWithContent(ContentState.createFromText(this.props.inputText)),
                FileManagerPending: false,
                inputText: this.props.inputText,
                TableBuilderPending: false,
                imageData:{
                    src:"",
                    alt:""
                },
                noToolbar:true,

            }
        }else{
            this.state = {
                editorState: EditorState.createEmpty(),
                FileManagerPending: false,
                TableBuilderPending: false,
                imageData:{
                    src:"",
                    alt:""
                },
                noToolbar:true,

            }
        }

        this.focus = () => this.refs.editor.focus();
        this.onChange = (editorState) => {
            this.setState({editorState})
            this.onSave();
        };
        this.getEditorState = () => this.state.editorState;
        this.handleKeyCommand = (command) => this._handleKeyCommand(command);
        this.onTab = (e) => this._onTab(e);
        this.toggleBlockType = (type) => this._toggleBlockType(type);
        this.toggleInlineStyle = (style) => this._toggleInlineStyle(style);
        this.picker = colorPickerPlugin(this.onChange, this.getEditorState);

    }
    async componentDidUpdate(prevProps){
        if(!!this.props.inputText){
            if(this.state.inputText !== this.props.inputText){
                this.setState({
                    editorState: EditorState.createWithContent(ContentState.createFromText(this.props.inputText)),
                    inputText: this.props.inputText,
                })
            }
        }

    }

    handlePastedText(text) {
        text = decodeURIComponent(text);
        this.onChange(EditorState.push(
            this.state.editorState,
            Modifier.replaceText(
                this.state.editorState.getCurrentContent(),
                this.state.editorState.getSelection(),
                text.replace(/\n/g, ' ')
            )
        ));
        return 'handled';
    }
    handleSetDefault(){
        this.setState({
            editorState: EditorState.createEmpty(),

        })
    }
    _handleKeyCommand(command) {
        const {editorState} = this.state;
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            this.onChange(newState);
            return true;
        }
        return false;
    }

    _onTab(e) {
        const maxDepth = 4;
        this.onChange(RichUtils.onTab(e, this.state.editorState, maxDepth));
    }

    _toggleBlockType(blockType) {
        this.onChange(
            RichUtils.toggleBlockType(
                this.state.editorState,
                blockType
            )
        );
    }

    _toggleInlineStyle(inlineStyle) {
        this.onChange(
            RichUtils.toggleInlineStyle(
                this.state.editorState,
                inlineStyle
            )
        );
    }

    onSave() {
        const {editorState} = this.state;
        let contentState = editorState.getCurrentContent();
        let PlainText = editorState.getCurrentContent().getPlainText();
        let html = null;
        let customStyle = {
            CENTER: {
                attributes: {class: 'text-center'},
            },
            RIGHT: {
                attributes: {class: 'text-right'},
            },
            LEFT: {
                attributes: {class: 'text-left'},
            },
        }
        if(this.picker !== undefined) {
            const inlineStyles = {...customStyle, ...this.picker.exporter(editorState)};
            html = stateToHTML(contentState, {inlineStyles});
        }else{
            const inlineStyles = {...customStyle};
            html = stateToHTML(contentState, {inlineStyles});

        }
        let note = {content: convertToRaw(editorState.getCurrentContent())};
        note["content"] = JSON.stringify(note.content);
        let code = {
            HTML: html,
            RAW: note["content"],
            PlainText: PlainText === null ? null : PlainText.slice(0, 300)

        }
        this.props.Output(code);

    }
    setImage = (data) => {
        const {editorState} = this.state;
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            "image",
            "IMMUTABLE",
            {src:data.src, alt:data.alt}
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(
            editorState,
            { currentContent: contentStateWithEntity },
            "create-entity"
        );
        this.setState(
            {
                editorState: AtomicBlockUtils.insertAtomicBlock(
                    newEditorState,
                    entityKey,
                    " "
                ),
                FileManagerPending:false

            },
            () => {
                setTimeout(() => this.focus(), 10);
            }
        );
    }
    setTable = (data) => {
        const text = data.shortcode;
        const editorState = this.state.editorState;
        const selectionState = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity("MENTION", "IMMUTABLE", { });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const modifiedContent = Modifier.insertText(contentState, selectionState, text,'', entityKey);
        const nextState = EditorState.push( editorState, modifiedContent, editorState.getLastChangeType() );
        this.setState({
            editorState: AtomicBlockUtils.insertAtomicBlock(
                nextState,
                entityKey,
                " "
            ),
        });
    };
    onAddTable = () => {
        this.setState({
            TableBuilderPending:true
        })
    };
    onAddImage = () => {
        this.setState({
            FileManagerPending:true
        })
    };

    cancleOperation= () => {
        this.setState({
            FileManagerPending:false,
            TableBuilderPending:false
        })
    }
    async handleChangeColor(color){
        await this.picker.addColor(color)
        this.focus()
    }

    render(){
        const {editorState,FileManagerPending,TableBuilderPending,noToolbar} = this.state;
        let {minHeight,short} = this.props;
        let className = 'RichEditor-editor';
        let contentState = editorState.getCurrentContent();
        if (!contentState.hasText()) {
            if (contentState.getBlockMap().first().getType() !== 'unstyled') {
                className += ' RichEditor-hidePlaceholder';
            }
        }

        return (
            <div className="RichEditor-root" style={{minHeight: minHeight ? minHeight : 500,background:this.props.theme.palette.background.paper}}>
                <div className="editor-control">
                    {
                        noToolbar ?
                            <Button
                                onClick={() => this.setState({noToolbar:false})}
                                size="small"
                                startIcon={<TextFormatIcon/>}
                            >
                                {translate('openControl')}
                            </Button> :
                            short ?
                                <React.Fragment>
                                    <BlockStyleControlsShort
                                        editorState={editorState}
                                        onToggle={this.toggleBlockType}
                                    />
                                    <InlineStyleControlsShort
                                        editorState={editorState}
                                        onToggle={this.toggleInlineStyle}
                                        onAddImage={this.onAddImage}
                                        onAddTable={this.onAddTable}
                                    />
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <BlockStyleControls
                                        editorState={editorState}
                                        onToggle={this.toggleBlockType}
                                    />
                                    <InlineStyleControls
                                        editorState={editorState}
                                        onToggle={this.toggleInlineStyle}
                                        onAddImage={this.onAddImage}
                                        onAddTable={this.onAddTable}
                                    />
                                    <div className="ColorPicker_holder">
                                        <PaletteRoundedIcon  style={{margin:"0 5",color:"rgba(0, 0, 0, 0.54)"}}/>
                                        <ColorPicker
                                            toggleColor={color => this.handleChangeColor(color)}
                                            presetColors={presetColors}
                                            color={this.picker.currentColor(editorState)}
                                        />
                                    </div>
                                </React.Fragment>
                    }
                </div>
                <div className={className} style={noToolbar ? {border:0} : null} onClick={this.focus}>
                    <Editor
                        customStyleFn={this.picker.customStyleFn}
                        blockStyleFn={getBlockStyle}
                        customStyleMap={styleMap}
                        editorState={editorState}
                        handleKeyCommand={this.handleKeyCommand}
                        onChange={this.onChange}
                        onTab={this.onTab}
                        placeholder={<Typography>{translate('descriptionPlaceHolder')}</Typography>}
                        ref="editor"
                        spellCheck={true}
                        plugins={plugins}
                        readOnly={this.props.readOnly}
                    />
                    <InlineToolbar>
                        {
                            (externalProps) => (
                                <div>
                                    <BoldButton {...externalProps} />
                                    <ItalicButton {...externalProps} />
                                    <UnderlineButton {...externalProps} />
                                    <linkPlugin.LinkButton {...externalProps} />
                                </div>
                            )
                        }
                    </InlineToolbar>
{/*
                    <AlignmentTool />
*/}

                </div>
          {/*      <div className="wordCounter-Editor">
                    <Typography syle={{color:"rgba(0, 0, 0, 0.54)"}} variant="body2"><CharCounter /> {translate('characters')}</Typography>
                    <Typography syle={{color:"rgba(0, 0, 0, 0.54)"}} variant="body2"><WordCounter /> {translate('words')}</Typography>
                </div>*/}
                {
                    FileManagerPending ? <FileManagerDialog fileType={"image"} cancleOperation={this.cancleOperation} setImage={this.setImage}/> : null
                }
                {
                    TableBuilderPending ? <TableBuilderDialog cancleOperation={this.cancleOperation} setTable={this.setTable}/> : null
                }
            </div>
        );
    }
}


export default withTheme(RichEditor);