import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Styles} from "../../../../config/design/styles";
import {withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import Card from "@material-ui/core/Card/Card";
import CardActionArea from "@material-ui/core/CardActionArea/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia/CardMedia";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Typography from "@material-ui/core/Typography/Typography";
import CardActions from "@material-ui/core/CardActions/CardActions";
import Button from "@material-ui/core/Button/Button";
import {defaultVariabls} from "../../../../config";
import {Translate} from "../../../../config/Translate";
import Grid from "@material-ui/core/Grid/Grid";
import Link from "@material-ui/core/Link/Link";
import IconButton from "@material-ui/core/IconButton/IconButton";
import FavoriteIcon from '@material-ui/icons/Favorite';
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

class SingleWishlistItem extends React.Component {
    state = {
        product: this.props.product,
        loading:false,
        deleted:false,
    }
    classes = this.props.classes;
   componentDidMount(){
       this.setState({
           product: this.props.product,
       })
   }
    handleRemove(){
       this.setState({
           loading:true
       })
        let data = new FormData();
        let {product} = this.state;
        data.append('id', product.id);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/wishlist/delete`, data, config)
            .then(response => {
                this.setState({
                    loading:false,
                    deleted:true
                })
                return true
            })
            .catch(error => {
                this.props.enqueueSnackbar(Translate.deleteContentFailed, {
                    variant: 'error',
                });
                return false
            });
    }
    render(){
            let{product,loading,deleted} = this.state;
        return (
            <>
                {
                    !deleted &&
                    <Grid item xs={6} md={3} lg={2}>
                        <Card>
                            <CardActionArea>
                                <CardMedia
                                    image={`${defaultVariabls.uploads}/FileManager/${product.thumbnail.name}_medium.${product.thumbnail.extension}`}
                                    title={product.name}
                                    style={{height:150}}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2" className="wishlist_title only-one-line">
                                        {product.name}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions style={{justifyContent:"space-between"}}>
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="outlined"
                                    component={Link}
                                    href={`${defaultVariabls.website}/product/${product.slug}`}
                                >
                                    {Translate.viewProduct}
                                </Button>
                                <IconButton
                                    size="small"
                                    onClick={() => this.handleRemove()}
                                >
                                    {
                                        loading ?
                                            <CircularProgress size={20}/>
                                            :
                                            <FavoriteIcon color="primary" fontSize="small"/>

                                    }
                                </IconButton>
                            </CardActions>
                        </Card>
                    </Grid>
                }
            </>
        );
    }

}
export default withSnackbar(withRouter(withStyles(Styles)(SingleWishlistItem)));