import React from 'react';
import axios from "axios";
import {defaultVariabls} from "../../../config";
import {setStore} from "trim-redux";
import AppCardLoaderFields from "../Skeletons/AppCardLoaderFields";


class ImageViewer extends React.Component {
    state = {
        loading:true,
        id:this.props.id,
        size:this.props.size,
        style:this.props.style,
        image:null,
    };
    async componentDidMount(){
        await this.setState({
            loading:true,
            id:this.props.id,
            size:this.props.size,
            className:this.props.className,
            style:this.props.style,
        })
        await this.loadData();
    }
    async componentDidUpdate(prevProps){
        if(this.state.id !== this.props.id){
            await this.setState({
                loading:true,
                id:this.props.id,
                size:this.props.size,
                className:this.props.className,
                style:this.props.style,
            })
            await this.loadData();
        }
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/image/${this.state.id}`, config)
                .then(response => {
                    this.setState({
                        image:response.data,
                        loading:false
                    })

                }).catch(error => {
                    console.log(error.response)
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }
    }
    render(){
        let {image,size,style,loading} = this.state;
        size = !!size ? "_"+size : '';
        return (
            <>
                {
                    !loading ?
                        <img
                            style={style}
                            className="imageViewer"
                            src={`${defaultVariabls.uploads}/FileManager/${image.name}${size}.${image.extension}`}
                            alt={image.alt}
                        />
                        : <AppCardLoaderFields/>
                }
                </>

        );
    }
}
export default ImageViewer;