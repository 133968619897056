import React from 'react';
import HorizontalItem from './HorizontalItem';
import List from '@material-ui/core/List';
import {getStore} from "trim-redux";
import {defaultVariabls} from "../../../../../config";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import {Icon, ListItem, ListItemText} from "@material-ui/core";
import Badge from "../Badge";
import {Link} from "react-router-dom";
import {translate} from "../../../../../config/lang";

const HorizontalNav = () => {
  let menu = getStore('menu');

  return (
    <List className='navbarNav' style={{color:"rgb(73, 80, 87)"}}>
      {menu.map((item) => (
        <React.Fragment key={item.id}>
            <HorizontalItem item={item} nestedLevel={0} />
        </React.Fragment>
      ))}
    </List>
  );
};

export default HorizontalNav;
