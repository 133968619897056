
import React from "react";
import Button from "@material-ui/core/Button/Button";
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import {Translate} from "../../../../config/Translate";
import {currencySymbol} from "../../../../config/values";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {withRouter} from "react-router";
import withStyles from "@material-ui/core/styles/withStyles";
import {Styles} from "../../../../config/design/styles";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import {connect} from "trim-redux";
import CurrencyFormat from 'react-currency-format';
import {Link} from "react-router-dom";

function MyWallet(props) {
    const classes = props.classes;
    let amount = !!props.user ? props.user.wallet.amount : 0 ;
    let demo = false;
    return (
        <>
            <Tooltip title={Translate.myWalletBalance} arrow>
            <Button
                className={classes.walletBtn}
                color="primary"
                component={Link}
                to={demo ? '' : "/wallet/view"}
                startIcon={<AccountBalanceWalletOutlinedIcon style={{fontSize:28}}/>}
            >
                {
                    props.width !== "xs" ?
                        <CurrencyFormat value={amount} displayType={'text'} thousandSeparator={true}  suffix={" "+currencySymbol} />
                        : null
                }
            </Button>
            </Tooltip>
            </>
    );
}
const mstp = state => ({
    user: state.user
});
export default withWidth()(withRouter(withStyles(Styles)(connect(mstp)(MyWallet))));
