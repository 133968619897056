import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import HeaderTags from "./HeaderTags";
import {translate} from "../../../config/lang";

const useStyles = makeStyles( theme => ({
    backDrop: {
        background: 'rgba(225,225,225,0.3)',
    },
}));
export default function Loader() {
    const classes = useStyles();
    return (
        <Backdrop className={classes.backDrop} open={true}>
            <HeaderTags title={translate('loading')} loader/>
            <CircularProgress color="secondary"/>
        </Backdrop>
    );
}