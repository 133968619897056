import React from 'react';
import {Styles} from '../../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import axios from "axios";
import {defaultVariabls} from "../../../../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import {setStore} from "trim-redux";
import ChipInput from "material-ui-chip-input";
import {translate} from "../../../../../../config/lang";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

class CurrencySetting extends React.Component {
    state = {
        currencies:[],
        loading:true,
        SubmitLoading:false,
    };
    classes = this.props.classes;

    componentDidMount(){
        this.loadData();
    }

    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/currencies/getAll`, config)
                .then(async response => {
                    let currencies = [];
                    await response.data.map((item) => {
                        currencies.push(item.name)
                    })
                    await this.setState({
                        currencies,
                        loading:false
                    })
                })
                .catch(error => {
                    console.log(error.response)
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
            this.setState({
                loading:false
            })
        }
    }
    async handleAddChip(item){
        let {currencies} = this.state;
        currencies.push(item);
        this.setState({
            loading:true,
            currencies
        })
        let data = new FormData();
        data.append('new', item);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/currency/make`, data, config)
            .then(async response => {
                this.loadData();
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(translate('allChangesSavingFailed'), {
                    variant: 'error',
                });
                this.loadData();
            });
    }
    async handleDeleteChip(chip, index){
        this.setState({
            loading:true
        })
        let data = new FormData();
        data.append('item', chip);
        console.log(chip)
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/currency/delete`, data, config)
            .then(async response => {
                this.loadData();
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(translate('allChangesSavingFailed'), {
                    variant: 'error',
                });
                this.loadData();
            });
    }
    render(){
        let {loading,currencies} = this.state;
        return (
            <React.Fragment>
                <ChipInput
                    value={currencies}
                    fullWidth
                    label={translate('currencies')}
                    variant={"outlined"}
                    color="secondary"
                    onAdd={(chip) => this.handleAddChip(chip)}
                    onDelete={(chip, index) => this.handleDeleteChip(chip, index)}
                    InputProps={loading && {
                        endAdornment: (
                            <InputAdornment position="end">
                                <CircularProgress size={20}/>
                            </InputAdornment>
                        ),
                    }}

                />
            </React.Fragment>

        );
    }
}

export default withSnackbar(withRouter(withStyles(Styles)(CurrencySetting)));