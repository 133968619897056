import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../config/Translate";
import AppAnimate from "../../components/AppAnimate";
import Grid from "@material-ui/core/Grid/Grid";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import {connect} from "trim-redux";
import UserGuidLine from "./user_components/UserGuidLine";
import ViewYourOrders from "./components/ViewYourOrders";
import UserDashboardConfirmation from "./components/UserDashboardConfirmation";
import AppCard from "../../components/layouts/components/AppCard";
import {translate} from "../../../config/lang";
import Button from "@material-ui/core/Button/Button";
import {defaultVariabls} from "../../../config";
import Alert from "@material-ui/lab/Alert/Alert";
import AnalyticsShort from "./user_components/AnalyticsShort";


class UserDashboard extends React.Component {
    classes = this.props.classes;
    render(){
        let {user} = this.props;
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={translate('dashboard')}>
                <Grid container spacing={2} justify="center">
                    <UserDashboardConfirmation/>
                    <Grid item xs={12} lg={11}>
                        <Alert
                            severity="info"
                            action={
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    component="a"
                                    href={`${defaultVariabls.website}/plans`}
                                >
                                    {translate('plansPage')}
                                </Button>
                            }
                        >
                            {translate('forPurchasingBetterPlansAdsAndReserveGoToPlans')}
                        </Alert>
                    </Grid>
                    <Grid item xs={12} lg={11}>
                       <UserGuidLine/>
                    </Grid>
                    <Grid item xs={12} lg={11}>
                        <AnalyticsShort/>
                    </Grid>
                </Grid>
            </AppAnimate>
        );
    }
}

const mstp = state => ({
    user: state.user,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(UserDashboard)))));
