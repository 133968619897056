import React from 'react';
import moment from "moment-jalaali";


class Clock extends React.Component {
    state = {
        date:moment().locale('en').format('YYYY/M/D'),
        time:moment().locale('en').format('HH:mm:ss')
    };
    componentDidMount(){
        this.interval = setInterval(() => {
            this.displayTime();
        }, 1000);
    }
    displayTime(){
        this.setState({
            time: moment().locale('en').format('HH:mm:ss')
        });
    }
    render(){
        let {time,date} = this.state;
        return (
            <div className="text-center">
                {time}<br />
                {date}
            </div>
        );
    }
}
export default Clock;