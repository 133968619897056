import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {defaultVariabls} from "../../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import {connect, getStore} from "trim-redux";
import Typography from "@material-ui/core/Typography/Typography";
import IconButton from "@material-ui/core/IconButton/IconButton";
import {getTimeZone, price} from "../../../../config/values";
import Link from "@material-ui/core/Link/Link";
import FeaturedPlayListOutlinedIcon from '@material-ui/icons/FeaturedPlayListOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import {makeSlug, translate} from "../../../../config/lang";
import DataUsageIcon from '@material-ui/icons/DataUsage';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import Button from "@material-ui/core/Button/Button";
import Grid from "@material-ui/core/Grid/Grid";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import moment from "moment-timezone";
import SubTitle from "../../../components/elements/SubTitle";
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import InfoTwoToneIcon from '@material-ui/icons/InfoTwoTone';
import AccessAlarmRoundedIcon from '@material-ui/icons/AccessAlarmRounded';
class SingleOrderItem extends React.Component {

    state = {
        item:this.props.item,
    };
    classes = this.props.classes;

    render(){
        let {discount} = this.state.item;
        let {item} = this.state;
        return (
            <div style={{border:"1px solid",marginBottom:15,borderRadius:4,borderColor: "#eee",paddingLeft:15,paddingRight:15}}>
                <div style={{display:'flex',alignItems:"center"}}>
                    {
                        !!discount &&
                        <Link href={makeSlug(`discount/${discount.slug}`)} target="_blank">
                            <img
                                src={`${defaultVariabls.uploads}/FileManager/${discount.thumbnail.name}_small.${discount.thumbnail.extension}`}
                                alt="img"
                                className="single-order-items-img"
                            />
                        </Link>
                    }
                    <div className="single_order_items">
                        {
                            !!discount &&
                            <Typography style={{marginBottom:10}}>
                                <IconButton size="small"><FeaturedPlayListOutlinedIcon/></IconButton><strong>{discount.name}</strong></Typography>
                        }
                        <Typography  style={{marginBottom:10}}><IconButton size="small"><MonetizationOnOutlinedIcon/></IconButton>
                            <strong>{price((!!item.discount_price ? item.discount_price : item.price )*item.quantity)+" "+item.currency}</strong>
                            <span style={{color:this.props.primary_color,fontSize:11}}>({item.quantity+" "+translate('number')})</span></Typography>
                    </div>
                </div>

                {
                    (this.props.factor_status === "payed") &&
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <SubTitle align="left">
                                {translate('vouchersList')}
                                <Button
                                    color="primary"
                                    size="small"
                                    variant="outlined"
                                    component="a"
                                    style={{margin:" 0 10px"}}
                                    href={`${defaultVariabls.website}/vouchers/${item.id}`}
                                >
                                    {translate('getAllVouchers')}
                                </Button>
                            </SubTitle>
                        </Grid>
                        {
                            item.vouchers.map((voucher,index)=>(
                                <Grid item xs={12} sm={6} md={3} key={index}>
                                    <Card
                                        style={{
                                            boxShadow:"none",
                                            border:"1px solid #eee",
                                            marginBottom:15,
                                            background: voucher.status === "not_use" ? getStore( 'mode') === "dark" ? "#413f34" :"#fffaec" : getStore( 'mode') === "dark" ? "#363b2c" :"#f4ffe1",
                                        }}
                                    >
                                        <CardContent>
                                            <Typography
                                                style={{
                                                    justifyContent: "center",
                                                    marginBottom: 10,
                                                    border: "1px solid #ddd",
                                                    padding: "5px 0",
                                                    borderRadius: 4,
                                                }}
                                                className={this.classes.voucher_text}
                                            >
                                                {translate('voucherEn')}:
                                                <span
                                                    style={{marginLeft:5}}
                                                    className="unique_key"
                                                >
                                                    {voucher.unique_key}
                                                </span>
                                            </Typography>
                                            <Typography className={this.classes.voucher_text}>
                                                <AccessAlarmRoundedIcon fontSize="small" style={{marginRight:5}}/>
                                                <span className="expired_at">
                                                    {
                                                        !!voucher.expired_at ?
                                                            <Typography className={this.classes.dateTable}>
                                                                {moment.tz(voucher.expired_at,'UTC').tz(getTimeZone()).format( 'YYYY/M/D')} - {moment.tz(voucher.expired_at,'UTC').tz(getTimeZone()).fromNow( true)}
                                                            </Typography>
                                                            :
                                                            translate('withoutExpire')
                                                    }
                                                </span>
                                            </Typography>
                                            <Typography
                                                style={{
                                                    display:"flex",
                                                    alignItems:"center",
                                                    fontSize:12,
                                                    marginTop:10,

                                                }}
                                            >
                                                {
                                                    voucher.status === "not_use" ?
                                                        <InfoTwoToneIcon fontSize="small" style={{color:"#ffc963",marginRight:5}}/>
                                                        :
                                                        <CheckCircleTwoToneIcon fontSize="small" style={{color:"#62c562",marginRight:5}}/>
                                                }
                                                <span className="status">
                                                    {translate(voucher.status)}
                                                </span>
                                            </Typography>
                                            <div style={{textAlign:"center",marginTop:10}}>
                                                <Button
                                                    color="primary"
                                                    size="small"
                                                    variant="outlined"
                                                    startIcon={<ConfirmationNumberIcon/>}
                                                    component="a"
                                                    href={makeSlug(`voucher/${voucher.unique_key}`)}
                                                    target='_blank'
                                                    disabled={voucher.status !== "not_use"}
                                                >
                                                    {translate('getVoucher')}
                                                </Button>
                                            </div>

                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))
                        }
                    </Grid>
                }
            </div>
        );
    }
}

const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_color: state.primary_color,
    locale: state.locale,

});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(SingleOrderItem))));