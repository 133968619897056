import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField/TextField";
import validator from "validator";
import axios from "axios";
import {defaultVariabls} from "../../../config/index";
import {Link, withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import LoadingButton from "../../components/elements/LoadingButton";
import SubTitle from "../../components/elements/SubTitle";
import AppAnimate from "../../components/AppAnimate/index";
import AppCard from "../../components/layouts/components/AppCard";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import {connect, setStore} from "trim-redux";
import BreadCrumbs from "../../components/elements/BreadCrumbs";
import AppCardLoaderFields from "../../components/Skeletons/AppCardLoaderFields";
import Alert from "@material-ui/lab/Alert/Alert";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import ImageGallery from "../fileManager/Components/ImageGallery";
import Button from "@material-ui/core/Button/Button";
import PageviewRoundedIcon from '@material-ui/icons/PageviewRounded';
import GetConfirmationText from "../../components/elements/GetConfirmationText";
import moment from "moment-timezone";
import {makeSlug, translate} from "../../../config/lang";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import FieldCounterWithoutBar from "../../components/elements/FieldCounterWithoutBar";
import UserCard from "../../components/elements/UserCard";
import {Translate} from "../../../config/Translate";
import MuiPhoneNumber from "material-ui-phone-number";
import SingleImageChooser from "../fileManager/Components/SingleImageChooser";
import SelectLocationFromMap from "../../components/map/SelectLocationFromMap";
import CountryChooser from "../../components/fields/CountryChooser";
import StateChooser from "../../components/fields/StateChooser";
import Divider from "@material-ui/core/Divider/Divider";
import CheckUserCanAdBusiness from "./components/CheckUserCanAdBusiness";
import {hasAccess, updateRedux} from "../../../config/Security";
import StarRateRoundedIcon from '@material-ui/icons/StarRateRounded';
import YourPlanDoesntSupportThis from "../Dashboard/components/YourPlanDoesntSupportThis";
import MakeEqualBusiness from "./MakeEqualBusiness";
import BusinessTypeChooser from "./components/BusinessTypeChooser";
import CustomFields from "../advertisements/components/CustomFields";
import Certificates from "./components/certificates/Certificates";
import ViewRelatedComments from "../admin/comments/components/ViewRelatedComments";
import Rating from "@material-ui/lab/Rating/Rating";
import MainProductsChooser from "./components/MainProductsChooser";
import CurrencyChooser from "../../components/fields/CurrencyChooser";
import OptionUnitChooser from "../../components/fields/OptionUnitChooser";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import {MenuItem} from "@material-ui/core";
import SubTitleLight from "../../components/elements/SubTitleLight";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import FormControl from "@material-ui/core/FormControl/FormControl";
import BusinessUserChooser from "./components/BusinessUserChooser";
import SingleFileChooser from "../fileManager/Components/SingleFileChooser";
import {getTimeZone} from "../../../config/values";

class MakeBusiness extends React.Component {
    constructor(props){
        super(props);
        this.ThumbChild = React.createRef();
        this.EditorChild = React.createRef();
    }
    state = {
        fields:{
            name:this.props.user.company_name,
            description:'',
            total_employees:'',
            total_annual_revenue:'',
            total_annual_revenueR:'',
            year:'',
            working_days_times:'',
            main_products:'',
            website:'',
            whatsapp:'',
            telegram:'',
            instagram:'',
            facebook:'',
            twitter:'',
            youtube:'',
            mobile:'',
            email:'',
            contact_person_name:'',
            contact_person_position:'',
            company_address:"",
            depot_address:"",
            phone:'',
            monday_start:"08:00",
            monday_end:"17:00",
            tuesday_start:"08:00",
            tuesday_end:"17:00",
            wednesday_start:"08:00",
            wednesday_end:"17:00",
            thursday_start:"08:00",
            thursday_end:"17:00",
            friday_start:"08:00",
            friday_end:"17:00",
            saturday_start:"08:00",
            saturday_end:"17:00",
            sunday_start:"08:00",
            sunday_end:"17:00",
        },
        monday_off:0,
        tuesday_off:0,
        wednesday_off:0,
        thursday_off:0,
        friday_off:0,
        saturday_off:0,
        sunday_off:0,
        type:null,
        has_depot_address:false,
        currency: '',
        related_user:null,
        logo: null,
        catalog: null,
        contact_person_avatar: null,
        errors:{},
        banners: [],
        loading: true,
        SubmitLoading: false,
        confirmed: false,
        real_confirmed: false,
        slug: null,
        user_id:this.props.user_id,
        editMode:false,
        owner_id:null,
        owner:null,
        updated_at:null,
        created_at:null,
        company_lat:"",
        company_lng:"",
        depot_lat:"",
        depot_lng:"",
        country:null,
        gallery: [],
        state:null,
        server_country:null,
        server_state:null,
        visits:0,
        today_visits:0,
        comments:0,
        allowed_comment: 0,
        average_rating: 0,
        lang: this.props.locale,
        item:null,
        equal:null,
        id:0,
        defaultCountryIso2:"ae",
        trade_capacity_fields:null,
        product_capacity_fields:null,
        years:[],
    };
    classes = this.props.classes;
    componentDidMount(){
        if(this.state.user_id){
            this.setState({
                editMode:true
            });
            this.loadData();
        }else{
            this.setState({
                loading:false
            })
        }
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
        let years = [];
        for(let i = 1900 ;  i < 2101 ; i++ ){
            years.push(i);
        }
        this.setState({
            years
        })
    }
    componentDidUpdate(prevProps){
        if(this.state.user_id !== this.props.user_id){
            this.setState({
                user_id: this.props.user_id
            })
        }
    }
    handleLogoImage(logo){
        this.setState({
            logo
        })
    }
    handleAvatarImage(avatar){
        this.setState({
            contact_person_avatar:avatar
        })
    }
    handleThumbImage(thumbnail){
        this.setState({
            thumbnail
        })
    }
    async loadData(){
        this.setState({
            loading:true
        })
        let loginToken = localStorage.getItem('ACT');
        let {locale} = this.props;
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };

            await axios.get(`${defaultVariabls.url}/business/get/${this.state.user_id}/${locale}`, config)
                .then(response => {
                    this.setState({
                        fields: {
                            name: response.data.name,
                            year: !!response.data.year ? response.data.year : '',
                            working_days_times: !!response.data.working_days_times ? response.data.working_days_times : '',
                            main_products: !!response.data.main_products ? response.data.main_products : '',
                            description: !!response.data.description ? response.data.description : '',
                            total_employees: !!response.data.total_employees ? response.data.total_employees : '',
                            total_annual_revenue: !!response.data.total_annual_revenue ? response.data.total_annual_revenue : '',
                            total_annual_revenueR: !!response.data.total_annual_revenue ? response.data.total_annual_revenue : '',
                            company_address: !!response.data.company_address ? response.data.company_address : '',
                            depot_address: !!response.data.depot_address ? response.data.depot_address : '',
                            phone: !!response.data.phone ? response.data.phone : '',
                            email: !!response.data.email ? response.data.email : '',
                            mobile: !!response.data.mobile ? response.data.mobile : '',
                            website: !!response.data.website ? response.data.website : '',
                            telegram: !!response.data.telegram ? response.data.telegram : '',
                            whatsapp: !!response.data.whatsapp ? response.data.whatsapp : '',
                            facebook: !!response.data.facebook ? response.data.facebook : '',
                            instagram: !!response.data.instagram ? response.data.instagram : '',
                            twitter: !!response.data.twitter ? response.data.twitter : '',
                            linkedin: !!response.data.linkedin ? response.data.linkedin : '',
                            youtube: !!response.data.youtube ? response.data.youtube : '',
                            contact_person_name:!!response.data.contact_person_name ? response.data.contact_person_name : '',
                            contact_person_position:!!response.data.contact_person_position ? response.data.contact_person_position : '',
                            monday_start:!!response.data.monday_start ? response.data.monday_start : "08:00",
                            monday_end:!!response.data.monday_end ? response.data.monday_end : "08:00",
                            tuesday_start:!!response.data.tuesday_start ? response.data.tuesday_start : "17:00",
                            tuesday_end:!!response.data.tuesday_end ? response.data.tuesday_end : "17:00",
                            wednesday_start:!!response.data.wednesday_start ? response.data.wednesday_start : "17:00",
                            wednesday_end:!!response.data.wednesday_end ? response.data.wednesday_end : "17:00",
                            thursday_start:!!response.data.thursday_start ? response.data.thursday_start : "17:00",
                            thursday_end:!!response.data.thursday_end ? response.data.thursday_end : "17:00",
                            friday_start:!!response.data.friday_start ? response.data.friday_start : "17:00",
                            friday_end:!!response.data.friday_end ? response.data.friday_end : "17:00",
                            saturday_start:!!response.data.saturday_start ? response.data.saturday_start : "17:00",
                            saturday_end:!!response.data.saturday_end ? response.data.saturday_end : "17:00",
                            sunday_start:!!response.data.sunday_start ? response.data.sunday_start : "17:00",
                            sunday_end:!!response.data.sunday_end ? response.data.sunday_end : "17:00",
                        },
                        monday_off:parseInt(response.data.monday_off),
                        tuesday_off:parseInt(response.data.tuesday_off),
                        wednesday_off:parseInt(response.data.wednesday_off),
                        thursday_off:parseInt(response.data.thursday_off),
                        friday_off:parseInt(response.data.friday_off),
                        saturday_off:parseInt(response.data.saturday_off),
                        sunday_off:parseInt(response.data.sunday_off),
                        comments:parseInt(response.data.comments_count),
                        logo: response.data.logo,
                        catalog: response.data.catalog,
                        has_depot_address:!!response.data.depot_lng,
                        currency: response.data.currency,
                        contact_person_avatar: response.data.contact_person_avatar,
                        thumbnail: response.data.thumbnail,
                        average_rating: response.data.average_rating,
                        allowed_comment:parseInt(response.data.allowed_comment),
                        avatar: response.data.avatar,
                        confirmed: response.data.confirmed,
                        real_confirmed: response.data.confirmed,
                        slug: response.data.slug,
                        owner_id: response.data.user_id,
                        id: response.data.id,
                        owner: response.data.owner,
                        updated_at: response.data.updated_at,
                        created_at: response.data.created_at,
                        company_lat:!!response.data.company_lat ? parseFloat(response.data.company_lat) : '',
                        company_lng:!!response.data.company_lng ?  parseFloat(response.data.company_lng) : '',
                        depot_lat:!!response.data.depot_lat ?  parseFloat(response.data.depot_lat) : '',
                        depot_lng:!!response.data.depot_lng ?  parseFloat(response.data.depot_lng) : '',
                        country:!!response.data.country ? response.data.country : null,
                        state:!!response.data.state ? response.data.state : null,
                        server_country:!!response.data.country ? response.data.country : null,
                        server_state:!!response.data.state ? response.data.state : null,
                        visits:parseInt(response.data.visits_count),
                        today_visits:parseInt(response.data.today_visits_count),
                        lang:response.data.lang,
                        product_capacity_fields:response.data.product_capacity_fields,
                        trade_capacity_fields:response.data.trade_capacity_fields,
                        type:response.data.type,
                    })
                    let bannerTemp=[];
                    let galleryTemp=[];
                    response.data.gallery.map(item =>{
                        galleryTemp.push({
                            src:`${defaultVariabls.uploads}/FileManager/${item.name}_s_thumb.${item.extension}`,
                            link:!!item.source_id ? `https://www.youtube.com/watch?v=${item.source_id}` : `${defaultVariabls.uploads}/FileManager/${item.name}.${item.extension}`,
                            id: item.id,
                            name: item.name,
                            type: item.type,
                            preview: item.preview_url ? item.preview_url : item.preview_id ? `${defaultVariabls.uploads}/FileManager/${item.preview.name}_s_thumb.${item.preview.extension}` : '',
                        })
                        return true
                    })
                    response.data.banners.map(item =>{
                        bannerTemp.push({
                            src:`${defaultVariabls.uploads}/FileManager/${item.name}_s_thumb.${item.extension}`,
                            link:!!item.source_id ? `https://www.youtube.com/watch?v=${item.source_id}` : `${defaultVariabls.uploads}/FileManager/${item.name}.${item.extension}`,
                            id: item.id,
                            name: item.name,
                            type: item.type,
                            preview: item.preview_url ? item.preview_url : item.preview_id ? `${defaultVariabls.uploads}/FileManager/${item.preview.name}_s_thumb.${item.preview.extension}` : '',
                        })
                        return true
                    })

                    this.setState({
                        banners: bannerTemp,
                        gallery: galleryTemp,
                        item:response.data,
                        equal:response.data.equal,
                        loading: false
                    })
                }).catch(error => {
                    console.log(error)
                    this.setState({
                        loading:false,
                        editMode:false,
                        id:0
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
            this.setState({
                loading:false
            })
        }
    }
    handleChangeCatalog(file){
        this.setState({
            catalog:file
        })
    }
    handleChange(e){
        let {errors,fields} = this.state;
        let target = e.target;
        if(target.name === "total_employees"){
            fields[target.name] = Math.abs(parseInt(target.value));
        }else if(target.name === "total_annual_revenue"){
            fields.total_annual_revenueR = Math.abs(parseInt(target.value.replace(/,/ig, '')));
        }else if(target.name === "name"){
            if(this.props.user.role.type === "admin"){
                fields[target.name] = target.value;
            }
        }else{
            fields[target.name] = target.value;
        }

        errors[target.name] = "";
        this.setState({
            fields,
        });
    }

    handleValidation(callback){
        let { fields,country,state,company_lat } = this.state;
        let errors = {};
        let formIsValid = true;

        //name
        if(validator.isEmpty(fields.name)){
            formIsValid = false;
            errors['name'] = translate('emptyField');
        }

        //address
        if(fields.company_address !== null ) {
            if (fields.company_address.length > 150) {
                formIsValid = false;
                errors['company_address'] = translate('maxLength150');
            }
        }

        //Description
        if(fields.description !== null ) {
            if (fields.description.length > 150) {
                formIsValid = false;
                errors['description'] = translate('maxLength150');
            }
        }
        /*if(!country){
            formIsValid = false;
            errors['country'] = translate('emptyField');
        }
        if(!state){
            formIsValid = false;
            errors['state'] = translate('emptyField');
        }
        */
        if(!company_lat){
            formIsValid = false;
            errors['geocode'] = translate('pleaseChooseYourLocation');
        }
        if(!formIsValid){
            this.props.enqueueSnackbar(translate('someFieldsHasError'), {
                variant: 'warning',
            });
            this.setState({
                errors,
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        let { id,fields,type,contact_person_avatar,gallery,currency,allowed_comment,
            editMode,average_rating,confirmed,lat,lng,country,state,banners,videos,logo,catalog,thumbnail,
            product_capacity_fields,trade_capacity_fields,related_user,company_lat,company_lng,depot_lat,depot_lng,
            monday_off,tuesday_off,wednesday_off,thursday_off,friday_off,saturday_off,sunday_off
        } = this.state;
        let data = new FormData();
        data.append('name', fields.name);
        data.append('lang', this.props.locale);
        data.append('id', id ? id : 0);
        data.append('company_address', fields.company_address);
        data.append('depot_address', fields.depot_address);
        data.append('bio', fields.bio);
        data.append('year', fields.year);
        data.append('related_user', related_user ? related_user.id : 0);
        data.append('working_days_times', fields.working_days_times);
        data.append('main_products', fields.main_products);
        data.append('description', fields.description);
        data.append('total_annual_revenue', fields.total_annual_revenueR);
        data.append('total_employees', fields.total_employees);
        data.append('logo', !!logo ? logo.id : 3);
        data.append('catalog', !!catalog ? catalog.id : '');
        data.append('thumbnail', !!thumbnail ? thumbnail.id : 1);
        data.append('contact_person_avatar', !!contact_person_avatar ? contact_person_avatar.id : 2);
        data.append('confirmed',confirmed ? 1 : 0);
        data.append('phone', fields.phone);
        data.append('currency', currency);
        data.append('email', fields.email);
        data.append('average_rating', average_rating);
        data.append('allowed_comment', parseInt(allowed_comment) );
        data.append('mobile', fields.mobile);
        data.append('company_lat', company_lat);
        data.append('company_lng', company_lng);
        data.append('depot_lat', depot_lat);
        data.append('depot_lng', depot_lng);
        data.append('country_id', !!country ? country.id : '');
        data.append('state_id', !!state ? state.id : '' );
        data.append('website', fields.website);
        data.append('whatsapp', fields.whatsapp);
        data.append('telegram', fields.telegram);
        data.append('facebook', fields.facebook);
        data.append('instagram', fields.instagram);
        data.append('twitter', fields.twitter);
        data.append('linkedin', fields.linkedin);
        data.append('youtube', fields.youtube);
        data.append('contact_person_name', fields.contact_person_name);
        data.append('contact_person_position', fields.contact_person_position);
        data.append('type_id', type.id);
        data.append('product_capacity_fields', JSON.stringify(product_capacity_fields));
        data.append('trade_capacity_fields', JSON.stringify(trade_capacity_fields));
        data.append('monday_off', !!monday_off ? 1 : 0 );
        data.append('tuesday_off', !!tuesday_off ? 1 : 0 );
        data.append('wednesday_off',!!wednesday_off ? 1 : 0 );
        data.append('thursday_off',!!thursday_off ? 1 : 0 );
        data.append('friday_off', !!friday_off ? 1 : 0 );
        data.append('saturday_off', !!saturday_off ? 1 : 0 );
        data.append('sunday_off', !!sunday_off ? 1 : 0 );
        data.append('monday_start', fields.monday_start);
        data.append('monday_end', fields.monday_end);
        data.append('tuesday_start', fields.tuesday_start);
        data.append('tuesday_end', fields.tuesday_end);
        data.append('wednesday_start', fields.wednesday_start);
        data.append('wednesday_end', fields.wednesday_end);
        data.append('thursday_start', fields.thursday_start);
        data.append('thursday_end', fields.thursday_end);
        data.append('friday_start', fields.friday_start);
        data.append('friday_end', fields.friday_end);
        data.append('saturday_start', fields.saturday_start);
        data.append('saturday_end', fields.saturday_end);
        data.append('sunday_start', fields.sunday_start);
        data.append('sunday_end', fields.sunday_end);

        if(banners !== null){
            let banners_ids = [];
            banners.map((row) =>{
                banners_ids.push(row.id);
                return true
            })
            banners = JSON.stringify(banners_ids);
        }
        data.append('banners', banners);

        if(gallery !== null){
            let galery_ids = [];
            gallery.map((row) =>{
                galery_ids.push(row.id);
                return true
            })
            gallery = JSON.stringify(galery_ids);
        }
        data.append('gallery', gallery);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        let {user} = this.props;
        axios.post(`${defaultVariabls.url}/business/make`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(editMode ? translate('businessEdited') : translate('businessAdded'), {
                    variant: 'success',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
                updateRedux();
                if(!!user.role.permission.manageAdvertisements){
                    this.props.history.push(`/manage/businesses`);
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    })
                }else{
                    this.loadData();
                    window.location.reload();
                }
                if(!this.state.editMode){

                }else{
                    this.loadData();

                }
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(editMode ?  translate('businessEditingFailed') : translate('businessAddingFailed'), {
                    variant: 'error',
                });

                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
            });

    }
    handleSubmit(e,draft = false){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.setState({
                    publish:draft
                })
                this.handleRequest();
            }
        });
    }
    handleChangeCurrency(currency){
        this.setState({
            currency
        })
    }
    handleBannersChange(data){
        this.setState({
            banners: data,
        })
    }
    handleVideosChange(data){
        this.setState({
            videos: data,
        })
    }
    handleChangeConfirmed(){
        this.setState({
            confirmed: !this.state.confirmed,
        })
    }

    handleChangePhone(phone,name){
        let {fields,errors} = this.state;
        fields[name] = phone;
        errors[name] = '';
        this.setState({
            fields,
            errors
        })
    }
    handleCompanyAddress(address){
        let {fields} = this.state;
        fields['company_address'] = address;
        this.setState({
            fields
        })

    }
    handleDepotAddress(address){
        let {fields} = this.state;
        fields['depot_address'] = address;
        this.setState({
            fields
        })

    }
    handleGalleryChange(data){
        this.setState({
            gallery: data,
        })
    }
    async handleRelateduser(res){
        await this.setState({
            loading:true
        })
        await this.setState({
            related_user:res,
            owner_id:res.id,
        });
        await this.setState({
            loading:false
        });
    }
    handleDeleteItem(){
        let data = new FormData();
        data.append('user_id', this.state.user_id);
        let loginToken = localStorage.getItem('ACT');
        console.log(this.state.user_id);
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/delete/business-by-user`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(translate('deleteBusiness'), {
                    variant: 'success',
                });
                this.props.history.push('/manage/businesses')
                return true
            })

            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(translate('deleteBusinessFailed'), {
                    variant: 'error',
                });
                return false
            });
    }
    render(){
        let {fields,item,equal,gallery,monday_off,tuesday_off,wednesday_off,thursday_off,friday_off,saturday_off,sunday_off
            ,currency,country,loading,average_rating,server_country,server_state,errors,slug,company_lat,company_lng,depot_lat,depot_lng,
            SubmitLoading,owner,logo,catalog,banners,videos,editMode,real_confirmed,confirmed,lat,lng,
            product_capacity_fields,trade_capacity_fields} = this.state;
        const {user} = this.props;
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={editMode ? translate('editBusiness') :  translate('makeBusiness')}>
                {
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} lg={11}>
                            <Grid container spacing={2} justifyContent="center">
                                {
                                    (user.role.type !== "admin" && !loading) &&
                                    <CheckUserCanAdBusiness edit={!!editMode}/>
                                }

                                {
                                    (!!editMode && !loading) &&
                                    <>
                                        <Grid item xs={12}>
                                            {
                                                !! user.role.permission.manageAdvertisements ?
                                                    <>
                                                        <Alert
                                                            severity="info"
                                                            action={
                                                                <FormControlLabel
                                                                    style={{marginRight:10}}
                                                                    control={
                                                                        <Switch
                                                                            checked={confirmed}
                                                                            onChange={this.handleChangeConfirmed.bind(this)}
                                                                            value={confirmed}
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                    label={confirmed ? translate('confirmed') : translate('unConfirmed')}
                                                                />
                                                            }
                                                        >
                                                            {translate('confirmationStatusDescription')}
                                                        </Alert>
                                                    </>
                                                    :
                                                    confirmed ?
                                                        <Alert severity="success" variant="filled">
                                                            <Typography  className={this.classes.alertText}>{translate('yourBusinessIsActive')}</Typography>
                                                        </Alert>
                                                        :
                                                        <Alert severity="warning">
                                                            <Typography className={this.classes.alertText}>{translate('yourBusinessIsInchecking')}</Typography>
                                                        </Alert>
                                            }
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <AppCard style={{height:"100%"}}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        {
                                                            !!real_confirmed ?
                                                                <>
                                                                    <Button
                                                                        component="a"
                                                                        target="_blank"
                                                                        size="small"
                                                                        startIcon={<PageviewRoundedIcon/>}
                                                                        href={makeSlug(`business/${slug}`,this.state.lang)}
                                                                        color="primary"
                                                                        variant="contained"
                                                                    >
                                                                        {translate('viewBusiness')}
                                                                    </Button>
                                                                </> :
                                                                <>
                                                                    <Button
                                                                        component="a"
                                                                        target="_blank"
                                                                        size="small"
                                                                        variant="contained"
                                                                        startIcon={<PageviewRoundedIcon/>}
                                                                        href={makeSlug(`business/${slug}`,this.state.lang)}
                                                                        color="primary"
                                                                    >
                                                                        {translate('viewPreview')}
                                                                    </Button>

                                                                </>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography  className='date-holder'>
                                                            <span>{translate('todayVisitsCount')}</span>
                                                            <strong>
                                                                {this.state.today_visits}
                                                            </strong>
                                                        </Typography>
                                                        <Divider style={{marginTop:10,marginBottom:10}}/>
                                                        <Typography  className='date-holder'>
                                                            <span>{translate('totalVisitsCount')}</span>
                                                            <strong>
                                                                {this.state.visits}
                                                            </strong>
                                                        </Typography>
                                                        <Divider style={{marginTop:10,marginBottom:10}}/>
                                                        <Typography  className='date-holder'>
                                                            <span>{translate('totalCommentsCount')} <ViewRelatedComments type={"business"} item_id={item.id}/></span>
                                                            <strong>
                                                                {this.state.comments}
                                                            </strong>
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </AppCard>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <AppCard style={{height:"100%"}}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <SubTitle align="left" style={{position:"relative"}}>
                                                            {translate('pageInfo')}
                                                            {
                                                                user.role.type !== "admin" &&
                                                                <Button
                                                                    color="secondary"
                                                                    size="small"
                                                                    style={{position:'absolute',top:-5,right:0}}
                                                                    variant="contained"
                                                                    component={Link}
                                                                    to={'/my-plan'}
                                                                    startIcon={<img style={{width:20}} src={`${defaultVariabls.uploads}/FileManager/${user.plan.parent.thumbnail.name}.${user.plan.parent.thumbnail.extension}`}  />}
                                                                >
                                                                    {user.plan.parent.name}
                                                                </Button>
                                                            }
                                                        </SubTitle>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography  className='date-holder'>
                                                                <span>
                                                                {translate('updated_at')}
                                                            </span>
                                                            <strong>
                                                                {`${moment.tz(this.state.updated_at,'UTC').tz(getTimeZone()).format( 'HH:mm , YYYY/M/D')}`}
                                                            </strong>
                                                        </Typography>
                                                        <Divider style={{marginTop:10,marginBottom:10}}/>
                                                        <Typography  className='date-holder'>
                                                             <span>
                                                                {translate('average_rating')}
                                                            </span>
                                                            <strong className="rating">
                                                                <Rating
                                                                    readOnly={user.role.type !== "admin"}
                                                                    name="rating"
                                                                    precision={0.5}
                                                                    value={average_rating}
                                                                    onChange={(event, newValue) => {
                                                                        this.setState({
                                                                            average_rating:newValue,
                                                                        })
                                                                    }}
                                                                />
                                                            </strong>
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </AppCard>
                                        </Grid>
                                    </>

                                }

                                {
                                    !!editMode &&
                                    <>
                                        {
                                            this.state.loading ? null :
                                                <Grid item xs={12}>
                                                    <AppCard
                                                        className={this.classes.equalAppCard}
                                                    >
                                                        <div
                                                            className={this.classes.equalHolder}
                                                        >
                                                            <SubTitle noHR align="left">{translate('equalItem')}</SubTitle>
                                                            <MakeEqualBusiness
                                                                equal_id={!!equal ? equal.id : null}
                                                                item={item}
                                                                reload={() => this.loadData()}
                                                            />
                                                        </div>
                                                    </AppCard>
                                                </Grid>
                                        }
                                    </>
                                }
                                <Grid item xs={12}>
                                    <AppCard
                                        title={editMode ? translate('editBusiness') :  translate('makeBusiness')}
                                        action={
                                            <BreadCrumbs singleLevel firstText={editMode ? translate('editBusiness') :  translate('makeBusiness')}/>
                                        }>
                                        {
                                            this.state.loading ? <AppCardLoaderFields/> :
                                                <Grid container spacing={2} justifyContent="center" alignItems="center" style={{marginBottom:10}}>
                                                    <Grid item xs={12} md={6}>
                                                        <BusinessTypeChooser
                                                            error={errors['type']}
                                                            handleType={(type) => this.setState({type})}
                                                            selected={this.state.type}
                                                            required
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            required
                                                            fullWidth
                                                            name="name"
                                                            color="secondary"
                                                            variant="outlined"
                                                            label={translate('businessName')}
                                                            type="text"
                                                            id="name"
                                                            value={fields.name}
                                                            disabled={user.role.type !== "admin"}
                                                            onChange={this.handleChange.bind(this)}
                                                            error={!!errors['name']}
                                                            helperText={errors['name']}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            fullWidth
                                                            name="total_employees"
                                                            variant="outlined"
                                                            color="secondary"
                                                            label={translate('total_employees')}
                                                            type="number"
                                                            value={fields.total_employees}
                                                            onChange={this.handleChange.bind(this)}
                                                            error={!!errors['total_employees']}
                                                            helperText={errors['total_employees']}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <CurrencyTextField
                                                            variant="outlined"
                                                            fullWidth
                                                            currencySymbol={<CurrencyChooser current={currency}
                                                                                             onChange={(currency) => this.handleChangeCurrency(currency)}/>}
                                                            decimalPlaces={0}
                                                            value={fields.total_annual_revenue}
                                                            id="total_annual_revenue"
                                                            label={translate('total_annual_revenue')}
                                                            name="total_annual_revenue"
                                                            color="secondary"
                                                            onChange={this.handleChange.bind(this)}
                                                            error={!!errors['total_annual_revenue']}
                                                            helperText={errors['total_annual_revenue']}
                                                            className={this.classes.currencyTextField}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            fullWidth
                                                            name="main_products"
                                                            variant="outlined"
                                                            label={translate('main_products')}
                                                            type="text"
                                                            color="secondary"
                                                            value={fields.main_products}
                                                            onChange={this.handleChange.bind(this)}
                                                            error={!!errors['main_products']}
                                                            helperText={errors['main_products']}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            fullWidth
                                                            name="year"
                                                            variant="outlined"
                                                            label={translate('foundingYear')}
                                                            type="number"
                                                            color="secondary"
                                                            value={fields.year}
                                                            onChange={this.handleChange.bind(this)}
                                                            error={!!errors['year']}
                                                            helperText={errors['year']}
                                                            select
                                                            SelectProps={{
                                                                native: true,
                                                            }}
                                                        >
                                                            {
                                                                this.state.years.map((item,index)=>(
                                                                        <option key={index} value={item}>
                                                                            {item}
                                                                        </option>
                                                                    )
                                                                )
                                                            }
                                                        </TextField>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            variant="outlined"
                                                            multiline
                                                            fullWidth
                                                            color="secondary"
                                                            name="description"
                                                            label={translate('businessDescription')}
                                                            type="text"
                                                            id="description"
                                                            minRows="4"
                                                            value={fields.description}
                                                            onChange={this.handleChange.bind(this)}
                                                            error={!!errors['description']}
                                                            helperText={errors['description']}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <FieldCounterWithoutBar length={fields.description.length} max={150}/>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        {
                                                            hasAccess("has_business_catalogue") &&
                                                            <>
                                                                <SubTitleLight align="left">{translate('businessCatalog')}</SubTitleLight>
                                                                <SingleFileChooser
                                                                    initial={catalog}
                                                                    onChange={(file) => this.handleChangeCatalog(file)}
                                                                />
                                                            </>
                                                        }
                                                    </Grid>
                                                </Grid>
                                        }
                                    </AppCard>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <AppCard style={{height:"100%"}}>
                                        <SubTitle align="left" style={{marginBottom:20}}>{translate('trade_capacity')}</SubTitle>
                                        {
                                            this.state.loading ? <AppCardLoaderFields/> :
                                                <>
                                                    <CustomFields
                                                        type={"business"}
                                                        position_type="trade_capacity"
                                                        input={this.state.trade_capacity_fields}
                                                        business_type={this.state.type}
                                                        handleCustomFields={(trade_capacity_fields) => this.setState({trade_capacity_fields})}
                                                    />
                                                </>
                                        }
                                    </AppCard>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <AppCard style={{height:"100%"}}>
                                        <SubTitle align="left" style={{marginBottom:20}}>{translate('product_capacity')}</SubTitle>
                                        {
                                            this.state.loading ? <AppCardLoaderFields/> :
                                                <>
                                                    <CustomFields
                                                        type={"business"}
                                                        position_type="product_capacity"
                                                        input={this.state.product_capacity_fields}
                                                        business_type={this.state.type}
                                                        handleCustomFields={(product_capacity_fields) => this.setState({product_capacity_fields})}
                                                    />
                                                </>
                                        }
                                    </AppCard>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <AppCard style={{height:"100%"}}>
                                        {
                                            this.state.loading ? <AppCardLoaderFields/> :
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <SubTitle align="left">{translate('businessLogo')}</SubTitle>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <SingleImageChooser
                                                            primary
                                                            logo
                                                            onChange={(logo) => this.handleLogoImage(logo)}
                                                            initial={logo}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography className={this.classes.condir} style={{textAlign:"center"}}>
                                                            {translate('thumbnailSize')}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                        }
                                    </AppCard>
                                </Grid>
                                <Grid item xs={12} md={9}>
                                    <AppCard style={{height:"100%"}}>
                                        {
                                            this.state.loading ? <AppCardLoaderFields/> :
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <SubTitle align="left">{translate('businessBanners')}</SubTitle>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <ImageGallery defined_width={1200} defined_height={300} type="image" onChange={this.handleBannersChange.bind(this)} initial={banners}/>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography className={this.classes.condir}>
                                                            {translate('bannerSize')}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                        }
                                    </AppCard>
                                </Grid>
                                <Grid item xs={12}>
                                    <AppCard style={{height:"100%"}}>
                                        {
                                            this.state.loading ? <AppCardLoaderFields/> :
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <SubTitle align="left">{translate('businessGallery')}</SubTitle>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <ImageGallery
                                                            type={hasAccess("has_business_video") ? "" : "image"}
                                                            onChange={this.handleGalleryChange.bind(this)}
                                                            initial={gallery}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography className={this.classes.condir}>
                                                            {translate('gallerySize')}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                        }
                                    </AppCard>
                                </Grid>
                                <Grid item xs={12}>
                                    <AppCard style={{height:"100%"}}>
                                        {
                                            this.state.loading ? <AppCardLoaderFields/> :
                                                <Grid container spacing={1} alignItems="center">
                                                    <Grid item xs={12}>
                                                        <SubTitle align='left'>
                                                            {translate('working_days_times')}
                                                        </SubTitle>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <SubTitleLight align="left">
                                                            {translate('monday')}
                                                        </SubTitleLight>
                                                    </Grid>
                                                    <Grid item xs={6} style={{display:"flex",justifyContent:"flex-end"}}>
                                                        <FormControl required component="fieldset" size="small">
                                                            <FormControlLabel size="small"
                                                                              style={{marginRight:0}}
                                                                              control={
                                                                                  <Checkbox
                                                                                      size="small"
                                                                                      checked={monday_off}
                                                                                      onChange={() => this.setState({monday_off: !monday_off})}
                                                                                  />
                                                                              }
                                                                              label={translate('off')}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    {
                                                        !monday_off &&
                                                        <>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    size="small"
                                                                    color="secondary"
                                                                    name="monday_start"
                                                                    label={translate('start_time')}
                                                                    value={fields.monday_start}
                                                                    onChange={this.handleChange.bind(this)}
                                                                    error={!!errors['monday_start']}
                                                                    helperText={errors['monday_start']}
                                                                    type="time"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    inputProps={{
                                                                        step: 1800,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    size="small"
                                                                    color="secondary"
                                                                    name="monday_end"
                                                                    label={translate('end_time')}
                                                                    value={fields.monday_end}
                                                                    onChange={this.handleChange.bind(this)}
                                                                    error={!!errors['monday_end']}
                                                                    helperText={errors['monday_end']}
                                                                    type="time"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    inputProps={{
                                                                        step: 1800,
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </>
                                                    }


                                                    <Grid item xs={6}>
                                                        <SubTitleLight align="left">
                                                            {translate('tuesday')}
                                                        </SubTitleLight>
                                                    </Grid>
                                                    <Grid item xs={6} style={{display:"flex",justifyContent:"flex-end"}}>
                                                        <FormControl required component="fieldset" size="small">
                                                            <FormControlLabel size="small"
                                                                              style={{marginRight:0}}
                                                                              control={
                                                                                  <Checkbox
                                                                                      size="small"
                                                                                      checked={tuesday_off}
                                                                                      onChange={() => this.setState({tuesday_off: !tuesday_off})}
                                                                                  />
                                                                              }
                                                                              label={translate('off')}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    {
                                                        !tuesday_off &&
                                                        <>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    size="small"
                                                                    color="secondary"
                                                                    name="tuesday_start"
                                                                    label={translate('start_time')}
                                                                    value={fields.tuesday_start}
                                                                    onChange={this.handleChange.bind(this)}
                                                                    error={!!errors['tuesday_start']}
                                                                    helperText={errors['tuesday_start']}
                                                                    type="time"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    inputProps={{
                                                                        step: 1800,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    size="small"
                                                                    color="secondary"
                                                                    name="tuesday_end"
                                                                    label={translate('end_time')}
                                                                    value={fields.tuesday_end}
                                                                    onChange={this.handleChange.bind(this)}
                                                                    error={!!errors['tuesday_end']}
                                                                    helperText={errors['tuesday_end']}
                                                                    type="time"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    inputProps={{
                                                                        step: 1800,
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </>
                                                    }

                                                    <Grid item xs={6}>
                                                        <SubTitleLight align="left">
                                                            {translate('wednesday')}
                                                        </SubTitleLight>
                                                    </Grid>
                                                    <Grid item xs={6} style={{display:"flex",justifyContent:"flex-end"}}>
                                                        <FormControl required component="fieldset" size="small">
                                                            <FormControlLabel size="small"
                                                                              style={{marginRight:0}}
                                                                              control={
                                                                                  <Checkbox
                                                                                      size="small"
                                                                                      checked={wednesday_off}
                                                                                      onChange={() => this.setState({wednesday_off: !wednesday_off})}
                                                                                  />
                                                                              }
                                                                              label={translate('off')}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    {
                                                        !wednesday_off &&
                                                        <>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    size="small"
                                                                    color="secondary"
                                                                    name="wednesday_start"
                                                                    label={translate('start_time')}
                                                                    value={fields.wednesday_start}
                                                                    onChange={this.handleChange.bind(this)}
                                                                    error={!!errors['wednesday_start']}
                                                                    helperText={errors['wednesday_start']}
                                                                    defaultValue="08:00"
                                                                    type="time"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    inputProps={{
                                                                        step: 1800,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    size="small"
                                                                    color="secondary"
                                                                    name="wednesday_end"
                                                                    label={translate('end_time')}
                                                                    value={fields.wednesday_end}
                                                                    onChange={this.handleChange.bind(this)}
                                                                    error={!!errors['wednesday_end']}
                                                                    helperText={errors['wednesday_end']}
                                                                    defaultValue="17:30"
                                                                    type="time"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    inputProps={{
                                                                        step: 1800,
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </>
                                                    }


                                                    <Grid item xs={6}>
                                                        <SubTitleLight align="left">
                                                            {translate('thursday')}
                                                        </SubTitleLight>
                                                    </Grid>
                                                    <Grid item xs={6} style={{display:"flex",justifyContent:"flex-end"}}>
                                                        <FormControl required component="fieldset" size="small">
                                                            <FormControlLabel size="small"
                                                                              style={{marginRight:0}}
                                                                              control={
                                                                                  <Checkbox
                                                                                      size="small"
                                                                                      checked={thursday_off}
                                                                                      onChange={() => this.setState({thursday_off: !thursday_off})}
                                                                                  />
                                                                              }
                                                                              label={translate('off')}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    {
                                                        !thursday_off &&
                                                        <>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    size="small"
                                                                    color="secondary"
                                                                    name="thursday_start"
                                                                    label={translate('start_time')}
                                                                    value={fields.thursday_start}
                                                                    onChange={this.handleChange.bind(this)}
                                                                    error={!!errors['thursday_start']}
                                                                    helperText={errors['thursday_start']}
                                                                    defaultValue="08:00"
                                                                    type="time"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    inputProps={{
                                                                        step: 1800,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    size="small"
                                                                    color="secondary"
                                                                    name="thursday_end"
                                                                    label={translate('end_time')}
                                                                    value={fields.thursday_end}
                                                                    onChange={this.handleChange.bind(this)}
                                                                    error={!!errors['thursday_end']}
                                                                    helperText={errors['thursday_end']}
                                                                    defaultValue="17:30"
                                                                    type="time"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    inputProps={{
                                                                        step: 1800,
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </>
                                                    }

                                                    <Grid item xs={6}>
                                                        <SubTitleLight align="left">
                                                            {translate('friday')}
                                                        </SubTitleLight>
                                                    </Grid>
                                                    <Grid item xs={6} style={{display:"flex",justifyContent:"flex-end"}}>
                                                        <FormControl required component="fieldset" size="small">
                                                            <FormControlLabel size="small"
                                                                              style={{marginRight:0}}
                                                                              control={
                                                                                  <Checkbox
                                                                                      size="small"
                                                                                      checked={friday_off}
                                                                                      onChange={() => this.setState({friday_off: !friday_off})}
                                                                                  />
                                                                              }
                                                                              label={translate('off')}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    {
                                                        !friday_off &&
                                                        <>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    size="small"
                                                                    color="secondary"
                                                                    name="friday_start"
                                                                    label={translate('start_time')}
                                                                    value={fields.friday_start}
                                                                    onChange={this.handleChange.bind(this)}
                                                                    error={!!errors['friday_start']}
                                                                    helperText={errors['friday_start']}
                                                                    defaultValue="08:00"
                                                                    type="time"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    inputProps={{
                                                                        step: 1800,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    size="small"
                                                                    color="secondary"
                                                                    name="friday_end"
                                                                    label={translate('end_time')}
                                                                    value={fields.friday_end}
                                                                    onChange={this.handleChange.bind(this)}
                                                                    error={!!errors['friday_end']}
                                                                    helperText={errors['friday_end']}
                                                                    defaultValue="17:30"
                                                                    type="time"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    inputProps={{
                                                                        step: 1800,
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </>
                                                    }


                                                    <Grid item xs={6}>
                                                        <SubTitleLight align="left">
                                                            {translate('saturday')}
                                                        </SubTitleLight>
                                                    </Grid>
                                                    <Grid item xs={6} style={{display:"flex",justifyContent:"flex-end"}}>
                                                        <FormControl required component="fieldset" size="small">
                                                            <FormControlLabel size="small"
                                                                              style={{marginRight:0}}
                                                                              control={
                                                                                  <Checkbox
                                                                                      size="small"
                                                                                      checked={saturday_off}
                                                                                      onChange={() => this.setState({saturday_off: !saturday_off})}
                                                                                  />
                                                                              }
                                                                              label={translate('off')}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    {
                                                        !saturday_off &&
                                                        <>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    size="small"
                                                                    color="secondary"
                                                                    name="saturday_start"
                                                                    label={translate('start_time')}
                                                                    value={fields.saturday_start}
                                                                    onChange={this.handleChange.bind(this)}
                                                                    error={!!errors['saturday_start']}
                                                                    helperText={errors['saturday_start']}
                                                                    defaultValue="08:00"
                                                                    type="time"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    inputProps={{
                                                                        step: 1800,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    size="small"
                                                                    color="secondary"
                                                                    name="saturday_end"
                                                                    label={translate('end_time')}
                                                                    value={fields.saturday_end}
                                                                    onChange={this.handleChange.bind(this)}
                                                                    error={!!errors['saturday_end']}
                                                                    helperText={errors['saturday_end']}
                                                                    defaultValue="17:30"
                                                                    type="time"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    inputProps={{
                                                                        step: 1800,
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </>
                                                    }

                                                    <Grid item xs={6}>
                                                        <SubTitleLight align="left">
                                                            {translate('sunday')}
                                                        </SubTitleLight>
                                                    </Grid>
                                                    <Grid item xs={6} style={{display:"flex",justifyContent:"flex-end"}}>
                                                        <FormControl required component="fieldset" size="small">
                                                            <FormControlLabel size="small"
                                                                              style={{marginRight:0}}
                                                                              control={
                                                                                  <Checkbox
                                                                                      size="small"
                                                                                      checked={sunday_off}
                                                                                      onChange={() => this.setState({sunday_off: !sunday_off})}
                                                                                  />
                                                                              }
                                                                              label={translate('off')}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    {
                                                        !sunday_off &&
                                                        <>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    size="small"
                                                                    color="secondary"
                                                                    name="sunday_start"
                                                                    label={translate('start_time')}
                                                                    value={fields.sunday_start}
                                                                    onChange={this.handleChange.bind(this)}
                                                                    error={!!errors['sunday_start']}
                                                                    helperText={errors['sunday_start']}
                                                                    defaultValue="08:00"
                                                                    type="time"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    inputProps={{
                                                                        step: 1800,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    size="small"
                                                                    color="secondary"
                                                                    name="sunday_end"
                                                                    label={translate('end_time')}
                                                                    value={fields.sunday_end}
                                                                    onChange={this.handleChange.bind(this)}
                                                                    error={!!errors['sunday_end']}
                                                                    helperText={errors['sunday_end']}
                                                                    defaultValue="17:30"
                                                                    type="time"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    inputProps={{
                                                                        step: 1800,
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </>
                                                    }

                                                </Grid>
                                        }
                                    </AppCard>
                                </Grid>
                                <Grid item xs={12} >
                                    <AppCard style={{height:"100%"}}>
                                        {
                                            this.state.loading ? <AppCardLoaderFields/> :
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <SubTitle align='left'>
                                                            {translate('companyAddress')}
                                                        </SubTitle>
                                                    </Grid>
                                                    {
                                                        !!server_country ?
                                                            <>
                                                                <Grid item xs={12}>
                                                                    <Typography>
                                                                        {server_country.name} / {server_state.name}
                                                                    </Typography>
                                                                </Grid>
                                                            </>
                                                            :
                                                            <>
                                                                <Grid item xs={12} md={6}>
                                                                    <CountryChooser
                                                                        error={errors['country']}
                                                                        handleCountry={(country) => this.setState({country})}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} md={6}>
                                                                    <StateChooser
                                                                        country={country}
                                                                        error={errors['state']}
                                                                        handleState={(state) => this.setState({state})}
                                                                    />
                                                                </Grid>
                                                            </>
                                                    }
                                                    <Grid item xs={12}>
                                                        <SelectLocationFromMap
                                                            init_geocode={(!!company_lat && !!company_lng) ? {lat:company_lat,lng:company_lng} : ""}
                                                            geocode={(lat,lng) => this.setState({company_lat:lat,company_lng:lng})}
                                                            setAddress={(address) => this.handleCompanyAddress(address)}
                                                            country={country}
                                                            state={this.state.state}
                                                        />
                                                        {
                                                            !!errors["geocode"] &&
                                                            <Alert severity="error">
                                                                <Typography className={this.classes.alertText}>
                                                                    {errors["geocode"]}
                                                                </Typography>
                                                            </Alert>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            variant="outlined"
                                                            multiline
                                                            fullWidth
                                                            color="secondary"
                                                            name="company_address"
                                                            label={translate('company_address')}
                                                            type="text"
                                                            minRows="2"
                                                            value={fields.company_address}
                                                            onChange={this.handleChange.bind(this)}
                                                            error={!!errors['company_address']}
                                                            helperText={errors['company_address']}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <FieldCounterWithoutBar length={fields.company_address.length} max={150}/>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                        }
                                    </AppCard>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <AppCard style={{height:"100%"}}>
                                        {
                                            this.state.loading ? <AppCardLoaderFields/> :
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <SubTitle align="left">{translate('contactPersonAvatar')}</SubTitle>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <SingleImageChooser
                                                            primary
                                                            logo
                                                            large
                                                            onChange={(avatar) => this.handleAvatarImage(avatar)}
                                                            initial={this.state.contact_person_avatar}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            name="contact_person_name"
                                                            variant="outlined"
                                                            label={translate('contact_person_name')}
                                                            type="text"
                                                            color="secondary"
                                                            value={fields.contact_person_name}
                                                            onChange={this.handleChange.bind(this)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            name="contact_person_position"
                                                            variant="outlined"
                                                            label={translate('contact_person_position')}
                                                            type="text"
                                                            color="secondary"
                                                            value={fields.contact_person_position}
                                                            onChange={this.handleChange.bind(this)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                        }
                                    </AppCard>
                                </Grid>
                                <Grid item xs={12} lg={9}>
                                    <AppCard>
                                        {
                                            this.state.loading ? <AppCardLoaderFields/> :
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <SubTitle align="left">{translate('businessContactInfo')}</SubTitle>
                                                    </Grid>

                                                    <Grid item xs={12} md={6}>
                                                        <MuiPhoneNumber
                                                            fullWidth
                                                            label={translate('phoneNumber')}
                                                            name="phone"
                                                            dir="ltr"
                                                            variant="outlined"
                                                            color="secondary"
                                                            disabled={!hasAccess('has_phone')}
                                                            onChange={(phone) => this.handleChangePhone(phone,"phone")}
                                                            disableAreaCodes
                                                            defaultCountry={!!country ? country.iso2.toLowerCase() : 'ae'}
                                                            value={fields.phone}
                                                            error = {!!errors['phone']}
                                                            helperText={errors['phone']}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <MuiPhoneNumber
                                                            fullWidth
                                                            label={translate('mobileNumber')}
                                                            name="mobile"
                                                            color="secondary"
                                                            dir="ltr"
                                                            variant="outlined"
                                                            disabled={!hasAccess('has_phone')}
                                                            onChange={(phone) => this.handleChangePhone(phone,"mobile")}
                                                            disableAreaCodes
                                                            defaultCountry={!!country ? country.iso2.toLowerCase() : 'ae'}
                                                            value={fields.mobile}
                                                            error = {!!errors['mobile']}
                                                            helperText={errors['mobile']}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <MuiPhoneNumber
                                                            fullWidth
                                                            label={translate('whatsapp')}
                                                            name="mobile"
                                                            dir="ltr"
                                                            variant="outlined"
                                                            color="secondary"
                                                            disabled={!hasAccess('has_phone')}
                                                            onChange={(phone) => this.handleChangePhone(phone,"whatsapp")}
                                                            disableAreaCodes
                                                            defaultCountry={!!country ? country.iso2.toLowerCase() : 'ae'}
                                                            value={fields.whatsapp}
                                                            error = {!!errors['whatsapp']}
                                                            helperText={errors['whatsapp']}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            fullWidth
                                                            variant="outlined"
                                                            color="secondary"
                                                            label={translate('email')}
                                                            name="email"
                                                            dir="ltr"
                                                            disabled={!hasAccess('has_phone')}
                                                            value={fields.email}
                                                            onChange={this.handleChange.bind(this)}
                                                            error = {!!errors['email']}
                                                            helperText={errors['email']}
                                                        />
                                                    </Grid>
                                                    {
                                                        !hasAccess("has_phone") &&
                                                        <Grid item xs={12}>
                                                            <YourPlanDoesntSupportThis visible={!hasAccess('has_phone')}/>
                                                        </Grid>
                                                    }
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            fullWidth
                                                            name="website"
                                                            color="secondary"
                                                            variant="outlined"
                                                            label={translate('websiteLink')}
                                                            placeholder={translate('websitePlaceHolder')}
                                                            type="url"
                                                            dir="ltr"
                                                            disabled={!hasAccess('has_link')}
                                                            value={fields.website}
                                                            onChange={this.handleChange.bind(this)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            fullWidth
                                                            name="facebook"
                                                            variant="outlined"
                                                            color="secondary"
                                                            label={translate('facebookLink')}
                                                            placeholder={translate('facebookPlaceHolder')}
                                                            type="url"
                                                            dir="ltr"
                                                            disabled={!hasAccess('has_link')}
                                                            value={fields.facebook}
                                                            onChange={this.handleChange.bind(this)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            fullWidth
                                                            color="secondary"
                                                            name="telegram"
                                                            variant="outlined"
                                                            label={translate('telegramLink')}
                                                            placeholder={translate('telegramPlaceHolder')}
                                                            type="url"
                                                            dir="ltr"
                                                            disabled={!hasAccess('has_link')}
                                                            value={fields.telegram}
                                                            onChange={this.handleChange.bind(this)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            fullWidth
                                                            name="instagram"
                                                            variant="outlined"
                                                            color="secondary"
                                                            label={translate('instagramLink')}
                                                            placeholder={translate('instagramPlaceHolder')}
                                                            type="url"
                                                            dir="ltr"
                                                            disabled={!hasAccess('has_link')}
                                                            value={fields.instagram}
                                                            onChange={this.handleChange.bind(this)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            fullWidth
                                                            name="twitter"
                                                            color="secondary"
                                                            variant="outlined"
                                                            label={translate('twitterLink')}
                                                            placeholder={translate('twitterPlaceHolder')}
                                                            type="url"
                                                            dir="ltr"
                                                            disabled={!hasAccess('has_link')}
                                                            value={fields.twitter}
                                                            onChange={this.handleChange.bind(this)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            fullWidth
                                                            name="linkedin"
                                                            color="secondary"
                                                            variant="outlined"
                                                            label={translate('linkedinLink')}
                                                            placeholder={translate('linkedinPlaceHolder')}
                                                            type="url"
                                                            dir="ltr"
                                                            disabled={!hasAccess('has_link')}
                                                            value={fields.linkedin}
                                                            onChange={this.handleChange.bind(this)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            fullWidth
                                                            name="youtube"
                                                            color="secondary"
                                                            variant="outlined"
                                                            label={translate('youtubeChanel')}
                                                            placeholder={translate('youtubePlaceHolder')}
                                                            type="url"
                                                            dir="ltr"
                                                            disabled={!hasAccess('has_link')}
                                                            value={fields.youtube}
                                                            onChange={this.handleChange.bind(this)}
                                                        />
                                                    </Grid>
                                                    {
                                                        !hasAccess("has_link") &&
                                                        <Grid item xs={12}>
                                                            <YourPlanDoesntSupportThis visible={!hasAccess('has_link')}/>
                                                        </Grid>
                                                    }
                                                </Grid>
                                        }
                                    </AppCard>
                                </Grid>
                                <Grid item xs={12}>
                                    <AppCard>
                                        <SubTitle align="lefts">
                                            {translate('certificates')}
                                        </SubTitle>
                                        {
                                            !hasAccess("has_certificates") ?
                                                <YourPlanDoesntSupportThis visible={!hasAccess('has_certificates')}/>
                                                :
                                                <>
                                                    {
                                                        !loading ?
                                                            <Certificates owner_id={this.state.owner_id}/>
                                                            :
                                                            <AppCardLoaderFields/>
                                                    }
                                                </>
                                        }


                                    </AppCard>
                                </Grid>
                                <Grid item xs={12}>
                                    <AppCard>
                                        {
                                            !loading ?
                                                <MainProductsChooser owner_id={this.state.owner_id}/>
                                                :
                                                <AppCardLoaderFields/>
                                        }
                                    </AppCard>
                                </Grid>
                                {
                                    (!! user.role.permission.manageShops && !!editMode && !this.state.loading) &&
                                    <Grid item xs={12}>
                                        <UserCard title={translate('creator')} id={owner.id}/>
                                    </Grid>
                                }
                                {
                                    (user.role.type === "admin" && !loading) &&
                                    <Grid item xs={12}>
                                        <Alert
                                            severity="info"
                                            action={
                                                <FormControlLabel
                                                    style={{marginRight:10}}
                                                    control={
                                                        <Switch
                                                            checked={this.state.allowed_comment}
                                                            onChange={() => this.setState({allowed_comment: parseInt(this.state.allowed_comment === 1 ? 0 : 1)})}
                                                            value={this.state.allowed_comment}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={this.state.allowed_comment ? translate('allowed') : translate('not_allowed')}
                                                />
                                            }
                                        >
                                            {translate('allowed_comments')}
                                        </Alert>
                                    </Grid>
                                }
                                {
                                    (!!user.role.permission.manageShops  && !editMode) &&
                                    <Grid item xs={12}>
                                        <AppCard style={{height:'100%'}}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <SubTitle align="left">{translate('relatedUser')}</SubTitle>
                                                </Grid>
                                                {
                                                    !!this.state.related_user &&
                                                    <Grid item xs={12}>
                                                        {this.state.related_user.first_name} {this.state.related_user.last_name}
                                                    </Grid>
                                                }
                                                {
                                                    this.state.loading ? <AppCardLoaderFields/> :
                                                        <Grid item xs={12}>
                                                            <BusinessUserChooser
                                                                outPut={(res) => this.handleRelateduser(res)}
                                                            />
                                                        </Grid>
                                                }
                                            </Grid>
                                        </AppCard>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <LoadingButton
                                        variant="contained"
                                        color="primary"
                                        style={{minWidth:200}}
                                        onClick = {this.handleSubmit.bind(this)}
                                        loading = {SubmitLoading}
                                    >
                                        {editMode ? translate('editBusiness') :  translate('makeBusiness')}
                                    </LoadingButton>
                                </Grid>
                                <Grid item xs={12}>
                                    {
                                        !!editMode ?
                                            !!user.role.permission.manageAdvertisements &&
                                            <GetConfirmationText
                                                variant="outlined"
                                                style={{color: "#f44336",backgroundColor:"#fff"}}
                                                tooltip={translate('removeBusiness')}
                                                handleAction={() => this.handleDeleteItem()}
                                            >
                                                {translate('removeBusiness')}
                                            </GetConfirmationText>
                                            : null
                                    }
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>

                }
            </AppAnimate>

        );
    }
}

const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,
    locale: state.locale,
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MakeBusiness))));