import React from 'react';
import Typography from '@material-ui/core/Typography';
import withWidth from "@material-ui/core/withWidth/withWidth";
import {withStyles} from "@material-ui/core/styles";
import {Styles} from "../../../config/design/styles";
import {withRouter} from "react-router-dom";
import {connect} from "trim-redux";

function SubTitle(props) {
    return (
        <>
            <Typography style={props.style} component="h3" variant="subtitle1" className="subheading" color={props.color} align={props.align ? props.align : "center"}>
                {props.children} {props.required ? " *" : null}
                {
                    !props.noHR &&
                    <div style={{marginBottom:25}} className={props.classes.horizontalHR}>
                        <hr/>
                    </div>
                }
            </Typography>

        </>

    );
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    global_loading:state.global_loading,
    logo:state.logo
});
export default withWidth()(withStyles(Styles)(withRouter(connect(mstp)(SubTitle))));
