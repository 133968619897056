import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from '../../../../../config/Translate';
import validator from 'validator';
import axios from 'axios';
import { defaultVariabls } from '../../../../../config/index';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import LoadingButton from "../../../../components/elements/LoadingButton";
import {connect} from "trim-redux";
import AppCardLoaderFields from "../../../../components/Skeletons/AppCardLoaderFields";
import {secret} from "../../../../../config/Security";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import {rolesWithName} from '../../../../../config/Permissions'
import MuiPhoneNumber from "material-ui-phone-number";
import {translate} from "../../../../../config/lang";
import AppCard from "../../../../components/layouts/components/AppCard";
import SubTitleLight from "../../../../components/elements/SubTitleLight";
import SubTitle from "../../../../components/elements/SubTitle";
import Divider from "@material-ui/core/Divider/Divider";
import Typography from "@material-ui/core/Typography";
import {getMonths} from "../../../../../config/values";
class PlanChooser extends React.Component {
    classes = this.props.classes
    state={
        plans:[],
        loading:true,
        plan:null,
    }
    componentDidMount(){
        this.loadData();
    }
    loadData(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.get(`${defaultVariabls.url}/plans/getAll`, config)
            .then(response => {
                console.log(response.data)
                this.setState({
                    plans:response.data,

                })
            })
            .catch(error => {
                console.log(error.response)
                this.setState({
                    loading:false
                })
            });
    }
    handleChangePlan(e){
        this.setState({
            plan :e.target.value
        })
        this.props.handlePlan(e.target.value)
    }
    render() {
        const {plan,plans} = this.state;
        return (
            <>
                <TextField
                    select
                    label={translate('choosePlan')}
                    value={plan}
                    onChange={(e) => this.handleChangePlan(e)}
                    fullWidth
                    variant="outlined"
                >
                    {
                        plans.map((plan,index) =>(
                            <MenuItem key={index} value={plan.id}>
                                {plan.parent.name} ({!!plan.duration ? plan.duration >= 30 ? getMonths(plan.duration)+" Months" : plan.duration+ " Days" : ''} )
                            </MenuItem>
                        ))
                    }
                </TextField>
            </>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    locale: state.locale,
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(PlanChooser))));