import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import withWidth from "@material-ui/core/withWidth/withWidth";
import {connect, setStore} from "trim-redux";
import Grid from "@material-ui/core/Grid";
import BusinessTypeChooser from "../../businesses/components/BusinessTypeChooser";
import axios from "axios";
import {defaultVariabls} from "../../../../config";
import AppCardLoaderFields from "../../../components/Skeletons/AppCardLoaderFields";
import AddFieldButton from "./components/AddFieldButton";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import RootRef from "@material-ui/core/RootRef";
import {List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, MenuItem} from "@material-ui/core";
import DragIndicatorRoundedIcon from "@material-ui/icons/DragIndicatorRounded";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";

import Typography from "@material-ui/core/Typography/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import CustomInputIcons from "./components/CustomInputIcons";
import {translate} from "../../../../config/lang";
import SubTitle from "../../../components/elements/SubTitle";
import Title from "../../../components/elements/Title";
import Divider from "@material-ui/core/Divider/Divider";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};
const getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,

    ...(isDragging && {
        background: "rgb(235,235,235)",

    })
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#fbfbfb' : null,
    borderRadius:10
});

class BusinessFields extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type:'all',
            position_type:'trade_capacity',
            loading:true,
            fields:[],
            dragLoading: false,
        };
        this.onDragEnd = this.onDragEnd.bind(this);
        this.containerRef = React.createRef()

    }

    componentDidMount() {
        this.loadData();
    }
    loadData(){
        let {type,position_type} = this.state;
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        if(!!type){
            if(!!type.id){
                type = type.id
            }
        }else{
            type = "all";
            this.setState({
                type:"all"
            })
        }
        axios.get(`${defaultVariabls.url}/fields/getAllByType/${type}/${position_type}`, config)
            .then(response => {
                this.setState({
                    fields:response.data,
                    loading:false,
                })
            })
            .catch(error => {
                console.log(error.response)
                this.setState({
                    loading:false
                })
            });
    }
    reload(){
        this.setState({
            loading:true,
        })
        this.loadData();
    }
    async onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        await  this.setState({
            dragLoading:true
        })

        let source = this.state.fields[result.source.index];
        let destination = this.state.fields[result.destination.index];
        const fields = reorder(
            this.state.fields,
            result.source.index,
            result.destination.index
        );
        this.setState({
            fields
        });
        let loginToken = localStorage.getItem('ACT');
        let data = new FormData();
        data.append('source', source.id);
        data.append('destination', destination.id);
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.post(`${defaultVariabls.url}/fields/changeOrder`,data, config)
                .then(response => {
                    this.setState({
                        dragLoading:false
                    });
                }).catch(error => {
                    console.log(error.response)
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }

    }
    async handleChangeType(type){
        if(!!type){
            await this.setState({
                type
            })
        }else{
            await this.setState({
                type:"all"
            })
        }

        await this.reload();
    }
    async handleChangePosition(e){
        await this.setState({
            position_type:e.target.value
        })
        await this.reload();
    }
    render(){
        let{loading,fields,type,position_type} = this.state;
        return (
            <>
                {
                    loading ? <AppCardLoaderFields/> :
                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                            <Grid item xs={12} lg={11} style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                                <SubTitle noHR align="left">
                                    {translate("businessFields")}
                                </SubTitle>
                                <AddFieldButton
                                    reload={() => this.reload()}
                                    type={type}
                                    position_type={position_type}
                                />
                            </Grid>
                            <Grid item xs={12} lg={11}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography style={{marginBottom:5}}>
                                            {translate('pleaseChooseABusinessTypeForFields')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <BusinessTypeChooser
                                            size="small"
                                            handleType={(type) => this.handleChangeType(type)}
                                            selected={type}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            name="position_type"
                                            color="secondary"
                                            label={translate('position_type')}
                                            type="text"
                                            value={position_type}
                                            onChange={(e) => this.handleChangePosition(e)}
                                            select
                                        >
                                            <MenuItem value={'trade_capacity'}>
                                                {translate('trade_capacity')}
                                            </MenuItem>
                                            <MenuItem value={'product_capacity'}>
                                                {translate('product_capacity')}
                                            </MenuItem>

                                        </TextField>
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid item xs={12} lg={11} style={{marginTop:10}}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={12} lg={11} style={{marginTop:10,paddingBottom:0,marginBottom:0}}>
                                <SubTitle align="left" >
                                    {type === "all" ? translate("globalFields") : type.name+" "+translate("fields")}
                                    {" "}
                                    <span style={{fontSize:14,opacity:0.8}}>({translate(this.state.position_type)})</span>
                                </SubTitle>
                                {
                                    this.state.dragLoading ?
                                        <LinearProgress />
                                        : null
                                }
                            </Grid>
                            <Grid item xs={12} lg={11} style={{paddingTop:0}}>
                                <DragDropContext onDragEnd={this.onDragEnd}>
                                    <Droppable droppableId="droppable">
                                        {(provided, snapshot) => (
                                            <RootRef rootRef={provided.innerRef}>
                                                <List style={getListStyle(snapshot.isDraggingOver)} className="drag-container">
                                                    {fields.map((item, index) => (
                                                        <Draggable key={"item-"+item.id} draggableId={"item-"+item.id} index={index}>
                                                            {(provided, snapshot) => (
                                                                <ListItem
                                                                    ContainerComponent="li"
                                                                    ContainerProps={{ ref: provided.innerRef }}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style
                                                                    )}
                                                                >
                                                                    <ListItemIcon>
                                                                        <DragIndicatorRoundedIcon />
                                                                    </ListItemIcon>
                                                                    <ListItemText>
                                                                        <Typography style={{marginBottom:8}}>{item.unique_key}</Typography>
                                                                        <TextField
                                                                            required={!!parseInt(item.required)}
                                                                            fullWidth
                                                                            size="small"
                                                                            disabled
                                                                            variant="outlined"
                                                                            label={item.name}
                                                                            value={(item.field_type === "select" || item.field_type === "multi_select") ? item.options : ''}
                                                                            multiline={item.field_type === "long_text"}
                                                                            minRows={3}
                                                                            InputProps={{
                                                                                endAdornment:(
                                                                                    <InputAdornment position="end">
                                                                                        <CustomInputIcons
                                                                                            reload={() => this.reload()}
                                                                                            item={item}
                                                                                        />
                                                                                    </InputAdornment>
                                                                                )
                                                                            }}
                                                                            helperText={item.description}
                                                                        />
                                                                    </ListItemText>
                                                                    <ListItemSecondaryAction>

                                                                    </ListItemSecondaryAction>
                                                                </ListItem>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </List>
                                            </RootRef>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </Grid>
                        </Grid>
                }
            </>
        );
    }
}
const mstp = state => ({
    user: state.user,
    features: state.features,
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(BusinessFields)))));
