import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import BreadCrumbs from "../../../components/elements/BreadCrumbs";
import AppCard from "../../../components/layouts/components/AppCard";
import AppAnimate from "../../../components/AppAnimate";
import AddUser from "./components/AddUser";
import {translate} from "../../../../config/lang";

class AddUserPage extends React.Component {
    render() {
        return(
            <AppAnimate animation='transition.slideUpIn' delay={200} title={translate('addUser')}>
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12} lg={11}>
                        <AppCard
                            title={translate('addUser')}
                            action={
                                <BreadCrumbs singleLevel firstText={translate('addUser')}/>
                            }>
                            <AddUser/>
                        </AppCard>
                    </Grid>
                </Grid>
            </AppAnimate>
        )
    }
}
export default withStyles(Styles)(AddUserPage);