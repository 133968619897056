import React from 'react';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import axios from "axios";
import {defaultVariabls} from "../../../../../config/index";
import {connect, setStore} from "trim-redux";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import withWidth from "@material-ui/core/withWidth/withWidth";
import AppCardLoaderFields from "../../../../components/Skeletons/AppCardLoaderFields";
import Grid from "@material-ui/core/Grid/Grid";
import BannerItem from "./BannerItem";

class BannersManager extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    state = {
        loading:true,
        items:[],
    };
    componentDidMount(){
        this.loadData()
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/banners/getAll/${this.props.locale}`, config)
                .then(response => {
                    this.setState({
                        items:response.data,
                        loading:false
                    })

                }).catch(error => {
                    console.log(error.response)
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }
    }

    render(){
        let {loading,items} = this.state;
        return (
            <>
                {
                    loading ? <AppCardLoaderFields/> :
                        <Grid container spacing={2} alignItems="center">
                            {
                                items.map((item,index) => (
                                    <Grid
                                        key={index}
                                        item xs={12}
                                        md={item.position === "home_page_top_banner" ? 12 : item.position === "categories_banner" ? 4 : item.type === "banner" ? 4 : 4}
                                        lg={item.position === "home_page_top_banner" ? 12 : item.position === "categories_banner" ? 3 : item.type === "banner" ? 3 : 3}
                                    >
                                        <BannerItem item={item} reload={() => this.loadData()}/>
                                    </Grid>
                                ))
                            }

                        </Grid>
                }
            </>
        );
    }
}

const mstp = state => ({
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(BannersManager)))));