import React from 'react';
import Grid from "@material-ui/core/Grid/Grid";
import Skeleton from "@material-ui/lab/Skeleton/Skeleton";

export default function AppCardLoaderTable() {
    return (
        <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item xs={6}>
                <Skeleton style={{borderRadius:7}} variant="text"  width="50%"  height={50}/>
            </Grid>
            <Grid item xs={6}>
                <Skeleton style={{borderRadius:7}} variant="text"  width="100%"  height={50}/>
            </Grid>
            <Grid item xs={12}>
                <Skeleton style={{borderRadius:7}} variant="rect" height={500} width="100%"/>
            </Grid>
        </Grid>
    );
}