import React from 'react';
import {connect, setStore} from "trim-redux";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../../config/design/styles";
import {defaultVariabls} from "../../../../../config";
import Typography from "@material-ui/core/Typography/Typography";
import axios from "axios";
import {Translate} from "../../../../../config/Translate";
import {LoadSetting} from "../../../../../config/Security";

class TemplatePartChooser extends React.Component{

    state = {
        loading: false,
    };
    handleChangeSetting(type,name){
        setStore({
            global_loading:true,
        })
        let data = new FormData();
        data.append('type', type);
        data.append('name', name);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/setting/change`, data, config)
            .then(async response => {
                await LoadSetting();
                await this.props.enqueueSnackbar( Translate.templateUpdated, {
                    variant: 'success',
                });
                await setStore({
                    global_loading:false,
                })
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar( Translate.errorHappens, {
                    variant: 'error',
                });
                setStore({
                    global_loading:false,
                })

            });
    }
    render(){
        let {loading} = this.state;
        let {item,type,setting} = this.props;
        let current = setting[type];
        return (
            <div
                className={current === item.name ? "header-part-chooser active" : "header-part-chooser"}
                onClick={() => this.handleChangeSetting(type,item.name)}
            >
                <img src={`${defaultVariabls.uploads}/FileManager/${item.image.name}.${item.image.extension}`} />
                <Typography>{item.pretty_name}</Typography>
            </div>
        )
    }
}
const mstp = state => ({
    setting: state.setting,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(TemplatePartChooser)))));