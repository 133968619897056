import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { defaultVariabls } from '../../../../config/index';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import LoadingButton from "../../../components/elements/LoadingButton";
import {connect, getStore, setStore} from "trim-redux";
import AppCardLoaderFields from "../../../components/Skeletons/AppCardLoaderFields";
import {updateRedux} from "../../../../config/Security";
import Typography from "@material-ui/core/Typography/Typography";
import {translate} from "../../../../config/lang";
import AppCard from "../../../components/layouts/components/AppCard";
import AppAnimate from "../../../components/AppAnimate";
import SingleImageChooser from "../../fileManager/Components/SingleImageChooser";
import SubTitleLight from "../../../components/elements/SubTitleLight";
import Alert from "@material-ui/lab/Alert/Alert";
import UserDocumentsTable from "./UserDocumentsTable";
import DocumentChooser from "../../../components/fields/DocumentChooser";
import Divider from "@material-ui/core/Divider/Divider";
class details extends React.Component {
    classes = this.props.classes
    state={
        user_documents:[],
        loading:true,
        SubmitLoading:false,
        faults:'',

    };
    componentDidMount(){
        this.loadData();
    }
    loadData(){
        this.setState({
            loading:true
        })
        let loginToken = localStorage.getItem('ACT');
        let {manage,user_id} = this.props;
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.get(`${defaultVariabls.url}/user/get/${manage ? user_id : this.props.user.id}`, config)
            .then(response => {
                this.setState({
                    loading:false,
                    user_documents: response.data.user_documents,
                    faults: response.data.faults,

                })
            })
            .catch(error => {
                this.setState({
                    loading:false
                })
            });
    }
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        errors[target.name] = "";
        fields[target.name] = target.value;
        this.setState({
            fields,
        });
    }
    handleValidation(callback){
        let { user_documents } = this.state;
        let {user} = this.props;
        let errors = {};
        let formIsValid = true;
        if(user_documents.length < 3){
            formIsValid = false;
        }
        if(!formIsValid){
            this.props.enqueueSnackbar(translate('someFieldsHasError'), {
                variant: 'warning',
            });
            this.setState({
                SubmitLoading:false,
                errors
            })
            console.log(errors);
        }
        return callback(formIsValid)
    }
    handleRequest(){
        let data = new FormData();

        let loginToken = localStorage.getItem('ACT');
        let {manage,user_id} = this.props;
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/user_business/set_file/${manage ? user_id : this.props.user.id}`, data, config)
            .then(async response => {
                this.props.enqueueSnackbar(translate('detailsSet'), {
                    variant: 'success',
                });
                this.setState({
                    SubmitLoading:false
                });
                await  updateRedux();
                await this.props.reload();
                await this.loadData();
            })
            .catch(error => {
                console.log(error.response);
                if(error.response.status === 422){
                    this.props.enqueueSnackbar(translate('anotherUserHasThisInfo'), {
                        variant: 'error',
                    });
                    this.setState({
                        loading:true,
                    })
                    this.loadData();
                }else{
                    this.props.enqueueSnackbar(translate('detailsSetFailed'), {
                        variant: 'error',
                    });
                }
                this.setState({
                    SubmitLoading:false
                });
            });
    }
    refreshStore(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            axios.get(`${defaultVariabls.url}/checkLogin`, config)
                .then(response => {
                    setStore({
                        isAuth : true,
                        user : response.data,
                    });

                }).catch(error => {
                setStore({
                    isAuth : false,
                    user : null,
                });
            })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        });
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    handleChangeConfirmed(){
        this.setState({
            confirmed: this.state.confirmed === "confirmed" ? "pending" : "confirmed",
        })
    }

    handleLogoImage(logo){
        this.setState({
            logo
        })
    }

    handleAddress(address){
        let {fields} = this.state;
        fields['address'] = address;
        this.setState({
            fields
        })

    }
    render() {
        const {errors,user_documents,SubmitLoading,logo,trade_license,loading,emirates_card} = this.state;
        let {purchase,user,manage,user_id,primary_color} = this.props;
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={translate('step3')}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} lg={11}>
                        <AppCard title={translate('step3')}>
                            {
                                loading ? <AppCardLoaderFields/> :
                                    <Grid container spacing={2} justifyContent="center">
                                        <Grid item xs={12} md={11} lg={10}>
                                            <Grid container  spacing={2} justifyContent="center" alignItems="flex-start">
                                                <CssBaseline/>
                                                {
                                                    !!this.state.faults &&
                                                    <Grid item xs={12}>
                                                        <Typography className={"faults small"}>
                                                            {this.state.faults}
                                                        </Typography>
                                                    </Grid>
                                                }
                                                <Grid item xs={12}>
                                                    <Alert severity="info" variant="outlined">
                                                        {translate('uploadAtLeast3items')}
                                                    </Alert>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <DocumentChooser
                                                        user_id={manage ? user_id : this.props.user.id}
                                                        reload={() => this.loadData()}
                                                    />
                                                </Grid>
                                                {
                                                    !!user_documents.length &&
                                                    <>
                                                        <Grid item xs={12} style={{marginTop:30}}>
                                                            <Divider/>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <UserDocumentsTable documents={user_documents} reload={() => this.loadData()}/>
                                                        </Grid>
                                                    </>
                                                }
                                                <Grid item xs={12}>
                                                    <LoadingButton
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={user_documents.length < 3}
                                                        onClick = {this.handleSubmit.bind(this)}
                                                        loading = {SubmitLoading}
                                                    >
                                                        {translate('saveAndContinue')}
                                                    </LoadingButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                            }
                        </AppCard>
                    </Grid>
                </Grid>
            </AppAnimate>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_color: state.primary_color
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(details))));