import axios from "axios";
import {defaultVariabls} from "./index";
import {getStore, setStore} from "trim-redux";

export const secret = 'o4rC4FpP8FyX5DOPasMX23hzFWoJNyMIZ5eAeCjE';
export const loginToken = localStorage.getItem('ACT');

export const passwordRegx = new RegExp("^(?=.*[a-z])(?=.*[0-9])(?=.{6,})");

export let updateRedux = async () => {
    let loginToken = localStorage.getItem('ACT');
    if(loginToken !== null){
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        await axios.get(`${defaultVariabls.url}/checkLogin`, config)
            .then(response => {
                setStore({
                    isAuth : true,
                    user : response.data,
                    umc : response.data.umc,
                });
            }).catch(error => {
                setStore({
                    isAuth : false,
                    user : null,
                });
            })
    }else {
        setStore({
            isAuth : false,
            user : null,
        });
    }
    return "done";
};
export const LoadSetting = async() => {
    let loginToken = localStorage.getItem('ACT');

    let config = {
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${loginToken}`

        }
    };
    await axios.get(`${defaultVariabls.url}/settings/get_all_for_user/${getStore('locale')}`, config)
        .then(response => {
            setStore({
                setting:response.data,
                primary_color:response.data.primary_color,
                secondary_color:response.data.secondary_color,
                logo:response.data.site_logo,
                app_name:response.data.app_name,
                x_price:response.data.x_price,
                primary_shop:response.data.primary_shop,
                global_need_verification:parseInt(response.data.global_need_verification),
                admins:response.data.admins,
                menu:response.data.menu,
                about:response.data.about,
                features:response.data.features,
            });
        }).catch(error => {
            console.log(error.response)
        })
}
export let beSupplier = async () => {
    let loginToken = localStorage.getItem('ACT');
    if(loginToken !== null){
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        await axios.get(`${defaultVariabls.url}/beSupplier`, config)
            .then(response => {

                updateRedux();
                window.open(`${defaultVariabls.React}`,'_self');

            }).catch(error => {
                console.log(error.response)
            })
    }else {
        setStore({
            isAuth : false,
            user : null,
        });
    }
    return "done";
};

export function checkMelliCode(code)
{

    let L=code.length;

    if(L<8 || parseInt(code,10)===0) return false;
    code=('0000'+code).substr(L+4-10);
    if(parseInt(code.substr(3,6),10)===0) return false;
    let c=parseInt(code.substr(9,1),10);
    let s=0;
    for(let i=0;i<9;i++)
        s+=parseInt(code.substr(i,1),10)*(10-i);
    s=s%11;
    return (s<2 && c===s) || (s>=2 && c===(11-s));

    return true;
}
export const getCurrentLocation = async () => {
    await navigator.geolocation.getCurrentPosition(async function(position) {
        await setStore({
            lat:position.coords.latitude,
            lng: position.coords.longitude
        })
    },function(error) {
        if (error.code === error.PERMISSION_DENIED)
            alert("You didn't give permission to access your location.");
    });
}
export function hasAccess(item) {
    let user = getStore('user')
    if(user.role.type === "admin"){
        return true
    }else{
        return user.plan[item] !== 0
    }
}
export const DEMO = false;

export const GoogleMapAPIKey = "AIzaSyClzAgRMIMgcYexLxWWuXTzo0TjgLjItsw";