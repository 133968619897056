import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import AppAnimate from "../../../components/AppAnimate";
import AppCard from "../../../components/layouts/components/AppCard";
import Grid from "@material-ui/core/Grid/Grid";
import {connect, setStore} from "trim-redux";
import BreadCrumbs from "../../../components/elements/BreadCrumbs";
import AppCardLoaderFields from "../../../components/Skeletons/AppCardLoaderFields";
import Typography from "@material-ui/core/Typography/Typography";
import {currencySymbol, price} from "../../../../config/values";
import SubTitle from "../../../components/elements/SubTitle";
import moment from "jalali-moment";
import SingleOrderItem from "../components/SingleOrderItem";
import Divider from "@material-ui/core/Divider/Divider";
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import Button from "@material-ui/core/Button/Button";
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';

class CheckOrder extends React.Component {

    state = {
        factor:null,
        SubmitLoading:false,
        id:this.props.match.params.id,
        loading:true
    };
    classes = this.props.classes;
    async componentDidMount(){
        await this.loadData();

    }
    componentDidUpdate(prevProps){
        if(this.state.id !== this.props.match.params.id){
            this.setState({
                id: this.props.match.params.id
            })
        }
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/factor/get_for_shop/${this.state.id}`, config)
                .then(response => {
                    this.setState({
                        factor:response.data,
                        loading:false
                    })

                }).catch(error => {
                    console.log(error.response)
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }
    }
    reload(){
        this.props.enqueueSnackbar(Translate.factorConfirmedToPurchase, {
            variant: 'success',
        });
        this.props.history.push(`/shop-orders`)

    }
    render(){
        let {factor} = this.state;
        let {user} = this.props;
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={Translate.viewOrder}>
                {

                    <Grid container spacing={2} justify="center">
                        <Grid item xs={12} md={11}>
                            <AppCard
                                title={Translate.viewOrder}
                                action={
                                    <BreadCrumbs  firstText={Translate.orders} firstLink="/shop-orders" secondText={Translate.viewOrder}/>
                                }>
                                {
                                    this.state.loading ?
                                        <AppCardLoaderFields/>
                                        :
                                        <Grid container spacing={2} justify="center" alignItems="center">
                                            <Grid item xs={12} style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                                                <SubTitle align="left">{Translate.orderDetails}</SubTitle>
                                                {
                                                    factor.orders[0].status === "payed_to_shop" &&
                                                    <Button
                                                        color="primary"
                                                        component={"a"}
                                                        href={`${defaultVariabls.website}/view/seller-invoice/${factor.number}/${user.shops[0].id}`}
                                                        target="_blank"
                                                        size="small"
                                                        variant="outlined"
                                                        startIcon={<ChevronLeftRoundedIcon/>}
                                                    >
                                                        {Translate.viewSellerInvoice}
                                                    </Button>
                                                }
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div className="order-items-holder">
                                                    <Typography>{Translate.order_status} <span className="result" style={{color: factor.status === "pending" ? "#f82e4e" : ''}}>{Translate[factor.orders[0].status]}</span> </Typography>
                                                    <Typography>
                                                        <FiberManualRecordRoundedIcon fontSize="small"/>
                                                    </Typography>
                                                    <Typography>{Translate.factor_number} <span className="result">{factor.number}</span></Typography>
                                                    <Typography>
                                                        <FiberManualRecordRoundedIcon fontSize="small"/>
                                                    </Typography>
                                                    <Typography>
                                                        {Translate.created_at_factor}
                                                        <span className="result">
                                                            {`${moment(factor.created_at).locale('fa').format( 'YYYY/M/D')}`}
                                                        </span>
                                                    </Typography>
                                                </div>
                                            </Grid>
                                            {/*<Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div className="order-items-holder mb-15">
                                                    <Typography>{Translate.user_delivery} <span className="result">{factor.user.first_name} {factor.user.last_name}</span></Typography>
                                                    <Typography>
                                                        <FiberManualRecordRoundedIcon fontSize="small"/>
                                                    </Typography>
                                                    <Typography>{Translate.mobileNumber} <span className="result">{factor.user.mobile}</span></Typography>
                                                </div>
                                                <div className="order-items-holder">
                                                    <Typography>{Translate.address} <span className="result">{factor.address.province} / {factor.address.city}، {factor.address.address}، {Translate.postal_code} {factor.address.postal_code}.  </span></Typography>
                                                </div>
                                            </Grid>*/}
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div className="order-items-holder">
                                                    <Typography>{Translate.priceName}<span className="result">{price(factor.orders[0].total_price_payed - factor.orders[0].commission)} {currencySymbol}</span></Typography>
                                                    <Typography>
                                                        <FiberManualRecordRoundedIcon fontSize="small"/>
                                                    </Typography>
                                                    <Typography>{Translate.discounts} <span className="result"> {price(factor.orders[0].total_discount_price)} {currencySymbol}</span></Typography>
                                                    <Typography>
                                                        <FiberManualRecordRoundedIcon fontSize="small"/>
                                                    </Typography>
                                                    <Typography>{Translate.site_commission} <span className="result"> {price(factor.orders[0].commission)} {currencySymbol}</span></Typography>

                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <SubTitle align="left">{Translate.productsList}</SubTitle>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {
                                                    factor.order_items.map((item,index) => (
                                                        <SingleOrderItem reload={() => this.reload()} factor_status={factor.status} key={index} item={item} no_shop/>
                                                    ))
                                                }
                                            </Grid>
                                        </Grid>
                                }
                            </AppCard>
                        </Grid>
                    </Grid>
                }
            </AppAnimate>

        );
    }
}
const mstp = state => ({
    user: state.user,
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(CheckOrder))));