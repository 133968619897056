import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import {connect} from "trim-redux";
import {getCurrentLocation, GoogleMapAPIKey} from "../../../config/Security";
import AutoComplete from "react-google-autocomplete";
import GoogleMapReact from "google-map-react";
import MapMarker from "./components/MapMarker";
import IconButton from "@material-ui/core/IconButton/IconButton";
import MyLocationTwoToneIcon from '@material-ui/icons/MyLocationTwoTone';
import Geocode from "react-geocode";
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import withTheme from "@material-ui/core/styles/withTheme";

class SelectLocationFromMap extends React.Component {
    state = {
        lng:this.props.lng,
        lat:this.props.lat,
        country:this.props.country,
        state:this.props.state,
        search:false,
        loading:false,
    };
    classes = this.props.classes;

    async componentDidMount(){
        await getCurrentLocation();
        if(!!this.props.init_geocode){
            await this.setState({
                lng:this.props.init_geocode.lng,
                lat:this.props.init_geocode.lat,
            })
        }else{
            await this.setState({
                lng:this.props.lng,
                lat:this.props.lat,
            })
        }
        if(!!this.props.country){
            if(!!this.props.country.latitude){
                await this.setState({
                    country:this.props.country,
                    lat:Number(this.props.country.latitude),
                    lng:Number(this.props.country.longitude),
                })
            }
        }
    }
    async componentDidUpdate(prevProps){
        if((this.props.country !== this.state.country) && !!this.props.country){
            if(!!this.props.country.latitude){
                await this.setState({
                    country:this.props.country,
                    lat:Number(this.props.country.latitude),
                    lng:Number(this.props.country.longitude),
                })
                if(!this.props.disabled) {
                    this.props.geocode(this.props.country.latitude, this.props.country.longitude)
                }
            }
        }
        if((this.props.state !== this.state.state) && !!this.props.state){
            if(!!this.props.state.latitude){
                await this.setState({
                    state:this.props.state,
                    lat:Number(this.props.state.latitude),
                    lng:Number(this.props.state.longitude),
                })
                if(!this.props.disabled) {
                    this.props.geocode(this.props.state.latitude, this.props.state.longitude)
                }
            }
        }
    }
    handleAddress(place){
        this.setState({
            lat:place.geometry.location.lat(),
            lng:place.geometry.location.lng(),
            search:false,
        })
        if(!this.props.disabled){
            this.props.geocode(place.geometry.location.lat(),place.geometry.location.lng())
            this.props.setAddress(place.formatted_address)
        }

    }
    async onClickMap(location){
        await this.setState({
            lat:location.lat,
            lng:location.lng,
            search:false,
        })
        await Geocode.setApiKey(GoogleMapAPIKey);
        await Geocode.fromLatLng(location.lat,location.lng).then(
            (response) => {
                const address = response.results[0].formatted_address;
                if(!this.props.disabled) {
                    this.props.geocode(location.lat, location.lng)
                    this.props.setAddress(address)
                }

            },
            (error) => {
                console.error(error);
            }
        );
    }
    async handleMyLocation(){
        await getCurrentLocation();
        await this.setState({
            lng:this.props.lng,
            lat:this.props.lat,
        })
        if(!this.props.disabled) {
            this.props.geocode(this.props.lat, this.props.lng)
        }

    }
    render(){
        let {lng,lat,search} = this.state;
        let {disabled} = this.props;
        return(
            <>
                <div style={{ height: '300px', width: '100%',position:"relative",borderRadius:4 }}>
                    {
                        !!search &&
                        <div style={{
                            position:"absolute",
                            top:0,
                            width:"100%",
                            right:0,
                            left:0,
                            backgroundColor:"rgba(255,255,255,0.5)",
                            zIndex:1001,
                            backdropFilter:"blur(5px)",
                            padding:15,
                            paddingBottom:0,
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"space-between"
                        }}>
                            <IconButton size="small" style={{marginBottom:12,marginRight:10}}>
                                <CloseRoundedIcon onClick={() => this.setState({search:false,})}/>
                            </IconButton>
                            <AutoComplete
                                apiKey={GoogleMapAPIKey}
                                onPlaceSelected={(place) => this.handleAddress(place)}
                                options={{
                                    types: '',
                                }}
                            />
                        </div>
                    }
                    <IconButton
                        style=
                            {{
                                position:"absolute",
                                zIndex:1000,
                                right: 10,
                                bottom: 115,
                                backgroundColor:this.props.theme.palette.background.paper,
                                borderRadius:2,
                                padding:8,
                                boxShadow:"rgba(0,0,0,.3) 0px 1px 4px -1px"
                            }}
                        onClick={()=> this.handleMyLocation()}
                    >
                        <MyLocationTwoToneIcon/>
                    </IconButton>
                    <IconButton
                        style=
                            {{
                                position:"absolute",
                                zIndex:1000,
                                left: 10,
                                top: 10,
                                backgroundColor:this.props.theme.palette.background.paper,
                                borderRadius:2,
                                padding:8,
                                boxShadow:"rgba(0,0,0,.3) 0px 1px 4px -1px"
                            }}
                        onClick={()=> this.setState({search:true})}
                    >
                        <SearchRoundedIcon/>
                    </IconButton>
                    {
                        !this.state.loading &&
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: GoogleMapAPIKey,libraries:['places'] }}
                            center={{
                                lat: lat,
                                lng: lng
                            }}
                            options={{ scrollwheel: true}}
                            defaultZoom={11}
                            onClick={(location) => this.onClickMap(location)}
                        >
                            {
                                !disabled &&
                                <MapMarker
                                    lat={lat}
                                    lng={lng}
                                />
                            }

                        </GoogleMapReact>
                    }
                </div>
            </>
        )
    }
}
const mstp = state => ({
    lng: state.lng,
    lat: state.lat,

});
export default withRouter(withSnackbar(withStyles(Styles)(withTheme(connect(mstp)(SelectLocationFromMap)))));
