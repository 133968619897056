import React, {Component} from 'react';
import TextField from "@material-ui/core/TextField/TextField";
import IconButton from "@material-ui/core/IconButton/IconButton";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../config/design/styles";
import {connect} from "trim-redux";
import SendIcon from '@material-ui/icons/Send';
import axios from "axios";
import {defaultVariabls} from "../../../../config";
import SendFileInChatButton from "./components/SendFileInChatButton";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import Grid from "@material-ui/core/Grid/Grid";
import {translate} from "../../../../config/lang";
import Hidden from "@material-ui/core/Hidden/Hidden";
import Button from "@material-ui/core/Button/Button";
import {Link} from "react-router-dom";
import ArrowRightAltRoundedIcon from "@material-ui/icons/ArrowRightAltRounded";
class ChatFooter extends Component {
    classes = this.props.classes;
    state = {
        message: '',
        loading:false,
        fileLoading:false,
        planLoading:false,
        show_emojies:false,
    };
    handleSendMessage(){
        let {message,loading} = this.state;
        if(message.length > 0 && !loading){
            this.setState({
                loading:true
            })
            let {user,receiver,chatList} = this.props;
            let data = new FormData();
            data.append('message', message);
            data.append('sender', user.id);
            data.append('receiver', receiver);
            data.append('ticket_list', chatList);
            data.append('type', "text");
            let loginToken = localStorage.getItem('ACT');
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            axios.post(`${defaultVariabls.url}/ticket/sendSingleTextMessage`, data, config)
                .then(response => {
                    this.setState({
                        message: '',
                        loading:false,
                    });

                    this.props.reload();
                })
                .catch(error => {
                    console.log(error.response);

                    this.props.enqueueSnackbar(translate('detailsSetFailed'), {
                        variant: 'error',
                    });
                    this.setState({
                        loading:false,
                        SubmitLoading:false
                    });
                });
        }
    }
    handleOpenEmojies(){
        this.setState({
            show_emojies: !this.state.show_emojies
        })
    }
    handleSetFile(id){
        let {loading} = this.state;
        if(id > 0 && !loading){
            this.setState({
                loading:true,
                fileLoading:true,
            })
            let {user,receiver,chatList} = this.props;
            let data = new FormData();
            data.append('file_id', id);
            data.append('sender', user.id);
            data.append('receiver', receiver);
            data.append('ticket_list', chatList);
            data.append('type', "file");
            let loginToken = localStorage.getItem('ACT');
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            axios.post(`${defaultVariabls.url}/ticket/sendSingleTextMessage`, data, config)
                .then(response => {
                    this.setState({
                        loading:false,
                        fileLoading:false,
                    })
                    this.props.reload();
                })
                .catch(error => {
                    console.log(error.response);
                    this.props.enqueueSnackbar(translate('detailsSetFailed'), {
                        variant: 'error',
                    });
                    this.setState({
                        loading:false,
                        SubmitLoading:false
                    });
                });
        }
    }
    handleSendPlan(id){
        let {loading} = this.state;
        if(id > 0 && !loading){
            this.setState({
                loading:true,
                planLoading:true,
            })
            let {user,receiver,chatList} = this.props;
            let data = new FormData();
            data.append('plan_id', id);
            data.append('sender', user.id);
            data.append('receiver', receiver);
            data.append('ticket_list', chatList);
            data.append('type', "plan");
            let loginToken = localStorage.getItem('ACT');
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            axios.post(`${defaultVariabls.url}/ticket/sendSingleTextMessage`, data, config)
                .then(response => {
                    this.setState({
                        loading:false,
                        planLoading:false,
                    })
                    this.props.reload();
                })
                .catch(error => {
                    console.log(error.response);
                    this.props.enqueueSnackbar(translate('detailsSetFailed'), {
                        variant: 'error',
                    });
                    this.setState({
                        loading:false,
                        SubmitLoading:false
                    });
                });
        }
    }
    addEmoji = emoji => {
        this.setState({
            message: this.state.message + emoji
        });
    };
    render() {
        let {fileLoading,planLoading,loading} = this.state;
        let {manage,type} = this.props;
        return (
            <Grid container spacing={1}>
            <Grid item xs={12}>
                <TextField
                    value={this.state.message}
                    style={{width: '100%'}}
                    variant='outlined'
                    multiline
                    maxRows={3}
                    minRows={1}
                    margin="dense"
                    color="secondary"
                    onChange={(e) => this.setState({message: e.target.value})}
                    disabled={this.props.loading || manage}
                    placeholder={translate('sendMessage')}
                    InputProps={{
                        /*startAdornment: (
                            <InputAdornment position="start">
                                <IconButton onClick={this.handleOpenEmojies.bind(this)}>
                                <EmojiEmotionsRoundedIcon />
                                </IconButton>
                            </InputAdornment>
                        ),*/
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    disabled={manage}
                                    onClick={this.handleSendMessage.bind(this)}
                                >
                                    {
                                        loading ?
                                            <CircularProgress size={20} color="secondary"/>
                                            :
                                            <SendIcon color="secondary"/>

                                    }
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}

                />
            </Grid>
                {/*{
                    !!this.state.show_emojies &&
                    <Grid item xs={12}>
                        <Emojies addEmoji={this.addEmoji.bind(this)}/>
                    </Grid>
                }*/}
                <Grid item xs={6}>
                    <SendFileInChatButton
                        sendFile={this.handleSetFile.bind(this)}
                        disabled={manage || fileLoading}
                    />
                </Grid>
                <Grid item xs={6} style={{textAlign:"right"}}>
                    <Hidden mdDown>
                        <Button
                            component={Link}
                            to={manage ? "/manage/tickets" : `/messages/${type === "system" ? "ticket" : type}`}
                            size="small"
                            variant="outlined"
                            endIcon={<ArrowRightAltRoundedIcon />}
                        >
                            {translate('backToTickets')}
                        </Button>
                    </Hidden>
                    <Hidden mdUp>
                        <Button
                            component={Link}
                            to={manage ? "/manage/tickets" : `/messages/${type === "system" ? "ticket" : type}`}
                            size="small"
                            variant="outlined"
                            endIcon={<ArrowRightAltRoundedIcon />}
                        >
                            {translate('back')}
                        </Button>
                    </Hidden>
                </Grid>

            </Grid>
        );
    }
}

const mstp = state => ({
    user: state.user,
    Translate: state.Translate,
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(ChatFooter))));
