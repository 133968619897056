
import React, {useState} from "react";
import TextField from "@material-ui/core/TextField/TextField";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import IconButton from "@material-ui/core/IconButton/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";

export default function PassField(props) {
    const [visible, setVisible] = useState(false);

    return (
        <>
            <TextField
                {...props}
                type={visible ? "text" : "password"}
                InputProps={{
                    endAdornment:(
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                edge="end"
                                onClick={() => setVisible(!visible)}
                                onMouseDown={() => setVisible(!visible)}
                            >
                                {visible ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                        )
                }}

            />
        </>
    );
}