import React from 'react';
import Button from '@material-ui/core/Button';
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../config/design/styles";
import {connect} from "trim-redux";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import Typography from "@material-ui/core/Typography/Typography";
import Grid from "@material-ui/core/Grid/Grid";
import {makeSlug, translate} from "../../../../config/lang";
import SubTitle from "../../../components/elements/SubTitle";

class CheckUserCanAdRFQ extends React.Component{
    state= {
        dialogOpen: false,
    };
    classes = this.props.classes;
    componentDidMount(){
        let {user,edit} = this.props;
        if(!edit){
            if(parseInt(user.plan.rfq_per_week) === parseInt(user.week_rfq)){
                this.setState({
                    dialogOpen:true,
                })
            }
        }
    }
    render(){
        let{dialogOpen} = this.state;
        let{edit} = this.props;
        return (
            <>
                <Dialog
                    open={dialogOpen}
                    onClose={() => this.setState({dialogOpen:false})}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth={'xs'}
                    fullWidth
                >
                    <DialogTitle id="alert-dialog-title" className={this.classes.alertText}>
                        <SubTitle >{translate('youCantAddRFQ')}</SubTitle>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography className={this.classes.textFloat}>{translate('planLimitationDescription')}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component="a"
                                    href={makeSlug(`plans`)}
                                >
                                    {translate('viewPricing')}
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(CheckUserCanAdRFQ))));