import React from 'react';
import Typography from '@material-ui/core/Typography';

export default function Title(props) {
    return (
        <Typography
            style={props.style}
            component={!!props.component ? props.component : "h2"}
            variant="h6"
            to={props.to}
            className="heading"
            color={props.color ? props.color : "primary"}
            align={props.align ? props.align : "center"}
            gutterBottom
        >
            {props.children}
        </Typography>
    );
}
