import Typography from "@material-ui/core/Typography/Typography";
import React from "react";
export default function FieldCounter(props) {
    return (
        <React.Fragment>
            <Typography
                style={{direction:"ltr",textAlign:"right"}}
                color={props.length > props.max ? "error" : "secondary"}
                variant="body2"
            >
                {props.length}<span className={props.length <= props.max ? "disabled-text" : null}>/{props.max}</span>
            </Typography>
        </React.Fragment>
    );
}