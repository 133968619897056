import React from 'react';
import {getStore} from "trim-redux";

const Feature = ({check,children}) => {
  let features = getStore('features');

  return (
    !!features[check] ? children : null
  );
};

export default Feature;
