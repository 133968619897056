import React from 'react';
import {Translate} from "../../../../../config/Translate";
import {connect, setStore} from "trim-redux";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../../config/design/styles";
import axios from "axios";
import {defaultVariabls} from "../../../../../config";
import {LoadSetting} from "../../../../../config/Security";
import ColorPicker from "../../../../components/fields/ColorPicker";
import {theme} from "../../../../../config/design/theme";

class SiteColorPicker extends React.Component{
    state = {
        primary_color: this.props.setting.primary_color,
        secondary_color: this.props.setting.secondary_color,

    };
    handleRequest(){
        this.setState({
            SubmitLoading: true,
        })
        let { code,type} = this.state;
        let data = new FormData();
        data.append('val', code);
        data.append('type', type);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/about/change`, data, config)
            .then(async response => {
                await LoadSetting();
                await this.setState({
                    loading:false,
                    SubmitLoading: false,
                    SubmitDraftLoading: false,
                    open:false,
                });
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(Translate.dataEditingFailed, {
                    variant: 'error',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                    SubmitDraftLoading: false
                });
            });

    }
    handleChangeSetting(type,name){
        setStore({
            global_loading:true,
        })
        let data = new FormData();
        data.append('type', type);
        data.append('name', name);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/setting/change`, data, config)
            .then(async response => {
                await LoadSetting();
                await this.props.enqueueSnackbar( Translate.templateUpdated, {
                    variant: 'success',
                });
                await setStore({
                    global_loading:false,
                })
                await theme();
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar( Translate.errorHappens, {
                    variant: 'error',
                });
                setStore({
                    global_loading:false,
                })

            });
    }
    render(){
        let {secondary_color,primary_color} = this.state;
        return (
            <>
                <ColorPicker
                    color={primary_color}
                    title={Translate.changePrimaryColor}
                    onChange={(color) => this.handleChangeSetting('primary_color',color)}
                />
                <ColorPicker
                    color={secondary_color}
                    title={Translate.changeSecondaryColor}
                    onChange={(color) => this.handleChangeSetting('secondary_color',color)}

                />
            </>
        );
    }
}
const mstp = state => ({
    setting: state.setting,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(SiteColorPicker)))));