import {fade, makeStyles} from '@material-ui/core/styles';
import Noor from "../../../../files/img/noor.png";
import {getStore} from "trim-redux";
const useStyles = makeStyles((theme) => ({
    appbar:{
        right:0,
        width:"100vw",
        backgroundColor:getStore('mode') === "dark" ? "#353535" : "#ffffff",
        backgroundImage:`url("${Noor}")`,
        backgroundSize:"cover",
        backgroundRepeat:"no-repeat",
        [theme.breakpoints.down('md')]: {
            left:0,
            width:"100%",
        },

    },
    appToolbar: {
        paddingLeft: 20,
        paddingRight: 20,
        minHeight: 56,
        [theme.breakpoints.up('sm')]: {
            minHeight: 78,
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: 30,
            paddingRight: 30,
        },
        color:"#fefefe",
        [theme.breakpoints.up('lg')]: {
            width:'96vw',
            marginLeft:'2vw'
        },
    },
    headerContainer:{
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingLeft: 20,
        paddingRight: 20,
        [theme.breakpoints.up('lg')]: {
            maxWidth: 1140,
        },
        [theme.breakpoints.up('xl')]: {
            maxWidth: 1720,
        },
    },
    headerNav: {
        color: theme.palette.primary.contrastText,
        '& .navbarNav': {
            display: 'flex',
            padding: 0,
            marginLeft: -16,
            marginRight: -16,
            [theme.breakpoints.up('lg')]: {
                marginLeft: -20,
                marginRight: -20,
            },
        },
        '& .navItem': {
            width: 'auto',
            cursor: 'pointer',
            [theme.breakpoints.up('lg')]: {
                paddingLeft: 20,
                paddingRight: 20,
            },
            '&.active': {
                color: '#000',
                '& .navLinkIcon': {
                    color: theme.palette.secondary.main,
                },
            },
        },
        '& .navLinkIcon': {
            marginRight: 10,
            color: theme.palette.primary.contrastText,
            fontSize: 20,
        },
    },
    grow: {
        flexGrow: 1,
    },
    menuIcon: {
        width: 36,
        height: 36,
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: 5,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    menuItemRoot: {
        padding: 0,
    },
    pointer: {
        cursor: 'pointer',
    },
    middleRoot: {
        verticalAlign: 'middle',
        display: 'inline-block',
    },
    LayoutLogo:{
        height:36,
        marginRight:10,
        [theme.breakpoints.down('md')]: {
            height:30,

        },
    },
    notiBtn: {
        justifyContent: 'flex-start',
        width: '100%',
        height: 56,
        fontSize: 18,
        borderRadius: 0,
        paddingLeft: '0.4rem',
        paddingRight: '0.4rem',
        [theme.breakpoints.up('sm')]: {
            height: 70,
        },
        [theme.breakpoints.up('md')]: {
            justifyContent: 'center',
            width: 'auto',
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: '1rem',
            paddingRight: '1rem',
        },
        [theme.breakpoints.up('xl')]: {
            paddingLeft: '1rem',
            paddingRight: '1rem',
        },
    },
    notiIcon: {
        fontSize: 28,
        [theme.breakpoints.up('xl')]: {
            fontSize: 22,
        },
    },
}));
export default useStyles;
