import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { defaultVariabls } from '../../../../../config/index';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import LoadingButton from "../../../../components/elements/LoadingButton";
import {connect} from "trim-redux";
import AppCardLoaderFields from "../../../../components/Skeletons/AppCardLoaderFields";
import {translate} from "../../../../../config/lang";
import AppCard from "../../../../components/layouts/components/AppCard";
import SubTitleLight from "../../../../components/elements/SubTitleLight";
import moment from "moment-timezone";
import SubTitle from "../../../../components/elements/SubTitle";
import Divider from "@material-ui/core/Divider/Divider";
import Typography from "@material-ui/core/Typography";
import {getMonths, getTimeZone} from "../../../../../config/values";
import PlanChooser from "./PlanChooser";
import List from "@material-ui/core/List/List";
import {ListItem} from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import UserSuspension from "./UserSuspension";
import FormControl from "@material-ui/core/FormControl/FormControl";
import {FormLabel} from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";

class GetPlanToUser extends React.Component {
    classes = this.props.classes
    state={
        plans:[],
        loading:true,
        SubmitLoading:false,
        user_id:this.props.user_id,
        user:null,
        plan_id:null,
        user_has_plan:false,
        reserve:"reserve",
    }
    componentDidMount(){
        this.loadData();
    }
    loadData(){
        let loginToken = localStorage.getItem('ACT');
        let {user_id} = this.props;
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.get(`${defaultVariabls.url}/user/get/${user_id}`, config)
            .then(response => {
                let user_has_plan = false;
                if(!!response.data.plan){
                    if(response.data.plan.parent.type === "premium"){
                        user_has_plan = true;
                    }

                }
                this.setState({
                    user_has_plan,
                    user:response.data,
                    loading:false

                })
            })
            .catch(error => {
                this.setState({
                    loading:false
                })
            });
    }

    handleRequest(){
        this.setState({
            SubmitLoading:true
        });
        const {plan_id,user_id,reserve} = this.state;
        let data = new FormData();
        data.append('user_id', user_id);
        data.append('plan_id', plan_id);
        data.append('reserve', reserve);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/user/setPlan`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(translate('detailsSet'), {
                    variant: 'success',
                });
                this.setState({
                    SubmitLoading:false
                });
                this.props.history.push(`/manage/users/view/all`);
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(translate('detailsSetFailed'), {
                    variant: 'error',
                });
            });
    }

    handleChangeReserve(e){
        this.setState({
            reserve: e.target.value
        })
    }
    render() {
        const {errors,user,SubmitLoading,user_has_plan,plan_id,reserve} = this.state;
        if (this.state.loading) {
            return (
                <AppCardLoaderFields/>
            )
        } else {
            return (
                <Grid container spacing={2} justify="center">
                    {
                        user.role.type !== "admin" ?
                            <Grid item xs={12} md={11} lg={10}>
                                <Grid container  spacing={2} justify="center" alignItems="center">
                                    <CssBaseline/>
                                    {
                                        !!user.plan_id &&
                                        <>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <AppCard>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12} className="my-plan-container">
                                                                    <img
                                                                        src={`${defaultVariabls.uploads}/FileManager/${user.plan.parent.thumbnail.name}.${user.plan.parent.thumbnail.extension}`} />
                                                                    <SubTitleLight style={{color:user.plan.parent.color}}>{user.plan.parent.name}</SubTitleLight>
                                                                    {
                                                                        !!user.plan_usage.finishes_at &&
                                                                        <span style={{color:user.plan.parent.color}}>{translate('planValidity')} <strong>{moment.tz(user.plan_usage.finishes_at,'UTC').tz(getTimeZone()).format( 'YYYY/M/D')}</strong></span>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </AppCard>
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <AppCard style={{height:"100%"}}>
                                                            <SubTitle align="left" style={{position:"relative",marginBottom:33}}>
                                                                {translate('planInfo')}
                                                            </SubTitle>
                                                            <Typography className='date-holder'>
                                                                <span>{translate('make_products')}</span>
                                                                <strong className={this.classes.primary_color}>{user.plan.make_products === null ? translate('infinite') :  (parseInt(user.make_products) - parseInt(user.plan_usage.make_products))}</strong>
                                                            </Typography>
                                                            <Divider style={{marginTop:10,marginBottom:10}}/>
                                                            <Typography className='date-holder'>
                                                                <span>{translate('answer_to_rfq')}</span>
                                                                <strong className={this.classes.primary_color}>{user.plan.answer_to_rfq === null ? translate('infinite') : parseInt(user.plan.answer_to_rfq)}</strong>
                                                            </Typography>
                                                        </AppCard>
                                                    </Grid>
                                                    <Grid item xs={12} md={8}>
                                                        <AppCard>
                                                            <SubTitle align="left">{translate('planFeatures')}</SubTitle>
                                                            <List dense>
                                                                {
                                                                    !!user.plan.verify_badge &&
                                                                    <ListItem>
                                                                        <ListItemText>
                                                                            {translate('verify_badge')}
                                                                        </ListItemText>
                                                                        <ListItemSecondaryAction>
                                                                            <CheckRoundedIcon />
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                }
                                                                {
                                                                    !!user.plan.has_products_top_list &&
                                                                    <ListItem>
                                                                        <ListItemText>
                                                                            {translate('has_products_top_list')}
                                                                        </ListItemText>
                                                                        <ListItemSecondaryAction>
                                                                            <CheckRoundedIcon />
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                }
                                                                {
                                                                    !!user.plan.has_commercial_banner &&
                                                                    <ListItem>
                                                                        <ListItemText>
                                                                            {translate('has_commercial_banner')}
                                                                        </ListItemText>
                                                                        <ListItemSecondaryAction>
                                                                            <CheckRoundedIcon />
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                }
                                                                {
                                                                    !!user.plan.has_business_page &&
                                                                    <ListItem>
                                                                        <ListItemText>
                                                                            {translate('has_business_page')}
                                                                        </ListItemText>
                                                                        <ListItemSecondaryAction>
                                                                            <CheckRoundedIcon />
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                }
                                                                {
                                                                    !!user.plan.has_banner_design &&
                                                                    <ListItem>
                                                                        <ListItemText>
                                                                            {translate('has_banner_design')}
                                                                        </ListItemText>
                                                                        <ListItemSecondaryAction>
                                                                            <CheckRoundedIcon />
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                }
                                                                {
                                                                    !!user.plan.has_products_showcase &&
                                                                    <ListItem>
                                                                        <ListItemText>
                                                                            {translate('has_products_showcase')}
                                                                        </ListItemText>
                                                                        <ListItemSecondaryAction>
                                                                            <CheckRoundedIcon />
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                }
                                                                {
                                                                    !!user.plan.has_business_catalogue &&
                                                                    <ListItem>
                                                                        <ListItemText>
                                                                            {translate('has_business_catalogue')}
                                                                        </ListItemText>
                                                                        <ListItemSecondaryAction>
                                                                            <CheckRoundedIcon />
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                }
                                                                {
                                                                    !!user.plan.has_business_video &&
                                                                    <ListItem>
                                                                        <ListItemText>
                                                                            {translate('has_business_video')}
                                                                        </ListItemText>
                                                                        <ListItemSecondaryAction>
                                                                            <CheckRoundedIcon />
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                }
                                                                {
                                                                    !!user.plan.has_business_image &&
                                                                    <ListItem>
                                                                        <ListItemText>
                                                                            {translate('has_business_image')}
                                                                        </ListItemText>
                                                                        <ListItemSecondaryAction>
                                                                            <CheckRoundedIcon />
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                }
                                                                {
                                                                    !!user.plan.has_certificates &&
                                                                    <ListItem>
                                                                        <ListItemText>
                                                                            {translate('has_certificates')}
                                                                        </ListItemText>
                                                                        <ListItemSecondaryAction>
                                                                            <CheckRoundedIcon />
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                }
                                                                {
                                                                    !!user.plan.has_send_rfq &&
                                                                    <ListItem>
                                                                        <ListItemText>
                                                                            {translate('has_send_rfq')}
                                                                        </ListItemText>
                                                                        <ListItemSecondaryAction>
                                                                            <CheckRoundedIcon />
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                }
                                                                {
                                                                    !!user.plan.has_receive_public_rfq &&
                                                                    <ListItem>
                                                                        <ListItemText>
                                                                            {translate('has_receive_public_rfq')}
                                                                        </ListItemText>
                                                                        <ListItemSecondaryAction>
                                                                            <CheckRoundedIcon />
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                }
                                                                {
                                                                    !!user.plan.has_receive_private_rfq &&
                                                                    <ListItem>
                                                                        <ListItemText>
                                                                            {translate('has_receive_private_rfq')}
                                                                        </ListItemText>
                                                                        <ListItemSecondaryAction>
                                                                            <CheckRoundedIcon />
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                }
                                                                {
                                                                    !!user.plan.has_backlink &&
                                                                    <ListItem>
                                                                        <ListItemText>
                                                                            {translate('has_backlink')}
                                                                        </ListItemText>
                                                                        <ListItemSecondaryAction>
                                                                            <CheckRoundedIcon />
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                }
                                                                {
                                                                    !!user.plan.has_social_media_marketing &&
                                                                    <ListItem>
                                                                        <ListItemText>
                                                                            {translate('has_social_media_marketing')}
                                                                        </ListItemText>
                                                                        <ListItemSecondaryAction>
                                                                            <CheckRoundedIcon />
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                }
                                                                {
                                                                    !!user.plan.has_support &&
                                                                    <ListItem>
                                                                        <ListItemText>
                                                                            {translate('has_support')}
                                                                        </ListItemText>
                                                                        <ListItemSecondaryAction>
                                                                            <CheckRoundedIcon />
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                }
                                                                {
                                                                    !!user.plan.has_youtube &&
                                                                    <ListItem>
                                                                        <ListItemText>
                                                                            {translate('has_youtube')}
                                                                        </ListItemText>
                                                                        <ListItemSecondaryAction>
                                                                            <CheckRoundedIcon />
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                }
                                                            </List>
                                                        </AppCard>
                                                    </Grid>
                                                    {
                                                        !!user.reserved_plan_id &&
                                                        <Grid item xs={12}>
                                                            <AppCard>
                                                                <SubTitle align="left">
                                                                    {translate('reserved_plan')}
                                                                </SubTitle>
                                                                <Divider style={{marginTop:10,marginBottom:10}}/>
                                                                <Typography style={{color:user.reserved_plan.parent.color,display:"flex",alignItems:"center"}}>
                                                                    <img style={{width:24,marginRight:10}} src={`${defaultVariabls.uploads}/FileManager/${user.reserved_plan.parent.thumbnail.name}.${user.reserved_plan.parent.thumbnail.extension}`} />
                                                                    <span>{user.reserved_plan.parent.name} ({getMonths(user.reserved_plan.duration)} Months)</span>
                                                                </Typography>
                                                                <Divider style={{marginTop:10,marginBottom:10}}/>
                                                                <Typography className='date-holder'>
                                                                    <span>{translate('staringDate')}</span>
                                                                    <strong>{moment.tz(user.plan_usage.finishes_at,'UTC').tz(getTimeZone()).format( 'YYYY/M/D')}</strong>
                                                                </Typography>
                                                            </AppCard>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </>
                                    }
                                    <Grid item xs={12}>
                                        <SubTitle align={"left"}>{translate('givePlanToUser')}</SubTitle>
                                        <PlanChooser handlePlan={(plan_id) => this.setState({plan_id})}/>
                                    </Grid>
                                    {
                                        (!!plan_id && !!user_has_plan) &&
                                        <Grid item xs={12}>
                                            <FormControl className="reserve-radio">
                                                <FormLabel>{translate('reserveSituation')}</FormLabel>
                                                <RadioGroup name="reserve" value={reserve} onChange={this.handleChangeReserve.bind(this)}>
                                                    <FormControlLabel value={"reserve"} control={<Radio />} label={translate('do_reserve')} />
                                                    <FormControlLabel value="renew" control={<Radio />} label={translate('do_renew')} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>

                                    }
                                    <Grid item xs={12}>
                                        <LoadingButton
                                            variant="contained"
                                            color="primary"
                                            onClick = {this.handleRequest.bind(this)}
                                            loading = {SubmitLoading}
                                        >
                                            {translate('save')}
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Grid> :
                            <Grid item xs={12} md={11} lg={10} >
                                <Typography style={{textAlign:"center"}} className={this.classes.condir}>{translate('adminHasFullAccess')}</Typography>
                            </Grid>
                    }
                    {
                        (
                            user.role.type !== "admin" &&
                            (!!user.plan_id || !!user.suspended_plan_id) &&
                            (!!user.suspended_plan_id || user.step === 6)
                        ) &&
                        <Grid item xs={12}  md={11} lg={10}>
                            <UserSuspension user={user}/>
                        </Grid>
                    }
                </Grid>
            );
        }
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    locale: state.locale,
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(GetPlanToUser))));