import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Translate} from "../../../../../config/Translate";
import {connect} from "trim-redux";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../../config/design/styles";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import validator from "validator";
import axios from "axios";
import {defaultVariabls} from "../../../../../config";
import Grid from "@material-ui/core/Grid/Grid";
import TextField from "@material-ui/core/TextField/TextField";
import LoadingButton from "../../../../components/elements/LoadingButton";
import EditRoundedIcon from '@material-ui/icons/EditRounded';
class AddBrandButton extends React.Component{
    state = {
        open: false,
        fields:{
            name:"",
            url:"",
        },
        errors:{},
        SubmitLoading: false,
        item:this.props.item,
        id:0,
        editMode: !!this.props.item,
    };
    componentDidMount(){
        let {item} = this.state;
        if(!!item){
            this.setState({
                fields:{
                    name:item.name,
                    url:item.url,
                },
                id:item.id,
            })
        }
    }
    toggleOpen(){
        this.setState({
            open: !this.state.open
        })
    }
    handleChange(e){
        let {errors,fields} = this.state;
        let target = e.target;
        fields[target.name] = target.value;
        errors[target.name] = "";
        this.setState({fields});
    }

    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //name
        if(validator.isEmpty(fields.name)){
            formIsValid = false;
            errors['name'] = Translate.emptyField;
        }
        //url
        if(validator.isEmpty(fields.url)){
            formIsValid = false;
            errors['url'] = Translate.emptyField;
        }

        if(!formIsValid){
            this.props.enqueueSnackbar(Translate.someFieldsHasError, {
                variant: 'warning',
            });
            this.setState({
                errors,
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        let { editMode,fields,id} = this.state;
        let data = new FormData();
        data.append('name', fields.name);
        data.append('id', id ? id : 0);
        data.append('url', fields.url);
        data.append('position', this.props.position);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/menu_item/make`, data, config)
            .then(response => {
                this.props.reload()
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                    SubmitDraftLoading: false,
                    open:false,
                });
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(editMode ?  Translate.dataEditingFailed : Translate.dataAddingFailed, {
                    variant: 'error',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                    SubmitDraftLoading: false
                });
            });

    }
    handleSubmit(e){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    render(){
        let {open,fields,errors,SubmitLoading,editMode} = this.state;
        return (
            <>
                {
                    editMode ?
                        <Tooltip arrow placement="top" title={Translate.editMenu} aria-label={Translate.editMenu}>
                            <IconButton
                                color="primary"
                                onClick={() => this.toggleOpen()}
                            >
                                <EditRoundedIcon fontSize="small"/>
                            </IconButton>
                        </Tooltip>
                        :
                        <Tooltip arrow placement="top" title={Translate.addMenu} aria-label={Translate.addMenu}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => this.toggleOpen()}
                                startIcon={<AddBoxRoundedIcon />}
                            >
                                {Translate.addMenu}
                            </Button>
                        </Tooltip>
                }
                <Dialog
                    open={open}
                    onClose={() => this.toggleOpen()}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    maxWidth="xs"
                >
                    <DialogTitle id="form-dialog-title">
                        <IconButton
                            onClick={() => this.toggleOpen()}
                            style={{position:"absolute",left:15,top:15}}
                            size="small"
                        >
                            <CloseRoundedIcon fontSize="small"/>
                        </IconButton>
                        {editMode ? Translate.editMenu : Translate.addMenu}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} justify="center">
                            <Grid container spacing={2} justify="center" alignItems="center">
                                <Grid item xs={12}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        variant="outlined"
                                        name="name"
                                        label={Translate.menu_title}
                                        type="text"
                                        id="name"
                                        value={fields.name}
                                        onChange={this.handleChange.bind(this)}
                                        error = {!!errors['name']}
                                        helperText={errors['name']}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            name="url"
                                            label={Translate.url}
                                            placeholder={Translate.url}
                                            type="text"
                                            value={fields.url}
                                            onChange={this.handleChange.bind(this)}
                                            error = {!!errors['url']}
                                            helperText={errors['url']}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <LoadingButton
                                        variant="contained"
                                        color="primary"
                                        onClick = {this.handleSubmit.bind(this)}
                                        loading = {SubmitLoading}
                                    >
                                        {Translate.submit}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </>
        );
    }
}
const mstp = state => ({
    user: state.user,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(AddBrandButton)))));