import React from 'react';
import {connect, setStore} from "trim-redux";
import {SnackbarProvider} from "notistack";
import {theme} from "./config/design/theme";
import App from "./App";
import {ThemeProvider, withStyles} from '@material-ui/core/styles';
import './index.css';
import Loader from "./class/components/elements/Loader";
import {Styles} from "./config/design/styles";

class Definer extends React.Component{
    state = {
        loading:true,
    }
    async componentDidMount(){
        let locale = await localStorage.getItem('locale');
        if(locale === 'ar'){
            await setStore({
                locale:'ar',
                fake_locale:'ar',
            })
        }
        await this.setState({
            loading:false
        })
    }
    classes = this.props.classes;

    render(){
        let {locale,global_loading} = this.props;
        let {loading} = this.state;
        return(
            <ThemeProvider theme={global_loading ? theme(locale) : theme(locale)}>
                {
                    loading ? <Loader/> :
                        <SnackbarProvider anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} className={this.classes.condir}>
                            {/*{
                                locale === "ar" ?
                                    <RTL>
                                        <App/>
                                    </RTL>
                                    :
                                    <App/>
                            }*/}
                            <App/>
                        </SnackbarProvider>
                }

            </ThemeProvider>
        )
    }
}


const mstp = state => ({
    locale: state.locale,
    global_loading: state.global_loading,
});
export default withStyles(Styles)(connect(mstp)(Definer));
