import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import withWidth from "@material-ui/core/withWidth/withWidth";
import withStyles from "@material-ui/core/styles/withStyles";
import {Styles} from "../../../../config/design/styles";
import {connect} from "trim-redux";
import {Link, withRouter} from 'react-router-dom';

import clsx from "clsx";
import ChatTwoToneIcon from "@material-ui/icons/ChatTwoTone";

class MessagesIcon extends React.Component {
    state = {
        umc:this.props.umc
    };
    classes = this.props.classes;

    async componentDidUpdate(prevProps){
        if(this.state.umc.total !== this.props.umc.total){
           this.setState({
               umc:this.props.umc
           })
        }

    }
    render(){
        let {umc} = this.state;
        return (
            <>
                <IconButton
                    component={Link}
                    to={"/messages/ticket"}
                    className={clsx(this.classes.notiBtn, 'notiBtn')}
                >
                    <Badge badgeContent={umc.total} color="primary">
                        <ChatTwoToneIcon className={clsx(this.classes.notiIcon, 'notiIcon')}/>
                    </Badge>
                </IconButton>
            </>
        );
    }

}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    umc: state.umc,
    locale: state.locale,
});
export default withWidth()(withRouter(withStyles(Styles)(connect(mstp)(MessagesIcon))));
