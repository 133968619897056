import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import validator from 'validator';
import axios from 'axios';
import { defaultVariabls } from '../../../../../config/index';
import {Link, withRouter} from 'react-router-dom';
import { withSnackbar } from 'notistack';
import LoadingButton from "../../../../components/elements/LoadingButton";
import Typography from "@material-ui/core/Typography/Typography";
import Button from "@material-ui/core/Button/Button";
import {translate} from "../../../../../config/lang";
import Hidden from "@material-ui/core/Hidden/Hidden";
import MuiPhoneNumber from "material-ui-phone-number";

class ChooseMobile extends React.Component {
    constructor(props){
        super(props);
        this.state={
            fields: {
                mobile:0,
            },
            errors:{},
            SubmitLoading:false,
        };
    }
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        errors[target.name] = "";
        fields[target.name] = target.value;
        this.setState({fields});
    }
    async handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //mobile
        if(!validator.isNumeric(fields.mobile) || fields.mobile.length <= 11){
            formIsValid = false;
            errors['mobile'] = translate('wrongMobile');
        }
        await this.setState({errors},() =>{
            if(!formIsValid){
                this.setState({
                    SubmitLoading:false
                })
            }
            return callback(formIsValid)
        });
    }
    async handleRequest(){
        const {mobile} = this.state.fields;
        let data = new FormData();
        data.append('mobile', mobile);
        let config = {
            headers: {
                Accept: 'application/json',
            }
        };
        await axios.post(`${defaultVariabls.url}/token-login/setMobile`, data, config)
            .then(response =>{
                this.setState({
                    SubmitLoading:false
                })
                this.props.setUser(response.data);

                this.props.setNextLevel();
            })
            .catch(error =>{
                console.log(error.response);
                let {iframe} = this.props;
                if(iframe){
                    window.top.location.replace(`${defaultVariabls.React}/register/${mobile}`);
                }else{
                    window.location.replace(`${defaultVariabls.React}/register/${mobile}`);
                }
                this.setState({
                    SubmitLoading:false
                })
            });
    }
    handleChangeMobile(mobile){
        let {fields} = this.state;
        let res = mobile.replace(/\s/g, '');
        res = res.replace('(','')
        res = res.replace(')','')
        res = res.replace('-','')
        fields.mobile = res
        this.setState({
            fields,
        })
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    render() {
        const {errors,SubmitLoading,fields} = this.state;
        const {classes,iframe} = this.props;

        return (
            <form className={classes.form} noValidate onSubmit={this.handleSubmit.bind(this)}>
                <Grid container spacing={0} justify="center" alignItems='center'>
                    <Grid item xs={12}>
                        <MuiPhoneNumber
                            required
                            fullWidth
                            id="mobile"
                            label={translate('mobile')}
                            name="mobile"
                            margin="normal"
                            color="secondary"
                            dir="ltr"
                            variant="outlined"
                            onChange={this.handleChangeMobile.bind(this)}
                            autoFocus
                            disableAreaCodes
                            defaultCountry={'ae'}
                            error = {!!errors['mobile']}
                            helperText={errors['mobile']}
                        />
                    </Grid>
                    <Grid item xs={12} style={{marginBottom:54}}>
                        <Typography className={classes.condir} align="left" color="textSecondary">{translate('addYourNumberFirst')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                            style={{width:"100%"}}
                            onClick = {this.handleSubmit.bind(this)}
                            loading = {SubmitLoading}
                        >
                            {translate('confirm')}
                        </LoadingButton>
                    </Grid>
                    {
                        this.props.hasBack &&
                        <>
                            <Grid item xs={12}>
                                <Button size="small" style={{width:'100%'}} component={Link} to={iframe ? "/iframe/login" : "/login"} color="secondary" variant="outlined">
                                    <strong>
                                        {translate('loginWithUserPass')}
                                    </strong>
                                </Button>
                            </Grid>
                            {/*<Grid item xs={12} className={classes.textLeft} style={{marginTop:15}}>
                                <Typography>
                                    <Button  component={Link}  to={iframe ? "/iframe/register" : "/register"}>
                                        <strong>
                                            {translate('doRegister')}
                                        </strong>
                                    </Button>
                                </Typography>
                            </Grid>*/}
                        </>
                    }

                </Grid>
            </form>

        );

    }
}

export default withSnackbar(withRouter(withStyles(Styles)(ChooseMobile)));