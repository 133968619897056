import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import validator from 'validator';
import axios from 'axios';
import { defaultVariabls } from '../../../../../config/index';
import {withRouter} from 'react-router-dom';
import { withSnackbar } from 'notistack';
import LoadingButton from "../../../../components/elements/LoadingButton";
import Typography from "@material-ui/core/Typography/Typography";
import Button from "@material-ui/core/Button/Button";
import {translate} from "../../../../../config/lang";
import ChangeMobileModal from "../ChangeMobileModal";
import ChangeMobileModalVerify from "../ChangeMobileModalVerify";

class LoginUser extends React.Component {
    constructor(props){
        super(props);
        this.state={
            fields: {
                token:0,
            },
            errors:{},
            SubmitLoading:false,
            user:this.props.user,
            minutes:3,
            seconds:0
        };
    }
    componentDidMount(){
        this.setState({
            minutes:3,
            seconds:0
        })
        this.myInterval = setInterval(() => {
            const { seconds, minutes } = this.state

            if (seconds > 0) {
                this.setState(({ seconds }) => ({
                    seconds: seconds - 1
                }))
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(this.myInterval)
                } else {
                    this.setState(({ minutes }) => ({
                        minutes: minutes - 1,
                        seconds: 59
                    }))
                }
            }
        }, 1000);
    }
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        errors[target.name] = "";
        fields[target.name] = target.value;
        this.setState({fields});
        if(fields['token'].length === 5) {
            this.setState({
                SubmitLoading:true
            })
            this.handleRequest();
        }
    }
    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //token
        if(validator.isEmpty(fields.token)){
            formIsValid = false;
            errors['token'] = translate('emptyField');
        }
        this.setState({errors},() =>{
            if(!formIsValid){
                this.setState({
                    SubmitLoading:false
                })
            }
            return callback(formIsValid)
        });
    }
    async handleRequest(){
        const {token} = this.state.fields;
        const {user} = this.state;
        let data = new FormData();
        data.append('token', token);
        data.append('userId', user.id);
        let config = {
            headers: {
                Accept: 'application/json',
            }
        };
        await axios.post(`${defaultVariabls.url}/loginWithToken/checkToken`, data, config)
            .then(response =>{
                this.setState({
                    SubmitLoading:false

                })
                this.props.makeLogin(response.data)
            })
            .catch(error =>{
                this.setState({
                    SubmitLoading:false

                })
                this.props.enqueueSnackbar(translate('wrongToken'), {
                    variant: 'error',
                });
            });
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    async makeToken(){
        const {mobile} = this.props.user;
        let data = new FormData();
        await this.setState({
            SubmitLoading:true
        })
        data.append('mobile', mobile);
        let config = {
            headers: {
                Accept: 'application/json',
            }
        };
        await axios.post(`${defaultVariabls.url}/forgetPass/setMobile`, data, config)
            .then(response =>{
                this.setState({
                    SubmitLoading:false
                })
                this.props.enqueueSnackbar(translate('sendTokenTextResend'), {
                    variant: 'info',
                });
            })
            .catch(error =>{
                this.props.enqueueSnackbar(translate('invalidMobileNumber'), {
                    variant: 'error',
                });
                this.setState({
                    SubmitLoading:false
                })
            });
    }
    render() {
        const {classes} = this.props;
        const {errors,SubmitLoading,success,user,minutes,seconds} = this.state;
        return (
            <form className={classes.form} noValidate onSubmit={this.handleSubmit.bind(this)}>
                <Grid container spacing={0} justify="center" alignItems='center'>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            id="token"
                            label={translate('token')}
                            name="token"
                            margin="normal"
                            color="secondary"
                            variant="outlined"
                            onChange={this.handleChange.bind(this)}
                            autoFocus
                            error = {!!errors['token']}
                            helperText={errors['token']}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align="left" color="textSecondary">
                            {translate('sendTokenText1')} <strong dir="ltr">{user.mobile}</strong> {translate('sendTokenText2')} </Typography>
                        <Button
                            size="small"
                            color="secondary"
                            onClick={() => window.location.reload(true)}
                        >
                            {translate('editMobile')}
                        </Button>
                        {
                            minutes !== 0 || seconds !== 0 ? <Typography align="left"  color="textSecondary">{translate('sendTokenText3')}: <strong>{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</strong> </Typography> : <Button color="secondary" onClick={this.makeToken.bind(this)}>{translate('sendAgain')}</Button>
                        }
                    </Grid>
                    <Grid item xs={12} style={{marginTop:54}}>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                            style={{width:"100%"}}
                            onClick = {this.handleSubmit.bind(this)}
                            loading = {SubmitLoading}
                            success = {success}
                        >
                            {translate('confirm')}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </form>
        );

    }
}

export default withSnackbar(withRouter(withStyles(Styles)(LoginUser)));