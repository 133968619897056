import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import clsx from 'clsx';
import UserInfo from './UserInfo';
import Navigation from './VerticleNav';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Scrollbar from '../../Scrollbar';
import useStyles from './AppSidebar.style';
import {connect, getStore, setStore} from "trim-redux";
import Button from "@material-ui/core/Button/Button";
import {Translate} from "../../../../config/Translate";
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import {defaultVariabls} from "../../../../config";
const AppSidebar = (props) => {

  const navCollapsed = props.navCollapsed;
    const classes = useStyles();
  let sidebarClasses = classes.sidebarStandard;


    return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor={getStore("locale") === "en" ? "left" : "right"}
          open={navCollapsed}
          onClose={(ev) => setStore({navCollapsed:!props.navCollapsed})}
          style={{position: 'absolute'}}>
            <Box height='100%' className={classes.drawerContainer}>
                <Box className={clsx(classes.sidebarBg, sidebarClasses)}>
                    <UserInfo />
                    <div className={classes.horizontalHR}>
                        <hr/>
                    </div>
                    <div className="scrollbar-container">
                        <Navigation />
                    </div>
                </Box>
            </Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Box height='100%' className={classes.drawerContainer}>
          <Box className={clsx(classes.sidebarBg, sidebarClasses)}>
              <UserInfo />
              <div className={classes.horizontalHR}>
                  <hr/>
              </div>
              <div className="scrollbar-container">
                <Navigation />
              </div>
          </Box>
        </Box>
      </Hidden>
    </>
  );
};

const mstp = state => ({
    navCollapsed: state.navCollapsed,
    logo:state.logo
});
export default connect(mstp)(AppSidebar);

AppSidebar.defaultProps = {
  variant: '',
  position: 'left',
};

AppSidebar.propTypes = {
  position: PropTypes.string,
  variant: PropTypes.string,
};
