import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import BackupIcon from '@material-ui/icons/Backup';
import Button from "@material-ui/core/Button/Button";
import {Translate} from "../../../../config/Translate";
import axios from "axios/index";
import {defaultVariabls} from "../../../../config/index";
import CircularProgress from '@material-ui/core/CircularProgress';
import { withSnackbar } from 'notistack';
import withWidth from "@material-ui/core/withWidth/withWidth";
import IconButton from "@material-ui/core/IconButton/IconButton";
import {translate} from "../../../../config/lang";
import CloudUploadTwoToneIcon from '@material-ui/icons/CloudUploadTwoTone';
import VideoThumbnailChooser from "./VideoThumbnailChooser";
class AddFile extends React.Component {
   state = {
       open:false,
       file:this.props.file
   }
    classes = this.props.classes;
   componentDidMount() {
       this.setState({
           file:this.props.file
       })
   }
    handleVideoPreview(res){
       this.props.handlePreview(res)
       this.setState({
           open:false,
       })
    }
    render(){
        let {open,file} = this.state;
        let {style} = this.props;
        return(
            <>
                <Button
                    size="small"
                    style={style}
                    onClick={() => this.setState({
                        open:true
                    })}
                >
                    {translate('changeCover')}
                </Button>
                {
                    !!open &&
                    <VideoThumbnailChooser
                        file={file}
                        onChange={(preview) => this.handleVideoPreview(preview)}
                    />
                }

            </>
        )
    }
}

export default withWidth()(withSnackbar(withStyles(Styles)(AddFile)));