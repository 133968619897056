import Typography from "@material-ui/core/Typography/Typography";
import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
export default function FieldCounter(props) {
    return (
        <React.Fragment>
            <Typography
                style={{direction:"ltr",textAlign:"right"}}
                color={props.length > props.max ? "error" : "primary"}
            >
                {props.length}  <strong>/ {props.max}</strong>
            </Typography>
            <LinearProgress
                variant="determinate"
                value={(props.length*100)/props.max}
                className={props.length > props.max ? "textCounterError" : "textCounter"}
                color="primary"
            />
        </React.Fragment>
    );
}