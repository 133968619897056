import React from "react";
import {withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../config/design/styles";
import {connect, setStore} from "trim-redux";
import axios from "axios";
import {defaultVariabls} from "../../../config";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField/TextField";
import {translate} from "../../../config/lang";
import Grid from "@material-ui/core/Grid";
import SubTitle from "../elements/SubTitle";
import SingleFileChooser from "../../pages/fileManager/Components/SingleFileChooser";
import LoadingButton from "../elements/LoadingButton";
import {updateRedux} from "../../../config/Security";

class DocumentChooser extends React.Component{
    state = {
        documents:[],
        selected_cat:null,
        document:null,
        loading:true,
        open:false,
        open2:false,
        file:null,
        SubmitLoading:false,
    };
    async componentDidMount(){
        await this.loadData();
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.get(`${defaultVariabls.url}/documents/getAll`, config)
            .then(response => {
                this.setState({
                    loading:false,
                    documents:response.data
                })
            })
            .catch(error => {
                this.setState({
                    loading:false
                })
            });
    }
    handleCat(data){
        this.setState({
            selected_cat:data
        })
    }
    handleDocument(data){
        this.setState({
            document:data
        })
    }
    handleChangeFile(file){
        this.setState({
            file
        })
        console.log(file);
    }
    handleValidation(callback){
        let { file,document } = this.state;
        let formIsValid = true;
        if(!file || !document){
            formIsValid = false;
        }
        if(!formIsValid){
            this.props.enqueueSnackbar(translate('someFieldsHasError'), {
                variant: 'warning',
            });
            this.setState({
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        const {file,document} = this.state;
        let {manage,user_id} = this.props;
        let data = new FormData();

        data.append('user_id', user_id);
        data.append('document_id', !!document ? document.id : '');
        data.append('file_id', !!file ? file.id : '');

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/document/upload`, data, config)
            .then(async response => {

                this.setState({
                    documents:[],
                    selected_cat:null,
                    document:null,
                    loading:true,
                    open:false,
                    open2:false,
                    file:null,
                    SubmitLoading:false,
                });
                await this.props.reload();
            })
            .catch(error => {
                console.log(error.response);
                if(error.response.status === 403){
                    this.props.enqueueSnackbar(translate('duplicated_document'), {
                        variant: 'error',
                    });
                }else{
                    this.props.enqueueSnackbar(translate('detailsSetFailed'), {
                        variant: 'error',
                    });
                }
                this.setState({
                    SubmitLoading:false
                });
            });
    }

    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        });
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    render(){
        let {documents,loading,selected_cat,open,open2,document,file,SubmitLoading} = this.state;
        let{required,error,label,margin,size} = this.props;
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <SubTitle align="left" noHR>
                        {translate('uploadDocument')}
                    </SubTitle>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Autocomplete
                        fullWidth
                        open={open}
                        color="secondary"
                        onOpen={() => {
                            this.setState({open:true})
                        }}
                        onClose={() => {
                            this.setState({open:false})
                        }}
                        required={required}
                        size={!!size ? size : "normal"}
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => (
                            <span style={{display:"flex",alignItems:"center"}}>
                        <span>{option.name}</span>
                    </span>
                        )}
                        onChange={(event, value) => this.handleCat(value)}
                        options={documents}
                        value={selected_cat}
                        loading={loading}
                        disabled={this.props.disabled}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={!!label ? label : translate('documentCategory')}
                                variant="outlined"
                                color="secondary"
                                size={!!size ? size : "normal"}
                                margin={!!margin ? margin : ""}
                                required={required}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                                error={error}
                                helperText={!!error && error}
                            />
                        )}
                    />
                </Grid>
                {
                    !!selected_cat &&
                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            fullWidth
                            open={open2}
                            onOpen={() => {
                                this.setState({open2:true})
                            }}
                            onClose={() => {
                                this.setState({open2:false})
                            }}
                            disabled={!selected_cat}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, value) => this.handleDocument(value)}
                            options={!!selected_cat ? selected_cat.documents : null}
                            loading={loading}
                            required={required}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    required={required}
                                    label={translate('documentName')}
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                    error={error}
                                    helperText={!!error && error}
                                />
                            )}
                        />
                    </Grid>
                }
                {
                    !!document &&
                    <Grid item xs={12} md={6}>
                        <SingleFileChooser
                            onlyFile
                            onChange={(file) => this.handleChangeFile(file)}
                        />
                    </Grid>
                }
                <Grid item xs={12}>
                    <LoadingButton
                        variant="contained"
                        color="secondary"
                        size="small"
                        disabled={!file}
                        onClick = {this.handleSubmit.bind(this)}
                        loading = {SubmitLoading}
                    >
                        {translate('upload')}
                    </LoadingButton>
                </Grid>
            </Grid>
        </>
    );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,

});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(DocumentChooser))));