import React from 'react';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {connect} from "trim-redux";
import {Link, withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import withWidth from "@material-ui/core/withWidth/withWidth";
import Grid from "@material-ui/core/Grid/Grid";
import {Translate} from "../../../../../config/Translate";
import Title from "../../../../components/elements/Title";
import CommissionField from "./components/CommissionField";
import SubTitle from "../../../../components/elements/SubTitle";
import HidePriceField from "./components/HidePriceField";
import ServicesPriceField from "./components/ServicesPriceField";
import XPriceField from "./components/XPriceField";
import GlobalNeedVerificationField from "./components/GlobalNeedVerificationField";


class SiteSettings extends React.Component {
    classes = this.props.classes;
    state = {

    };

    render(){
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <SubTitle align="left">{Translate.manageGlobalCommission}</SubTitle>
                    <CommissionField/>
                </Grid>
                <Grid item xs={12}>
                    <SubTitle align="left">{Translate.servicesPrice}</SubTitle>
                    <ServicesPriceField/>
                </Grid>
                <Grid item xs={12}>
                    <SubTitle align="left">{Translate.x_price}</SubTitle>
                    <XPriceField/>
                </Grid>
                <Grid item xs={12}>
                    <SubTitle align="left">{Translate.hidePrices}</SubTitle>
                    <HidePriceField/>
                </Grid>
                <Grid item xs={12}>
                    <SubTitle align="left">{Translate.globalNeedVerification}</SubTitle>
                    <GlobalNeedVerificationField/>
                </Grid>
            </Grid>
            );
    }
}

const mstp = state => ({
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(SiteSettings)))));