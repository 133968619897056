import React from 'react'
import Slide from '@material-ui/core/Slide';
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../../config/design/styles";
import {translate} from "../../../../../config/lang";
import axios from "axios";
import {defaultVariabls} from "../../../../../config";
import EditFieldButton from "./EditFieldButton";
import DeleteButton from "../../../../components/elements/DeleteButton";


class CustomInputIcons extends React.Component{
    handleDelete(){
        let {item} = this.props;
        let data = new FormData();
        data.append('id', item.id);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/field/delete`, data, config)
            .then(response => {
                this.props.reload();
            })
            .catch(error => {
                this.props.enqueueSnackbar(translate('dataRemovingFailed'), {
                    variant: 'error',
                });
            });

        this.setState({
            loading:false,
            SubmitLoading:false
        });
    }
    render(){
        let {item}  = this.props;
        return(
            <>
                <EditFieldButton id={item.id} reload={() => this.props.reload()}/>
                <DeleteButton
                    handleDelete = {() => this.handleDelete()}
                />
            </>
        )
    }
}
export default  withStyles(Styles)(CustomInputIcons)