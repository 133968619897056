import React from 'react';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField/TextField";
import validator from "validator";
import axios from "axios";
import {defaultVariabls} from "../../../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import LoadingButton from "../../../../components/elements/LoadingButton";
import SubTitle from "../../../../components/elements/SubTitle";
import AppAnimate from "../../../../components/AppAnimate";
import AppCard from "../../../../components/layouts/components/AppCard";
import Grid from "@material-ui/core/Grid/Grid";
import {connect, setStore} from "trim-redux";
import BreadCrumbs from "../../../../components/elements/BreadCrumbs";
import AppCardLoaderFields from "../../../../components/Skeletons/AppCardLoaderFields";
import Hidden from "@material-ui/core/Hidden/Hidden";
import {translate} from "../../../../../config/lang";
import SingleImageChooser from "../../../fileManager/Components/SingleImageChooser";
import MetaDescription from "../../../fileManager/Components/MetaDescription";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import FieldCounterWithoutBar from "../../../../components/elements/FieldCounterWithoutBar";
import ChipInput from "material-ui-chip-input";
import Typography from "@material-ui/core/Typography/Typography";
import ColorPicker from "../../../../components/fields/ColorPicker";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import Alert from "@material-ui/lab/Alert/Alert";

class MakeParent extends React.Component {

    constructor(props){
        super(props);
        this.ThumbChild = React.createRef();
        this.EditorChild = React.createRef();

    }
    state = {
        fields:{
            name:"",
            arabic_name:"",
            description:"",
            arabic_description:"",
        },
        features:[],
        arabic_features:[],
        thumbnail: null,
        errors:{},
        slug:null,
        loading: true,
        confirmed: false,

        SubmitLoading: false,
        id:this.props.match.params.id,
        editMode:false,
        color:'',
    };
    classes = this.props.classes;
    async componentDidMount(){
        if(!!this.state.id){
            this.setState({
                editMode:true
            })
            await this.loadData();
        }else{
            this.setState({
                loading:false,
            })
        }
    }
    componentDidUpdate(prevProps){
        if(this.state.id !== this.props.match.params.id){
            this.setState({
                id: this.props.match.params.id
            })
        }
    }
    handleThumbImage(thumbnail){
        this.setState({
            thumbnail
        })
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/plan_parent/get/${this.state.id}`, config)
                .then(response => {
                    this.setState({
                        fields: {
                            name: response.data.name,
                            arabic_name: response.data.arabic_name,
                            description: !!response.data.description ? response.data.description : '',
                            arabic_description: !!response.data.arabic_description ? response.data.arabic_description : '',
                        },
                        features: !!response.data.features ? response.data.features.split(',') : [],
                        arabic_features: !!response.data.arabic_features ? response.data.arabic_features.split(',') : [],
                        thumbnail: response.data.thumbnail,
                        confirmed: response.data.confirmed,
                        slug: response.data.slug,
                        color: response.data.color,
                        loading:false
                    })

                }).catch(error => {
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }
    }
    handleChange(e){
        let {errors,fields} = this.state;
        let target = e.target;
        fields[target.name] = target.value;
        errors[target.name] = "";
        this.setState({fields});
    }

    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //name
        if(validator.isEmpty(fields.name)){
            formIsValid = false;
            errors['name'] = translate('emptyField');
        }
        //Description
        if(fields.description !== null ) {
            if (fields.description.length > 1000) {
                formIsValid = false;
                errors['description'] = translate('maxLength1000');
            }
        }
        //name
        if(validator.isEmpty(fields.arabic_name)){
            formIsValid = false;
            errors['arabic_name'] = translate('emptyField');
        }
        //Description
        if(fields.arabic_description !== null ) {
            if (fields.description.length > 1000) {
                formIsValid = false;
                errors['arabic_description'] = translate('maxLength1000');
            }
        }

        if(!formIsValid){
            this.props.enqueueSnackbar(translate('someFieldsHasError'), {
                variant: 'warning',
            });
            this.setState({
                errors,
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        let { fields,thumbnail,features,confirmed,arabic_features,id,editMode,color,hide_products,parentId,icon} = this.state;
        let data = new FormData();
        data.append('name', fields.name);
        data.append('arabic_name', fields.arabic_name);
        data.append('id', id ? id : 0);
        data.append('description', fields.description);
        data.append('arabic_description', fields.arabic_description);
        data.append('features', features.toString());
        data.append('arabic_features', arabic_features.toString());
        data.append('thumbnail', !!thumbnail ? thumbnail.id : 1);
        data.append('color', color);
        data.append('confirmed',confirmed ? 1 : 0);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/plan_parent/make`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(editMode ? translate('dataEdited') : translate('dataAdded'), {
                    variant: 'success',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                    SubmitDraftLoading: false
                });
                if(!this.state.editMode){
                    this.props.history.push(`/manage/plan-parents`);
                }
            })
            .catch(error => {
                console.log(error.response);
                if(error.response.status === 403){
                    this.props.enqueueSnackbar(translate('categoryPublishedBefore'), {
                        variant: 'error',
                    });
                }else{
                    this.props.enqueueSnackbar(editMode ?  translate('dataEditingFailed') : translate('dataAddingFailed'), {
                        variant: 'error',
                    });
                }

                this.setState({
                    loading:false,
                    SubmitLoading: false,
                    SubmitDraftLoading: false
                });
            });

    }

    handleSubmit(e){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    handleOutPut(code){
        this.setState({
            content:code
        })
    }
    handleChangeConfirmed(){
        this.setState({
            confirmed: !this.state.confirmed,
        })
    }
    handleChangeHideProducts(){
        this.setState({
            hide_products: !this.state.hide_products,
        })
    }
    handleParent(value){
        if(value === null){
            this.setState({
                parentId:''
            })
        }else{
            this.setState({
                parentId:value.id
            })
        }
    }
    async handleAddChip(item){
        let {features} = this.state;
        if(features.length < 3){
            features.push(item);
            this.setState({
                features
            })
        }else{
            this.setState({
                features
            })
        }

    }
    async handleDeleteChip(chip, index){
        let {features} = this.state;
        features.splice(index, 1);
        this.setState({
            features
        })
        this.setState({
            features
        })
    }
    async handleAddChipArabic(item){
        let {arabic_features} = this.state;
        if(arabic_features.length < 3){
            arabic_features.push(item);
            this.setState({
                arabic_features
            })
        }else{
            this.setState({
                arabic_features
            })
        }

    }
    async handleDeleteChipArabic(chip, index){
        let {arabic_features} = this.state;
        arabic_features.splice(index, 1);
        this.setState({
            arabic_features
        })
        this.setState({
            arabic_features
        })
    }
    render(){
        let {fields, errors,SubmitLoading,thumbnail,color,arabic_features,editMode,confirmed,equal,category,slug,categories,parentId} = this.state;
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={editMode ? translate('editParent') : translate('makeParent')}>
            {
                        <Grid container spacing={2} justify="center">
                            <Grid item xs={12} lg={11}>
                                <Grid container spacing={2} justify="center">
                                    <Grid item xs={12} md={8}>
                                        <AppCard
                                            title={editMode ? translate('editParent') : translate('makeParent')}
                                            action={
                                                <BreadCrumbs singleLevel firstText={editMode ? translate('editParent') : translate('makeParent')}/>
                                            }>
                                            {
                                                this.state.loading ? <AppCardLoaderFields/> :
                                                    <Grid container spacing={2} justify="center" alignItems="center">
                                                        <Grid item xs={12}>
                                                            <Alert
                                                                severity="info"
                                                                action={
                                                                    <FormControlLabel
                                                                        style={{marginRight:10}}
                                                                        control={
                                                                            <Switch
                                                                                checked={confirmed}
                                                                                onChange={this.handleChangeConfirmed.bind(this)}
                                                                                value={confirmed}
                                                                                color="primary"
                                                                            />
                                                                        }
                                                                        label={confirmed ? translate('confirmed') : translate('unConfirmed')}
                                                                    />
                                                                }
                                                            >
                                                                {translate('confirmationStatusDescription')}
                                                            </Alert>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                margin="normal"
                                                                required
                                                                fullWidth
                                                                variant="outlined"
                                                                name="name"
                                                                label={translate('planName')}
                                                                type="text"
                                                                id="name"
                                                                value={fields.name}
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['name']}
                                                                helperText={errors['name']}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                margin="normal"
                                                                required
                                                                fullWidth
                                                                variant="outlined"
                                                                name="arabic_name"
                                                                label={translate('planArabicName')}
                                                                type="text"
                                                                value={fields.arabic_name}
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['arabic_name']}
                                                                helperText={errors['arabic_name']}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                variant="outlined"
                                                                margin="normal"
                                                                multiline
                                                                fullWidth
                                                                name="description"
                                                                label={translate('description')}
                                                                placeholder={translate('description')}
                                                                type="text"
                                                                minRows="4"
                                                                value={fields.description}
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['description']}
                                                                helperText={errors['description']}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <FieldCounterWithoutBar length={fields.description.length} max={1000}/>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <ChipInput
                                                                value={this.state.features}
                                                                fullWidth
                                                                label={translate('planFeatures')}
                                                                variant={"outlined"}
                                                                multiline
                                                                minRows={5}
                                                                onAdd={(chip) => this.handleAddChip(chip)}
                                                                onDelete={(chip, index) => this.handleDeleteChip(chip, index)}
                                                            />
                                                            <Typography  style={{fontSize:12,marginLeft:10,marginTop:5}}>
                                                                {translate('pressEnterToMakeEachOption')}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                variant="outlined"
                                                                margin="normal"
                                                                multiline
                                                                fullWidth
                                                                name="arabic_description"
                                                                label={translate('arabic_description')}
                                                                placeholder={translate('arabic_description')}
                                                                type="text"
                                                                minRows="4"
                                                                value={fields.arabic_description}
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['arabic_description']}
                                                                helperText={errors['arabic_description']}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <FieldCounterWithoutBar length={fields.arabic_description.length} max={1000}/>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <ChipInput
                                                                value={this.state.arabic_features}
                                                                fullWidth
                                                                label={translate('planArabicFeatures')}
                                                                variant={"outlined"}
                                                                multiline
                                                                minRows={5}
                                                                onAdd={(chip) => this.handleAddChipArabic(chip)}
                                                                onDelete={(chip, index) => this.handleDeleteChipArabic(chip, index)}
                                                            />
                                                            <Typography  style={{fontSize:12,marginLeft:10,marginTop:5}}>
                                                                {translate('pressEnterToMakeEachOption')}
                                                            </Typography>
                                                        </Grid>
                                                        <Hidden mdDown>
                                                            <Grid item xs={12}>
                                                                <LoadingButton
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick = {this.handleSubmit.bind(this)}
                                                                    loading = {SubmitLoading}
                                                                >
                                                                    {editMode ? translate('editParent') : translate('makeParent')}
                                                                </LoadingButton>
                                                            </Grid>
                                                        </Hidden>
                                                    </Grid>
                                            }
                                        </AppCard>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Grid container spacing={2}>
                                            <Hidden smDown>
                                                <Grid item xs={12} style={{paddingTop: 0}}>
                                                    <LoadingButton
                                                        variant="contained"
                                                        color="primary"
                                                        style={{width: "100%"}}
                                                        onClick={this.handleSubmit.bind(this)}
                                                        loading={SubmitLoading}
                                                    >
                                                        {editMode ? translate('editParent') : translate('makeParent')}
                                                    </LoadingButton>
                                                </Grid>
                                            </Hidden>

                                            <Grid item xs={12}>
                                                <AppCard>
                                                    {
                                                        this.state.loading ? <AppCardLoaderFields/> :
                                                            <Grid container spacing={2} alignItems="center">
                                                                <Grid item xs={12} style={{paddingBottom:15}}>
                                                                    <SubTitle>{translate('ThumbnailTitle')}</SubTitle>
                                                                    <SingleImageChooser
                                                                        primary
                                                                        onChange={(thumbnail) => this.handleThumbImage(thumbnail)}
                                                                        initial={thumbnail}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <MetaDescription primary width={800} height={800} size={"10MB"}/>
                                                                </Grid>

                                                                <Grid item xs={12}>
                                                                    <ColorPicker
                                                                        color={color}
                                                                        title={translate('color')}
                                                                        onChange={(color) => this.setState({color})}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                    }
                                                </AppCard>
                                            </Grid>


                                            <Hidden mdUp>
                                                <Grid item xs={12} style={{paddingTop: 0}}>
                                                    <LoadingButton
                                                        variant="contained"
                                                        color="primary"
                                                        style={{width: "100%"}}
                                                        onClick={this.handleSubmit.bind(this)}
                                                        loading={SubmitLoading}
                                                    >
                                                        {editMode ? translate('editParent') : translate('makeParent')}
                                                    </LoadingButton>
                                                </Grid>
                                            </Hidden>
                                        </Grid>


                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                }
            </AppAnimate>

        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,
    fake_locale: state.fake_locale,

});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MakeParent))));