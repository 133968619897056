import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import axios from "axios";
import {defaultVariabls} from "../../../../config";
import {connect} from "trim-redux";
import TextField from "@material-ui/core/TextField/TextField";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../config/design/styles";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import IconButton from "@material-ui/core/IconButton/IconButton";
import Grid from "@material-ui/core/Grid/Grid";
import LoadingButton from "../../../components/elements/LoadingButton";
import ChatRoundedIcon from '@material-ui/icons/ChatRounded';
import {translate} from "../../../../config/lang";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import UserChooser from "./components/UserChooser";
import RFQChooser from "./components/RFQChooser";
class AddTicketModal extends React.Component{
    state = {
        fields:{
            subject:"",
            message:"",
            priority:"medium",
            order_item:"",
            type:"system",

        },
        receiver:null,
        has_receiver:false,
        rfq:null,
        SubmitLoading:false,
        departments:[],
        orderItems:[],
        loading:true,
        open:false,
        errors:{},
        anchorEl:null
    };
    handleClickMenu = (event) => {
        this.setState({
            anchorEl: event.currentTarget
        })
    };

    handleCloseMenu = () => {
        this.setState({
            anchorEl: null
        })
    };

    async componentDidMount(){
        if(!!this.props.receiver){
            await this.setState({
                receiver:this.props.receiver,
                has_receiver:true,
            })
        }
        await this.loadData()
    }
    loadData(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.get(`${defaultVariabls.url}/departments/getAllWithOrders?lang=${this.props.locale}`, config)
            .then(response => {
                this.setState({
                    departments: response.data.departments,
                    loading:false,
                })
            })
            .catch(error => {
                console.log(error.response)
                this.setState({
                    loading:false
                })
            });
    }
    toggleOpen(type){
        this.setState({
            open: !this.state.open,
            type:type
        })
    }
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        errors[target.name] = "";
        fields[target.name] = target.value;
        this.setState({
            fields,
            errors
        });
    }

    handleValidation(callback){
        let { fields,rfq } = this.state;
        let errors = {};
        let formIsValid = true;


        //subject
        if(!fields.message) {
            formIsValid = false;
            errors['message'] = translate('emptyField');
        }
        if(fields.type === "rfq"){
            //order_item
            if(!rfq) {
                formIsValid = false;
                errors['rfq_id'] = translate('emptyField');
            }
        }else{
            //subject
            if(!fields.subject) {
                formIsValid = false;
                errors['subject'] = translate('emptyField');
            }
        }

        if(!formIsValid){
            this.props.enqueueSnackbar(translate('someFieldsHasError'), {
                variant: 'warning',
            });
            this.setState({
                SubmitLoading:false,
                errors
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        const {fields,receiver,rfq} = this.state;
        let data = new FormData();
        data.append('subject', fields.subject);
        data.append('message', fields.message);
        data.append('priority', fields.priority);
        data.append('type', fields.type);
        data.append('receiver_id', !!receiver ? receiver.id : '');
        if(fields.type === "rfq"){
            data.append('rfq_id', !!rfq ? rfq.id : '');
        }
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/ticket/add-by-admin`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(translate('ticket_sent'), {
                    variant: 'success',
                });
                this.setState({
                    fields:{
                        subject:"",
                        message:"",
                        priority:"medium",
                        order_item:"",
                        type:"system",

                    },
                    SubmitLoading:false,
                    open:false
                });
                this.props.reload()

            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(translate('detailsSetFailed'), {
                    variant: 'error',
                });
                this.setState({
                    SubmitLoading:false
                });
            });
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }

        });
    }
    render(){
        let {loading,SubmitLoading,receiver,errors,fields,open,orderItems,has_receiver} = this.state;
        let {user,only_big} = this.props;
        return (
            <>
                {
                    (this.props.width !== "xs" || !!only_big) ?
                        <Button
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            variant="outlined"
                            color="secondary"
                            onClick={(e) => this.toggleOpen('system')}
                            startIcon={<ChatRoundedIcon/>}
                            size={"small"}
                        >
                            {translate('addTicket')}
                        </Button>
                        :
                        <Tooltip
                            title={translate('addTicket')}

                        >
                            <IconButton color="primary" onClick={(e) => this.toggleOpen('system')}>
                                <ChatRoundedIcon />
                            </IconButton>
                        </Tooltip>

                }
                <Dialog
                    open={open}
                    onClose={() => this.toggleOpen()}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    maxWidth="md"
                >
                    <DialogTitle id="form-dialog-title">
                        <IconButton
                            onClick={() => this.toggleOpen()}
                            style={{position:"absolute",right:15,top:15}}
                            size="small"
                        >
                            <CloseRoundedIcon fontSize="small"/>
                        </IconButton>
                        {translate('addTicket')}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                    select
                                    name="priority"
                                    value={fields.priority}
                                    label={translate('priority')}
                                    onChange={this.handleChange.bind(this)}
                                    error = {!!errors['priority']}
                                    helperText={errors['priority']}
                                >
                                    <MenuItem value={"low"}>{translate('low')}</MenuItem>
                                    <MenuItem value={"medium"}>{translate('medium')}</MenuItem>
                                    <MenuItem value={"high"}>{translate('high')}</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                    select
                                    name="type"
                                    value={fields.type}
                                    label={translate('ticketType')}
                                    onChange={this.handleChange.bind(this)}
                                    error = {!!errors['type']}
                                    helperText={errors['type']}
                                >
                                    <MenuItem value={"system"}>{translate('ticket')}</MenuItem>
                                    <MenuItem value={"rfq"}>{translate('rfq')}</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    !!has_receiver ?
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            margin="normal"
                                            disabled
                                            value={receiver.first_name+" "+receiver.last_name}
                                            label={translate('receiver')}
                                        />
                                        :
                                        <UserChooser
                                            outPut={(res) => this.setState({
                                                receiver:res
                                            })
                                            }
                                        />
                                }
                            </Grid>
                            {
                                fields.type === "rfq" ?
                                <Grid item xs={12}>
                                    <RFQChooser
                                        outPut={(res) => this.setState({
                                            rfq:res
                                        })
                                        }
                                    />
                                </Grid> :
                                    <Grid item xs={12}>
                                        <TextField
                                            color="secondary"
                                            variant="outlined"
                                            value={fields.subject}
                                            fullWidth
                                            label={translate('subject')}
                                            name="subject"
                                            onChange={this.handleChange.bind(this)}
                                            error = {!!errors['subject']}
                                            helperText={errors['subject']}
                                        />
                                    </Grid>
                            }

                            <Grid item xs={12}>
                                <TextField
                                    color="secondary"
                                    variant="outlined"
                                    value={fields.message}
                                    fullWidth
                                    label={translate('message')}
                                    name="message"
                                    minRows={5}
                                    multiline
                                    onChange={this.handleChange.bind(this)}
                                    error = {!!errors['message']}
                                    helperText={errors['message']}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    onClick = {this.handleSubmit.bind(this)}
                                    loading = {SubmitLoading}
                                >
                                    {translate('addTicket')}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </>
        );
    }
}
const mstp = state => ({
    user: state.user,
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(AddTicketModal)))));