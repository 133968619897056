import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {getDataTableLocalization} from "../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../config/index";
import {connect} from "trim-redux";
import MaterialTable from "material-table";
import {tableIcons} from "../../../config/design/TableIcons";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import moment from "moment-timezone";
import {TableCount, TablePage} from "../../../config/Permissions";
import Container from "@material-ui/core/Container/Container";
import Typography from "@material-ui/core/Typography/Typography";
import AppCardLoaderTable from "../../components/Skeletons/AppCardLoaderTable";
import Button from "@material-ui/core/Button/Button";
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import {makeSlug, translate} from "../../../config/lang";
import OpenInNewRoundedIcon from "@material-ui/icons/OpenInNewRounded";
import {getTimeZone, truncate} from "../../../config/values";
import withWidth from "@material-ui/core/withWidth/withWidth";

class Tickets extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    state = {
        columns: [
            { title: translate('subject'),
                field: 'subject',
                filterPlaceholder: translate('search'),
                render: rowData =>(
                    <Typography style={{whiteSpace:"nowrap"}}>
                        {rowData.type === "rfq" ?
                            <>
                                <span className={"sub-btn"}>
                                    {translate('rfq')}
                                </span>
                                <Button
                                    size="small"
                                    startIcon={<OpenInNewRoundedIcon color="primary"/>}
                                    component="a"
                                    variant="outlined"
                                    color="secondary"
                                    style={{minWidth:110}}
                                    href={makeSlug(`rfq/${rowData.rfq.slug}`,rowData.rfq.lang)}
                                    target="_blank"
                                >
                                    {truncate(rowData.rfq.name,8)}
                                </Button>
                            </>
                            :
                            rowData.type === "product" ?
                                <>
                                <span className={"sub-btn"}>
                                    {translate('product')}
                                </span>
                                    <Button
                                        size="small"
                                        startIcon={<OpenInNewRoundedIcon color="primary"/>}
                                        component="a"
                                        variant="outlined"
                                        color="secondary"
                                        style={{minWidth:110}}

                                        href={makeSlug(`product/${rowData.product.slug}`,rowData.product.lang)}
                                        target="_blank"
                                    >
                                        {truncate(rowData.product.name,8)}
                                    </Button>
                                </>
                            :
                                rowData.type === "business" ?
                                    <>
                                <span className={"sub-btn"}>
                                    {translate('provider')}
                                </span>
                                        <Button
                                            size="small"
                                            startIcon={<OpenInNewRoundedIcon color="primary"/>}
                                            component="a"
                                            variant="outlined"
                                            style={{minWidth:110}}

                                            color="secondary"
                                            href={makeSlug(`business/${rowData.business.slug}`,rowData.business.lang)}
                                            target="_blank"
                                        >
                                            {truncate(rowData.business.name,8)}
                                        </Button>
                                    </>
                                    :
                                    rowData.type === "sample" ?
                                        <>
                                <span className={"sub-btn"}>
                                    {translate('sample_request')}
                                </span>
                                            <Button
                                                size="small"
                                                startIcon={<OpenInNewRoundedIcon color="primary"/>}
                                                component="a"
                                                variant="outlined"
                                                color="secondary"
                                                style={{minWidth:120}}
                                                href={makeSlug(`product/${rowData.product.slug}`,rowData.product.lang)}
                                                target="_blank"
                                            >
                                                {truncate(rowData.product.name,10)}
                                            </Button>
                                        </>
                                        : null
                        }

                    </Typography>
                )
            },
            { title: translate('contact'),
                field: 'contact',
                hidden: this.props.width === "xs",
                filterPlaceholder: translate('search')
            },
            { title: translate('ticket_status'),
                editable: 'never',
                field: 'status',
                hidden: this.props.width === "xs",
                filterPlaceholder: translate('choose'),
                lookup: {
                    "sent" :
                        <Typography style={{color: "#58b956"}} variant="body2"> {translate('sentTicket')}</Typography>
                    ,"read" :
                        <Typography style={{color: "#51a5b9"}} variant="body2"> {translate('read')}</Typography>
                    , "unread":
                        <Typography style={{color: "#b9554e"}} variant="body2"> {translate('unread')}</Typography>
                },
            },
            { title: translate('updated_at'),editable: 'never',filtering: false, field: 'lastUpdate',hidden: this.props.width === "xs",},
            {
                title: translate('activity'),
                editable: 'never',
                field: 'action',
                render: rowData =>(
                    <>
                        <Button
                            variant="outlined"
                            size="small"
                            style={{marginBottom:5}}
                            startIcon={
                                <ChevronRightRoundedIcon/>
                            }
                            onClick={() => this.props.history.push(`/ticket/${rowData.id}`)}
                        >
                            {translate('view')}
                        </Button>
                    </>
                )
            }
        ],

        data: [],
        loading:false,
        ChangeLoader:false,
        manage:this.props.manage,
        type:this.props.type,
    };
    componentDidMount(){
        this.loadData();
    }
    async componentDidUpdate(prevProps){
        if(this.state.type !== this.props.type){
            await this.setState({
                type:this.props.type,
            })
            await this.loadData();

        }
    }
    loadData(){
        this.tableRef.current && this.tableRef.current.onQueryChange()

    }
    render(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        let {user} = this.props;
        return (
            <>
                {
                this.state.loading ? <AppCardLoaderTable/> :
                    <Container maxWidth="xl">
                        <MaterialTable
                            tableRef={this.tableRef}
                            icons={tableIcons}
                            title={""}
                            localization={getDataTableLocalization()}
                            columns={this.state.columns}
                            data={query =>
                                new Promise((resolve, reject) => {
                                    axios.get(`${defaultVariabls.url}/${this.props.manage ? "tickets" : "my-tickets"}/quickView?page=${query.page + 1}&per_page=${query.pageSize}&search=${query.search}&type=${this.state.type}`, config)
                                        .then(response => {
                                            let data = [];
                                            // eslint-disable-next-line
                                            response.data.data.map((row) => {
                                                data.push({
                                                    id: row.id,
                                                    receiver: row.receiver.first_name + " " + row.receiver.last_name,
                                                    starter: row.starter.first_name + " " + row.starter.last_name,
                                                    type: row.type,
                                                    rfq: row.rfq,
                                                    product: row.product,
                                                    business: row.business,
                                                    contact: user.id === row.starter.id ? row.receiver.first_name + " " + row.receiver.last_name : row.starter.first_name + " " + row.starter.last_name,
                                                    status: row.status,
                                                    lastUpdate:
                                                        <Typography className={this.classes.dateTable}>
                                                            {`${moment.tz(row.updated_at,'UTC').tz(getTimeZone()).fromNow()}`}
                                                            <br/>
                                                            {`${moment.tz(row.updated_at,'UTC').tz(getTimeZone()).format( 'HH:mm , YYYY/M/D')}`}
                                                        </Typography>
                                                })
                                            });
                                            resolve({
                                                data: data,
                                                page: response.data.current_page - 1,
                                                totalCount: response.data.total,
                                            })
                                        }).catch(error => {
                                        console.log(error.response)
                                    })
                                })
                            }
                            options={{
                                pageSize: TablePage,
                                pageSizeOptions: TableCount,
                            }}

                        />
                    </Container>
                }
            </>
        );
    }
}

const mstp = state => ({
    locale: state.locale,
    user: state.user,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(Tickets)))));