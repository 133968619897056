import React from 'react';
import Brightness7RoundedIcon from '@material-ui/icons/Brightness7Rounded';
import NightsStayRoundedIcon from '@material-ui/icons/NightsStayRounded';
import IconButton from "@material-ui/core/IconButton/IconButton";
import {connect, setStore} from "trim-redux";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import {translate} from "../../../config/lang";

class ToggleDarkMode extends React.Component {

    handleToggle(){
        setStore({
            global_loading: true
        })
        let {mode} = this.props;
        if(mode === "dark"){
            setStore({
                mode: "light",
            })
            localStorage.setItem('mode','light');
        }else{
            setStore({
                mode: "dark",
            })
            localStorage.setItem('mode','dark');
        }
        setTimeout(function() {
            setStore({
                global_loading: false
            })
        }, 2000);
    }
    render(){
        let {mode} = this.props;
        return (
            <>
                <Tooltip arrow title={mode === "dark" ? translate('toggleLight') : translate('toggleDark') }>
                <IconButton onClick={() => this.handleToggle()}>
                    {
                        mode === "dark" ? <Brightness7RoundedIcon/> : <NightsStayRoundedIcon/>
                    }
                </IconButton>
                </Tooltip>
            </>

        );
    }
}
const mstp = state => ({
    locale: state.locale,
    mode: state.mode,
});
export default connect(mstp)(ToggleDarkMode);