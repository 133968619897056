import React, {Component} from 'react';
import ReceiverMessageItem from "./ReceiverMessageItem";
import SenderMessageItem from "./SenderMessageItem";
import Box from "@material-ui/core/Box/Box";
import AppList from "./components/AppList";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../../config/design/styles";
import {connect} from "trim-redux";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

class Launcher extends Component {
    state = {
        see_unread:false,
    }
    render() {
        let {messageList,myself,contact,loading,manage} = this.props;
        return(
            <Box px={3} py={3}>
                {
                    loading ?
                        <Box style={{textAlign:"center",paddingTop:200}}>
                            <CircularProgress/>
                        </Box>
                        :
                        <AppList
                            animation='transition.slideUpIn'
                            data={messageList}
                            renderRow={(item) => {
                                if (item.sender_id === myself.id) {
                                    return (
                                        <SenderMessageItem
                                            author={myself}
                                            item={item}
                                            manage={manage}
                                            key={item.id}
                                        />
                                    );
                                } else {
                                    return(
                                        <ReceiverMessageItem
                                            author={contact}
                                            item={item}
                                            key={item.id}
                                        />
                                    )

                                }
                            }}
                        />
                }
            </Box>
            )
    }
}
const mstp = state => ({
    Translate: state.Translate,
    user: state.user,
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(Launcher))));