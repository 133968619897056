import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../../config/Translate";
import {defaultVariabls} from "../../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import Grid from "@material-ui/core/Grid/Grid";
import {connect} from "trim-redux";
import Typography from "@material-ui/core/Typography/Typography";
import IconButton from "@material-ui/core/IconButton/IconButton";
import MoneyOffRoundedIcon from '@material-ui/icons/MoneyOffRounded';
import {currencySymbol, price} from "../../../../config/values";
import Link from "@material-ui/core/Link/Link";
import FeaturedPlayListOutlinedIcon from '@material-ui/icons/FeaturedPlayListOutlined';
import StorefrontOutlinedIcon from '@material-ui/icons/StorefrontOutlined';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import {makeSlug, translate} from "../../../../config/lang";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import RemoveRoundedIcon from "@material-ui/icons/RemoveRounded";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import axios from "axios";

class SingleCartItem extends React.Component {

    state = {
        cart:this.props.cart,
        action_loading:false,

    };
    classes = this.props.classes;
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.cart !== this.props.cart){
            this.setState({
                cart:this.props.cart,
            })
        }
    }
    handleAction(action){
        this.setState({
            action_loading:true,
        })
        let {cart} = this.state;
        let data = new FormData();
        data.append('action',action);
        data.append('id',cart.id);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/cart/action`, data, config)
            .then(async response => {
                await this.props.reload();
                await this.setState({
                    action_loading:false,
                })
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(translate('dataAddingFailed'), {
                    variant: 'error',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                    SubmitDraftLoading: false
                });
            });
    }
    render(){
        let {showcase} = this.state.cart;
        let {cart,action_loading} = this.state;
        return (
            <div style={{border:"1px solid #eee",marginBottom:15,borderRadius:4,paddingLeft:15,paddingRight:15}}>
                <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                    <div style={{display:"flex",alignItems:"center"}}>
                        <IconButton
                            style={{marginRight:10}}
                            size="small"
                            disabled={action_loading}
                            onClick={() => this.handleAction('delete')}
                        >
                            <CloseRoundedIcon fontSize="small"/>
                        </IconButton>

                        <div  className="single_order_items">
                            <Typography style={{marginBottom:10}}>
                                <IconButton size="small"><FeaturedPlayListOutlinedIcon/></IconButton><strong>{translate('buyAd')}</strong></Typography>
                            <Typography  style={{marginBottom:10}}>
                                <IconButton size="small">
                                    <MonetizationOnOutlinedIcon/>
                                </IconButton>
                                {
                                    !!showcase.discount_price ?
                                        <>
                                            <del style={{color:"#f82e4e"}}>
                                                {price(showcase.price*cart.quantity)}
                                            </del>
                                            <strong>
                                                {price(showcase.discount_price*cart.quantity)+" AED"}</strong>
                                            <span style={{color:this.props.primary_color,fontSize:11}}>
                                            ({cart.quantity+" "+translate('number')})
                                        </span>
                                        </>
                                        :
                                        <>
                                            <strong>
                                                {price(showcase.price*cart.quantity)+" AED"}
                                            </strong>
                                        </>
                                }

                            </Typography>
                        </div>
                    </div>
                    <div className="controllers">
                        <IconButton
                            size="small"
                            disabled={action_loading}
                            onClick={() => this.handleAction('plus')}
                        >
                            <AddRoundedIcon/>
                        </IconButton>
                        <span style={{color:this.props.primary_color,padding:10}}>
                            {
                                action_loading ? <CircularProgress size={18}/> : cart.quantity
                            }
                        </span>
                        <IconButton
                            size="small"
                            disabled={action_loading}
                            onClick={() => this.handleAction('minus')}
                        >
                            <RemoveRoundedIcon/>
                        </IconButton>


                    </div>
                </div>
            </div>
        );
    }
}

const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_color: state.primary_color,
    global_need_verification: state.global_need_verification
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(SingleCartItem))));