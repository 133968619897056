import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from '../../../../config/Translate';
import axios from 'axios';
import { defaultVariabls } from '../../../../config/index';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import LoadingButton from "../../../components/elements/LoadingButton";
import {connect} from "trim-redux";
import AppCardLoaderFields from "../../../components/Skeletons/AppCardLoaderFields";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import FieldCounterWithoutBar from "../../../components/elements/FieldCounterWithoutBar";
import {updateRedux} from "../../../../config/Security";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import {Province} from "../../../components/elements/Province";
import Typography from "@material-ui/core/Typography/Typography";
class MakeAddress extends React.Component {
    classes = this.props.classes
    state={
        fields: {
            address:'',
            postal_code:'',
        },
        province:null,
        cities: null,
        city:null,
        errors:{},
        loading:!!this.props.id,
        SubmitLoading:false,
        id: !!this.props.id ? this.props.id : 0,
        editMode: !!this.props.id
    };
    componentDidMount(){
        if(!!this.props.id){
            this.loadData();
        }
    }
    loadData(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.get(`${defaultVariabls.url}/address/get/${this.state.id}`, config)
            .then(response => {
                this.setState({
                    fields:{
                        address: !!response.data.address ? response.data.address : '',
                        postal_code: !!response.data.postal_code ? response.data.postal_code : '',
                    },
                    province: response.data.province,
                    city: response.data.city,
                    loading:false,
                })
            })
            .catch(error => {
                console.log(error.response)
                this.setState({
                    loading:false
                })
            });
    }
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        errors[target.name] = "";
        fields[target.name] = target.value;
        this.setState({
            fields,
            errors
        });
    }

    handleValidation(callback){
        let { fields,province,city } = this.state;
        let errors = {};
        let formIsValid = true;

        //province
        if(!province) {
            formIsValid = false;
            errors['province'] = Translate.emptyField;
        }
        //city
        if(!city) {
            formIsValid = false;
            errors['city'] = Translate.emptyField;
        }
        //address
        if(!fields.address) {
            formIsValid = false;
            errors['address'] = Translate.emptyField;
        }
        //postal_code
        if(!fields.postal_code) {
            formIsValid = false;
            errors['postal_code'] = Translate.emptyField;
        }

        if(!formIsValid){
            this.props.enqueueSnackbar(Translate.someFieldsHasError, {
                variant: 'warning',
            });
            this.setState({
                SubmitLoading:false,
                errors
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        const {fields,id,province,city} = this.state;
        let data = new FormData();
        data.append('province', province);
        data.append('city', city);
        data.append('address', fields.address);
        data.append('postal_code', fields.postal_code);
        data.append('id', !!id ? id : 0);
        if (city !== null ){
            if(city.name === undefined){
                data.append('city', city);
            }else {
                data.append('city', city.name);
            }
        }
        if (province !== null ){
            if(province.name === undefined){
                data.append('province', province);
            }else{
                data.append('province', province.name);
            }
        }
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/address/make`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.detailsSet, {
                    variant: 'success',
                });
                this.setState({
                    SubmitLoading:false
                });
                updateRedux();
                this.props.handleDone();

            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(Translate.detailsSetFailed, {
                    variant: 'error',
                });
                this.setState({
                    SubmitLoading:false
                });
            });
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }

        });
    }
    handChangeProvince(province){
        if (province === null){
            this.setState({
                province,
                cities:null,
                city:null
            })
        }else{
            this.setState({
                province,
                cities: province.Cities,
            })
        }

    }
    handChangeCity(city){
        this.setState({
            city
        })
    }
    render() {
        const {errors,fields,SubmitLoading,province,city,editMode} = this.state;
        if (this.state.loading) {
            return (
                <AppCardLoaderFields/>
            )
        } else {
            return (
                <React.Fragment>
                    <Grid container  spacing={2} justify="center" alignItems="center" >
                        <CssBaseline/>
                        <Grid item xs={12} md={6}>
                            {
                                editMode ?
                                    <TextField
                                        required
                                        autocomplete="off"
                                        label={Translate.province}
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        value={province}
                                    />
                                    :
                                    <Autocomplete
                                        options={Province}
                                        required
                                        onChange={(event, value) => this.handChangeProvince(value)}
                                        getOptionLabel={option => option.name}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                required
                                                label={Translate.province}
                                                variant="outlined"
                                                value={province}
                                                fullWidth
                                                autocomplete="off"
                                                error = {!!errors['province']}
                                                helperText={errors['province']}
                                            />
                                        )}
                                    />
                            }

                        </Grid>
                        <Grid item xs={12} md={6}>
                            {
                                editMode ?
                                    <TextField
                                        required
                                        autocomplete="off"
                                        label={Translate.city}
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        value={city}
                                    />
                                    :
                                    <Autocomplete
                                        options={this.state.cities}
                                        onChange={(event, value) => this.handChangeCity(value)}
                                        getOptionLabel={option => option.name}
                                        value={city}
                                        required
                                        disabled={this.state.cities === null}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                required
                                                autocomplete="off"
                                                label={Translate.city}
                                                variant="outlined"
                                                fullWidth
                                                error = {!!errors['city']}
                                                helperText={errors['city']}
                                            />
                                        )}
                                    />
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                value={fields.postal_code}
                                fullWidth
                                label={Translate.postal_code}
                                name="postal_code"
                                type="number"
                                onChange={this.handleChange.bind(this)}
                                error = {!!errors['postal_code']}
                                helperText={errors['postal_code']}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                value={fields.address}
                                fullWidth
                                label={Translate.address}
                                name="address"
                                minRows={4}
                                maxRows={5}
                                multiline
                                onChange={this.handleChange.bind(this)}
                                error = {!!errors['address']}
                                helperText={errors['address']}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <FieldCounterWithoutBar length={fields.address.length} max={500}/>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                onClick = {this.handleSubmit.bind(this)}
                                loading = {SubmitLoading}
                            >
                                {Translate.setDetails}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </React.Fragment>
            );
        }
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MakeAddress))));