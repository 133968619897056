import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../../config/Translate";
import TextField from "@material-ui/core/TextField/TextField";
import validator from "validator";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import LoadingButton from "../../../components/elements/LoadingButton";
import AppAnimate from "../../../components/AppAnimate";
import AppCard from "../../../components/layouts/components/AppCard";
import Grid from "@material-ui/core/Grid/Grid";
import {connect, setStore} from "trim-redux";
import BreadCrumbs from "../../../components/elements/BreadCrumbs";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import Alert from "@material-ui/lab/Alert/Alert";
import AppCardLoaderFields from "../../../components/Skeletons/AppCardLoaderFields";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import Typography from "@material-ui/core/Typography/Typography";
import SingleFileChooser from "../../fileManager/Components/SingleFileChooser";
class Verification extends React.Component {

    constructor(props){
        super(props);
        this.ThumbChild = React.createRef();
        this.EditorChild = React.createRef();
    }
    state = {
        fields:{
            bank_name:"",
            credit_number:"",
            sheba:"",
        },
        credit_card_image: null,
        errors:{},
        loading: true,
        SubmitLoading: false,
        editMode: false,
        confirmed: true,
    };
    classes = this.props.classes;
    componentDidMount(){
        this.loadData();
    }
    handleCreditImage(id){
        let {errors} = this.state;
        errors['creditcard_image'] ='';
        this.setState({
            creditcard_image: id
        })
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/user/getVerification`, config)
                .then(response => {
                    this.setState({
                        fields: {
                            bank_name:response.data.bank_name,
                            sheba:response.data.sheba,
                            credit_number:response.data.credit_number,
                        },
                        credit_card_image: response.data.credit_card,
                        confirmed: parseInt(response.data.user.verified),
                        editMode:true,
                        loading:false

                    })

                }).catch(error => {
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }
    }
    handleChange(e){
        let {errors,fields} = this.state;
        let target = e.target;
        fields[target.name] = target.value;
        errors[target.name] = "";
        this.setState({fields});
    }

    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //name
        if(validator.isEmpty(fields.bank_name)){
            formIsValid = false;
            errors['bank_name'] = Translate.emptyField;
        }
        //name
        if(validator.isEmpty(fields.sheba)){
            formIsValid = false;
            errors['sheba'] = Translate.emptyField;
        }else if(fields.sheba.length !== 24){
            formIsValid = false;
            errors['sheba'] = Translate.wrongSheba;
        }
        //name
        if(validator.isEmpty(fields.credit_number)){
            formIsValid = false;
            errors['credit_number'] = Translate.emptyField;
        }else if(fields.credit_number.length !== 16){
            formIsValid = false;
            errors['credit_number'] = Translate.wrongCreditNumber;
        }
        if(!formIsValid){
            this.props.enqueueSnackbar(Translate.someFieldsHasError, {
                variant: 'warning',
            });
            this.setState({
                errors,
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        let { fields,credit_card_image,confirmed} = this.state;
        let data = new FormData();
        data.append('bank_name', fields.bank_name);
        data.append('sheba', fields.sheba);
        data.append('credit_number', fields.credit_number);
        data.append('creditcard_image', !!credit_card_image ? credit_card_image.id : null);
        data.append('confirmed',  confirmed ? 1 : 0);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/user/setVerification`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.verificationDataSets, {
                    variant: 'success',
                });
                this.setState({
                    loading:true,
                    SubmitLoading: false,
                });
                this.loadData();


            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(Translate.dataAddingFailed, {
                    variant: 'error',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                    SubmitDraftLoading: false
                });
            });

    }
    handleSubmit(e){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    handleChangeConfirmed(){
        this.setState({
            confirmed: !this.state.confirmed,
        })
    }
    render(){
        let {fields, errors,SubmitLoading,confirmed,credit_card_image,editMode} = this.state;
        let {user} = this.props;
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={Translate.verificationDetails}>
                {

                    <Grid container spacing={2} justify="center">
                        <Grid item xs={12}>
                            <AppCard
                                title={Translate.verificationDetails}
                                action={
                                    <BreadCrumbs singleLevel firstText={Translate.verificationDetails}/>
                                }>
                                {
                                    this.state.loading ? <AppCardLoaderFields/> :
                                        <Grid container spacing={2} justify="center" alignItems="center">
                                            <Grid item xs={12} md={11} lg={10} alignItems="center">
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        {
                                                            !editMode ?
                                                                <Alert
                                                                    severity="info"
                                                                >
                                                                    {Translate.verificationDescription}
                                                                </Alert>
                                                                :
                                                                <>
                                                                    {
                                                                        !! user.role.permission.manageShops ?
                                                                            <>
                                                                                <Alert
                                                                                    severity="info"
                                                                                    action={
                                                                                        <FormControlLabel
                                                                                            style={{marginRight:10}}
                                                                                            control={
                                                                                                <Switch
                                                                                                    checked={confirmed}
                                                                                                    onChange={this.handleChangeConfirmed.bind(this)}
                                                                                                    value={confirmed}
                                                                                                    color="primary"
                                                                                                />
                                                                                            }
                                                                                            label={confirmed ? Translate.confirmed : Translate.unConfirmed}
                                                                                        />
                                                                                    }
                                                                                >
                                                                                    {Translate.confirmationStatus}
                                                                                </Alert>
                                                                            </>
                                                                            :
                                                                            confirmed ?
                                                                                <Alert  severity="success">
                                                                                    {Translate.yourBankInfoIsActive}
                                                                                </Alert>
                                                                                :
                                                                                <Alert severity="warning">
                                                                                    {Translate.yourBankInfoIsInchecking}
                                                                                </Alert>
                                                                    }
                                                                </>

                                                        }
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            variant="outlined"
                                                            name="bank_name"
                                                            label={Translate.bank_name}
                                                            type="text"
                                                            value={fields.bank_name}
                                                            onChange={this.handleChange.bind(this)}
                                                            error = {!!errors['bank_name']}
                                                            helperText={errors['bank_name']}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            variant="outlined"
                                                            name="sheba"
                                                            type="number"
                                                            label={Translate.sheba}
                                                            value={fields.sheba}
                                                            onChange={this.handleChange.bind(this)}
                                                            error = {!!errors['sheba']}
                                                            helperText={errors['sheba']}
                                                            className="insta-field"
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        IR
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            variant="outlined"
                                                            name="credit_number"
                                                            type="number"
                                                            label={Translate.credit_number}
                                                            value={fields.credit_number}
                                                            onChange={this.handleChange.bind(this)}
                                                            error = {!!errors['credit_number']}
                                                            helperText={errors['credit_number']}
                                                            className="insta-field"

                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <SingleFileChooser
                                                            title={Translate.credit_card_image}
                                                            onChange={(credit_card_image) =>this.setState({credit_card_image,errors:[]})}
                                                            initial={credit_card_image}
                                                            type="image"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <LoadingButton
                                                            variant="contained"
                                                            color="primary"
                                                            onClick = {this.handleSubmit.bind(this)}
                                                            loading = {SubmitLoading}
                                                        >
                                                            {Translate.setDetails}
                                                        </LoadingButton>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                }
                            </AppCard>
                        </Grid>
                    </Grid>
                }
            </AppAnimate>

        );
    }
}

const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(Verification))));