import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Translate} from "../../../../config/Translate";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import axios from "axios";
import {defaultVariabls} from "../../../../config";
import {connect} from "trim-redux";
import TextField from "@material-ui/core/TextField/TextField";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../config/design/styles";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import IconButton from "@material-ui/core/IconButton/IconButton";
import Grid from "@material-ui/core/Grid/Grid";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import LoadingButton from "../../../components/elements/LoadingButton";
import ChatRoundedIcon from '@material-ui/icons/ChatRounded';
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";
import Paper from "@material-ui/core/Paper/Paper";
import Menu from "@material-ui/core/Menu/Menu";
import ButtonGroup from "@material-ui/core/ButtonGroup/ButtonGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
class SendMessageToUserButton extends React.Component{
    state = {
        fields:{
            subject:"",
            message:"",
        },
        sms:false,
        email:true,
        user:{},
        user_id : this.props.user_id,
        SubmitLoading:false,
        loading:true,
        open:false,
        errors:{},
    };
    componentDidMount(){
        this.loadData()
    }
    loadData(){
        let loginToken = localStorage.getItem('ACT');
        let {user_id} = this.state;
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.get(`${defaultVariabls.url}/user/get/${user_id}`, config)
            .then(response => {
                this.setState({
                    user:response.data,
                    loading:false,
                })
            })
            .catch(error => {
                this.setState({
                    loading:false
                })
            });
    }
    toggleOpen(){
        this.setState({
            open: !this.state.open,
        })
    }
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        errors[target.name] = "";
        fields[target.name] = target.value;
        this.setState({
            fields,
            errors
        });
    }

    handleValidation(callback){
        let { fields,type } = this.state;
        let errors = {};
        let formIsValid = true;


        //subject
        if(!fields.subject) {
            formIsValid = false;
            errors['subject'] = Translate.emptyField;
        }
        //subject
        if(!fields.message) {
            formIsValid = false;
            errors['message'] = Translate.message;
        }

        if(!formIsValid){
            this.props.enqueueSnackbar(Translate.someFieldsHasError, {
                variant: 'warning',
            });
            this.setState({
                SubmitLoading:false,
                errors
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        const {fields,sms,email,user} = this.state;
        let data = new FormData();
        data.append('subject', fields.subject);
        data.append('message', fields.message);
        data.append('sms', sms ? 1 : 0);
        data.append('email', email ? 1 : 0);
        data.append('user_id', user.id);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/adminMessage/send`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.ticket_sent, {
                    variant: 'success',
                });
                this.setState({
                    SubmitLoading:false,
                    open:false
                });

            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(Translate.detailsSetFailed, {
                    variant: 'error',
                });
                this.setState({
                    SubmitLoading:false
                });
            });
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }

        });
    }
    render(){
        let {loading,SubmitLoading,errors,fields,open,sms,email} = this.state;
        let {user} = this.props;
        return (
            <>
                <Button
                    variant="outlined"
                    color="primary"
                    style={{marginBottom:20,width:"100%"}}
                    onClick={() => this.toggleOpen()}
                    startIcon={<ChatRoundedIcon/>}
                >
                    {Translate.sendMessageToUser}
                </Button>

                <Dialog
                    open={open}
                    onClose={() => this.toggleOpen()}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    maxWidth="md"
                >
                    <DialogTitle id="form-dialog-title">
                        <IconButton
                            onClick={() => this.toggleOpen()}
                            style={{position:"absolute",left:15,top:15}}
                            size="small"
                        >
                            <CloseRoundedIcon fontSize="small"/>
                        </IconButton>
                        {Translate.sendMessageToUser}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    value={fields.subject}
                                    fullWidth
                                    required
                                    label={Translate.subject}
                                    name="subject"
                                    onChange={this.handleChange.bind(this)}
                                    error = {!!errors['subject']}
                                    helperText={errors['subject']}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    value={fields.message}
                                    fullWidth
                                    required
                                    label={Translate.message}
                                    name="message"
                                    minRows={5}
                                    multiline
                                    onChange={this.handleChange.bind(this)}
                                    error = {!!errors['message']}
                                    helperText={errors['message']}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={email}
                                            onChange={() => this.setState({email: !email})}
                                            name="email"
                                        />
                                    }
                                    label={Translate.email}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={sms}
                                            onChange={() => this.setState({sms: !sms})}
                                            name="sms"
                                        />
                                    }
                                    label={Translate.sms}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LoadingButton
                                    disabled={!sms && !email}
                                    variant="contained"
                                    color="primary"
                                    onClick = {this.handleSubmit.bind(this)}
                                    loading = {SubmitLoading}
                                >
                                    {Translate.sendMessage}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </>
        );
    }
}
const mstp = state => ({
    user: state.user,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(SendMessageToUserButton)))));