import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import validator from 'validator';
import axios from 'axios';
import { defaultVariabls } from '../../../../config/index';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import ProfileImage from "../../fileManager/Components/ProfileImage";
import LoadingButton from "../../../components/elements/LoadingButton";
import {connect, getStore, setStore} from "trim-redux";
import LockOpenRoundedIcon from '@material-ui/icons/LockOpenRounded';
import Button from "@material-ui/core/Button/Button";
import AppCardLoaderFields from "../../../components/Skeletons/AppCardLoaderFields";
import {checkMelliCode, updateRedux} from "../../../../config/Security";
import Typography from "@material-ui/core/Typography/Typography";
import GetConfirmationButton from "../../../components/elements/GetConfirmationButton";
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';
import SendMessageToUserButton from "../../admin/users/SendMessageToUserButton";
import Feature from "../../../components/elements/Feature";
import {translate} from "../../../../config/lang";
import MuiPhoneNumber from "material-ui-phone-number";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import IconButton from "@material-ui/core/IconButton/IconButton";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import CountryChooser from "../../../components/fields/CountryChooser";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
class details extends React.Component {
    classes = this.props.classes
    state={
        fields: {
            email:'',
            mobile:'',
            first_name:'',
            last_name:'',
            company_name: '',
            role: ''
        },
        lock:true,
        errors:{},
        loading:true,
        country:'',
        SubmitLoading:false,
        confirmed:'',
        mobile_confirmed:false,
        melli_card:null,
    };
    componentDidMount(){
        this.loadData();
    }
    loadData(){
        let loginToken = localStorage.getItem('ACT');
        let {manage,user_id} = this.props;
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.get(`${defaultVariabls.url}/user/get/${manage ? user_id : this.props.user.id}`, config)
            .then(response => {
                this.setState({
                    fields:{
                        name: response.data.name,
                        first_name: response.data.first_name,
                        last_name: response.data.last_name,
                        company_name: response.data.company_name,
                        email:response.data.email,
                        mobile:response.data.mobile,
                        role:response.data.role.name,
                    },
                    mobile_confirmed: parseInt(response.data.mobile_confirmed),
                    country:response.data.country,
                    loading:false,
                })
            })
            .catch(error => {
                this.setState({
                    loading:false
                })
            });
    }
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        errors[target.name] = "";
        fields[target.name] = target.value;
        this.setState({
            fields,
        });
    }
    handleValidation(callback){
        let { fields } = this.state;
        let {user} = this.props;
        let errors = {};
        let formIsValid = true;
        if(user.role.type !== "admin"){
            try {
                //first_name
                if(validator.isEmpty(fields.first_name)){
                    formIsValid = false;
                    errors['first_name'] = translate('emptyField');
                }else if(!validator.isByteLength(fields.first_name,{min:0, max:255})){
                    formIsValid = false;
                    errors['first_name'] = translate('maxLength255');
                }
                //last_name
                if(validator.isEmpty(fields.last_name)){
                    formIsValid = false;
                    errors['last_name'] = translate('emptyField');
                }else if(!validator.isByteLength(fields.last_name,{min:0, max:255})){
                    formIsValid = false;
                    errors['last_name'] = translate('maxLength255');
                }

            }catch (e) {
                console.log(e)
            }
        }

        if(!formIsValid){
            this.props.enqueueSnackbar(translate('someFieldsHasError'), {
                variant: 'warning',
            });
            this.setState({
                SubmitLoading:false,
                errors
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        const {fields,confirmed,mobile_confirmed,lock} = this.state;
        let data = new FormData();
        data.append('first_name', fields.first_name);
        data.append('last_name', fields.last_name);
        data.append('company_name', fields.company_name);
        data.append('confirmed', confirmed);
        data.append('mobile_confirmed', !!mobile_confirmed ?  1 : 0);
        if(!lock){
            data.append('mobile', fields.mobile);
            data.append('email', fields.email);
        }
        let loginToken = localStorage.getItem('ACT');
        let {manage,user_id} = this.props;
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/user/set/${manage ? user_id : this.props.user.id}`, data, config)
            .then(response => {
                console.log(response);
                this.props.enqueueSnackbar(translate('detailsSet'), {
                    variant: 'success',
                });
                this.setState({
                    SubmitLoading:false
                });
                if(!manage){
                    this.setState({
                        loading:true,
                        lock:true
                    })
                    if(!lock){
                        window.location.reload();
                    }else{
                        updateRedux();
                        this.loadData();
                    }

                }else{
                    this.setState({
                        loading:true,
                        lock:true
                    })
                    this.loadData();
                }
            })
            .catch(error => {
                console.log(error.response);
                if(error.response.status === 422){
                    this.props.enqueueSnackbar(translate('anotherUserHasThisInfo'), {
                        variant: 'error',
                    });
                    this.setState({
                        loading:true,
                    })
                    this.loadData();
                }else{
                    this.props.enqueueSnackbar(translate('detailsSetFailed'), {
                        variant: 'error',
                    });
                }
                this.setState({
                    SubmitLoading:false
                });
            });
    }
    refreshStore(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            axios.get(`${defaultVariabls.url}/checkLogin`, config)
                .then(response => {
                    setStore({
                        isAuth : true,
                        user : response.data,
                        umc : response.data.umc,
                    });

                }).catch(error => {
                setStore({
                    isAuth : false,
                    user : null,
                });
            })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        });
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    handleChangeConfirmed(){
        this.setState({
            confirmed: this.state.confirmed === "confirmed" ? "pending" : "confirmed",
        })
    }
    loginToUserAccount(){
        let {mobile_confirmed} = this.state;
        if(!!mobile_confirmed){
            this.setState({
                loading:true
            })
            let loginToken = localStorage.getItem('ACT');
            let {user_id} = this.props;
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            axios.get(`${defaultVariabls.url}/loginToUserAccount/${user_id}`, config)
                .then(async response => {
                    await localStorage.setItem('ADMIN_ACT',localStorage.getItem('ACT'));
                    await localStorage.setItem('ACT',response.data.accessToken);
                    await updateRedux();
                    let user = await getStore('user')
                    let username = user.email;
                    let login_token = user.login_token;
                    await window.location.replace(`${defaultVariabls.website}/loginFromPanel/${username}/${login_token}`);
                })
                .catch(error => {
                    this.setState({
                        loading:false
                    })
                });
        }else{
            this.props.enqueueSnackbar(translate('userShouldConfirmMobileFirst'), {
                variant: 'error',
            });
        }

    }
    handleChangeMobile(mobile){
        let {fields,errors} = this.state;
        let res = mobile.replace(/\s/g, '');
        res = res.replace('(','')
        res = res.replace(')','')
        res = res.replace('-','')
        fields.mobile = res
        errors.mobile = '';
        this.setState({
            fields,
            errors
        })
    }
    render() {
        const {errors,fields,lock,SubmitLoading,mobile_confirmed} = this.state;
        let {purchase,user,manage,user_id,primary_color} = this.props;
        if (this.state.loading) {
            return (
                <AppCardLoaderFields/>
            )
        } else {
            return (
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12} md={11} lg={10}>
                        <Grid container  spacing={2} justify="center" alignItems="flex-start">
                            <CssBaseline/>
                            {
                                !!purchase ? null :
                                    <Grid item xs={12} md={3} style={{textAlign:"center"}}>
                                        {
                                            !!user.role.permission.manageSettings &&
                                            <>
                                                <Feature check="login_to_user_account">
                                                    <Button
                                                        style={{marginBottom:20,marginTop:20,width:"100%"}}
                                                        startIcon={<LockOpenRoundedIcon/>}
                                                        variant="outlined"
                                                        color="primary"
                                                        onClick={() => this.loginToUserAccount()}
                                                    >
                                                        {translate('loginToUserAccount')}
                                                    </Button>
                                                </Feature>
                                                <Feature check="admin_message">
                                                    <SendMessageToUserButton user_id={user_id}/>
                                                </Feature>
                                            </>
                                        }

                                        <ProfileImage manage={this.props.manage} user_id={this.props.user_id}/>
                                    </Grid>
                            }
                            <Grid item xs={12} md={9}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <RadioGroup  aria-label="role" name="role" value={this.state.fields.role}
                                                    onChange={this.handleChange.bind(this)}>
                                            <Grid container>
                                                <Grid item xs={6} style={{textAlign: "right"}}>
                                                    <FormControlLabel
                                                        value="user"
                                                        disabled
                                                        control={<Radio/>}
                                                        className={this.classes.correctText}
                                                        label={translate('buyer')}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} style={{textAlign: "left"}}>
                                                    <FormControlLabel
                                                        value="supplier"
                                                        disabled
                                                        control={<Radio/>}
                                                        className={this.classes.correctText}
                                                        label={<Typography>{translate('supplier')}</Typography>}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </RadioGroup>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            value={fields.first_name}
                                            fullWidth
                                            color="secondary"
                                            id="first_name"
                                            label={translate('first_name')}
                                            name="first_name"
                                            onChange={this.handleChange.bind(this)}
                                            error = {!!errors['first_name']}
                                            helperText={errors['first_name']}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            value={fields.last_name}
                                            fullWidth
                                            color="secondary"
                                            id="last_name"
                                            label={translate('last_name')}
                                            name="last_name"
                                            onChange={this.handleChange.bind(this)}
                                            error = {!!errors['last_name']}
                                            helperText={errors['last_name']}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            variant="outlined"
                                            value={fields.company_name}
                                            label={translate('company_name')}
                                            name="company_name"
                                            color="secondary"
                                            onChange={this.handleChange.bind(this)}
                                            error = {!!errors['company_name']}
                                            helperText={errors['company_name']}
                                        />
                                    </Grid>
                                    {
                                        !!this.state.country &&
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                required
                                                color="secondary"
                                                disabled
                                                fullWidth
                                                variant="outlined"
                                                label={translate('country')}
                                                type="text"
                                                value={this.state.country.name}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <img src={`https://flagsapi.com/${this.state.country.iso2}/flat/16.png`} style={{marginRight:5}}/>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                    }

                                    <Grid item xs={12} md={6}>
                                        <MuiPhoneNumber
                                            required
                                            fullWidth
                                            id="mobile"
                                            label={translate('mobile')}
                                            name="mobile"
                                            color="secondary"
                                            onChange={this.handleChangeMobile.bind(this)}
                                            variant="outlined"
                                            disabled={lock}
                                            disableAreaCodes={lock}
                                            value={fields.mobile}
                                            InputProps={!!user.mobile_confirmed &&{
                                                endAdornment: (
                                                    <React.Fragment>
                                                        <Tooltip title="Verified">
                                                            <IconButton color="primary">
                                                                <CheckCircleOutlineIcon/>
                                                            </IconButton>
                                                        </Tooltip>

                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            value={fields.email}
                                            fullWidth
                                            id="email"
                                            disabled={lock}
                                            color="secondary"
                                            label={translate('email')}
                                            name="email"
                                            autoComplete="email"
                                            onChange={this.handleChange.bind(this)}
                                            error = {!!errors['email']}
                                            helperText={errors['email']}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {
                                            !!lock ?
                                                <Button
                                                    onClick={() => this.setState({lock:false})}
                                                    color="secondary"
                                                >
                                                    {translate('changeMobileOrEmail')}
                                                </Button>
                                                :
                                                <Typography classes={this.classes.condir}>{translate('changeMobileOrEmailDesc')}</Typography>
                                        }

                                    </Grid>
                                    {/*{
                                    manage &&
                                    <Grid item xs={12}>
                                        {
                                            mobile_confirmed ?
                                                <Typography style={{color: "#38bf84"}}>{translate('userMobileIsConfirmed')}</Typography>
                                                :
                                                <Typography style={{color: "#bfb20d",display:"flex",alignItems:"center"}}>
                                                    <span>
                                                    {translate('userMobileIsNotConfirmed')}
                                                    </span>
                                                    <GetConfirmationButton
                                                        handleAction={() => this.setState({mobile_confirmed:1})}
                                                        question={translate('doYouWanaConfirm')}
                                                        icon={<CheckBoxRoundedIcon/>}
                                                        color={primary_color}
                                                        tooltip={translate('confirm')}
                                                    >
                                                        <CheckBoxRoundedIcon fontSize="small" style={{color:primary_color}}/>
                                                    </GetConfirmationButton>
                                                </Typography>
                                        }
                                    </Grid>
                                }*/}
                                </Grid>
                            </Grid>
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                onClick = {this.handleSubmit.bind(this)}
                                loading = {SubmitLoading}
                            >
                                {translate('setDetails')}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
            );
        }
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_color: state.primary_color
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(details))));