import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../../config/Translate";
import { withSnackbar } from 'notistack';
import BreadCrumbs from "../../../components/elements/BreadCrumbs";
import AppAnimate from "../../../components/AppAnimate";
import AppCard from "../../../components/layouts/components/AppCard";
import WalletHistory from "./WalletHistory";
import {withRouter} from "react-router";
import {translate} from "../../../../config/lang";


class WalletHistoryPage extends React.Component {
    classes = this.props.classes;
    render(){
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={translate('manageUserWallet')}>
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12} lg={11}>
                        <AppCard
                            title={translate('manageUserWallet')}
                            action={
                                <BreadCrumbs  firstText={translate('manageWallet')} firstLink="/manage/wallets" secondText={translate('manageUserWallet')}/>
                            }>
                            <WalletHistory id={this.props.match.params.id}/>
                        </AppCard>
                    </Grid>
                </Grid>
            </AppAnimate>
        );
    }
}
export default withRouter(withSnackbar(withStyles(Styles)(WalletHistoryPage)));
