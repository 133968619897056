import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../../../config/Translate";
import { withSnackbar } from 'notistack';
import BreadCrumbs from "../../../../components/elements/BreadCrumbs";
import AppAnimate from "../../../../components/AppAnimate";
import AppCard from "../../../../components/layouts/components/AppCard";
import CodeEditor from "../../template/components/CodeEditor";
import Accordion from "@material-ui/core/Accordion/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails/AccordionDetails";
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import SubTitle from "../../../../components/elements/SubTitle";
import Alert from "@material-ui/lab/Alert/Alert";
import LiveHelpRoundedIcon from '@material-ui/icons/LiveHelpRounded';
import AlertTitle from "@material-ui/lab/AlertTitle/AlertTitle";
import Typography from "@material-ui/core/Typography/Typography";
import {translate} from "../../../../../config/lang";

class CustomCode extends React.Component {
    classes = this.props.classes;
    render(){
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={translate('custom_code')}>
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12}>
                        <AppCard
                            title={translate('custom_code')}
                            action={
                                <BreadCrumbs singleLevel firstText={translate('custom_code')}/>
                            }>
                            <Alert style={{marginBottom:30}} severity="info" icon={<LiveHelpRoundedIcon fontSize="inherit" />} >
                                <AlertTitle><strong>{translate('customCodeTitle')}</strong></AlertTitle>
                                {translate('customCodeText')}
                            </Alert>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreRoundedIcon />}
                                >
                                    <SubTitle align="left">{translate('custom_css')}</SubTitle>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2} justify="center" alignItems="center">
                                        <Grid item xs={12}>
                                            <CodeEditor type="custom_css"/>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreRoundedIcon />}
                                >
                                    <SubTitle align="left">{translate('custom_js')}</SubTitle>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2} justify="center" alignItems="center">
                                        <Grid item xs={12}>
                                            <CodeEditor type="custom_js"/>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreRoundedIcon />}
                                >
                                    <SubTitle align="left">{translate('custom_head')}</SubTitle>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2} justify="center" alignItems="center">
                                        <Grid item xs={12}>
                                            <Typography>
                                                {translate('customHeadDescription')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CodeEditor type="custom_head"/>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreRoundedIcon />}
                                >
                                    <SubTitle align="left">{translate('custom_body')}</SubTitle>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2} justify="center" alignItems="center">
                                        <Grid item xs={12}>
                                            <Typography>
                                                {translate('customBodyDescription')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CodeEditor type="custom_body"/>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </AppCard>
                    </Grid>
                </Grid>
            </AppAnimate>
        );
    }
}
export default withSnackbar(withStyles(Styles)(CustomCode));
