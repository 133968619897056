import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import TextField from "@material-ui/core/TextField/TextField";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {connect, setStore} from "trim-redux";
import { withSnackbar } from 'notistack';
import LoadingButton from "../../../components/elements/LoadingButton";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {translate} from "../../../../config/lang";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import FormControl from "@material-ui/core/FormControl/FormControl";
import AppCardLoaderFields from "../../../components/Skeletons/AppCardLoaderFields";
import {MenuItem} from "@material-ui/core";
import ChipInput from "material-ui-chip-input";
import Divider from "@material-ui/core/Divider/Divider";
import Typography from "@material-ui/core/Typography/Typography";

class MakeField extends React.Component {
    state = {
        fields:{
            field_type:"text",
            unique_key:"",
            name:'',
            arabic_name:'',
            description:'',
            arabic_description:'',
        },
        options:[],
        arabic_options:[],
        units:[],
        arabic_units:[],
        loading: true,
        SubmitLoading:false,
        id: this.props.id,
        errors:[],
        business_type:"all",
        editMode:false,
        required:false,
        in_compare:false,
    };

    classes = this.props.classes;
    async componentDidMount(){
        let {id} = this.props;
        if(!!id){
            this.setState({
                editMode:true
            })
            await this.loadData();
        }else{
            this.setState({
                loading:false,
            })
        }

    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        let {id} = this.state;
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/field/get/${id}`, config)
                .then(response => {
                    this.setState({
                        fields: {
                            unique_key: response.data.unique_key,
                            name: response.data.name,
                            field_type: response.data.field_type,
                            arabic_name: response.data.arabic_name,
                            description: response.data.description,
                            arabic_description: response.data.arabic_description,
                        },
                        options: !!response.data.options ? response.data.options.split(",") : [],
                        arabic_options: !!response.data.arabic_options ? response.data.arabic_options.split(",") : [],
                        units: !!response.data.units ? response.data.units.split(",") : [],
                        arabic_units: !!response.data.arabic_units ? response.data.arabic_units.split(",") : [],
                        required:parseInt(response.data.required) === 1,
                        in_compare:parseInt(response.data.in_compare) === 1,
                        loading: false,
                    })
                    return true
                }).catch(error => {
                    console.log(error)
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
                loading:false
            });
            this.setState({
                loading:false
            })
        }
    }
    handleChangeConfirmed(){
        this.setState({
            confirmed: !this.state.confirmed,
        })
    }
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        if(target.name === "unique_key"){
            fields[target.name] = target.value.replace(/\s/g, '').toLowerCase();
        }else{
            fields[target.name] = target.value;
        }
        errors[target.name] = "";
        this.setState({
            fields,
            PreventingToExit:true
        });

    }
    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //comment
        if(!fields.name) {
            formIsValid = false;
            errors['name'] = translate('emptyField');
        }
        if(!fields.arabic_name) {
            formIsValid = false;
            errors['arabic_name'] = translate('emptyField');
        }
        if(!fields.unique_key) {
            formIsValid = false;
            errors['unique_key'] = translate('emptyField');
        }
        if(!formIsValid){
            this.setState({
                SubmitLoading:false,
                errors
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        let { fields,required,in_compare,options,arabic_options,units,arabic_units} = this.state;
        let {type,position_type,main_type} = this.props;
        let data = new FormData();
        data.append('id', this.state.id);
        data.append('unique_key', fields.unique_key);
        data.append('name', fields.name);
        data.append('field_type', fields.field_type);
        data.append('options',options.toString());
        data.append('arabic_options',arabic_options.toString());
        data.append('units',units.toString());
        data.append('arabic_units',arabic_units.toString());
        data.append('arabic_name', fields.arabic_name);
        data.append('description', fields.description);
        data.append('arabic_description', fields.arabic_description);
        data.append('required', !!required ? 1 : 0);
        data.append('in_compare', !!in_compare ? 1 : 0);
        if(main_type === "product" || main_type === "rfq"){
            data.append('category_id', !!type ? !!type.id ? type.id : "all" : "all");
        }else{
            data.append('business_type', !!type ? !!type.id ? type.id : "all" : "all");
        }
        data.append('position_type', position_type);
        data.append('type', !!main_type ? main_type : "business");

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/field/make`, data, config)
            .then(response => {
                this.setState({
                    fields:{
                        unique_key:"",
                        name:'',
                        arabic_name:'',
                        description:'',
                        arabic_description:'',
                    },
                    items:[],
                    loading: true,
                    SubmitLoading:false,
                    id: this.props.id,
                    errors:[],
                    type:"business",
                    business_type:"all",
                    field_type:"text",
                    required:false,
                    in_compare:false,
                });

                this.props.reload()
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(translate('dataEditingFailed'), {
                    variant: 'error',
                });
                this.setState({
                    SubmitLoading:false
                })
            });

    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    async handleAddChip(item,place){
        let {options,arabic_options,units,arabic_units} = this.state;
        if(place === "arabic_options"){
            arabic_options.push(item);
            this.setState({
                arabic_options
            })
        }else if(place === "units"){
            units.push(item);
            this.setState({
                units
            })
        }else if(place === "arabic_units"){
            arabic_units.push(item);
            this.setState({
                arabic_units
            })
        }else{
            options.push(item);
            this.setState({
                options
            })
        }
    }
    async handleDeleteChip(chip, index,place){
        let {options,arabic_options,units,arabic_units} = this.state;
        if(place === "arabic_options"){
            arabic_options.splice(index, 1);
            this.setState({
                arabic_options
            })
        }else if(place === "units"){
            units.splice(index, 1);
            this.setState({
                units
            })
        }else if(place === "arabic_units"){
            arabic_units.splice(index, 1);
            this.setState({
                arabic_units
            })
        }else{
            options.splice(index, 1);
            this.setState({
                options
            })
        }
    }
    render(){
        let {fields,errors,loading,SubmitLoading,required,in_compare,options,arabic_options,units,arabic_units,editMode} = this.state;
        let {dialog,id} = this.props;
        return (
            <>
                {
                    loading ? <AppCardLoaderFields/> :
                        <Grid container spacing={2} direction="row">
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    name="field_type"
                                    label={translate('field_type')}
                                    type="text"
                                    color="secondary"
                                    value={fields.field_type}
                                    onChange={this.handleChange.bind(this)}
                                    error={!!errors['field_type']}
                                    helperText={errors['field_type']}
                                    select
                                >
                                    <MenuItem value={'text'}>
                                        {translate('text')}
                                    </MenuItem>
                                    <MenuItem value={'number'}>
                                        {translate('number')}
                                    </MenuItem>
                                    <MenuItem value={'long_text'}>
                                        {translate('long_text')}
                                    </MenuItem>
                                    <MenuItem value={'select'}>
                                        {translate('select_field')}
                                    </MenuItem>
                                    <MenuItem value={'multi_select'}>
                                        {translate('multi_select_field')}
                                    </MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    fullWidth
                                    name="unique_key"
                                    label={translate('uniqueKey')}
                                    type="text"
                                    value={fields.unique_key}
                                    onChange={this.handleChange.bind(this)}
                                    error={!!errors['unique_key']}
                                    helperText={errors['unique_key']}
                                    disabled={!!id}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    color="secondary"
                                    name="name"
                                    label={translate('fieldName')}
                                    type="text"
                                    value={fields.name}
                                    onChange={this.handleChange.bind(this)}
                                    error={!!errors['name']}
                                    helperText={errors['name']}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    name="description"
                                    label={translate('fieldDescription')}
                                    type="text"
                                    value={fields.description}
                                    onChange={this.handleChange.bind(this)}
                                    error={!!errors['description']}
                                    helperText={errors['description']}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    color="secondary"
                                    name="arabic_name"
                                    label={translate('fieldArabicName')}
                                    type="text"
                                    value={fields.arabic_name}
                                    onChange={this.handleChange.bind(this)}
                                    error={!!errors['arabic_name']}
                                    helperText={errors['arabic_name']}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    name="arabic_description"
                                    label={translate('fieldDescriptionArabic')}
                                    type="text"
                                    color="secondary"
                                    value={fields.arabic_description}
                                    onChange={this.handleChange.bind(this)}
                                    error={!!errors['arabic_description']}
                                    helperText={errors['arabic_description']}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                            {
                                (fields.field_type === "select" || fields.field_type === "multi_select") ?
                                <>
                                    <Grid item xs={12}>
                                        <ChipInput
                                            value={options}
                                            fullWidth
                                            color="secondary"
                                            label={translate('options')}
                                            variant={"outlined"}
                                            onAdd={(chip) => this.handleAddChip(chip,"options")}
                                            onDelete={(chip, index) => this.handleDeleteChip(chip, index,"options")}
                                        />
                                        <Typography  style={{fontSize:12,marginLeft:10,marginTop:5}}>
                                            {translate('pressEnterToMakeEachOption')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ChipInput
                                            value={arabic_options}
                                            fullWidth
                                            color="secondary"
                                            label={translate('arabic_options')}
                                            variant={"outlined"}
                                            onAdd={(chip) => this.handleAddChip(chip,"arabic_options")}
                                            onDelete={(chip, index) => this.handleDeleteChip(chip, index,"arabic_options")}
                                        />
                                        <Typography style={{fontSize:12,marginLeft:10,marginTop:5}}>
                                            {translate('pressEnterToMakeEachOption')}
                                        </Typography>
                                    </Grid>
                                </>
                                    :
                                    <>
                                        <Grid item xs={12}>
                                            <ChipInput
                                                value={units}
                                                fullWidth
                                                color="secondary"
                                                label={translate('units')}
                                                variant={"outlined"}
                                                onAdd={(chip) => this.handleAddChip(chip,"units")}
                                                onDelete={(chip, index) => this.handleDeleteChip(chip, index,"units")}
                                            />
                                            <Typography  style={{fontSize:12,marginLeft:10,marginTop:5}}>
                                                {translate('pressEnterToMakeEachOption')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ChipInput
                                                value={arabic_units}
                                                fullWidth
                                                color="secondary"
                                                label={translate('arabic_units')}
                                                variant={"outlined"}
                                                onAdd={(chip) => this.handleAddChip(chip,"arabic_units")}
                                                onDelete={(chip, index) => this.handleDeleteChip(chip, index,"arabic_units")}
                                            />
                                            <Typography  style={{fontSize:12,marginLeft:10,marginTop:5}}>
                                                {translate('pressEnterToMakeEachOption')}
                                            </Typography>
                                        </Grid>
                                    </>
                            }
                            <Grid item xs={12}>
                                <FormControl required component="fieldset">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={required}
                                                onChange={() => this.setState({required: !required})}
                                            />
                                        }
                                        label={translate('isThisFieldRequired')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl component="fieldset">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled={false}
                                                checked={in_compare}
                                                onChange={() => this.setState({in_compare: !in_compare})}
                                            />
                                        }
                                        label={translate('isThisFieldInCompare')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleSubmit.bind(this)}
                                    loading={SubmitLoading}
                                >
                                    {!!editMode ? translate('editField') : translate('makeField')}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                }
            </>

        );
    }
}
const mstp = state => ({
    user: state.user,
    locale: state.locale,
});
export default withSnackbar(withRouter(withWidth()(withStyles(Styles)(connect(mstp)(MakeField)))));