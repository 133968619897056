import React from 'react';
import {connect, getStore, setStore} from "trim-redux";
import Button from "@material-ui/core/Button/Button";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import LanguageTwoToneIcon from '@material-ui/icons/LanguageTwoTone';
import {translate} from "../../../config/lang";
import {defaultVariabls} from "../../../config";
import {withRouter} from "react-router-dom";
class LanguageSwitcher extends React.Component {
    state = {
        anchorEl : null
    }

    handleClick = (event) => {
        this.setState({
            anchorEl:event.currentTarget
        })
    };
    handleClose = () => {
        this.setState({
            anchorEl:null
        })
    };
    async changeLanguage(locale){
        if(locale === "en"){
            localStorage.setItem('locale','en');
            window.location.reload(true);
        }else{
            localStorage.setItem('locale','ar');
            window.location.reload(true);
        }
    }
    async toggleLanguage(){
        let current = getStore('locale');
        let locale = 'ar'
        if(current === "ar"){
            locale = 'en'
        }
        /*setStore({
            locale
        });*/
        if(locale === "ar"){
            localStorage.setItem('locale','ar');
            window.location.replace(`${defaultVariabls.React}${this.props.location.pathname}`);
        }else{
            localStorage.setItem('locale','en');
            window.location.replace(`${defaultVariabls.React}${this.props.location.pathname}`);
        }
    }
    render(){
        let {locale,style,className,menu,mode,setting} = this.props;
        let {anchorEl} = this.state;
        return (
            <>
                {
                    !!setting &&
                    <div style={style} className={className}>
                        {
                            !!setting.multilingual &&
                            <>
                                <Button
                                    aria-controls="Language"
                                    aria-haspopup="true"
                                    style={!!menu ? {color: mode === "dark" ? "#fefefe" : "#333"} : null}
                                    onClick={() => this.toggleLanguage()}
                                    startIcon={<LanguageTwoToneIcon color={'inherit'}/>}
                                >
                                    {translate(locale === "ar" ? "en" : 'ar')}
                                </Button>
                                {/*<Button
                    aria-controls="Language"
                    aria-haspopup="true"
                    style={!!menu ? {color: mode === "dark" ? "#fefefe" : "#333"} : null}
                    onClick={(e) => this.handleClick(e)}
                    startIcon={<LanguageTwoToneIcon color={'inherit'}/>}
                >
                    {translate(locale)}
                </Button>*/}
                                {/*<Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => this.handleClose()}
                >
                    <MenuItem onClick={() => this.changeLanguage('en')}>{translate('en')}</MenuItem>
                    <MenuItem onClick={() => this.changeLanguage('ar')}>{translate('ar')}</MenuItem>
                </Menu>*/}
                            </>
                        }

                    </div>
                }
            </>


        );
    }
}
const mstp = state => ({
    locale: state.locale,
    mode: state.mode,
    setting: state.setting,
});
export default withRouter(connect(mstp)(LanguageSwitcher));