import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography/Typography";
import {translate} from "../../../../config/lang";
import Grid from "@material-ui/core/Grid";
class SingleFileChooser extends React.Component {
    classes = this.props.classes;


    render(){
        let{width,height,size,video_size,primary,large} = this.props;
        return(
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={primary ? 12 : 6} md={large ? 12 : primary ? 10 : 3}>
                    <div className="metaDescription">
                        <Typography style={{display:"flex", alignItems:"center",justifyContent:"space-between",fontSize:12,marginBottom:5}}>
                            <span>{translate('imageProperWidthHeight')}</span>
                            <span>{width}x{height} px</span>
                        </Typography>
                        {
                            !!size &&
                            <Typography style={{display:"flex", alignItems:"center",justifyContent:"space-between",fontSize:12,marginBottom:5}}>
                                <span>{translate('imageProperSize')}</span>
                                <span>{size}</span>
                            </Typography>
                        }

                        {
                            !!video_size &&
                                <Typography style={{display:"flex", alignItems:"center",justifyContent:"space-between",fontSize:12}}>
                                    <span>{translate('videoProperSize')}</span>
                                    <span>{video_size}</span>
                                </Typography>
                        }
                    </div>
                </Grid>
            </Grid>
        )

    }
}
export default withStyles(Styles)(SingleFileChooser);