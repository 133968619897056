import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../config/index";
import {withRouter,Link} from "react-router-dom";
import { withSnackbar } from 'notistack';
import AppAnimate from "../../components/AppAnimate/index";
import AppCard from "../../components/layouts/components/AppCard";
import Grid from "@material-ui/core/Grid/Grid";
import {connect, setStore} from "trim-redux";
import BreadCrumbs from "../../components/elements/BreadCrumbs";
import AppCardLoaderFields from "../../components/Skeletons/AppCardLoaderFields";
import Typography from "@material-ui/core/Typography/Typography";
import SubTitle from "../../components/elements/SubTitle";
import SingleCartItem from "./components/SingleCartItem";
import {currencySymbol, price} from "../../../config/values";
import Divider from "@material-ui/core/Divider/Divider";
import Button from "@material-ui/core/Button/Button";
import {translate} from "../../../config/lang";
import LoadingButton from "../../components/elements/LoadingButton";
import SingleAdCartItem from "./components/SingleAdCartItem";
import SinglePlanCartItem from "./components/SinglePlanCartItem";

class CheckoutPage extends React.Component {

    state = {
        carts:[],
        loading: true,
        SubmitLoading: false,
        total_price:null,
        total_count:null,
        total_discount:null,
        currency:null,
    };
    classes = this.props.classes;
    componentDidMount(){
        this.loadData();
    }

    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/get_my_cart/0`, config)
                .then(response => {
                    if(!response.data.carts.length) this.props.history.push('/dashboard');
                    this.setState({
                        carts:response.data.carts,
                        total_price:response.data.total_price,
                        total_discount:response.data.total_discount,
                        total_count:response.data.total_count,
                        currency:response.data.currency,
                        loading: false
                    });
                }).catch(error => {
                    console.log(error.response);
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
            this.setState({
                loading:false
            })
        }
    }
    handleRequest(){
        let data = new FormData();
        data.append('lang',this.props.locale);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/factor/make`, data, config)
            .then(response => {
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
                this.props.history.push(`/order/view/${response.data.id}`)
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(translate('dataAddingFailed'), {
                    variant: 'error',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                    SubmitDraftLoading: false
                });
            });
    }
    handleValidation(callback){
        let errors = {};
        let formIsValid = true;

        if(!formIsValid){
            this.props.enqueueSnackbar(translate('errorHappens'), {
                variant: 'warning',
            });
            this.setState({
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    render(){
        let {SubmitLoading,loading,carts,total_price,total_discount,total_count,currency} = this.state;
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={translate('checkout')}>
                {
                    <Grid container spacing={2} justify="center">
                        <Grid item xs={12} lg={11}>
                            <Grid container spacing={2} justify="center">
                                <Grid item xs={12} md={8} lg={9}>
                                    <Grid container spacing={2} justify="center">
                                        <Grid item xs={12}>
                                            <AppCard
                                                title={translate('checkout')}
                                                action={
                                                    <BreadCrumbs singleLevel firstText={translate('checkout')}/>
                                                }>
                                                {
                                                    loading ? <AppCardLoaderFields/> :
                                                        <Grid container spacing={2} justify="center" alignItems="center" style={{marginBottom:10}}>
                                                            <Grid item xs={12}>
                                                                {
                                                                    !!carts.length &&
                                                                    carts.map((cart,index) => (
                                                                        <>
                                                                            {
                                                                                cart.type === "plan" &&
                                                                                <SinglePlanCartItem key={index} cart={cart} reload={() => this.loadData()}/>
                                                                            }
                                                                        </>
                                                                    ))
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                }
                                            </AppCard>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={4} lg={3}>
                                    <Grid container spacing={2} alignItems="center" justify="center" className="sticky-grid">
                                        <Grid item xs={12}>
                                            <AppCard
                                                title={translate('bill')}
                                            >
                                                {
                                                    loading ? <AppCardLoaderFields/> :
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} className="mt-15">
                                                                <Typography>{translate('totalPrice')}<span
                                                                    className="float-right">{price(total_price + total_discount) + " " + currency}</span></Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography>{translate('totalCartPrice')} <strong
                                                                    className="float-right">{price(total_price) + " " + currency}</strong></Typography>
                                                            </Grid>
                                                            {
                                                                !!total_discount &&
                                                                <>
                                                                    <Grid item xs={12}>
                                                                        <Divider/>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <Typography color="primary">{translate('totalDiscounts')} <strong
                                                                            className="float-right">{price(total_discount) + " " + currency}</strong></Typography>
                                                                    </Grid>
                                                                </>
                                                            }
                                                            <Grid item xs={12}  className="mt-15">
                                                                <LoadingButton
                                                                    variant="contained"
                                                                    color="secondary"
                                                                    styleForParent={{marginBottom:10}}
                                                                    style={{width: "100%"}}
                                                                    loading={SubmitLoading}
                                                                    onClick = {this.handleSubmit.bind(this)}
                                                                >
                                                                    {translate('makeOrder')}
                                                                </LoadingButton>
                                                            </Grid>
                                                        </Grid>
                                                }
                                            </AppCard>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </AppAnimate>

        );
    }
}

const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    locale: state.locale,
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(CheckoutPage))));