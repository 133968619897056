import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {DataTableLocalization, getDataTableLocalization} from "../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {connect} from "trim-redux";
import MaterialTable from "material-table";
import {tableIcons} from "../../../../config/design/TableIcons";
import {Link, withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import moment from "moment-timezone";
import {TableCount, TablePage} from "../../../../config/Permissions";
import Container from "@material-ui/core/Container/Container";
import Typography from "@material-ui/core/Typography/Typography";
import AppCardLoaderTable from "../../../components/Skeletons/AppCardLoaderTable";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {currencySymbol, getTimeZone} from "../../../../config/values";
import CurrencyFormat from "react-currency-format";
import {translate} from "../../../../config/lang";
import Button from "@material-ui/core/Button/Button";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";

class Wallets extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    state = {
        columns: [
            {
                field: 'user',
                editable: 'never',
                filtering: false,
                title: translate('userName'),
            },
            { title: translate('amount'),
                field: 'amount',
                render: rowData => <CurrencyFormat value={rowData.amount} displayType={'text'} thousandSeparator={true}  suffix={" "+currencySymbol} />
            },
            {
                title: translate('created_at'),
                editable: 'never',
                field: 'created_at',
                render: rowData =>
                    <Typography className={this.classes.dateTable}>
                        {`${moment.tz(rowData.created_at,'UTC').tz(getTimeZone()).fromNow()}`}
                        <br/>
                        {`${moment.tz(rowData.created_at,'UTC').tz(getTimeZone()).format( 'HH:mm , YYYY/M/D')}`}
                    </Typography>
            },
            { title: translate('activity'),
                editable: 'never',
                field: 'activity',
                sorting:false,
                render: rowData =>
                    <Button
                        variant="outlined"
                        color="secondary"
                        component={Link}
                        to={`/manage/wallet/${rowData.id}`}
                        endIcon={this.props.locale === "ar" ? <ChevronRightRoundedIcon/> : <ChevronRightRoundedIcon/>}
                        size={"small"}
                        style={{margin:5}}
                    >
                        {translate('edit')}
                    </Button>
            },
        ],
        data: [],
        loading:false,
        ChangeLoader:false
    };
    loadData(){
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }
    handleRowClick(data){
        this.props.history.push(`/manage/wallet/${data.id}`);
    }
    render(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        return (
            <>
                {
                    this.state.loading ? <AppCardLoaderTable/> :
                        <Container maxWidth="xl">
                            <MaterialTable
                                parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                                tableRef={this.tableRef}
                                icons={tableIcons}
                                title={<> </>}
                                localization={getDataTableLocalization()}
                                columns={this.state.columns}
                                data={query =>
                                    new Promise((resolve, reject) => {
                                        axios.get(`${defaultVariabls.url}/wallet/getAllUsersWallet?page=${query.page + 1}&per_page=${query.pageSize}&search=${query.search}`, config)
                                            .then(response => {
                                                let data = [];
                                                // eslint-disable-next-line
                                                response.data.data.map((row) => {
                                                    data.push({
                                                        amount: row.amount,
                                                        id: row.id,
                                                        user: row.user.first_name+" "+row.user.last_name,
                                                        active: row.active,
                                                        created_at: row.created_at,
                                                    })
                                                });
                                                resolve({
                                                    data: data,
                                                    page: response.data.current_page - 1,
                                                    totalCount: response.data.total,
                                                })
                                            }).catch(error => {
                                            console.log(error.response)
                                        })
                                    })
                                }
                                options={{
                                    pageSize: TablePage,
                                    pageSizeOptions: TableCount,
                                    defaultExpanded:true,
                                }}
                            />
                        </Container>
                }
            </>

        );
    }
}

const mstp = state => ({
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(Wallets)))));