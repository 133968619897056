import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import Loader from "../../../components/elements/Loader";
import TextField from "@material-ui/core/TextField/TextField";
import validator from "validator";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {connect} from "trim-redux";
import { withSnackbar } from 'notistack';
import SubTitle from "../../../components/elements/SubTitle";
import LoadingButton from "../../../components/elements/LoadingButton";
import BreadCrumbs from "../../../components/elements/BreadCrumbs";
import Title from "../../../components/elements/Title";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import Permission from "../../../components/elements/Permission";
import HeaderTags from "../../../components/elements/HeaderTags";
import withWidth from "@material-ui/core/withWidth/withWidth";
import ChatBubbleOutlineRoundedIcon from '@material-ui/icons/ChatBubbleOutlineRounded';
import ChooseBlogComment from "./components/ChooseBlogComment";
import ChooseCommentReplay from "./components/ChooseCommentReplay";
import {Translate} from "../../../../config/Translate";

class AddComment extends React.Component {
    state = {
        fields:{
            name:this.props.user.name,
            email:this.props.user.email,
            comment:'',
        },
        loading: true,
        rating: 4.5,
        SubmitLoading:false,
        confirmed:true,
        user:null,
        content_id:null,
        replay_to:null,
        parent: null,
        errors:[],
    };

    classes = this.props.classes;
    async componentDidMount(){
        await this.setState({
            content_id: this.props.replay_content,
            replay_to: this.props.replay_comment,
            loading:false
        })
    }
    handleChangeConfirmed(){
        this.setState({
            confirmed: !this.state.confirmed,
        })
    }
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        fields[target.name] = target.value;
        errors[target.name] = "";
        this.setState({
            fields,
            PreventingToExit:true
        });

    }
    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //name
        if(validator.isEmpty(fields.name)){
            formIsValid = false;
            errors['name'] = Translate.emptyField;
        }else if(!validator.isByteLength(fields.name,{min:0, max:255})){
            formIsValid = false;
            errors['name'] = Translate.maxLength255;
        }
        //comment
        if(fields.comment !== null ) {
            if (fields.comment.length > 1000) {
                formIsValid = false;
                errors['comment'] = Translate.maxLength1000;
            }
        }
        if(!formIsValid){
            this.setState({
                SubmitLoading:false
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        let { fields,confirmed,rating,content_id,replay_to } = this.state;
        let data = new FormData();
        data.append('id', this.state.id);
        data.append('content', this.state.id);
        data.append('name', fields.name);
        data.append('email', fields.email);
        data.append('comment', fields.comment);
        data.append('content_id', content_id);
        data.append('rate', rating);
        data.append('replay_to', replay_to);
        data.append('confirmed', confirmed ? 1 : 0);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/blog-comment/add`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.commentAdded, {
                    variant: 'success',
                });
                this.setState({
                    SubmitLoading:false
                })
                if(this.props.dialog)
                this.props.handleDone()
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(Translate.commentAddingFailed, {
                    variant: 'error',
                });
                this.setState({
                    SubmitLoading:false
                })
            });

        this.setState({
            loading:false,
            SubmitLoading:false
        });
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.setState({
                    loading:true
                });
                this.handleRequest();
            }
        });
    }
    handleProduct(value){
        this.setState({
            content_id:!!value ? value.id : null
        })
    }
    handleReplay(value){
        this.setState({
            replay_to:value.id
        })
    }
    render(){
        let {fields, confirmed,errors,loading,rating,SubmitLoading,content_id,replay_to} = this.state;
        let {dialog} = this.props;
        return (
            <React.Fragment>
                <HeaderTags title={Translate.editComment}/>
                {
                    loading ? <Loader/> :
                        <React.Fragment>
                            {
                                !dialog ?
                                    <Grid item xs={12}>
                                        <BreadCrumbs  firstText={Translate.comments} firstLink="/setting/comments" secondText={Translate.editComment}/>
                                    </Grid>
                                    : null
                            }

                            <Grid item xs={12}>
                                <Grid container spacing={1} direction="row" className={dialog ? null :this.classes.GridAsaPaper}>
                                    {
                                        !dialog ?
                                            <Grid item xs={12} className="TitleContainer">
                                                <Title>{Translate.editComment} <ChatBubbleOutlineRoundedIcon style={{float:'left'}}/></Title>
                                            </Grid>
                                            : null
                                    }

                                    <Grid item xs={12} md={!!content_id ? 6 : 12}>
                                        <ChooseBlogComment inPut={content_id} outPut={this.handleProduct.bind(this)}/>
                                    </Grid>
                                    {
                                        !!content_id ?
                                            <Grid item xs={12} md={6}>
                                                <ChooseCommentReplay inPut={replay_to} content_id={content_id} outPut={this.handleReplay.bind(this)}/>
                                            </Grid>
                                            : null

                                    }
                                    <Grid item xs={12} className="field-sticky">
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    name="name"
                                                    label={Translate.userName}
                                                    type="text"
                                                    id="name"
                                                    value={fields.name}
                                                    onChange={this.handleChange.bind(this)}
                                                    error = {!!errors['name']}
                                                    helperText={errors['name']}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    name="email"
                                                    label={Translate.email}
                                                    type="email"
                                                    id="email"
                                                    value={fields.email}
                                                    onChange={this.handleChange.bind(this)}
                                                    error = {!!errors['email']}
                                                    helperText={errors['email']}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} direction="row">

                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            multiline
                                            fullWidth
                                            name="comment"
                                            label={Translate.comment}
                                            type="text"
                                            id="comment"
                                            rows="4"
                                            value={fields.comment}
                                            onChange={this.handleChange.bind(this)}
                                            error = {!!errors['comment']}
                                            helperText={errors['comment']}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container  direction="row" className={dialog ? null : this.classes.GridAsaPaper}>
                                    <Permission rule="make">
                                        <Grid item xs={5} md={2}>
                                            <SubTitle>{Translate.confirmStatus} : </SubTitle>
                                        </Grid>
                                        <Grid item xs={7} md={4}>
                                            <FormControlLabel
                                                style={{marginRight:10}}
                                                control={
                                                    <Switch
                                                        checked={confirmed}
                                                        onChange={this.handleChangeConfirmed.bind(this)}
                                                        value={confirmed}
                                                        color="primary"
                                                    />
                                                }
                                                label={confirmed ? Translate.confirmed : Translate.unConfirmed}
                                            />
                                        </Grid>
                                    </Permission>
                                    <Grid item xs={12}>
                                        <LoadingButton
                                            variant="contained"
                                            color="primary"
                                            onClick = {this.handleSubmit.bind(this)}
                                            loading = {SubmitLoading}
                                        >
                                            {Translate.addComment}
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                }
            </React.Fragment>

        );
    }
}
const mstp = state => ({
    user: state.user
});
export default withSnackbar(withRouter(withWidth()(withStyles(Styles)(connect(mstp)(AddComment)))));