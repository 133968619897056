import React from 'react';
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../config/design/styles";
import {withRouter} from 'react-router-dom';
import Button from "@material-ui/core/Button/Button";
import Grid from "@material-ui/core/Grid/Grid";
import ChooseDate from "../../../components/elements/ChooseDate";
import {connect} from "trim-redux";
import {Translate} from "../../../../config/Translate";
import OrdersAnalyticsShort from "./components/OrdersAnalyticsShort";
import OrdersTable from "./components/OrdersTable";

class OrdersAnalytics extends React.Component {
    classes = this.props.classes;
    state = {
        beginDate: '',
        endDate: '',
    }

    handleBeginDate(beginDate){
        this.setState({
            beginDate,
        })

    }
    async handleEndDate(endDate){
        await this.setState({
            endDate
        })
        await this.setState({
            ChangeLoader:true
        })
    }
    async removeFilter(){
        this.setState({
            beginDate:'',
            endDate:''
        })

    }
    render(){
        let {beginDate,endDate} = this.state;
        console.log(beginDate,endDate);
        return (
            <Grid container spacing={2} alignItems="center" justify="center">
                <Grid item xs={12}>
                    <Grid container direction="row"  alignItems="center" className={this.classes.filterContainer}>
                        <Grid item xs={12}>
                            <Grid container spacing={2} alignItems="center" direction="row" >
                                <Grid item xs={6} md={3}>
                                    <ChooseDate input={beginDate} handleDate={this.handleBeginDate.bind(this)} label={Translate.chooseBeginDate}/>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <ChooseDate input={endDate} handleDate={this.handleEndDate.bind(this)} label={Translate.chooseEndDate}/>
                                </Grid>
                                {
                                    (!!beginDate || !!endDate) &&
                                    <Grid item xs={12}>
                                        <Button
                                            onClick={this.removeFilter.bind(this)}
                                            color="primary"
                                            size="small"
                                        >
                                            {Translate.removeFilter}
                                        </Button>
                                    </Grid>
                                }

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <OrdersAnalyticsShort beginDate={beginDate} endDate={endDate}/>
                </Grid>
                <Grid item xs={12}>
                    <OrdersTable beginDate={beginDate} endDate={endDate}/>
                </Grid>
            </Grid>

        );
    }
}
const mstp = state => ({
    Translate: state.Translate,
    locale: state.locale,
});
export default withStyles(Styles)(connect(mstp)(withRouter(OrdersAnalytics)));