import React from 'react';
import Helmet from "react-helmet/es/Helmet";
import {getStore} from "trim-redux";

export default function HeaderTags(props) {
    const app_name = getStore('app_name');
    return (
        <Helmet>
            {
                props.loader ?
                    <title>{props.title}</title>
                    :
                    <title>{app_name} | {props.title}</title>
            }

            <link rel="canonical" href={window.location.href} />
        </Helmet>
    );
}
