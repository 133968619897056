import {translate} from "../lang";
import {getStore} from "trim-redux";
function getChevronEnd(){
    let locale = getStore('locale');
    return locale === "en" ? "chevron_right" : "chevron_right"
}
export const getRoutes = function () {
    return [
        {
            id: 'dashboard',
            title: translate('dashboard'),
            type: 'item',
            icon: 'dashboard',
            url: '/dashboard',
        },
        {
            id: 'tickets',
            title: translate('messages'),
            type: 'item',
            icon: 'chat',
            url: '/messages/ticket',
            feature:"tickets",
            roles: ['user','supplier'],
        },
        {
            id: 'business',
            title: translate('myBusiness'),
            type: 'item',
            icon: 'business',
            url: '/business',
            roles: ['user','supplier','admin'],
            restrict:'has_business_page'

        },
        {
            id: 'orders',
            title: translate('orders'),
            type: 'group',
            roles: ['user','supplier'],
            children: [
                {
                    id: 'ordersList',
                    title: translate('ordersList'),
                    type: 'item',
                    icon: 'list',
                    url: '/my-orders',
                },
                {
                    id: 'myTransactions',
                    title: translate('myTransactions'),
                    type: 'item',
                    icon: 'receipt_long',
                    url: '/wallet/view',
                },

            ],
        },
        {
            id: 'products',
            title: translate('products'),
            type: 'group',
            roles: ['user','supplier'],
            restrict: "make_products",
            children: [
                {
                    id: 'makeProduct',
                    title: translate('makeProduct'),
                    icon: 'edit_note',
                    type: 'item',
                    url: '/create/product',
                },
                {
                    id: 'productsList',
                    title: translate('productsMyList'),
                    type: 'item',
                    icon: 'list',
                    url: '/my-products',
                },

            ],
        },
        {
            id: 'rfqs',
            title: translate('rfqs'),
            type: 'group',
            roles: ['user','supplier'],

            children: [
                {
                    id: 'makeRFQ',
                    title: translate('makeRFQ'),
                    icon: 'edit_note',
                    type: 'item',
                    url: '/create/rfq',
                },
                {
                    id: 'rfqList',
                    title: translate('rfqMyList'),
                    type: 'item',
                    icon: 'request_quote',
                    url: '/my-rfqs',
                },

            ],
        },
        {
            id: 'site_settings',
            title: translate('siteSettings'),
            type: 'collapse',
            icon: 'web',
            permission: 'managePayments',
            children: [
                {
                    id: 'siteInformation',
                    title: translate('siteInformation'),
                    type: 'item',
                    icon: getChevronEnd(),
                    url: '/setting/information',
                    exact:true
                },
                {
                    id: 'manageContacts',
                    title: translate('siteContactInformation'),
                    type: 'item',
                    icon: getChevronEnd(),
                    url: '/setting/contact',
                    exact:true
                },
                {
                    id: 'manageSlideShows',
                    title: translate('manageSlideShows'),
                    type: 'item',
                    icon: getChevronEnd(),
                    url: '/settings/slideshows',
                    exact:true
                },
                {
                    id: 'manageBanners',
                    title: translate('manageBanners'),
                    type: 'item',
                    icon: getChevronEnd(),
                    url: '/settings/banners',
                    exact:true
                },
                {
                    id: 'manageFields',
                    title: translate('manageFields'),
                    type: 'item',
                    icon: getChevronEnd(),
                    url: '/manage/fields',
                    exact:true
                },
            ],

        },
        {
            id: 'palns',
            title: translate('plans'),
            type: 'collapse',
            icon: 'verified',
            permission: 'managePayments',
            children: [
                {
                    id: 'managePlanParents',
                    title: translate('managePlanParents'),
                    type: 'item',
                    icon: getChevronEnd(),
                    url: '/manage/plan-parents',
                    exact:true
                },
                {
                    id: 'managePlans',
                    title: translate('managePlans'),
                    type: 'item',
                    icon: getChevronEnd(),
                    url: '/manage/plans',
                    exact:true
                },
            ],
        },
        {
            id: 'manageCategories',
            title: translate('categories'),
            type: 'item',
            permission: 'managePayments',
            url: '/manage/categories',
            icon: "category",
            exact:true
        },
        {
            id: 'manageBusinesses',
            title: translate('manageBusinesses'),
            type: 'collapse',
            icon: 'business',
            permission: 'manageAdvertisements',
            children: [
                {
                    id: 'businessList',
                    title: translate('businessesList'),
                    type: 'item',
                    url: '/manage/businesses',
                    icon: getChevronEnd(),
                    exact:true
                },
                {
                    id: 'manageBusinessType',
                    title: translate('businessTypes'),
                    type: 'item',
                    url: '/manage/business-types',
                    icon: getChevronEnd(),
                    exact:true
                },
            ]
        },
        {
            id: 'manageProducts',
            title: translate('manageProducts'),
            type: 'collapse',
            icon: 'list',
            permission: 'manageAdvertisements',
            children: [
                {
                    id: 'products',
                    title: translate('productsList'),
                    type: 'item',
                    url: '/manage/products',
                    icon: getChevronEnd(),
                    exact: true
                },
            ]
        },
        {
            id: 'manageRFQs',
            title: translate('manageRFQs'),
            type: 'collapse',
            icon: 'request_quote',
            permission: 'manageAdvertisements',
            children: [
                {
                    id: 'rfqs',
                    title: translate('rfqList'),
                    type: 'item',
                    url: '/manage/rfqs',
                    icon: getChevronEnd(),
                    exact: true
                },
            ]
        },
        {
            id: 'manageComments',
            title: translate('manageComments'),
            type: 'collapse',
            icon: 'rate_review',
            permission: 'manageAdvertisements',
            children: [
                {
                    id: 'manageProductsComments',
                    title: translate('manageProductsComments'),
                    type: 'item',
                    url: '/manage/comments/product',
                    icon: getChevronEnd(),
                    exact:true
                },
                {
                    id: 'manageBusinessesComments',
                    title: translate('manageBusinessesComments'),
                    type: 'item',
                    url: '/manage/comments/business',
                    icon: getChevronEnd(),
                    exact:true
                },
            ],
        },
        {
            id: 'manageOrders',
            title: translate('manageOrders'),
            type: 'collapse',
            icon: 'receipt',
            permission: 'manageOrders',
            children: [
                {
                    id: 'manageOrders',
                    title: translate('ordersListAdmin'),
                    type: 'item',
                    icon: getChevronEnd(),
                    url: '/manage/orders',
                    exact:true
                },
                {
                    id: 'manageWallets',
                    title: translate('manageWallets'),
                    type: 'item',
                    icon: getChevronEnd(),
                    url: '/manage/wallets',
                    exact:true,
                },
            ],
        },
        {
            id: 'usersManagement',
            title: translate('usersManagement'),
            type: 'collapse',
            icon: 'group',
            permission: 'manageSettings',
            children: [
                {
                    id: 'allUsers',
                    title: translate('allUsers'),
                    type: 'item',
                    url: '/manage/users/view/all',
                    icon: getChevronEnd(),
                    exact:true
                }, {
                    id: 'verifiedUsers',
                    title: translate('verifiedUsers'),
                    type: 'item',
                    url: '/manage/verified-users',
                    icon: getChevronEnd(),
                    exact:true
                },
                {
                    id: 'usersActivity',
                    title: translate('usersActivity'),
                    type: 'item',
                    url: '/manage/activities',
                    exact:true,
                    icon: getChevronEnd(),
                    feature:"activity_log"
                },
                {
                    id: 'manageNewsLetter',
                    title: translate('newsletterSubscribers'),
                    type: 'item',
                    url: '/manage/subscribers',
                    icon: getChevronEnd(),
                    exact:true
                },
            ],
        },
        {
            id: 'manageMessages',
            title: translate('manageMessages'),
            type: 'collapse',
            icon: 'chat',
            permission: 'manageSettings',
            feature:"tickets",
            children: [
                {
                    id: 'manageDepartments',
                    title: translate('manageTickets'),
                    type: 'item',
                    url: '/manage/tickets',
                    icon: getChevronEnd(),
                    exact:true
                },
                {
                    id: 'referToMe',
                    title: translate('referToMe'),
                    type: 'item',
                    url: '/messages/ticket',
                    icon: getChevronEnd(),
                    exact:true
                },
                {
                    id: 'manageDepartments',
                    title: translate('manageDepartments'),
                    type: 'item',
                    url: '/manage/departments',
                    icon: getChevronEnd(),
                    exact:true
                },

            ]
        },
        {
            id: 'managePages',
            title: translate('managePages'),
            type: 'item',
            icon: 'description',
            url: '/manage/pages',
            permission: 'manageSettings',
        },
        {
            id: 'manageContact',
            title: translate('manageContact'),
            type: 'item',
            url: '/manage/contacts',
            icon: 'contact_mail',
            permission: 'manageSettings',
            exact:true
        },
        {
            id: 'manageFAQ',
            title: translate('manageFAQ'),
            type: 'item',
            url: '/manage/faq',
            icon: 'quiz',
            permission: 'manageSettings',
            exact:true
        },
        /*{
            id: 'paymentManagement',
            title: translate('paymentManagement'),
            type: 'collapse',
            icon: 'badge',
            permission: 'managePayments',
            feature:"wallet",
            children: [
                {
                    id: 'manageVerifications',
                    title: translate('manageVerifications'),
                    type: 'item',
                    url: '/manage/verifications',
                    exact:true
                },
                {
                    id: 'manageWallets',
                    title: translate('manageWallets'),
                    type: 'item',
                    url: '/manage/wallets',
                    exact:true,
                },

            ],
        },*/
        {
            id: 'blog',
            title: translate('blog'),
            type: 'collapse',
            icon: 'article',
            feature:"blog",
            roles: ['admin'],
            children: [
                {
                    id: 'blogCategories',
                    title: translate('manageBlogCategories'),
                    type: 'item',
                    icon: getChevronEnd(),
                    url: '/manage/blog-categories',
                    exact:true,
                },
                {
                    id: 'contents',
                    title: translate('contentsList'),
                    type: 'item',
                    url: '/manage/contents',
                    icon: getChevronEnd(),
                    exact:true
                },
            ],
        },
        {
            id: 'myDetails',
            title: translate('myDetails'),
            type: 'group',
            icon: 'person',
            children: [
                {
                    id: 'settings',
                    title: translate('account'),
                    type: 'item',
                    icon: 'badge',
                    url: '/account/details',
                },
                {
                    id: 'password',
                    title: translate('managePassword'),
                    icon: 'password',
                    type: 'item',
                    url: '/account/password',
                },
            ],
        },
        {
            id: 'fileManager',
            title: translate('fileManager'),
            type: 'item',
            icon: 'image',
            url: '/file-manager',
            roles: ['admin','support','supplier','product_manager'],
            permission: "fileManagerPage",
        },
    ]
}

