import {makeStyles} from '@material-ui/core/styles';
import {getStore} from "trim-redux";

const useStyles = makeStyles((theme) => {
    return {
        navItem: {
            height: 40,
            marginTop: 2,
            marginBottom: 2,
            paddingLeft: 24,
            position:"relative",
            whiteSpace:"nowrap",
            paddingRight:(props) => 17 + 40 * props.level,
            color: getStore('mode') === "dark" ? 'rgb(180, 180, 180)' : 'rgb(60, 60, 60)' ,
            fontWeight: 700,
            fontSize: 12,
            textDecoration: 'none!important',

            [theme.breakpoints.up('xl')]: {
                // fontSize: 16,
                marginTop: 4,
                marginBottom: 4,
                paddingLeft:
                    theme.direction === 'ltr' ? (props) => 24 + 40 * props.level : 12,
                paddingRight:
                    theme.direction === 'rtl' ? (props) => 24 + 40 * props.level : 12,
            },
            '&.nav-item-header': {
                textTransform: 'uppercase',
            },
            '&:after':{
                content: "' '",
                position: "relative",
                borderBottom: "1px solid rgb(161 150 183)",
                width: "100%",
                overflow: "hidden",
                left: 15,
            },
        },
    };
});

export default useStyles;
