import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import AppAnimate from "../../../components/AppAnimate";
import AppCard from "../../../components/layouts/components/AppCard";
import Grid from "@material-ui/core/Grid/Grid";
import {connect, setStore} from "trim-redux";
import BreadCrumbs from "../../../components/elements/BreadCrumbs";
import AppCardLoaderFields from "../../../components/Skeletons/AppCardLoaderFields";
import Typography from "@material-ui/core/Typography/Typography";
import CurrencyFormat from "react-currency-format";
import LoadingButton from "../../../components/elements/LoadingButton";
import {updateRedux} from "../../../../config/Security";
import SubTitle from "../../../components/elements/SubTitle";
import moment from "moment-timezone";
import SingleOrderItem from "../components/SingleOrderItem";
import Divider from "@material-ui/core/Divider/Divider";
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import {makeSlug, translate} from "../../../../config/lang";
import SinglePlanOrderItem from "../components/SinglePlanOrderItem";
import {getTimeZone} from "../../../../config/values";
import {FormLabel} from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import FormControl from "@material-ui/core/FormControl/FormControl";
class ViewOrder extends React.Component {

    state = {
        factor:null,
        SubmitLoading:false,
        id:this.props.match.params.id,
        loading:true,
        reserve:"reserve",
        user_has_plan:false,
    };
    classes = this.props.classes;
    async componentDidMount(){
        await this.loadData();

    }
    componentDidUpdate(prevProps){
        if(this.state.id !== this.props.match.params.id){
            this.setState({
                id: this.props.match.params.id
            })
        }
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/factor/get/${this.state.id}`, config)
                .then(async response => {
                    let user_has_plan = false
                    await response.data.factor_items.map(item => {
                        if(item.type === "plan"){
                            let {user} = this.props;
                            if(user.plan.parent.type === "premium"){
                                user_has_plan = true;
                            }
                        }
                    })
                    this.setState({
                        factor:response.data,
                        loading:false,
                        user_has_plan
                    })

                }).catch(error => {
                    console.log(error.response)
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }
    }
    async handleRequest(){
        let { id,reserve} = this.state;
        let data = new FormData();
        data.append('id', id);
        data.append('reserve', reserve);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        await axios.post(`${defaultVariabls.url}/factor/pay`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(translate('orderPayed'), {
                    variant: 'success',
                });
                updateRedux();
                this.setState({
                    loading:true,
                    SubmitLoading: false,
                });
                this.loadData();

            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(translate('orderPayingFailed'), {
                    variant: 'error',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                    SubmitDraftLoading: false
                });
            });

    }
    async handleRequestDirect(){
        let {factor,reserve} = this.state;
        this.setState({
            SubmitLoading: true
        })
        window.open(makeSlug(`payment/factor/${factor.id}/${reserve}`),'_self')
    }

    handleSubmit(e){
        this.setState({
            SubmitLoading: true
        })
        this.handleRequest();

    }
    handleChangeReserve(e){
        this.setState({
            reserve: e.target.value
        })
    }
    render(){
        let {factor,SubmitLoading,loading,reserve,user_has_plan} = this.state;
        let total_price,wallet_amount,can_pay_with_wallet,transfer_price;
        if(!!factor){
            total_price = factor.total_price_payed;
            transfer_price = factor.transfer_price;
            wallet_amount = this.props.user.wallet.amount;
            can_pay_with_wallet = wallet_amount >= total_price;
        }
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={translate('viewOrder')}>
                {

                    <Grid container spacing={2} justify="center">
                        <Grid item xs={12} md={(!loading && factor.status === "not_payed") ? 7 : 11} lg={(!loading && factor.status === "not_payed") ? 8 : 11}>
                            <AppCard
                                title={translate('viewOrder')}
                                action={
                                    <BreadCrumbs  firstText={translate('orders')} firstLink="/my-orders" secondText={translate('viewOrder')}/>
                                }>
                                {
                                    this.state.loading ?
                                        <AppCardLoaderFields/>
                                        :
                                        <Grid container spacing={2} justify="center" alignItems="center">
                                            <Grid item xs={12} style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                                                <SubTitle align="left">{translate('orderDetails')}</SubTitle>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div className="order-items-holder">
                                                    <Typography>{translate('order_status')} <span className="result" style={{color: factor.status === "not_payed" ? "#f82e4e" : '#3eaa23'}}>{translate(factor.status)}</span> </Typography>
                                                    <Typography>
                                                        <FiberManualRecordRoundedIcon fontSize="small"/>
                                                    </Typography>
                                                    <Typography>{translate('factor_number')} <span className="result">{factor.number}</span></Typography>
                                                    <Typography>
                                                        <FiberManualRecordRoundedIcon fontSize="small"/>
                                                    </Typography>
                                                    <Typography>
                                                        {translate('created_at_factor')}
                                                        <span className="result">
                                                            {`${moment.tz(factor.created_at,'UTC').tz(getTimeZone()).format( 'YYYY/M/D')}`}
                                                        </span>
                                                    </Typography>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div className="order-items-holder mb-15">
                                                    <Typography>{translate('user')} <span className="result">{factor.user.first_name} {factor.user.last_name}</span></Typography>
                                                    <Typography>
                                                        <FiberManualRecordRoundedIcon fontSize="small"/>
                                                    </Typography>
                                                    <Typography>{translate('mobileNumber')} <span className="result">{factor.user.mobile}</span></Typography>
                                                    <Typography>
                                                        <FiberManualRecordRoundedIcon fontSize="small"/>
                                                    </Typography>
                                                    <Typography>{translate('email')} <span className="result">{factor.user.email}</span></Typography>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {
                                                    factor.factor_items.map((item,index) => (
                                                        <>
                                                            {
                                                                item.type === "plan" &&
                                                                <SinglePlanOrderItem factor_status={factor.status} key={index} item={item} view/>
                                                            }
                                                        </>
                                                    ))
                                                }
                                            </Grid>
                                        </Grid>
                                }
                            </AppCard>
                        </Grid>
                        {
                            !this.state.loading &&
                            factor.status === "not_payed" &&
                            <Grid item xs={12} md={4} lg={3} className="text-center">
                                <AppCard
                                    title={translate('purchase')}
                                >
                                    {
                                        this.state.loading ?
                                            <AppCardLoaderFields/>
                                            :
                                            <>
                                                <Typography paragraph color="secondary" style={{textAlign:"left"}}>
                                                    {translate('payableMoney')}:
                                                    <strong className="float-right">
                                                        <CurrencyFormat
                                                            value={factor.total_price} displayType={'text'}
                                                            thousandSeparator={true}
                                                            suffix={" " + factor.currency}/>
                                                    </strong>
                                                </Typography>
                                                {
                                                    !!user_has_plan &&
                                                    <>
                                                        <Divider style={{marginTop:15}}/>
                                                        <FormControl className="reserve-radio">
                                                            <FormLabel>{translate('reserveSituation')}</FormLabel>
                                                            <RadioGroup name="reserve" value={reserve} onChange={this.handleChangeReserve.bind(this)}>
                                                                <FormControlLabel value={"reserve"} control={<Radio />} label={translate('do_reserve')} />
                                                                <FormControlLabel value="renew" control={<Radio />} label={translate('do_renew')} />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </>

                                                }
                                                <LoadingButton
                                                    variant="contained"
                                                    color="secondary"
                                                    styleForParent={{marginBottom: 10,marginTop:15}}
                                                    style={{width: "100%"}}
                                                    onClick={this.handleRequestDirect.bind(this)}
                                                    loading={SubmitLoading}
                                                >
                                                    {translate('payOrder')}
                                                    {
                                                        factor.total_price === 0 &&
                                                        <small style={{margin:"0 5px"}}>
                                                            ({translate('free')})
                                                        </small>
                                                    }
                                                </LoadingButton>
                                            </>
                                    }
                                </AppCard>
                            </Grid>
                        }
                    </Grid>
                }
            </AppAnimate>

        );
    }
}
const mstp = state => ({
    user: state.user,
    locale: state.locale,
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(ViewOrder))));