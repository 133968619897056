import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import BreadCrumbs from "../../components/elements/BreadCrumbs";
import AppAnimate from "../../components/AppAnimate";
import AppCard from "../../components/layouts/components/AppCard";
import ManageTickets from "../tickets/ManageTickets";
import MessagesList from "./MessagesList";

class RequestsPage extends React.Component {
    classes = this.props.classes;

    render(){
        let {title,type}= this.props;
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={title}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} lg={11}>
                        <AppCard
                            title={title}
                            action={
                                <BreadCrumbs singleLevel firstText={title}/>
                            }>
                            {
                                this.props.manage ?
                                    <ManageTickets manage/> :
                                    <MessagesList type={type}/>
                            }
                        </AppCard>
                    </Grid>
                </Grid>
            </AppAnimate>
        );
    }
}
export default withSnackbar(withStyles(Styles)(RequestsPage));
