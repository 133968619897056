import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import BreadCrumbs from "../../../components/elements/BreadCrumbs";
import {Translate} from "../../../../config/Translate";
import AppCard from "../../../components/layouts/components/AppCard";
import AppAnimate from "../../../components/AppAnimate";
import MyAddresses from "./MyAddresses";

class UserDetails extends React.Component {
    render() {
        return(
            <AppAnimate animation='transition.slideUpIn' delay={200} title={Translate.addresses}>
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12}>
                        <AppCard
                            title={Translate.addresses}
                            action={
                                <BreadCrumbs singleLevel firstText={Translate.addresses}/>
                            }>
                            <MyAddresses/>
                        </AppCard>
                    </Grid>
                </Grid>
            </AppAnimate>
        )
    }
}
export default withStyles(Styles)(UserDetails);