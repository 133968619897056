import React from "react";
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import TocIcon from '@material-ui/icons/Toc';
import FormatListNumberedRtlIcon from '@material-ui/icons/FormatListNumberedRtl';
import CodeIcon from '@material-ui/icons/Code';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import GridOnIcon from '@material-ui/icons/GridOn';
import TitleRoundedIcon from '@material-ui/icons/TitleRounded';
import {FormatAlignCenter, FormatAlignLeft, FormatAlignRight} from "@material-ui/icons";
export const styleMap = {
    CODE: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: 2,
    },
    CENTER:{
        textAlign: "center",
        display: "block"
    },
    RIGHT:{
        textAlign: "right",
        display: "block"
    },
    LEFT:{
        textAlign: "left",
        display: "block"
    },
};

export function getBlockStyle(block) {
    switch (block.getType()) {
        case 'blockquote': return 'RichEditor-blockquote';
        default: return null;
    }
}

class StyleButton extends React.Component {
    constructor(props) {
        super(props);
        this.onToggle = (e) => {
            e.preventDefault();
            this.props.onToggle(this.props.style);
        };
    }

    render() {
        let className = 'RichEditor-styleButton';
        if (this.props.active) {
            className += ' RichEditor-activeButton';
        }

        return (
            <span className={className} onMouseDown={this.onToggle}>
              {this.props.label}
            </span>
        );
    }
}

const BLOCK_TYPES = [
    {label: <TitleRoundedIcon fontSize="large"/> , style: 'header-three',name: 'header-three'},
    {label: <TitleRoundedIcon />, style: 'header-four',name: 'header-four'},
    {label: <FormatQuoteIcon/>, style: 'blockquote',name: 'blockquote'},
    {label: <TocIcon/>, style: 'unordered-list-item',name: 'unordered-list-item'},
    {label: <FormatListNumberedRtlIcon/>, style: 'ordered-list-item',name: 'ordered-list-item'},
    {label: <CodeIcon/>, style: 'code-block',name: 'code-block'},
];

export const BlockStyleControls = (props) => {
    const {editorState} = props;
    const selection = editorState.getSelection();
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();

    return (
        <div className="RichEditor-controls">
            {BLOCK_TYPES.map((type) =>
                <StyleButton
                    key={type.name}
                    active={type.style === blockType}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            )}
        </div>
    );
};

const INLINE_STYLES = [
    {label: <FormatBoldIcon/> , style: 'BOLD',name: 'BOLD'},
    {label: <FormatItalicIcon/>, style: 'ITALIC',name: 'ITALIC'},
    {label: <FormatUnderlinedIcon/>, style: 'UNDERLINE',name: 'UNDERLINE'},
    {label: <FormatAlignLeft/>, style: 'LEFT',name: 'LEFT'},
    {label: <FormatAlignCenter/>, style: 'CENTER',name: 'CENTER'},
    {label: <FormatAlignRight/>, style: 'RIGHT',name: 'RIGHT'},

];

export const InlineStyleControls = (props) => {
    let currentStyle = props.editorState.getCurrentInlineStyle();
    return (
        <div className="RichEditor-controls">
            {INLINE_STYLES.map(type =>
                <StyleButton
                    key={type.name}
                    active={currentStyle.has(type.style)}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            )}
            <PermMediaIcon className = 'RichEditor-styleButton' onClick={props.onAddImage}/>

            <GridOnIcon className = 'RichEditor-styleButton' onClick={props.onAddTable}/>

        </div>
    );
};