import React from 'react';
import Button from '@material-ui/core/Button';
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../config/design/styles";
import {connect} from "trim-redux";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import Typography from "@material-ui/core/Typography/Typography";
import {defaultVariabls} from "../../../../config";
import FormControl from "@material-ui/core/FormControl/FormControl";
import LoadingButton from "../../../components/elements/LoadingButton";
import axios from "axios";
import Grid from "@material-ui/core/Grid/Grid";
import {updateRedux} from "../../../../config/Security";
import {translate} from "../../../../config/lang";
import SubTitle from "../../../components/elements/SubTitle";
import {Link} from "react-router-dom";
import ChevronRightRoundedIcon from "@material-ui/core/SvgIcon/SvgIcon";
import Alert from "@material-ui/lab/Alert/Alert";
import {Translate} from "../../../../config/Translate";

class UpdateButton extends React.Component{
    state= {
        dialogOpen: false,
        loading: false,
    };
    classes = this.props.classes;
    toggleModal(){
        this.setState({
            dialogOpen: !this.state.dialogOpen,
        })
    }
    handleUpdateAd(){
        let {id} = this.props;
        let data = new FormData();
        data.append('id',id);
        this.setState({
            loading:true,
        });
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/advertisement/real_update`, data, config)
            .then(response => {
                this.setState({
                    loading:false,
                    dialogOpen: false,

                });
                this.props.enqueueSnackbar(translate('advertisementUpdated'), {
                    variant: 'success',
                });
                this.props.reload();
            })
            .catch(error => {
                console.log(error.response);

                this.setState({
                    loading:false,
                });
            });
    }
    render(){
        let{dialogOpen,loading} = this.state;
        let{user} = this.props;
        let user_can_edit
        if(user.role.type !== "admin"){
            user_can_edit = parseInt(user.plan.edit_count) - parseInt(user.plan_usage.edit_count)
        }else{
            user_can_edit = true;
        }
        return (
            <>
                {
                    !!user_can_edit &&
                        <>
                            <Button
                                variant="contained"
                                color="primary"
                                size={"small"}
                                onClick={() => this.toggleModal()}
                                style={{margin:5}}
                            >
                                {translate('update')}
                            </Button>
                            <Dialog
                                open={dialogOpen}
                                onClose={() => this.toggleModal()}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                maxWidth={'xs'}
                                fullWidth
                            >
                                <DialogTitle id="alert-dialog-title" className={this.classes.alertText}>
                                    <SubTitle >{translate('updateAdReal')}</SubTitle>
                                </DialogTitle>
                                <DialogContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography className={this.classes.textFloat}>{translate('updateAdRealDesc')}</Typography>
                                        </Grid>
                                        {
                                            (user.role.type !== "admin") &&
                                            <Grid item xs={12}>
                                                <Alert>
                                                    <span>{translate('monthlyEditCount')}: </span>
                                                    {" "}
                                                    <strong className={this.classes.primary_color}>{parseInt(user.plan.edit_count) - parseInt(user.plan_usage.edit_count)}</strong>
                                                </Alert>
                                            </Grid>
                                        }
                                        <Grid item xs={12}>
                                            <LoadingButton
                                                variant="contained"
                                                color="primary"
                                                onClick={() => this.handleUpdateAd()}
                                                loading={loading}
                                            >
                                                {translate('update')}
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                            </Dialog>
                        </>
                }
            </>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(UpdateButton))));