import React from 'react';
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../../config/design/styles";
import TinyAreaChart from "../../../../components/charts/TinyAreaChart";
import axios from "axios";
import {defaultVariabls} from "../../../../../config/index";
import {setStore} from "trim-redux";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
class LiveUsers extends React.Component {
    state = {
        liveUsers: null,
    };
    componentDidMount(){
        this.loadData();
        this.interval = setInterval(() => {
            this.loadData();
        }, 6000);
    }
    componentWillUnmount(){
        clearInterval(this.interval);

    }
    classes = this.props.classes;
    async loadData(){
        let loginToken = localStorage.getItem('ACT');

        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/analytics/real-time-users`, config)
                .then(response => {
                    this.setState({
                        liveUsers: response.data
                    })
                }).catch(error => {
                    console.log(error.response)
                    this.setState({
                        loading:false,
                        liveUsers:'--'
                    })
                    clearInterval(this.interval);

                })
        }else {
            setStore({
                isAuth : false,
                user : null,
                loading:false
            });
            this.setState({
                loading:false
            })
        }
    }
    async refresh(){
        await this.setState({
            liveUsers:null
        })
        await this.loadData();
    }
    render(){
        let {liveUsers} = this.state;
        return (
            <React.Fragment>
            <strong className="liveUserNumber" onClick={this.refresh.bind(this)}>
                {liveUsers === null ? <CircularProgress />  : liveUsers}
            </strong>
                <div style={{height:90}}>
                    <TinyAreaChart/>
                </div>
            </React.Fragment>
        );
    }
}
export default withStyles(Styles)(LiveUsers);