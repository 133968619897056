import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from '../../../config/Translate';
import {connect} from "trim-redux";
import {withRouter} from 'react-router-dom';
import { withSnackbar } from 'notistack';
import HeaderTags from "../../components/elements/HeaderTags";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import SubTitle from "../../components/elements/SubTitle";
import axios from "axios";
import {defaultVariabls} from "../../../config";
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import DoneAllRoundedIcon from '@material-ui/icons/DoneAllRounded';
import Typography from "@material-ui/core/Typography/Typography";
import AuthLayout from "../../components/layouts/AuthLayout";
import Box from "@material-ui/core/Box/Box";

class VerifyEmailPage extends React.Component {
    state = {
        loading:true,
        success:false,
    }
    id = this.props.match.params.id;
    code = this.props.match.params.code;
    componentDidMount(){
        this.handleRequest();
    }
    async handleRequest(){
        let data = new FormData();
        data.append('id', this.id);
        data.append('code', this.code);
        let config = {
            headers: {
                Accept: 'application/json',
            }
        };
        await axios.post(`${defaultVariabls.url}/verifyEmail`, data, config)
            .then(response =>{
                    this.setState({
                        loading:false,
                        success:true,
                    })
            })
            .catch(error =>{
                this.setState({
                    loading:false,
                    success:false,
                })
            });
    }
    render() {
        const {classes} = this.props;
        let {loading,success} = this.state;
        return (
            <AuthLayout>
                <HeaderTags title={Translate.verifyEmail}/>
                <Grid item md={10} lg={8} className={classes.authFormHolder}>
                    <Box mb={{xs: 3, xl: 8}}  fontSize={20}>
                        <br/>
                        <strong> {Translate.verifyEmail}</strong>
                        {
                            loading ?
                                <Grid container spacing={1} direction="row" justify="center" alignItems='center'>
                                    <Grid item xs={2}>
                                        <CircularProgress />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SubTitle>{Translate.verifingInfos}</SubTitle>
                                    </Grid>
                                </Grid>
                                :
                                <React.Fragment>
                                    {
                                        success ?
                                            <Grid container spacing={1} direction="row" justify="center" alignItems='center'>
                                                <Grid item xs={2}>
                                                    <DoneAllRoundedIcon style={{color:"#38bf84",fontSize:'5rem'}}/>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <SubTitle style={{color:"#38bf84"}}>{Translate.emailVerified}</SubTitle>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <a href={defaultVariabls.React}>
                                                        <Typography className="withCursor" color="primary" align="center">
                                                            {Translate.goBackToPanel}
                                                        </Typography>
                                                    </a>
                                                </Grid>
                                            </Grid>
                                            :
                                            <Grid container spacing={1} direction="row" justify="center" alignItems='center'>
                                                <Grid item xs={2}>
                                                    <WarningRoundedIcon style={{color:"#ef6b2c",fontSize:'5rem'}} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <SubTitle style={{color:"#ef6b2c"}}>{Translate.emailNotVerified}</SubTitle>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <a href={defaultVariabls.React}>
                                                        <Typography className="withCursor" color="primary" align="center">
                                                            {Translate.goBackToPanel}
                                                        </Typography>
                                                    </a>
                                                </Grid>
                                            </Grid>
                                    }
                                </React.Fragment>
                        }
                    </Box>
                </Grid>
            </AuthLayout>
        );

    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(VerifyEmailPage))));