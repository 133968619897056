import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {DataTableLocalization, getDataTableLocalization, Translate} from "../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import MaterialTable from "material-table";
import {tableIcons} from "../../../../config/design/TableIcons";
import {withRouter} from "react-router-dom";
import moment from "moment-timezone";
import { withSnackbar } from 'notistack';
import Container from "@material-ui/core/Container/Container";
import {TableCount, TablePage} from "../../../../config/Permissions";
import EditCommentDialog from "./components/EditCommentDialog";
import Button from "@material-ui/core/Button/Button";
import AddBox from "@material-ui/icons/AddBox";
import AddCommentDialog from "./components/AddCommentDialog";
import AppCardLoaderTable from "../../../components/Skeletons/AppCardLoaderTable";
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import Typography from "@material-ui/core/Typography/Typography";
import {makeSlug, translate} from "../../../../config/lang";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {connect} from "trim-redux";
import {websiteLink} from "../../../../config";
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';
import {getTimeZone} from "../../../../config/values";

class Comments extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    state = {
        columns: [
            {
                field: 'item',
                title: translate(this.props.type),
                render: rowData =>
                        <Button
                            startIcon={<OpenInNewRoundedIcon color="primary"/>}
                            component={'a'}
                            href={makeSlug(`${this.props.type}/${rowData.item.slug}`,rowData.item.lang)}
                        >
                            {rowData.item.name}
                        </Button>
            },
            {
                title: translate('rate'),
                field: 'rate',
            },
            {
                title: translate('simple_user'),
                field: 'user',
            },
            {
                title: translate('comment'),
                field: 'comment',
            },
            {
                title: translate('confirmationStatus'),
                field: 'confirmed',
                lookup: {
                    1 :
                        <Typography style={{color: "#38bf84"}}>{translate('confirmed')}</Typography>
                    , 0:
                        <Typography color="textSecondary">{translate('unConfirmed')}</Typography>
                },
            },
            {
                title: translate('created_at'),
                editable: 'never',
                field: 'created_at',
                render: rowData =>
                    <Typography className={this.classes.dateTable}>
                        {`${moment.tz(rowData.created_at,'UTC').tz(getTimeZone()).fromNow()}`}
                        <br/>
                        {`${moment.tz(rowData.created_at,'UTC').tz(getTimeZone()).format( 'HH:mm , YYYY/M/D')}`}
                    </Typography>
            },
            { title: translate('activity'),
                editable: 'never',
                field: 'activity',
                hidden: this.props.user.role.type !== "admin",

                render: rowData =>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => this.handleRowClick(rowData)}
                        endIcon={<ChevronRightRoundedIcon/>}
                        size={"small"}
                        style={{marginBottom:5,marginRight:5}}
                    >
                        {translate('details')}
                    </Button>
            },

        ],
        data: [],
        loading:false,
        ChangeLoader:false,
        id:null,
        replay_comment:null,
        replay_ad:null,
        openDialog:false,
        openDialogAdd:false,
        replaying:false,

    };
    componentDidMount(){
        this.loadData();
    }
    loadData(){
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }
    async handleReplayClick(data){
        await this.setState({
            replay_comment: data.id,
            replay_ad: data.item_id
        })
        await this.toggleDialogAdd();
    }

    onDelete(id,hasNotif = true) {
        let data = new FormData();
        data.append('id', id);
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/comment/delete`, data, config)
            .then(response => {
                if(hasNotif){
                    this.props.enqueueSnackbar(translate('commentRemoved'), {
                        variant: 'success',
                    });
                }
                return true
            })
            .catch(error => {
                this.props.enqueueSnackbar(translate('commentRemovingFailed'), {
                    variant: 'error',
                });
                return false
            });
        this.loadData();
    }
    async onDeleteSelected(data){
        await data.map((row) =>(
            this.onDelete(row.id,false)

        ));
        await this.loadData();
        await this.props.enqueueSnackbar(translate('deleteSelectedComments'), {
            variant: 'success',
        });
    }
    handleRowClick(data){
        this.setState({
            id:data.id,
            openDialog:true,
        })
    }
    changePageSize(data){
        setTimeout(function() {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }, 100);

    }
    async toggleDialog(){
        await this.setState({
            openDialog: !this.state.openDialog,
            id:null,
            ChangeLoader:true,
        })
        await this.loadData();
    }
    async toggleDialogAdd(){
        await this.setState({
            openDialogAdd: !this.state.openDialogAdd,
            ChangeLoader:true,
        })
        await this.loadData();
    }
    render(){
        let{openDialog,id,openDialogAdd}= this.state;
        let{type_id} = this.props;
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        return (
            <>
                    {
                        this.state.loading ? <AppCardLoaderTable/> :
                            <Container maxWidth="xl">
                            <MaterialTable
                                    tableRef={this.tableRef}
                                    icons={tableIcons}
                                    onChangeRowsPerPage={(data) => this.changePageSize(data)}
                                    /*title={
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={this.toggleDialogAdd.bind(this)}
                                            startIcon={<AddBox />}
                                        >
                                            {translate('addComment')}
                                        </Button>
                                    }*/
                                    title={" "}
                                    localization={getDataTableLocalization()}
                                    columns={this.state.columns}
                                    data={query =>
                                        new Promise((resolve, reject) => {
                                            axios.get(`${defaultVariabls.url}/comments/quickView?page=${query.page + 1}&per_page=${query.pageSize}&search=${query.search}${!!type_id ? `&type_id=${type_id}` : ''}&type=${this.props.type}`, config)
                                                .then(response => {
                                                    let data = [];
                                                    let {type} = this.props;
                                                    response.data.data.map((row) =>(
                                                        data.push({
                                                            item: type === "product" ? row.product : type === "business" ? row.business : null ,
                                                            item_id: !!row.product ? row.product.id : !!row.business ? row.business.id : null ,
                                                            user: !!row.user ? row.user.first_name+" "+row.user.last_name : null,
                                                            comment: !!row.comment ? row.comment.slice(0,15)+"..." : null,
                                                            confirmed: row.confirmed,
                                                            rate: row.rate,
                                                            id:row.id,
                                                            created_at:row.created_at,
                                                        })
                                                    ));
                                                    resolve({
                                                        data: data,
                                                        page: response.data.current_page - 1,
                                                        totalCount: response.data.total,
                                                    })
                                                })
                                                .catch(error => {
                                                    console.log(error.response)
                                                    this.props.enqueueSnackbar(translate('connectionError'), {
                                                        variant: 'error',
                                                    });
                                                });
                                        })
                                    }
                                    options={{
                                        selection: false,
                                        pageSize: TablePage,
                                        pageSizeOptions: TableCount,
                                        draggable: false,
                                        sorting:false,
                                    }}
                                    editable={{
                                        onRowDelete: oldData =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve();
                                                    this.onDelete(oldData.id);
                                                    this.setState(prevState => {
                                                        const data = [...prevState.data];
                                                        data.splice(data.indexOf(oldData), 1);
                                                        return { ...prevState, data };
                                                    });
                                                }, 600);
                                            }),
                                    }}
                                />
                            </Container>

                    }
                {
                    openDialog ? <EditCommentDialog type={this.props.type} id={id} cancleOperation={() => this.toggleDialog()} handleDone={() => this.toggleDialog()} /> : null
                }
                {
                    openDialogAdd ? <AddCommentDialog type={this.props.type} replay_comment={this.state.replay_comment} replay_ad={this.state.replay_ad} id={id} cancleOperation={() => this.toggleDialogAdd()} handleDone={() => this.toggleDialogAdd()} /> : null
                }
            </>

        );
    }
}

const mstp = state => ({
    user: state.user,
    features: state.features,
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(Comments)))));
