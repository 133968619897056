import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { defaultVariabls } from '../../../../config/index';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {connect} from "trim-redux";
import AppCardLoaderFields from "../../../components/Skeletons/AppCardLoaderFields";
import Typography from "@material-ui/core/Typography/Typography";
import {Translate} from "../../../../config/Translate";
import IconButton from "@material-ui/core/IconButton/IconButton";
import AppCard from "../../../components/layouts/components/AppCard";
import {updateRedux} from "../../../../config/Security";
import Title from "../../../components/elements/Title";
import {AddressesLimit} from "../../../../config/values";
import withWidth from "@material-ui/core/withWidth/withWidth";
import RoomRoundedIcon from '@material-ui/icons/RoomRounded';
import AddAddressDialog from "./AddAddressDialog";
import SingleAddress from "./SingleAddress";

class MyAddresses extends React.Component {
    classes = this.props.classes
    state={
        addresses:[],
        loading:true,
        chosen:null,
    };
    componentDidMount(){
        this.loadData();
    }
    async loadData(){
        await this.setState({
            loading:true
        })
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.get(`${defaultVariabls.url}/addresses/getAll`, config)
            .then(async response => {
                let chosen = await !!response.data ? !!response.data[0] ? response.data[0].id : 0 : 0;
                await this.setState({
                    addresses:response.data,
                    chosen:chosen,
                    loading:false
                })
                if(!!chosen){
                    await this.props.onChange(chosen)
                }
            })
            .catch(error => {
                console.log(error.response);
                this.setState({
                    loading:false
                })
            });
    }
    render() {
        const {addresses,loading,chosen} = this.state;
        const {choose} = this.props;
        return (
            <>
                {
                    loading ? <AppCardLoaderFields/> :
                        <Grid container spacing={2} alignItems="center" justify="center"  style={{paddingTop:choose ? 0 : 20,minHeight:choose ? 0 :300,paddingBottom:choose ? 0 : 100}}>
                            <Grid item xs={12} md={choose ? 12:11}>
                                <Grid container spacing={2}>
                                    <CssBaseline/>
                                    {
                                        addresses.length === 0 ?
                                            <Grid item xs={12} style={{textAlign:"center"}}>
                                                <img width={120} src="../assets/images/map.png" alt="work" loading="lazy"/>
                                                <Title paragraph>{Translate.addAddressDetails}</Title>
                                                <Typography paragraph align="center">{Translate.addAdressDetailsLong}</Typography>
                                                <AddAddressDialog first disabled={addresses.length >= AddressesLimit} length={addresses.length} reload={this.loadData.bind(this)}/>
                                            </Grid>
                                            : null
                                    }
                                    {
                                        addresses.map((address,index) => (
                                            <Grid item xs={12} md={6} lg={4} key={index}>
                                                <SingleAddress
                                                    chosen={chosen}
                                                    handleChoose ={(id) => {this.setState({chosen:id});this.props.onChange(id)}}
                                                    choose={choose}
                                                    data={address}
                                                    reload={this.loadData.bind(this)}
                                                />
                                            </Grid>
                                        ))
                                    }
                                    {
                                        addresses.length === 0 ? null :
                                            <Grid item xs={12} md={6} lg={4}>
                                                <AddAddressDialog choose={choose} disabled={addresses.length >= AddressesLimit} length={addresses.length} reload={this.loadData.bind(this)}/>
                                            </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                }

            </>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withWidth()(withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MyAddresses)))));