import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import Marker from "../../../../files/img/marker.png";
class MapMarker extends React.Component {
    classes = this.props.classes;
    render(){
        return(
            <>
                <img
                    style={{
                        width:35,
                        position:"absolute",
                        top:0,
                        left:0,
                    }}
                    src={Marker}
                    alt="marker"
                />
            </>
        )
    }
}
export default withSnackbar(withRouter(withStyles(Styles)(MapMarker)));