import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import {Translate} from "../../../config/Translate";
import RestoreRoundedIcon from '@material-ui/icons/RestoreRounded';
import Button from "@material-ui/core/Button/Button";
import {defaultVariabls} from "../../../config";
import {updateRedux} from "../../../config/Security";
import {getStore} from "trim-redux";
class CheckSimulation extends React.Component{
    state = {
        is_simulation:false
    }
    componentDidMount(){
        this.checkSimulation();
    }
    async checkSimulation(){
        let adminLoginToken = localStorage.getItem('ADMIN_ACT');
        if(!!adminLoginToken){
            this.setState({
                is_simulation:true
            })
        }

    }
    async handleBackToMyAccount(){
        await localStorage.setItem('ACT',localStorage.getItem('ADMIN_ACT'));
        await localStorage.removeItem('ADMIN_ACT');
        await updateRedux();
        let user = await getStore('user')
        let username = user.email;
        let login_token = user.login_token;
        await window.location.replace(`${defaultVariabls.website}/loginFromPanel/${username}/${login_token}`);
    }
    render(){
        let {is_simulation} = this.state;
        return (
            <>
                {
                    is_simulation &&
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        open={true}
                        message={Translate.youAreInSomeOneAccount}
                        action={
                            <Button
                                color="secondary"
                                variant="contained"
                                size="small"
                                startIcon={<RestoreRoundedIcon/>}
                                onClick={() => this.handleBackToMyAccount()}
                            >
                                {Translate.backToMyAccount}
                            </Button>
                        }
                    />
                }

            </>
        );
    }
}
export default CheckSimulation
