import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import BreadCrumbs from "../../../components/elements/BreadCrumbs";
import AppAnimate from "../../../components/AppAnimate";
import AppCard from "../../../components/layouts/components/AppCard";
import Plans from "./Plans";
import {translate} from "../../../../config/lang";


class ShopsPage extends React.Component {
    classes = this.props.classes;
    render(){
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={translate('managePlans')}>
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12} lg={11}>
                        <AppCard
                            title={ translate('managePlans')}
                            action={
                                <BreadCrumbs singleLevel firstText={translate('managePlans')}/>
                            }>
                            <Plans/>
                        </AppCard>
                    </Grid>
                </Grid>
            </AppAnimate>
        );
    }
}
export default withSnackbar(withStyles(Styles)(ShopsPage));
