import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {getDataTableLocalization} from "../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../config/index";
import {connect} from "trim-redux";
import MaterialTable from "material-table";
import {tableIcons} from "../../../config/design/TableIcons";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import moment from "moment-timezone";
import {TableCount, TablePage} from "../../../config/Permissions";
import Container from "@material-ui/core/Container/Container";
import Typography from "@material-ui/core/Typography/Typography";
import AppCardLoaderTable from "../../components/Skeletons/AppCardLoaderTable";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {currencySymbol, getTimeZone} from "../../../config/values";
import CurrencyFormat from "react-currency-format";

import WithdrawMoneyDialog from "./WithdrawMoneyDialog";
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import IconButton from "@material-ui/core/IconButton/IconButton";
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import {translate} from "../../../config/lang";
import MyWallet from "../../components/elements/MyWallet";
class Wallet extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    state = {
        columns: [
            {
                field: 'type',
                editable: 'never',
                filtering: false,
                title: translate('transactionType'),
                lookup: {
                    "deposit" :
                        <Typography style={{color: "#38bf84"}}><IconButton size="small"><AddRoundedIcon style={{color:"#38bf84"}}/></IconButton> {translate('deposit')}</Typography>
                    , "withdraw":
                        <Typography style={{color:"#BF8230"}}><IconButton size="small"><RemoveRoundedIcon style={{color:"#BF8230"}}/></IconButton> {translate('withdraw')}</Typography>
                },
            },
            { title: translate('amount'),
                field: 'amount',
                filterPlaceholder: translate('search'),
                render: rowData => <CurrencyFormat value={rowData.amount} displayType={'text'} thousandSeparator={true}  suffix={" "+currencySymbol} />
            },
            { title: translate('transactionTrackingCode'),
                field: 'refrence',
                filterPlaceholder: translate('search'),
            },
            { title: translate('details'),
                field: 'description',
                filterPlaceholder: translate('search'),
            },
            { title: translate('status'),
                editable: 'never',
                field: 'status',
                filterPlaceholder: translate('choose'),
                lookup: {
                    "confirmed" :
                        <Typography style={{color: "#38bf84"}}>{translate('confirmed')}</Typography>
                    , "pending":
                        <Typography color="textSecondary">{translate('pending')}</Typography>
                    , "not_payed":
                        <Typography color="textSecondary">{translate('not_payed')}</Typography>
                },
            },
            {
                title: translate('created_at'),
                editable: 'never',
                field: 'created_at',
                render: rowData =>
                    <Typography className={this.classes.dateTable}>
                        {`${moment.tz(rowData.created_at,'UTC').tz(getTimeZone()).fromNow()}`}
                        <br/>
                        {`${moment.tz(rowData.created_at,'UTC').tz(getTimeZone()).format( 'HH:mm , YYYY/M/D')}`}
                    </Typography>
            },
            /*{ title: translate('activity'),
                field: 'activity',
                render: rowData =>
                    <>
                        {
                            (rowData.status === "not_payed" || rowData.status === "pending") &&
                                <Button
                                    onClick={() =>this.handleRemoveItem(rowData.id)}
                                    color="secondary"
                                    size="small"
                                >
                                    {translate('remove')}
                                </Button>
                        }
                  </>
            },*/

        ],
        data: [],
        loading:false,
        ChangeLoader:false
    };
    loadData(){
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }
    handleRemoveItem(id){
        let data = new FormData();
        data.append('id', id);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/wallet/removeHistory`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(translate('dataRemoved'), {
                    variant: 'success',
                });
                this.loadData();
            })
            .catch(error => {
                this.props.enqueueSnackbar(translate('dataRemovingFailed'), {
                    variant: 'error',
                });

            });

        this.setState({
            loading:false,
            SubmitLoading:false
        });
    }
    render(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        return (
            <>
                {
                    this.state.loading ? <AppCardLoaderTable/> :
                        <Container maxWidth="xl">
                            <MaterialTable
                                parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                                tableRef={this.tableRef}
                                icons={tableIcons}
                                title={" "}
                                localization={getDataTableLocalization()}
                                columns={this.state.columns}
                                data={query =>
                                    new Promise((resolve, reject) => {
                                        axios.get(`${defaultVariabls.url}/wallet/myHistory?page=${query.page + 1}&per_page=${query.pageSize}&search=${query.search}`, config)
                                            .then(response => {
                                                let data = [];
                                                // eslint-disable-next-line
                                                response.data.data.map((row) => {
                                                    data.push({
                                                        amount: row.amount,
                                                        id: row.id,
                                                        description: row.description,
                                                        status: row.status,
                                                        type: row.type,
                                                        created_at: row.created_at,
                                                        refrence: row.refrence_id,
                                                    })
                                                });
                                                resolve({
                                                    data: data,
                                                    page: response.data.current_page - 1,
                                                    totalCount: response.data.total,
                                                })
                                            }).catch(error => {
                                            console.log(error.response)
                                        })
                                    })
                                }
                                options={{
                                    pageSize: TablePage,
                                    pageSizeOptions: TableCount,
                                    defaultExpanded:true,
                                    search:false,
                                }}
                            />
                        </Container>
                }
            </>

        );
    }
}

const mstp = state => ({
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(Wallet)))));