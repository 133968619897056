import queryString from "query-string";
import {setStore} from "trim-redux";

export const howToSale = ["روش فروش ۵","روش فروش ۴","روش فروش ۳","روش فروش ۲","روش فروش ۱"];

export function toEnglishDigits(str) {

    let  e = '۰'.charCodeAt(0);
    str = str.replace(/[۰-۹]/g, function(t) {
        return t.charCodeAt(0) - e;
    });
    return str;
}

export function price(x) {
    if(!!x){
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    }else {
        return x
    }
}

export function truncate(str, n){
    if(!!str){
        return (str.length > n) ? str.substr(0, n-1) + '...' : str;
    }else{
        return ''
    }
};
export function changeURL(queryStrong) {
    let url = queryString.stringifyUrl({url: window.location.href, query: queryStrong});
    window.history.replaceState(null, "",url);
}
export function changeMode(mode){
    setStore({
        global_loading: true
    })
    if(mode === "dark"){
        mode = 'dark';
    }else{
        mode = 'light';
    }
    setStore({
        mode:mode
    });
    localStorage.setItem('mode',mode);
    setTimeout(function() {
        setStore({
            global_loading: false
        })
    }, 200);
}
export function getMonths(days){
    return Math.round(days / 30);

}
export const getTimeZone = () => {
    const {timeZone} = Intl.DateTimeFormat().resolvedOptions();
    return timeZone
}
export const currencySymbol = '$';

export const AddressesLimit = 5;

