import React from 'react';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../config/design/styles";

class LoadingButton extends React.Component{
    state= {
        loading:false,
        success:false
    };
    componentDidMount(){
        this.setState({
            loading:this.props.loading,
            success:this.props.success
        })
    }
    classes = this.props.classes;
    render(){
        let {children,type,variant,color,onClick,size,loading,success,style,disabled,startIcon,styleForParent} =this.props;
        let buttonClassName = clsx({
            [this.classes.buttonSuccess]: success,
        });
    return (
        <div style={styleForParent} className={this.classes.LoadingButtonWrapper}>
            <Button
                variant={variant}
                type={type}
                style={style}
                color={color}
                startIcon={startIcon}
                className={buttonClassName}
                disabled={disabled || loading}
                onClick={onClick}
                size={size}
            >
                {children}
            </Button>
            {loading && <CircularProgress size={24} className={this.classes.buttonProgress} />}
        </div>
    );
}
}
export default withStyles(Styles)(LoadingButton);