import React from 'react';
import {connect} from "trim-redux";

class Permission extends React.Component {
    render (){
        const { children,isAuth,rule,rule2,user} = this.props;
        return (
            isAuth ? (
                (user.role.permission[rule] || (!!rule2 ? user.role.permission[rule2] : false) ) ? (
                    children
                ) : (
                    null
                )
            ) : (
                null
            )
        )
    }
}

const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default connect(mstp)(Permission);
