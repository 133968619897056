import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Styles} from "../../../../config/design/styles";
import {Translate} from "../../../../config/Translate";
import FileManagerDialog from "./FileManagerDialog";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import DefaultImage from '../../../../files/img/default.jpg';
import DefaultAvatar from '../../../../files/img/user-default.jpg';
import DefaultLogo from '../../../../files/img/logo-default.jpg';
import DefaultCover from '../../../../files/img/cover-default.jpg';
import { withSnackbar } from 'notistack';
import Avatar from "@material-ui/core/Avatar/Avatar";
import Badge from "@material-ui/core/Badge/Badge";
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import IconButton from "@material-ui/core/IconButton/IconButton";
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import {withRouter} from "react-router";
import {connect} from "trim-redux";

class Thumbnail extends React.Component{
    state = {
        FileManagerPending: false,
        image: this.props.avatar ? DefaultAvatar : this.props.cover ? DefaultCover : this.props.logo ? DefaultLogo : DefaultImage,
        init: this.props.initial,
        id: this.props.initial
    };
    classes = this.props.classes;
    size = this.props.size ? this.props.size: 245;
    image_ = (this.props.cover || this.props.simple_full) ? "" : this.size > 400 ? "_medium" : "_small";
    componentDidMount(){
        this.loadData()
    }

    loadData(){
        let id = this.state.init;
        let data = new FormData();
        data.append('id', id);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/file/get`, data, config)
            .then(response => {
                this.setState({
                    image: `${defaultVariabls.uploads}/FileManager/${response.data.name}${this.image_}.${response.data.extension}`,
                    init: id,
                    id: response.data.id,
                })
                this.props.handleThumbImage(response.data.id)
            })
            .catch(error => {
                this.props.enqueueSnackbar(Translate.loadingThumbnailFailed, {
                    variant: 'error',
                });
            });
    }
    toggleOperation= () => {
        this.setState({
            FileManagerPending:!this.state.FileManagerPending,
        })
    }
    setDefaultImage(){
        this.setState({
            image: DefaultImage
        })
    }
    setImage = (data) => {
        this.setState({
            image: data.thumbSource,
            id: data.id
        })
        this.props.handleThumbImage(data.id)
        this.toggleOperation();
    }
    async handleDefaultImage(){
        let check = this.props.avatar;
        let logo = this.props.logo;
        let cover = this.props.cover;
        if(check){
            await this.setState({
                image: DefaultAvatar,
                id:2,
            })
            await this.props.handleThumbImage(2)
        }else if(logo){
            await this.setState({
                image: DefaultLogo,
                id:3,
            })
            await  this.props.handleThumbImage(3)
        }else if(cover){
            await this.setState({
                image: DefaultCover,
                id:4,
            })
            await  this.props.handleThumbImage(4)
        }else{
            await this.setState({
                image: DefaultImage,
                id:1,
            })
            await this.props.handleThumbImage(1)
        }
    }
    render(){
        let {FileManagerPending,image,id} = this.state;
        let {title,rounded,primary_color,simple_full} = this.props;
        return (
            <div style={simple_full ? null : {textAlign:"center"}}>
                <Badge
                    className="withCursor"
                    overlap="circle"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    style={{
                        width: this.props.cover ? "100%" : "unset",
                    }}
                    badgeContent={<Avatar onClick={this.toggleOperation} className={this.classes.avatar_badge} style={{backgroundColor:primary_color}} alt={Translate.changeImage} > <EditRoundedIcon/> </Avatar>}
                >
                    <Avatar
                        alt={title ? title : Translate.ThumbnailTitle}
                        src={image}
                        variant={rounded ? "rounded" : "circle"}
                        onClick={this.toggleOperation}
                        style={{
                            width: (this.props.cover || this.props.simple_full) ? "100%" : this.size,
                            height: this.size
                        }}>
                        {Translate.appName.charAt(0)}
                    </Avatar>
                    {
                        id !== 1 && id !== 2 && id !== 3 && id !== 4 ?
                            <IconButton
                                className="thumbnailCloser"
                                onClick = {this.handleDefaultImage.bind(this)}
                                color="secondary"
                            >
                                <CancelRoundedIcon />
                            </IconButton>
                            : null
                    }
                </Badge>
                {
                FileManagerPending ? <FileManagerDialog selection size={this.props.size} cancleOperation={this.toggleOperation} setImage={this.setImage} cover={this.props.cover}/> : null
                }
            </div>
        );
    }
}
const mstp = state => ({
    primary_color: state.primary_color
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(Thumbnail))));