import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../../config/Translate";
import { withRouter} from 'react-router-dom';
import { withSnackbar } from 'notistack';
import BreadCrumbs from "../../../components/elements/BreadCrumbs";
import {connect} from "trim-redux";
import AppCard from "../../../components/layouts/components/AppCard";
import AppAnimate from "../../../components/AppAnimate";
import OrdersAnalytics from "./OrdersAnalytics";

class OrdersAnalyticsPage extends React.Component {
    classes = this.props.classes;
    render(){
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={Translate.ordersAnalytics}>
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12}>
                        <AppCard
                            title={ Translate.ordersAnalytics}
                            action={
                                <BreadCrumbs singleLevel firstText={Translate.ordersAnalytics}/>
                            }>
                            <OrdersAnalytics/>
                        </AppCard>
                    </Grid>
                </Grid>
            </AppAnimate>
        );
    }
}
const mstp = state => ({
    user: state.user
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(OrdersAnalyticsPage))));