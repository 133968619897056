import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Button from "@material-ui/core/Button/Button";
import FileManagerDialog from "./FileManagerDialog";
import Card from "@material-ui/core/Card/Card";
import IconButton from "@material-ui/core/IconButton/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import {withRouter} from "react-router";
import {withSnackbar} from "notistack";
import ListItem from "@material-ui/core/ListItem/ListItem";
import List from "@material-ui/core/List/List";
import ListItemAvatar from "@material-ui/core/ListItemAvatar/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar/Avatar";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction";
import OndemandVideoRoundedIcon from '@material-ui/icons/OndemandVideoRounded';
import {truncate} from "../../../../config/values";
import PictureAsPdfRoundedIcon from '@material-ui/icons/PictureAsPdfRounded';
import ImageRoundedIcon from '@material-ui/icons/ImageRounded';
import {translate} from "../../../../config/lang";
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import {defaultVariabls} from "../../../../config";
class FilesChooser extends React.Component {
    classes = this.props.classes;
    state={
        FileManagerPending: false,
        images: {},
    }
    componentDidMount(){
        this.loadData()
    }
    loadData(){
        this.setState({
            images:this.props.initial
        })
    }
    toggleOperation= () => {
        this.setState({
            FileManagerPending:!this.state.FileManagerPending,
        })
    }
    setImage = async (data) => {
        let {images} = this.state;
        let tempImage = images;
        await tempImage.push({
            type: data.type,
            name: data.name,
            src: data.thumbSource,
            extension: data.extension,
            id: data.id
        }) ;
        if(images.length >= 9){
            await this.props.enqueueSnackbar(translate('maxGallerySizeIs9'), {
                variant: 'warning',
            });
        }
        await this.setState({
            images: tempImage
        })
        await this.galleryChanges();
        await this.toggleOperation();

    }
    async removeImage(index) {
        let { images } = this.state;
        let tempImage =[];
        images.map(row =>{
            if(row.id !== index){
                tempImage.push({
                    src: row.src,
                    id: row.id,
                    type: row.type,
                    name: row.name
                })
            }
            return true
        });
        await this.setState({
            images: tempImage
        });
        this.galleryChanges();
    }
    galleryChanges(){
        let {images} = this.state;
        this.props.onChange(images)
    }
    render(){
        let {FileManagerPending,images} = this.state;
        return(
            <React.Fragment>
                <List dense className="gallery">
                    {
                        images.length ? images.map((row,key) =>{
                            return(
                                <Card className={this.classes.galleryRoot} item xs={4} md={2} >
                                    <ListItem key={key}>
                                        {
                                            row.type === "image" ?
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <ImageRoundedIcon/>
                                                    </Avatar>
                                                </ListItemAvatar>
                                                :
                                                row.type === "document" ?
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            <PictureAsPdfRoundedIcon/>
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    :
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <OndemandVideoRoundedIcon/>
                                                    </Avatar>
                                                </ListItemAvatar>
                                        }

                                        <ListItemText primary={truncate(row.name,18)} secondary={translate(row.type)} />
                                        <ListItemSecondaryAction>
                                            <IconButton size="small" onClick={() => this.removeImage(row.id)} aria-label={translate('removeItem')}>
                                                <DeleteIcon />
                                            </IconButton>
                                            <IconButton size="small" component="a" target="_blank" href={`${defaultVariabls.uploads}/FileManager/${row.name}.${row.extension}`}>
                                                <VisibilityRoundedIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </Card>
                            )
                        }) : null
                    }
                </List>
                <Grid container  direction="row">
                    <Button
                        onClick={this.toggleOperation}
                        variant="outlined"
                        color="secondary"
                        size="small"
                        startIcon={<AddBoxIcon />}
                    >
                        {translate('addFile')}
                    </Button>
                </Grid>
                {
                    FileManagerPending ? <FileManagerDialog onlyFile={this.props.onlyFile} fileType={"all"} selection multiInsert cancleOperation={this.toggleOperation} setImage={this.setImage}/> : null
                }
            </React.Fragment>
        )
    }
}
export default withRouter(withSnackbar(withStyles(Styles)(FilesChooser)));