import React from 'react';
import Button from '@material-ui/core/Button';
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../config/design/styles";
import {connect} from "trim-redux";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import Typography from "@material-ui/core/Typography/Typography";
import {defaultVariabls} from "../../../../config";
import FormControl from "@material-ui/core/FormControl/FormControl";
import LoadingButton from "../../../components/elements/LoadingButton";
import axios from "axios";
import Grid from "@material-ui/core/Grid/Grid";
import {updateRedux} from "../../../../config/Security";
import {makeSlug, translate} from "../../../../config/lang";
import SubTitle from "../../../components/elements/SubTitle";
import Alert from "@material-ui/lab/Alert/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle/AlertTitle";

class YourPlanDoesntSupportThis extends React.Component{

    classes = this.props.classes;

    render(){
        let {visible} = this.props;
        return (
            <>
                {
                    !!visible &&
                    <Alert
                        style={{marginTop:10,marginBottom:10}}
                        severity="warning"
                        action={
                            <Button
                                color="inherit"
                                size="small"
                                component="a"
                                href={makeSlug(`plans`)}
                            >
                                {translate('viewPricing')}
                            </Button>
                        }
                    >
                        <AlertTitle className={this.classes.condir}><strong>{translate('yourPlanDoesntSupportThis')}</strong></AlertTitle>
                        {translate('yourPlanDoesntSupportThisDesc')}
                    </Alert>
                }

            </>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(YourPlanDoesntSupportThis))));