import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container/Container";
import Title from "../../components/elements/Title";
import {Translate} from "../../../config/Translate";
import ButtonGroup from "@material-ui/core/ButtonGroup/ButtonGroup";
import Button from "@material-ui/core/Button/Button";
import { Link } from 'react-router-dom';
import {defaultVariabls} from "../../../config";
import HeaderTags from "../../components/elements/HeaderTags";

class Error404 extends React.Component {
    render() {
        const {classes} = this.props;
        return(
            <Container maxWidth="xl">
                <HeaderTags title={Translate.nothingFound}/>
                <Grid container spacing={2} direction="row" justify="center" alignItems="center"  className={classes.GridAsaPaper}>
                    <Grid item xs={12}>
                        <Title color="error">{Translate.notFoundPage}</Title>
                    </Grid>
                        <ButtonGroup style={{margin:"20px 0"}} variant="text" color="primary" aria-label="buttons group">
                            <Link to="/dashboard"> <Button>{Translate.dashboard}</Button> </Link>
                            <a href={defaultVariabls.website}><Button>{Translate.appName}</Button> </a>
                        </ButtonGroup>
                </Grid>
            </Container>
        )
    }
}
export default withStyles(Styles)(Error404);