import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import { withSnackbar } from 'notistack';
import ChooseMobile from './components/LoginWithToken/ChooseMobile'
import {defaultVariabls} from "../../../config";
import axios from "axios";
import {connect, getStore, setStore} from "trim-redux";
import LoginUser from "./components/LoginWithToken/LoginUser";
import HeaderTags from "../../components/elements/HeaderTags";
import Box from "@material-ui/core/Box/Box";
import AuthLayout from "../../components/layouts/AuthLayout";
import {translate} from "../../../config/lang";
import queryString from "query-string";

class LoginWithToken extends React.Component {
    constructor(props){
        super(props);
        const values = queryString.parse(this.props.location.search)
        let redirect = values.r;
        if(this.props.isAuth){
            this.handleLogout();
        }
        this.state={
            level:1,
            user:null,
            redirect: !!redirect ? redirect : null
        };
    }
    setNextLevel(){
        this.setState({
            level: this.state.level+1
        })
    }
    async setUser(user){
        await this.setState({
            user
        })
    }
    makeLogin(data){
        localStorage.setItem('ACT',data.accessToken);
        this.checkLogin();
    }
    async checkLogin(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/checkLogin`, config)
                .then(response => {
                    if(response.data.name === null){
                        localStorage.removeItem('ACT');
                        this.setState({
                            loading:false,
                            level:3
                        })
                    }else{
                        setStore({
                            isAuth : true,
                            user : response.data,
                            umc : response.data.umc,
                        });
                        this.setState({
                            loading:false
                        })
                    }


                }).catch(error => {
                setStore({
                    isAuth : false,
                    user : null,
                    loading:false
                });
                this.setState({
                    loading:false
                })
            })
        }else {
            setStore({
                isAuth : false,
                user : null,
                loading:false
            });
            this.setState({
                loading:false
            })
        }
        if (this.props.isAuth){
            let code = this.props.user.login_token;
            let {iframe} = this.props;
            let token = localStorage.getItem('ACT');
            let lang = localStorage.getItem('locale');
            let mode = localStorage.getItem('mode');
            if(mode === null){
                mode = "light";
            }
            if(iframe){
                await window.parent.location.replace(`${defaultVariabls.website}/loginFromPanel/${this.props.user.email}/${token}/${code}/${lang}/${mode}`);
            }else{
                await window.location.replace(`${defaultVariabls.website}/loginFromPanel/${this.props.user.email}/${token}/${code}/${lang}/${mode}`);
            }
        }
    }
    render() {
        const {classes,iframe} = this.props;
        const {level,user} = this.state;
        return (
            <AuthLayout iframe={iframe}>
                <HeaderTags title={translate('login')}/>
            <Grid item  md={10} lg={9}>
                <Box mb={{xs: 3, xl: 8}}  fontSize={20}>
                    <strong className={classes.auth_title}>
                    {level === 3 ? translate('register') : translate('login')}
                    </strong>
                </Box>
                    {
                        level === 1 ? <ChooseMobile iframe={iframe} hasBack setUser={this.setUser.bind(this)} setNextLevel={this.setNextLevel.bind(this)}/> : null
                    }
                    {
                        level === 2 ? <LoginUser user={user}  makeLogin={this.makeLogin.bind(this)}/> : null
                    }
            </Grid>
            </AuthLayout>
        );

    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(LoginWithToken))));
