import {createStore, getStore} from 'trim-redux'
const state = {
    locale : "en",
    fake_locale : "en",
    mode : "light",
    navCollapsed : false,
    isAuth : false,
    user : null,
    umc : null,
    navOpen:'',
    primary_color: "#4d0267",
    secondary_color:'#fee800',
    x_price:'',
    logo:'',
    app_name:'',
    global_need_verification:0,
    primary_shop:null,
    admins:{},
    menu:[],
    features:{},
    about:{},
    setting:null,
    global_loading:false,
    lat:25.208594880218353,
    lng:55.25179193609027,

};
export const store = createStore(state);