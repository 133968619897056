import {getStore} from "trim-redux";

export const defaultVariabls = {
    url: "https://b2bmineral.com/api",
    uploads: "https://b2bmineral.com/uploads",
    React:"https://panel.b2bmineral.com",
    website: "https://b2bmineral.com",
    youtube: "https://www.youtube.com/watch?v=",
};
/*export let defaultVariabls = {
    url: "http://localhost:8000/api",
    uploads: "http://localhost:8000/uploads",
    React:"http://localhost:3000",
    website: "http://127.0.0.1:8000",
    youtube: "https://www.youtube.com/watch?v=",
};*/

export function websiteLink($link) {
    let locale = getStore("locale");

    return `${defaultVariabls.website}/${locale === "en"  ? "en/" : ''}${$link}`
}

