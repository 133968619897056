import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {connect} from "trim-redux";
import {makeSlug, translate} from "../../../../config/lang";
import AppCard from "../../../components/layouts/components/AppCard";
import AppAnimate from "../../../components/AppAnimate";
import Step6 from "../../../../files/img/steps/6.png";
import Typography from "@material-ui/core/Typography";
import Title from "../../../components/elements/Title";
import Button from "@material-ui/core/Button/Button";
import Link from "@material-ui/core/Link/Link";
class details extends React.Component {
    classes = this.props.classes

    render() {
        let {about} = this.props;
        let {manage} = this.props;
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={translate('step6')}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} lg={11}>
                        <AppCard>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <div className="waiting-container">
                                        <Title color="secondary">{translate('congratulation')}!</Title>
                                        <Title color="secondary">{translate('yourInfoIsVerified')}!</Title>
                                        <img src={Step6} alt="waiting"/>
                                        <Typography style={{textAlign:"center"}} className={this.classes.condir}>{about.register_completed_text}</Typography>
                                    </div>
                                </Grid>
                                {
                                    !manage &&
                                    <>
                                        <Grid item xs={6} style={{textAlign:"center"}}>
                                            <Button
                                                color="secondary"
                                                variant="contained"
                                                component={"a"}
                                                href={makeSlug('buy-plan')}
                                                target="_blank"
                                            >
                                                {translate('buyPlan')}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6} style={{textAlign:"center"}}>
                                            <Button
                                                color="secondary"
                                                variant="contained"
                                                component={"a"}
                                                href={makeSlug('plans')}
                                                target="_blank"
                                            >
                                                {translate('moreInfo')}
                                            </Button>
                                        </Grid>

                                    </>
                                }

                            </Grid>
                        </AppCard>
                    </Grid>
                </Grid>
            </AppAnimate>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    about: state.about,
    primary_color: state.primary_color
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(details))));