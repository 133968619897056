import React, { Component } from 'react';
import {Redirect, Route} from 'react-router-dom';
import {connect} from "trim-redux";

class PrivateRoute extends Component {
    render (){
        const { children,isAuth, ...rest } = this.props;
        return (
                <Route
                    {...rest}
                    render={({ location }) =>
                        isAuth ? (
                            children
                        ) : (
                            <Redirect
                                to={{
                                    pathname: "/login",
                                    state: { from: location }
                                }}
                            />
                        )
                    }
                />
            )
    }
}

const mstp = state => ({  isAuth  :  state.isAuth  });
export default connect(mstp)(PrivateRoute);
