import React from "react";
import {withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../config/design/styles";
import {connect} from "trim-redux";
import axios from "axios";
import {defaultVariabls} from "../../../config";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField/TextField";
import {translate} from "../../../config/lang";

class CurrencyChooser extends React.Component{
    state = {
        countries:[],
        selected:null,
        loading:true,
        open:false,
    };
    async componentDidMount(){
        await this.loadData();
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.get(`${defaultVariabls.url}/countries/getAll`, config)
            .then(response => {
                if(this.props.has_default){
                    this.setState({
                        countries:response.data,
                        loading:false,
                        selected:response.data[222]
                    })
                    this.props.handleCountry(response.data[222]);
                }else{
                    this.setState({
                        countries:response.data,
                        loading:false,
                    })
                }


            })
            .catch(error => {
                this.setState({
                    loading:false
                })
            });
    }
    handleCountry(data){
        this.props.handleCountry(data);
        this.setState({
            selected:data
        })
    }
    render(){
        let {countries,loading,selected,open} = this.state;
        let{required,error,label,margin,size} = this.props;
    return (
        <>
            <Autocomplete
                fullWidth
                open={open}
                color="secondary"
                onOpen={() => {
                    this.setState({open:true})
                }}
                onClose={() => {
                    this.setState({open:false})
                }}
                required={required}
                size={!!size ? size : "normal"}
                getOptionLabel={(option) => option.name}
                renderOption={(option) => (
                    <span style={{display:"flex",alignItems:"center"}}>
{/*
                            <img src={`https://flagsapi.com/${option.iso2}/flat/16.png`} style={{marginRight:5}}/>
*/}
                            <span>{option.name}</span>
                    </span>
                )}
                onChange={(event, value) => this.handleCountry(value)}
                options={countries}
                value={selected}
                loading={loading}
                disabled={this.props.disabled}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={!!label ? label : translate('country')}
                        variant="outlined"
                        color="secondary"
                        size={!!size ? size : "normal"}
                        margin={!!margin ? margin : ""}
                        required={required}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                (!loading && !!selected) &&
                                <React.Fragment>
                                    <img src={`https://flagsapi.com/${selected.iso2}/flat/16.png`} style={{marginRight:5}}/>
                                </React.Fragment>
                            ),
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                        error={error}
                        helperText={!!error && error}
                    />
                )}
            />
        </>
    );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,

});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(CurrencyChooser))));