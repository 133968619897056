import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {CircularProgress, List, ListItem, ListItemSecondaryAction, ListItemText, withStyles} from "@material-ui/core";
import {Styles} from "../../../../config/design/styles";
import {translate} from "../../../../config/lang";
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import axios from "axios";
import {defaultVariabls} from "../../../../config";
import {setStore} from "trim-redux";
import Button from "@material-ui/core/Button/Button";
import {Link} from "react-router-dom";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class TotalResponses extends React.Component{
    state = {
        open: false,
        id:this.props.id,
        loading:true,
    };
    classes = this.props.classes;
    async componentDidMount(){
        await this.setState({
            id:this.props.id,
        })
        await this.loadData();
    }
    async componentDidUpdate(newProps){
        if(newProps !== this.props){
            await this.setState({
                id:this.props.id,
            })
        }
    }
    toggleOpen(){
        this.setState({
            open:!this.state.open
        });
    }
    handleClose = () => {
        this.setState({
            open:false
        });
    };
    async loadData(){
        this.setState({
            loading:true
        })
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/rfq/total_responses/${this.state.id}`, config)
                .then(response => {
                    this.setState({
                        responses:response.data,
                        loading:false,
                    })
                }).catch(error => {
                    console.log(error.response)

                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
            this.setState({
                loading:false
            })
        }
    }
    render(){
        let {open,loading,responses} = this.state;
        let {manage} = this.props;
        return(
            <>
                {
                    loading ? <CircularProgress size={20} color="secondary"/>
                        :
                        <>
                            {
                                responses.length > 0 &&
                                    <IconButton
                                        onClick={() => this.toggleOpen()}
                                        color="primary"
                                        size="small"
                                    >
                                        <VisibilityRoundedIcon fontSize="small"/>
                                    </IconButton>
                            }

                            <span>
                                {responses.length}
                            </span>
                            <Dialog fullWidth={true} maxWidth={"xs"} open={open} onClose={this.handleClose} TransitionComponent={Transition} >
                                <AppBar className={this.classes.appBarDialog}>
                                    <Toolbar>
                                        <Typography variant="h6" className={this.classes.titleDialog}>
                                            {translate('totalResponses')} ({responses.length})
                                        </Typography>
                                        <IconButton color="inherit" onClick={this.handleClose} aria-label="close">
                                            <CloseIcon />
                                        </IconButton>
                                    </Toolbar>
                                </AppBar>
                                <div style={{padding:20}}>
                                    <List dense>
                                        {
                                            responses.map((item,index)=>(
                                                <ListItem key={index}>
                                                    <ListItemText
                                                        primary={item.starter.first_name+" "+item.starter.last_name}
                                                        secondary={item.starter.company_name ? item.starter.company_name : null}
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <Button
                                                            size="small"
                                                            color="secondary"
                                                            component={Link}
                                                            to={manage ? `/manage/ticket/${item.id}` : `/ticket/${item.id}`}
                                                        >
                                                            {translate('viewTicket')}
                                                        </Button>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            ))
                                        }
                                    </List>
                                </div>
                            </Dialog>
                        </>
                }
            </>
        )
    }
}
export default  withStyles(Styles)(TotalResponses)