import React from 'react';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField/TextField";
import axios from "axios";
import {defaultVariabls} from "../../../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import LoadingButton from "../../../../components/elements/LoadingButton";
import Grid from "@material-ui/core/Grid/Grid";
import {connect, setStore} from "trim-redux";
import Thumbnail from "../../../fileManager/Components/Thumbnail";
import withWidth from "@material-ui/core/withWidth/withWidth";
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {translate} from "../../../../../config/lang";
import SingleImageChooser from "../../../fileManager/Components/SingleImageChooser";
import MetaDescription from "../../../fileManager/Components/MetaDescription";

class MakeCertificate extends React.Component {

    state = {
        fields:{
            name:'',
        },
        thumbnail: null,
        loading: true,
        SubmitLoading: false,
        id:this.props.id,
        editMode:!!this.props.id,
        errors:[]
    };
    classes = this.props.classes;
    async componentDidMount(){
        await this.setState({
            id:this.props.id,
            editMode:!!this.props.id
        })
        if(!!this.props.id) {
            await this.loadData();
        }else{
            this.setState({
                loading:false
            })
        }
    }
    handleThumbImage(thumbnail){
        this.setState({
            thumbnail
        })
    }
    async loadData(){
        let {id} = this.state;
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/certificate/get/${id}`, config)
                .then(response => {
                    this.setState({
                        fields: {
                            name:response.data.name,
                        },
                        editMode:true,
                        loading:false,
                        thumbnail: response.data.thumbnail,
                    })

                }).catch(error => {
                    console.log(error.response)
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }
    }
    handleChange(e){
        let {errors,fields} = this.state;
        let target = e.target;
        errors[target.name] = "";
        fields[target.name] = target.value;

        this.setState({
            fields,
            errors
        });    
    }

    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //name
        if(!fields.name ) {
            formIsValid = false;
            errors['name'] = translate('emptyField');
        }
        if(!formIsValid){
            this.props.enqueueSnackbar(translate('someFieldsHasError'), {
                variant: 'warning',
            });
            this.setState({
                errors,
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        let { id,fields,thumbnail,editMode} = this.state;
        let data = new FormData();
        data.append('name', fields.name);
        data.append('thumbnail', !!thumbnail ? thumbnail.id : 1);
        data.append('id', id);
        data.append('owner_id', this.props.owner_id);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/certificate/make`, data, config)
            .then(response => {
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
                this.props.reload();

            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(editMode ?  translate('dataEditingFailed') : translate('dataAddingFailed'), {
                    variant: 'error',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                    SubmitDraftLoading: false
                });
            });

    }

    handleSubmit(e){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }

    render(){
        let {fields, errors,SubmitLoading,thumbnail,loading,position,type} = this.state;
        return (
            <div className="modifySinglePricingHolder">
                {
                    loading ?
                        <div style={{paddingTop:100,paddingBottom:100,textAlign:"center"}}>
                            <CircularProgress size={35}/>
                        </div>
                        :
                        <Grid container spacing={2}  justifyContent="center">
                            <Grid item xs={12}>
                                <SingleImageChooser
                                    primary
                                    onChange={(thumbnail) => this.handleThumbImage(thumbnail)}
                                    initial={thumbnail}
                                />
                                <MetaDescription primary width={800} height={600} size={"20MB"}/>

                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    value={fields.name}
                                    fullWidth
                                    size="small"
                                    label={translate('certificate')}
                                    name="name"
                                    onChange={this.handleChange.bind(this)}
                                    error = {!!errors['name']}
                                    helperText={errors['name']}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LoadingButton
                                    styleForParent={{
                                        float: "left",
                                        margin:0
                                    }}
                                    startIcon={<SaveRoundedIcon/>}
                                    variant="contained"
                                    color="primary"
                                    onClick = {this.handleSubmit.bind(this)}
                                    loading = {SubmitLoading}
                                >
                                    {translate('save')}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                }

            </div>

        );
    }
}

const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    locale: state.locale,
});
export default withWidth()(withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MakeCertificate)))));