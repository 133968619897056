import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import RichEditor from "../../../components/editor/RichEditor";
import TextField from "@material-ui/core/TextField/TextField";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import Thumbnail from "../../fileManager/Components/Thumbnail";
import {connect, setStore} from "trim-redux";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import LoadingButton from "../../../components/elements/LoadingButton";
import BreadCrumbs from "../../../components/elements/BreadCrumbs";
import SubTitle from "../../../components/elements/SubTitle";
import FieldCounter from "../../../components/elements/FieldCounter";
import {Translate} from "../../../../config/Translate";
import AppAnimate from "../../../components/AppAnimate";
import AppCardLoaderFields from "../../../components/Skeletons/AppCardLoaderFields";
import AppCard from "../../../components/layouts/components/AppCard";

class ManageAbout extends React.Component {
    constructor(props){
        super(props);
        this.EditorChild = React.createRef();
    }
    state = {
        fields:{
            description:''
        },
        content:{
            HTML: '',
            RAW: null,
            PlainText: ''
        },
        thumbnail: 1,
        errors:{},
        loading: true,
        SubmitLoading: false,
    };
    classes = this.props.classes;
    componentDidMount(){
        this.loadData();
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');

        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/pages/get/about`, config)
                .then(response => {
                    this.setState({
                        fields: {
                            description: response.data.description,
                        },
                        thumbnail: response.data.thumbnail.id,
                        content: {
                            HTML: response.data.html,
                            RAW: response.data.raw,
                            PlainText: response.data.plainText
                        },
                        loading:false
                    })
                }).catch(error => {
                    console.log(error.response)
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
            this.setState({
                loading:false
            })
        }
    }
    handleThumbImage(id){
        this.setState({
            thumbnail: id
        })
    }
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        fields[target.name] = target.value;
        errors[target.name] = "";
        this.setState({fields});
    }
    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;
        //Description
        if(fields.description !== null ) {
            if (fields.description.length > 150) {
                formIsValid = false;
                errors['description'] = Translate.maxLength150;
            }
        }
        this.setState({errors},() =>{
            if(!formIsValid){
                this.setState({
                    SubmitLoading:false
                })
            }
            return callback(formIsValid)
        });
    }
    handleClickDescription(){
        let {fields,content} = this.state;
        if(fields['description'] === ''){
            fields['description'] = content.PlainText === null ? null : content.PlainText.slice(0, 150);
            this.setState({fields})
        }
    }
    handleRequest(){
        let { fields,thumbnail,content } = this.state;
        let data = new FormData();
        data.append('slug', 'about');
        data.append('html', content.HTML);
        data.append('raw', content.RAW);
        data.append('plainText', content.PlainText);
        data.append('lang', this.props.locale);
        data.append('description', fields.description === null ? '' : fields.description);
        data.append('thumbnail', thumbnail);
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/pages/make`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.allChangesSaved, {
                    variant: 'success',
                });
                this.setState({
                    SubmitLoading:false
                })
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(Translate.allChangesSavingFailed, {
                    variant: 'error',
                });
                this.setState({
                    SubmitLoading:false
                })
            });
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    handleOutPut(code){
        this.setState({
            content:code
        })
    }
    render(){
        let {fields, errors,thumbnail,loading,content,SubmitLoading} = this.state;
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={Translate.makeProduct}>
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12} md={8} lg={9}>
                        <AppCard
                            title={Translate.manageAbout}
                            action={
                                <BreadCrumbs singleLevel firstText={Translate.manageAbout}/>
                            }>
                            {
                                loading ? <AppCardLoaderFields/> :
                                    <Grid container spacing={2} justify="center" alignItems="center" direction="row">
                                        <Grid item xs={12} md={11} className="mt-20">
                                            <RichEditor placeholder={Translate.pageDetails} ref={this.EditorChild}
                                                        Output={this.handleOutPut.bind(this)} input={content.RAW}/>
                                        </Grid>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                multiline
                                                fullWidth
                                                name="description"
                                                label={Translate.description}
                                                type="text"
                                                id="description"
                                                rows="4"
                                                value={fields.description}
                                                onChange={this.handleChange.bind(this)}
                                                onClick={this.handleClickDescription.bind(this)}
                                                error={!!errors['description']}
                                                helperText={errors['description']}
                                            />
                                            <FieldCounter
                                                length={fields.description === null ? 0 : fields.description.length}
                                                max={150}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <LoadingButton
                                                variant="contained"
                                                color="primary"
                                                onClick={this.handleSubmit.bind(this)}
                                                loading={SubmitLoading}
                                            >
                                                {Translate.setDetails}
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                            }
                        </AppCard>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Grid container spacing={2} alignItems="center" justify="center">
                            <Grid item xs={12} style={{paddingTop: 0}}>
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    styleForParent={{marginBottom:10}}
                                    style={{width: "100%"}}
                                    onClick={this.handleSubmit.bind(this)}
                                    loading={SubmitLoading}
                                >
                                    {Translate.setDetails}
                                </LoadingButton>
                            </Grid>
                            <Grid item xs={12}>
                                <AppCard>
                                    {
                                        this.state.loading ? <AppCardLoaderFields/> :
                                            <Grid container spacing={2} alignItems="center">
                                                <Grid item xs={12}>
                                                    <SubTitle>{Translate.ThumbnailTitle}</SubTitle>
                                                    <Thumbnail rounded ref={this.ThumbChild}
                                                               handleThumbImage={this.handleThumbImage.bind(this)}
                                                               initial={thumbnail}/>
                                                </Grid>
                                            </Grid>
                                    }
                                </AppCard>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </AppAnimate>

        );
    }
}
const mstp = state => ({
    Translate: state.Translate,
    locale: state.locale,
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(ManageAbout))));