import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import Container from "@material-ui/core/Container/Container";
import Box from "@material-ui/core/Box/Box";
import Copyright from "../elements/Copyright";
import {Styles} from "../../../config/design/styles";
import ScrollToTop from "../elements/ScrollToTop";
import AppHeader from "./components/AppHeader";
import AppSidebar from "./components/AppSidebar";
import {connect, getStore} from "trim-redux";
import {withRouter} from 'react-router-dom';
import withWidth from "@material-ui/core/withWidth/withWidth";
import CheckSimulation from "../elements/CheckSimulation";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {defaultVariabls} from "../../../config";
import queryString from "query-string";
import {changeMode} from "../../../config/values";
import {BottomNavigation, BottomNavigationAction, Icon} from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden/Hidden";
import {Helmet} from "react-helmet/es/Helmet";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert/Alert";
import {translate} from "../../../config/lang";

class Layout extends React.Component{
    classes = this.props.classes;

    componentDidMount(){
        let { isAuth,user} = this.props;
        if(user === null){
            this.props.history.push('/login')
        }
        if (isAuth){
            if(user.mobile_confirmed === 0){
                this.props.history.push('/verify-mobile')
            }
        }else{
            this.setState({
                loading:false
            })
        }
        const values = queryString.parse(this.props.location.search)
        let url_mode = values.mode;
        if(!!url_mode){
            let mode = localStorage.getItem('mode')
            if(mode !== url_mode){
                changeMode(url_mode)
            }
        }
    }
    render(){
        const children = this.props.children;
        let menu = getStore('menu');
        let mode = getStore('mode');
        let {locale,user,isAuth} = this.props;

        return(
                <div className={this.classes.root} id={mode === "dark" ? "dark_root" : "light_root"}>
                    <CssBaseline />
                    <AppHeader/>
                    <div className={this.classes.rootContainer} id={locale === "en" ? "ltr_root" : "rtl_root"}>
                        <AppSidebar/>
                        <main className={this.classes.content} id="container">
                            <Container maxWidth="xl" className={this.classes.container} >
                                {
                                    (!!isAuth && !!user.is_suspended) &&
                                    <Grid container spacing={2} justifyContent="center" alignItems="center" justify="center">
                                        <Grid item xs={12} lg={11}>
                                            <Alert severity="error">
                                                {translate('yourAccountIsSuspended')}
                                            </Alert>
                                        </Grid>
                                    </Grid>
                                }
                                {
                                    children
                                }
                                <Box pt={4}>
                                    <Copyright />
                                </Box>
                                {/*<CheckInternetConnection/>*/}
                                <CheckSimulation/>
                                {
                                    this.props.global_loading &&
                                        <div
                                            style={{
                                                position:"fixed",
                                                inset:0,
                                                zIndex:9999999998,
                                                backgroundColor:"rgba(0,0,0,0.4)"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    position:"fixed",
                                                    left:"50%",
                                                    top:"50%",
                                                    transform:"translate(-50%,-50%)",
                                                    zIndex:9999999999,
                                                    backgroundColor:"#fff",
                                                    borderRadius:20,
                                                    padding:40,
                                                    minWidth:150,
                                                    boxShadow: 'rgba(0,0,0,.4) 0px 0px 10px 1px',
                                                    textAlign:"center"
                                                }}
                                            >
                                            <img
                                                style={{maxWidth:150,display:"block",marginBottom:20}}
                                                src={`${defaultVariabls.uploads}/FileManager/${this.props.logo}`}
                                            />
                                            <CircularProgress
                                                color="primary"

                                            />
                                            </div>
                                        </div>
                                }
                            </Container>
                        </main>
                    </div>
                    <ScrollToTop/>
                    <Hidden lgUp>
                        <BottomNavigation
                            style={{position:"fixed",bottom:0,left:0,width:"100%"}}
                            showLabels
                        >
                            {menu.map((item,index) => (
                            <BottomNavigationAction
                                key={index}
                                label={item.name}
                                component='a'
                                icon={<Icon className='material-symbols-outlined'>{item.icon}</Icon>}
                                href={item.url.search('http') > 0 ? `${item.url}` : `${defaultVariabls.website}${item.url}`}
                            />
                                ))
                            }
                        </BottomNavigation>
                    </Hidden>
                    {/*<Helmet>
                        <script src="//code.tidio.co/0p6xke9rith93c8r9xzvox17nz9kmjea.js" async></script>
                    </Helmet>*/}
                </div>
            )
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    locale: state.locale,
    global_loading:state.global_loading,
    logo:state.logo
});
export default withWidth()(withStyles(Styles)(withRouter(connect(mstp)(Layout))));
