import React from 'react';
import {ThemeProvider, withStyles} from '@material-ui/core/styles';
import {BrowserRouter as Router, Route, Switch, Redirect, withRouter} from 'react-router-dom';
import axios from 'axios';
import {defaultVariabls} from './config';
import Login from "./class/pages/Authentication/Login";
import Loader from "./class/components/elements/Loader";
import {Styles} from "./config/design/styles";
import PrivateRoute from "./class/components/elements/PrivateRoute";
import Layout from "./class/components/layouts/layout";
import Error404 from './class/pages/errors/404';
import FileManagerPage from "./class/pages/fileManager/FileManagerPage";
import MobileVerify from "./class/pages/Authentication/MobileVerify";
import ChangePassword from "./class/pages/user/ChangePassword";
import ForgetPassword from "./class/pages/Authentication/ForgetPassword";
import LoginWithToken from "./class/pages/Authentication/LoginWithToken";
import Register from "./class/pages/Authentication/Register";
import VerifyEmailPage from "./class/pages/Authentication/VerifyEmailPage";
import Permission from "./class/components/elements/Permission";
import {ErrorBoundary} from 'react-error-boundary'
import IconButton from "@material-ui/core/IconButton/IconButton";
import CachedRoundedIcon from '@material-ui/icons/CachedRounded';
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import MakeContent from "./class/pages/contents/MakeContent";
import UserDetails from "./class/pages/user/UserDetails";
import MakeProduct from "./class/pages/products/MakeProduct";
import CategoriesPage from "./class/pages/admin/categories/CategoriesPage";
import MakeCategory from "./class/pages/admin/categories/MakeCategory";
import ProductsPage from "./class/pages/products/ProductsPage";
import CommentsPage from "./class/pages/admin/comments/CommentsPage";
import ManageAbout from "./class/pages/admin/pages/ManageAbout";
import ManageTerms from "./class/pages/admin/pages/ManageTerms";
import UsersPage from "./class/pages/admin/users/UsersPage";
import ActivityLog from "./class/pages/admin/users/UsersLogPage";
import WalletPage from "./class/pages/wallet/WalletPage";
import Verification from "./class/pages/user/components/Verification";
import WalletsPage from "./class/pages/admin/wallets/WalletsPage";
import WalletHistoryPage from "./class/pages/admin/wallets/WalletHistoryPage";
import ManageVerificationsPage from "./class/pages/admin/users/ManageVerificationsPage";
import ManageOrdersPage from "./class/pages/admin/orders/ManageOrdersPage";
import ManageUserPage from "./class/pages/admin/users/ManageUserPage";
import MyAddressesPage from "./class/pages/user/address/MyAddressesPage";
import ManagePrivacyPolicy from "./class/pages/admin/pages/ManagePrivacyPolicy";
import ManageSellerTerms from "./class/pages/admin/pages/ManageSellerTerms";
import Wishlist from "./class/pages/user/wishlist/Wishlist";
import SlideShowsPage from "./class/pages/admin/settings/slideshows/SlideShowsPage";
import BannersPage from "./class/pages/admin/settings/banners/BannersPage";
import CheckoutPage from "./class/pages/checkout/CheckoutPage";
import UserOrdersPage from "./class/pages/orders/user_orders/UserOrdersPage";
import ViewOrder from "./class/pages/orders/user_orders/ViewOrder";
import ManageOrder from "./class/pages/admin/orders/ManageOrder";
import Role from "./class/components/elements/Role";
import CheckOrder from "./class/pages/orders/shop_orders/CheckOrder";
import SyncAuth from "./class/pages/Authentication/SyncAuth";
import AdminDashboard from "./class/pages/admin/analytics/AdminDashboard";
import OrdersAnalyticsPage from "./class/pages/admin/analytics/OrdersAnalyticsPage";
import ManageContact from "./class/pages/admin/pages/ManagePage";
import UserDashboard from "./class/pages/Dashboard/UserDashboard";
import RedirectsPage from "./class/pages/admin/settings/redirects/RedirectsPage";
import ManageViolations from "./class/pages/admin/pages/ManageViolations";
import TicketsPage from "./class/pages/tickets/TicketsPage";
import Ticket from "./class/pages/tickets/Ticket/index";
import DepartmentsPage from "./class/pages/tickets/departments/DepartmentsPage";
import Feature from "./class/components/elements/Feature";
import ContentsPage from "./class/pages/contents/ContentsPage";
import BlogCategoriesPage from "./class/pages/admin/blog_categories/BlogCategoriesPage";
import MakeBlogCategory from "./class/pages/admin/blog_categories/MakeBlogCategory";
import BlogCommentsPage from "./class/pages/admin/blog-comments/BlogCommentsPage";
import MakePage from "./class/pages/admin/pages/MakePage";
import PagesPage from "./class/pages/admin/pages/PagesPage";
import ManageTemplateWithoutDrag from "./class/pages/admin/template/ManageTemplateWithoutDrag";
import SiteInformationPage from "./class/pages/admin/settings/siteSettings/SiteInformationPage";
import ContactInformationPage from "./class/pages/admin/settings/siteSettings/contact/ContactInformationPage";
import CustomCode from "./class/pages/admin/settings/siteSettings/CustomCode";
import HeaderAndFooter from "./class/pages/admin/template/HeaderAndFooter";
import {translate} from "./config/lang";
import FieldsPage from "./class/pages/admin/fields/FieldsPage";
import Logout from "./class/pages/Authentication/Logout";
import BusinessesPage from "./class/pages/businesses/BusinessesPage";
import BusinessPage from "./class/pages/businesses/BusinessPage";
import FAQPage from "./class/pages/admin/faq/FAQPage";
import BusinessTypesPage from "./class/pages/admin/businesses/types/BusinessTypesPage";
import MakeRFQ from "./class/pages/rfqs/MakeRFQ";
import RFQsPage from "./class/pages/rfqs/RFQsPage";
import {changeMode} from "./config/values";
import MessagesPage from "./class/pages/messages/MessagesPage";
import SubscribersPage from "./class/pages/admin/users/subscribers/SubscribersPage";
import RegisterStepsPage from "./class/pages/register_steps/RegisterStepsPage";
import ParentsPage from "./class/pages/admin/plans/plan_parent/ParentsPage";
import MakeParent from "./class/pages/admin/plans/plan_parent/MakeParent";
import PlansPage from "./class/pages/admin/plans/PlansPage";
import MakePlan from "./class/pages/admin/plans/MakePlan";
import PlanRestriction from "./class/components/elements/PlanRestriction";
import MyPlanPage from "./class/pages/plan/MyPlanPage";
import queryString from "query-string";
import {connect, getStore, setStore} from 'trim-redux'
import ForgetPasswordByEmail from "./class/pages/Authentication/ForgetPasswordByEmail";
import AddUserPage from "./class/pages/admin/users/AddUserPage";

class App extends React.Component{
    state ={
        loading:true,
    };
    async componentDidMount(){
        const values = queryString.parse(document.location.search);
        if(!!values.mode){
            await localStorage.setItem('mode',values.mode);
        }
        let lang = values.lang;
        if(!!lang){
            if(lang !== getStore('locale')){
                setStore({
                    lang
                });
                if(lang === "ar"){
                    localStorage.setItem('locale','ar');
                    window.location.reload(true);
                }else{
                    localStorage.setItem('locale','en');
                    window.location.reload(true);
                }
            }
        }
        if(!localStorage.getItem('mode')){
            const hours = new Date().getHours()
            const isDayTime = hours > 6 && hours < 19;
            if(!isDayTime){
                changeMode("dark");
            }
        }else{
            let mode = localStorage.getItem('mode')
            setStore({
                global_loading: true
            })
            setStore({
                mode:mode
            });
            setTimeout(function() {
                setStore({
                    global_loading: false
                })
            }, 200);
        }

        await this.loadData();
        await this.loadSetting();
    }
    async loadSetting(){
        let config = {
            headers: {
                Accept: 'application/json',
            }
        };
        let {locale} = this.props;
        await axios.get(`${defaultVariabls.url}/settings/get_all/${locale}`, config)
            .then(response => {
                setStore({
                    setting:response.data,
                    primary_color:response.data.primary_color,
                    secondary_color:response.data.secondary_color,
                    logo:response.data.site_logo,
                    app_name:response.data.app_name,
                    x_price:response.data.x_price,
                    global_need_verification:parseInt(response.data.global_need_verification),
                    admins:response.data.admins,
                    menu:response.data.menu,
                    about:response.data.about,
                    features:response.data.features,
                });
                this.setState({
                    loading:false,
                })
            }).catch(error => {
                console.log(error.response)
            })
    }

    async loadData(){
        const values = queryString.parse(document.location.search);
        if(!!values.token){
            await localStorage.setItem('ACT',values.token);
            await window.location.replace(`${defaultVariabls.website}/redirect`);
        }
        let loginToken = localStorage.getItem('ACT');

        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/checkLogin`, config)
                .then(response => {
                    setStore({
                        isAuth : true,
                        user : response.data,
                        umc : response.data.umc,
                    });
                    if(response.data.mobile_confirmed === 1){
                        let redirect = localStorage.getItem('redirect');
                        if(!!redirect){
                            localStorage.removeItem('redirect');
                            this.props.history.push(redirect);
                        }
                        let external_redirect = localStorage.getItem('external_redirect');
                        if(!!external_redirect){
                            localStorage.removeItem('external_redirect');
                            window.location.replace(external_redirect);
                        }
                    }
                }).catch(error => {
                    console.log(error.response)
                    setStore({
                        isAuth : false,
                        user : null,
                    });
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
            this.setState({
                loading:false
            })
        }
    }

    ErrorFallback({error}) {
        if(!!error){
            return (
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={true}
                    message={translate('errorHappens')}
                    action={
                        <React.Fragment>
                            <IconButton color="secondary"  onClick={() => window.location.reload()}>
                                <CachedRoundedIcon color="secondary"/>
                            </IconButton>
                        </React.Fragment>
                    }
                />
            )
        }

    }
    classes = this.props.classes;
    render(){
        let {user,global_loading} = this.props;
        if(this.state.loading || global_loading){
            return(
                <Loader/>
            )
        }else{
            return(
                <ErrorBoundary FallbackComponent={this.ErrorFallback}>
                    <Router>
                        <Switch>
                            <Redirect exact from='/' to='/dashboard'/>
                            <Route  path="/notingFound" >
                                <Error404/>
                            </Route>
                            {/*Auth Routes*/}
                            <Route exact path="/login">
                                <Login />
                            </Route>
                            <Route exact path="/iframe/login">
                                <Login iframe/>
                            </Route>
                            <Route path="/sync-auth">
                                <SyncAuth />
                            </Route>
                            <Route exact path="/register/:mobile?">
                                <Register />
                            </Route>
                            <Route exact path="/iframe/register">
                                <Register iframe/>
                            </Route>
                            <Route exact path="/verifyEmail/:id/:code">
                                <VerifyEmailPage />
                            </Route>
                            <Route exact path="/token-login">
                                <LoginWithToken />
                            </Route>
                            <Route exact path="/iframe/token-login">
                                <LoginWithToken iframe />
                            </Route>
                            <Route exact path="/forget">
                                <ForgetPassword />
                            </Route>
                            <Route exact path="/iframe/forget">
                                <ForgetPassword iframe/>
                            </Route>
                            <Route exact path="/forget-email">
                                <ForgetPasswordByEmail />
                            </Route>
                            <Route exact path="/iframe/forget-email">
                                <ForgetPasswordByEmail iframe/>
                            </Route>
                            <PrivateRoute path="/verify-mobile">
                                <MobileVerify />
                            </PrivateRoute>
                            <Route exact path="/logout">
                                <Logout/>
                            </Route>
                            <Layout>

                                <PrivateRoute exact path="/dashboard">
                                    {
                                        !!user ? user.role.type === 'admin' ? <AdminDashboard/> :  <UserDashboard/>  : null
                                    }
                                </PrivateRoute>
                                <PrivateRoute path="/steps">
                                    <RegisterStepsPage />
                                </PrivateRoute>
                                <PrivateRoute path="/my-plan">
                                    <MyPlanPage />
                                </PrivateRoute>
                                <Feature check="tickets">
                                    <PrivateRoute path="/messages/:section">
                                        <MessagesPage />
                                    </PrivateRoute>
                                    <PrivateRoute path="/ticket/:id">
                                        <Ticket/>
                                    </PrivateRoute>
                                    <Permission rule="manageSettings">
                                        <PrivateRoute path="/manage/departments">
                                            <DepartmentsPage />
                                        </PrivateRoute>
                                        <PrivateRoute path="/manage/tickets">
                                            <TicketsPage manage />
                                        </PrivateRoute>
                                        <PrivateRoute path="/manage/ticket/:id">
                                            <Ticket manage/>
                                        </PrivateRoute>

                                    </Permission>
                                </Feature>
                                <Permission rule="fileManagerPage">
                                    <PrivateRoute path="/file-manager">
                                        <FileManagerPage/>
                                    </PrivateRoute>
                                </Permission>
                                <PrivateRoute path="/account/password">
                                    <ChangePassword/>
                                </PrivateRoute>
                                <Feature check="wishlist">
                                    <PrivateRoute path="/wishlist">
                                        <Wishlist/>
                                    </PrivateRoute>
                                </Feature>
                                <PrivateRoute path="/account/details">
                                    <UserDetails/>
                                </PrivateRoute>
                                <PrivateRoute path="/account/address">
                                    <MyAddressesPage/>
                                </PrivateRoute>
                                <PrivateRoute path="/account/verification">
                                    <Verification/>
                                </PrivateRoute>
                                <Feature check="wallet">
                                    <PrivateRoute exact path="/wallet/view">
                                        <WalletPage/>
                                    </PrivateRoute>
                                </Feature>
                                <PrivateRoute exact path="/checkout">
                                    <CheckoutPage/>
                                </PrivateRoute>
                                {/*<PrivateRoute exact path="/order">
                                                <CreateOrder/>
                                            </PrivateRoute>*/}
                                <Permission rule="manageSettings">
                                    <PrivateRoute exact path="/manage/comments/product">
                                        <CommentsPage type="product"/>
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/manage/comments/business">
                                        <CommentsPage type="business"/>
                                    </PrivateRoute>

                                    <PrivateRoute exact path="/manage/pages/about">
                                        <ManageAbout/>
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/manage/contacts">
                                        <ManageContact/>
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/manage/faq">
                                        <FAQPage/>
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/manage/violations">
                                        <ManageViolations/>
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/manage/pages/terms">
                                        <ManageTerms/>
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/manage/pages/privacy-policy">
                                        <ManagePrivacyPolicy/>
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/manage/pages/seller-terms">
                                        <ManageSellerTerms/>
                                    </PrivateRoute>


                                    <PrivateRoute exact path="/manage/template">
                                        <ManageTemplateWithoutDrag/>
                                    </PrivateRoute>

                                    <PrivateRoute exact path="/manage/users/view/:role?">
                                        <UsersPage/>
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/manage/verified-users">
                                        <UsersPage verified/>
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/manage/subscribers">
                                        <SubscribersPage/>
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/manage/users/add">
                                        <AddUserPage/>
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/manage/user/:id">
                                        <ManageUserPage/>
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/manage/register-user/:id">
                                        <RegisterStepsPage manage/>
                                    </PrivateRoute>

                                    <Feature check="activity_log">
                                        <PrivateRoute exact path="/manage/activities/:query?">
                                            <ActivityLog/>
                                        </PrivateRoute>
                                    </Feature>
                                    <PrivateRoute exact path="/analytics/orders">
                                        <OrdersAnalyticsPage/>
                                    </PrivateRoute>
                                </Permission>
                                <Permission rule="manageShops">

                                    <PrivateRoute exact path="/manage/products">
                                        <ProductsPage manage/>
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/manage/rfqs">
                                        <RFQsPage manage/>
                                    </PrivateRoute>

                                    <PrivateRoute exact path="/manage/orders">
                                        <ManageOrdersPage manage/>
                                    </PrivateRoute>
                                    <PrivateRoute path="/order/manage/:id">
                                        <ManageOrder/>
                                    </PrivateRoute>

                                    <PrivateRoute exact path="/manage/fields">
                                        <FieldsPage/>
                                    </PrivateRoute>

                                    <PrivateRoute exact path="/manage/pages">
                                        <PagesPage/>
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/create/page">
                                        <MakePage new/>
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/edit/page/:id">
                                        <MakePage />
                                    </PrivateRoute>
                                </Permission>
                                <Permission rule="managePayments">
                                    <PrivateRoute path="/setting/contact">
                                        <ContactInformationPage/>
                                    </PrivateRoute>
                                    <PrivateRoute path="/setting/custom-code">
                                        <CustomCode />
                                    </PrivateRoute>
                                    <PrivateRoute path="/setting/header-footer">
                                        <HeaderAndFooter />
                                    </PrivateRoute>
                                    <PrivateRoute path="/setting/information">
                                        <SiteInformationPage/>
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/settings/slideshows">
                                        <SlideShowsPage/>
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/settings/banners">
                                        <BannersPage/>
                                    </PrivateRoute>
                                    <Feature check="redirects">
                                        <PrivateRoute exact path="/settings/redirects">
                                            <RedirectsPage/>
                                        </PrivateRoute>
                                    </Feature>

                                    <PrivateRoute exact path="/manage/categories">
                                        <CategoriesPage/>
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/create/category">
                                        <MakeCategory new/>
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/edit/category/:id">
                                        <MakeCategory />
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/manage/plan-parents">
                                        <ParentsPage/>
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/create/plan-parent">
                                        <MakeParent new/>
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/edit/plan-parent/:id">
                                        <MakeParent />
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/manage/plans">
                                        <PlansPage/>
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/create/plan">
                                        <MakePlan new/>
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/edit/plan/:id">
                                        <MakePlan />
                                    </PrivateRoute>
                                    <Feature check="wallet">
                                        <PrivateRoute exact path="/manage/wallets">
                                            <WalletsPage/>
                                        </PrivateRoute>
                                        <PrivateRoute exact path="/manage/wallet/:id">
                                            <WalletHistoryPage/>
                                        </PrivateRoute>
                                        <PrivateRoute exact path="/manage/verifications">
                                            <ManageVerificationsPage/>
                                        </PrivateRoute>
                                    </Feature>
                                </Permission>
                                <Feature check="blog">
                                    <PrivateRoute exact path="/manage/blog-categories">
                                        <BlogCategoriesPage/>
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/manage/blog-comments">
                                        <BlogCommentsPage/>
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/create/blog-category">
                                        <MakeBlogCategory new/>
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/edit/blog-category/:id">
                                        <MakeBlogCategory />
                                    </PrivateRoute>
                                    <PrivateRoute path="/manage/contents" exact>
                                        <ContentsPage/>
                                    </PrivateRoute>
                                    <PrivateRoute path="/create/content" exact>
                                        <MakeContent new/>
                                    </PrivateRoute>
                                    <PrivateRoute path="/edit/content/:id">
                                        <MakeContent />
                                    </PrivateRoute>
                                </Feature>
                                <PlanRestriction rule="has_business_page">
                                    <PrivateRoute path="/my-products" exact>
                                        <ProductsPage/>
                                    </PrivateRoute>
                                </PlanRestriction>
                                <PrivateRoute path="/create/product" exact>
                                    <MakeProduct new/>
                                </PrivateRoute>
                                <PrivateRoute path="/edit/product/:id">
                                    <MakeProduct />
                                </PrivateRoute>
                                <PrivateRoute path="/my-rfqs" exact>
                                    <RFQsPage/>
                                </PrivateRoute>
                                <PrivateRoute path="/create/rfq" exact>
                                    <MakeRFQ new/>
                                </PrivateRoute>
                                <PrivateRoute path="/edit/rfq/:id">
                                    <MakeRFQ />
                                </PrivateRoute>
                                <Permission rule="manageAdvertisements">

                                    <PrivateRoute exact path="/manage/businesses">
                                        <BusinessesPage manage/>
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/manage/business-types">
                                        <BusinessTypesPage/>
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/create/business">
                                        <BusinessPage create/>
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/edit/business/:id">
                                        <BusinessPage manage/>
                                    </PrivateRoute>
                                </Permission>

                                <PlanRestriction rule="has_business_page">
                                    <PrivateRoute path="/business" exact>
                                        <BusinessPage/>
                                    </PrivateRoute>
                                </PlanRestriction>

                                <PrivateRoute path="/my-orders">
                                    <UserOrdersPage />
                                </PrivateRoute>
                                <PrivateRoute path="/order/view/:id">
                                    <ViewOrder/>
                                </PrivateRoute>
                                <Feature check="marketplace">
                                    <Role role="supplier" type="normal">
                                        <PrivateRoute path="/order/check/:id">
                                            <CheckOrder/>
                                        </PrivateRoute>
                                    </Role>
                                </Feature>
                                <Route  path="/404" >
                                    <Error404/>
                                </Route>
                            </Layout>
                            {/*Errors Routes*/}
                            <Route path="*" component={Error404} status={404}/>
                        </Switch>
                    </Router>
                </ErrorBoundary>
            )
        }

    }

}
const mstp = state => ({
    user: state.user,
    isAuth:state.isAuth,
    locale:state.locale,
    global_loading:state.global_loading
});
export default withStyles(Styles)(connect(mstp)(App));
