import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import axios from "axios";
import {connect} from "trim-redux";
import MaterialTable from "material-table";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import moment from "moment-timezone";
import Container from "@material-ui/core/Container/Container";
import Typography from "@material-ui/core/Typography/Typography";
import withWidth from "@material-ui/core/withWidth/withWidth";
import Grid from "@material-ui/core/Grid/Grid";
import queryString from "query-string";
import {translate} from "../../../../../config/lang";
import {TableCount, TablePage} from "../../../../../config/Permissions";
import {changeURL, getTimeZone} from "../../../../../config/values";
import AppCardLoaderTable from "../../../../components/Skeletons/AppCardLoaderTable";
import {tableIcons} from "../../../../../config/design/TableIcons";
import {getDataTableLocalization} from "../../../../../config/Translate";
import {defaultVariabls} from "../../../../../config";
import {Styles} from "../../../../../config/design/styles";

class Subscribers extends React.Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    state = {
        columns: [


            { title: translate('email'), field: 'email',filterPlaceholder: translate('search'),sorting:false},

            {
                title: translate('updateTime'),
                editable: 'never',
                sorting:false,
                hidden: this.props.width === "xs",
                field: 'created_at',
                render: rowData =>
                    <>
                        {
                            !!rowData.created_at &&
                            <Typography className={this.classes.dateTable}>
                                {`${moment.tz(rowData.created_at,'UTC').tz(getTimeZone()).fromNow()}`}
                                <br/>
                                {`${moment.tz(rowData.created_at,'UTC').tz(getTimeZone()).format( 'HH:mm , YYYY/M/D')}`}
                            </Typography>
                        }
                    </>
            },

        ],
        data: [],
        loading:false,
        ChangeLoader:false,
        status:"all",
        open_filter:false,
        page:0,
        def_page:0,
        per_page:TablePage,
        def_per_page:TablePage,
    };
    async componentDidMount(){
        await this.loadDefaults();
    }
    loadData(){
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }
    async loadDefaults(){
        const values = queryString.parse(window.location.search);
        let status = values.status;
        let page = values.page;
        let per_page = values.per_page;

        if(!!status){
            await this.setState({
                status,
                open_filter:true,
            })
        }
        if(!!page){
            await this.setState({
                def_page:page,
            })
        }
        if(!!per_page){
            await this.setState({
                per_page:per_page,
                def_per_page:per_page,
            })
        }
        await this.setState({
            loading:false,
        })
    }
    async handleChangeStatus(e) {
        let target = e.target;
        let status = target.value;
        await this.setState({
            status
        });
        changeURL({status: target.value});
        this.loadData();
    }
    async handleChangePage(page){
        await changeURL({page: page})
        await this.setState({
            page,
        });
    }
    async handleChangeRowsPerPage(per_page){
        await changeURL({per_page: per_page})
        await this.setState({
            per_page,
        });
    }

    render(){
        let {status,open_filter,def_page,per_page,def_per_page} = this.state;
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        return (
            <>
                {
                    this.state.loading ? <AppCardLoaderTable/> :
                        <Container maxWidth="xl">
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <MaterialTable
                                        tableRef={this.tableRef}
                                        icons={tableIcons}
                                        title={" "}
                                        onChangePage={(page) => this.handleChangePage(page)}
                                        onChangeRowsPerPage={(page) => this.handleChangeRowsPerPage(page)}
                                        localization={getDataTableLocalization()}
                                        columns={this.state.columns}
                                        data={query =>
                                            new Promise((resolve, reject) => {
                                                axios.get(`${defaultVariabls.url}/getSubscribers?page=${parseInt(def_page > 0 ? def_page: query.page) + 1}&per_page=${def_per_page > TablePage ? def_per_page : query.pageSize}&search=${query.search}&status=${status}&lang=${this.props.locale}`, config)
                                                    .then(response => {
                                                        let data = [];
                                                        // eslint-disable-next-line
                                                        response.data.data.map((row) => {
                                                            data.push({
                                                                email: row.email,
                                                                id: row.id,
                                                                created_at:row.created_at
                                                            })
                                                        });
                                                        if(def_page > 0){
                                                            this.setState({
                                                                def_page:0,
                                                            })
                                                        }
                                                        resolve({
                                                            data: data,
                                                            page: response.data.current_page - 1,
                                                            totalCount: response.data.total,
                                                        })
                                                    }).catch(error => {
                                                    console.log(error.response)
                                                })
                                            })
                                        }
                                        options={{
                                            draggable: false,
                                            pageSize: per_page,
                                            pageSizeOptions: TableCount,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Container>
                }
            </>
        );
    }
}

const mstp = state => ({
    user: state.user,
    features: state.features,
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(Subscribers)))));