import React from 'react';
import {Translate} from "../../../../../config/Translate";
import {connect} from "trim-redux";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../../config/design/styles";
import Grid from "@material-ui/core/Grid/Grid";
import IconButtonOpenner from "../../../../components/elements/IconButtonOpenner";
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import SubTitle from "../../../../components/elements/SubTitle";
import axios from "axios";
import {defaultVariabls} from "../../../../../config";
import AppCardLoaderFields from "../../../../components/Skeletons/AppCardLoaderFields";
import TemplatePartChooser from "../components/TemplatePartChooser";
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";
import Paper from "@material-ui/core/Paper/Paper";
import AppCard from "../../../../components/layouts/components/AppCard";
import Alert from "@material-ui/lab/Alert/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle/AlertTitle";
import LiveHelpRoundedIcon from '@material-ui/icons/LiveHelpRounded';
import MenuChooser from "../components/MenuChooser";

class HeaderManagement extends React.Component{

    state = {
        loading: true,
        tab:"header",
        headers:[],
    };

    loadData(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.get(`${defaultVariabls.url}/template_parts/get/header`, config)
            .then(response => {
                this.setState({
                    headers: response.data,
                    loading:false,
                })
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(Translate.dataFetchingError, {
                    variant: 'error',
                });
            });
    }
    render(){
        let {headers,loading,tab} = this.state;
        return (
            <IconButtonOpenner
                title={Translate.headerTemplate}
                icon={<SettingsRoundedIcon/>}
                opened={() => this.loadData()}
            >
                {
                    loading ?
                        <AppCardLoaderFields/>:
                        <>
                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                            <Grid item xs={12} md={10}>
                                <Tabs
                                    value={tab}
                                    onChange={(e,val) => this.setState({tab:val})}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    centered
                                >
                                    <Tab label={Translate.manageHeader} value="header"/>
                                    <Tab label={Translate.manageMenu} value="menu" />
                                </Tabs>
                            </Grid>
                            {
                                tab === "header" ?
                                    <>
                                        <Grid item xs={12} md={10}>
                                            <Alert severity="info" icon={<LiveHelpRoundedIcon fontSize="inherit" />} >
                                                <AlertTitle><strong>{Translate.chooseHeader}</strong></AlertTitle>
                                                {Translate.chooseHeaderText}
                                            </Alert>
                                        </Grid>
                                        {
                                            headers.map((header,index)=> (
                                                <Grid item xs={12} md={10} key={index}>
                                                    <TemplatePartChooser type="header" item={header}/>
                                                </Grid>
                                            ))
                                        }
                                    </>
                                    :

                                    <>
                                        <Grid item xs={12} md={10}>
                                            <Alert severity="info" icon={<LiveHelpRoundedIcon fontSize="inherit" />} >
                                                <AlertTitle><strong>{Translate.makeHeaderMenu}</strong></AlertTitle>
                                                {Translate.makeHeaderMenuText}
                                            </Alert>
                                        </Grid>
                                        <Grid item xs={12} md={10}>
                                            <MenuChooser position="top_menu"/>
                                        </Grid>
                                    </>
                            }

                        </Grid>
                        </>
                }

            </IconButtonOpenner>
        );
    }
}
const mstp = state => ({
    user: state.user,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(HeaderManagement)))));