import React from "react";
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import TocIcon from '@material-ui/icons/Toc';
import FormatListNumberedRtlIcon from '@material-ui/icons/FormatListNumberedRtl';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import Filter2Icon from '@material-ui/icons/Filter2';
import Filter3Icon from '@material-ui/icons/Filter3';
import Filter4Icon from '@material-ui/icons/Filter4';

class StyleButton extends React.Component {
    constructor(props) {
        super(props);
        this.onToggle = (e) => {
            e.preventDefault();
            this.props.onToggle(this.props.style);
        };
    }

    render() {
        let className = 'RichEditor-styleButton';
        if (this.props.active) {
            className += ' RichEditor-activeButton';
        }

        return (
            <span className={className} onMouseDown={this.onToggle}>
              {this.props.label}
            </span>
        );
    }
}

const BLOCK_TYPES = [
    {label: <Filter2Icon/>, style: 'header-two',name: 'header-two'},
    {label: <Filter3Icon/>, style: 'header-three',name: 'header-three'},
    {label: <Filter4Icon/>, style: 'header-four',name: 'header-four'},
    {label: <FormatQuoteIcon/>, style: 'blockquote',name: 'blockquote'},
    {label: <TocIcon/>, style: 'unordered-list-item',name: 'unordered-list-item'},
    {label: <FormatListNumberedRtlIcon/>, style: 'ordered-list-item',name: 'ordered-list-item'},
];

export const BlockStyleControlsShort = (props) => {
    const {editorState} = props;
    const selection = editorState.getSelection();
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();

    return (
        <div className="RichEditor-controls">
            {BLOCK_TYPES.map((type) =>
                <StyleButton
                    key={type.name}
                    active={type.style === blockType}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            )}
        </div>
    );
};

const INLINE_STYLES = [
    {label: <FormatBoldIcon/> , style: 'BOLD',name: 'BOLD'},
    {label: <FormatUnderlinedIcon/>, style: 'UNDERLINE',name: 'UNDERLINE'},
];

export const InlineStyleControlsShort = (props) => {
    let currentStyle = props.editorState.getCurrentInlineStyle();
    return (
        <div className="RichEditor-controls">
            {INLINE_STYLES.map(type =>
                <StyleButton
                    key={type.name}
                    active={currentStyle.has(type.style)}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            )}
        </div>
    );
};