import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import { withSnackbar } from 'notistack';
import {connect, setStore} from "trim-redux";

class SyncAuth extends React.Component {
    componentDidMount(){
        let {isAuth} = this.props;
        if(isAuth){
            this.handleLogout();
        }
        this.props.history.push('/')
    }
    handleLogout(){
        localStorage.removeItem('ACT');
        localStorage.removeItem('RET');
        setStore({
            isAuth : false,
            user : null,
        });
    }
    render(){
        return ("Sync Auth...")

    }

}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(SyncAuth))));
