import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Styles} from "../../../config/design/styles";
import { withRouter } from 'react-router-dom';
import {defaultVariabls} from "../../../config";
import {connect} from "trim-redux";
import withWidth from "@material-ui/core/withWidth/withWidth";
import Loader from "../../components/elements/Loader";

class Logout extends React.Component {

    componentDidMount(){
        this.handleLogout();
    }
    handleLogout(){
        localStorage.removeItem('ACT');
        localStorage.removeItem('RET');
        window.location.replace(`${defaultVariabls.website}/auth/logout`);
    }
    render(){
        return(
            <Loader/>
        )
    }


}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    Translate: state.Translate,
    locale: state.locale,
});
export default withWidth()(withRouter(withStyles(Styles)(connect(mstp)(Logout))));