import {getStore} from "trim-redux";
import {translate} from "./lang";

export const TablePage = 10;
export const TableCount = [10,20,50,100,200];
export const TableCountForCat = [3,5,10];

export const RWN = () => {
    let rwn = {
        admin: translate('admin'),
        support: translate('supportUser'),
        user: translate('buyer'),
        supplier: translate('supplier'),
    };
    return rwn;
};

export const rolesWithName = () => {
    let features = getStore('features');
    let rwn = [
        {
            value: "admin",
            type: "admin",
            name : translate('admin')
        },
        {
            value: "support",
            type: "admin",
            name : translate('supportUser')
        },
        {
            value: "user",
            type: "normal",
            name : translate('buyer')
        },
        {
            value: "supplier",
            type: "normal",
            name : translate('supplier')
        },
    ];
    return rwn;
};
export const typesWithName = [
    {
        value: "admin",
        name : translate('adminType')
    },
    {
        value: "normal",
        name : translate('normalType')
    },
];
