import React from 'react';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import {DataTableLocalization, Translate} from "../../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../../config/index";
import {setStore} from "trim-redux";
import MaterialTable from "material-table";
import {tableIcons} from "../../../../../config/design/TableIcons";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import moment from "jalali-moment";
import {TableCount, TablePage} from "../../../../../config/Permissions";
import Typography from "@material-ui/core/Typography/Typography";
import AppCardLoaderTable from "../../../../components/Skeletons/AppCardLoaderTable";
import Container from "@material-ui/core/Container/Container";


class ManageRedirects extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    fixedHeightPaper = clsx(this.classes.paper, this.classes.fixedHeight);
    state = {
        columns: [
            { title: Translate.old_url, field: 'old_url'},
            { title: Translate.new_url, field: 'new_url'},
            {
                title: Translate.created_at,
                editable: 'never',
                field: 'created_at',
                render: rowData =>
                    <Typography className="date_in_table">
                        {`${moment(rowData.created_at).locale('fa').fromNow()}`}
                        <br/>
                        {`${moment(rowData.created_at).locale('fa').format( 'HH:mm , YYYY/M/D')}`}
                    </Typography>
            },
        ],
        data: [],
        loading:false,
    };
    componentDidMount(){
        this.loadData();
    }
    loadData(){
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }
    onAdd(result) {
        if(result.old_url === undefined || result.new_url === undefined){
            this.props.enqueueSnackbar(Translate.addRedirectFailed, {
                variant: 'error',
            });
        }else{
            let data = new FormData();
            data.append('new_url', result.new_url);
            data.append('old_url', result.old_url);
            let loginToken = localStorage.getItem('ACT');

            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            axios.post(`${defaultVariabls.url}/redirect/add`, data, config)
                .then(response => {
                    this.props.enqueueSnackbar(Translate.addRedirect, {
                        variant: 'success',
                    });
                    this.loadData();
                    return true

                })
                .catch(error => {
                    this.props.enqueueSnackbar(Translate.addRedirectFailed, {
                        variant: 'error',
                    });
                    this.loadData();
                    return false
                });
        }

    }
    onEdit(result) {
        let data = new FormData();
        data.append('id', result.id);
        data.append('new_url', result.new_url);
        data.append('old_url', result.old_url);
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/redirect/edit`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.editRedirect, {
                    variant: 'success',
                });
                return true
            })
            .catch(error => {
                this.props.enqueueSnackbar(Translate.editRedirectFailed, {
                    variant: 'error',
                });
                this.loadData();
                return false
            });
    }
    onDelete(id,hasNotif = true) {
        let data = new FormData();
        data.append('id', id);
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/redirect/delete`, data, config)
            .then(response => {
                if(hasNotif){
                    this.props.enqueueSnackbar(Translate.deleteRedirect, {
                        variant: 'success',
                    });
                }
                return true
            })
            .catch(error => {
                this.props.enqueueSnackbar(Translate.deleteRedirectFailed, {
                    variant: 'error',
                });
                this.loadData();

                return false
            });
    }
    async onDeleteSelected(data){
        await data.map((row) =>(
            this.onDelete(row.id,false)

        ));
        await this.loadData();
        await this.props.enqueueSnackbar(Translate.deleteSelectedRedirects, {
            variant: 'success',
        });
    }
    render(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        return (
            <>
                {
                    this.state.loading ? <AppCardLoaderTable/> :
                        <Container maxWidth="xl">
                            <MaterialTable
                                icons={tableIcons}
                                title={Translate.redirects}
                                localization={DataTableLocalization}
                                columns={this.state.columns}
                                data={query =>
                                    new Promise((resolve, reject) => {
                                        axios.get(`${defaultVariabls.url}/redirect/quickView?page=${query.page + 1}&per_page=${query.pageSize}&search=${query.search}`, config)
                                            .then(response => {
                                                let data = [];
                                                // eslint-disable-next-line
                                                response.data.data.map((row) => {
                                                    data.push({
                                                        old_url: row.old_url,
                                                        id:row.id,
                                                        new_url:row.new_url,
                                                        created_at:row.created_at,
                                                    })
                                                });
                                                resolve({
                                                    data: data,
                                                    page: response.data.current_page - 1,
                                                    totalCount: response.data.total,
                                                })
                                            }).catch(error => {
                                            console.log(error)
                                        })
                                    })
                                }
                                options={{
                                    selection: true,
                                    pageSize: TablePage,
                                    pageSizeOptions: TableCount,
                                    sorting:false,
                                    draggable:false,
                                }}
                                actions={[
                                    {
                                        tooltip: Translate.deleteSelectedUsers,
                                        icon: tableIcons.Delete,
                                        onClick: (evt, data) => this.onDeleteSelected(data)
                                    },
                                ]}
                                editable={{
                                    onRowAdd: newData =>
                                        new Promise((resolve, reject) => {
                                            setTimeout(() => {
                                                this.onAdd(newData);
                                                resolve();
                                            }, 1000);
                                        }),
                                    onRowUpdate: async (newData, oldData) =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                this.onEdit(newData);
                                                if (oldData) {
                                                    this.setState(prevState => {
                                                        const data = [...prevState.data];
                                                        data[data.indexOf(oldData)] = newData;
                                                        return {...prevState, data};
                                                    });
                                                }
                                            }, 600);
                                        }),
                                    onRowDelete: oldData =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                this.onDelete(oldData.id);
                                                this.setState(prevState => {
                                                    const data = [...prevState.data];
                                                    data.splice(data.indexOf(oldData), 1);
                                                    return {...prevState, data};
                                                });
                                            }, 600);
                                        }),
                                }}

                            />
                        </Container>
                }
            </>
        );
    }
}
export default withSnackbar(withRouter(withStyles(Styles)(ManageRedirects)));