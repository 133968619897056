import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import AppCard from "../../../components/layouts/components/AppCard";
import {withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {connect} from "trim-redux";
import Grid from "@material-ui/core/Grid/Grid";
import {Translate} from "../../../../config/Translate";
import Typography from "@material-ui/core/Typography/Typography";
import AppAnimate from "../../../components/AppAnimate";
import Button from "@material-ui/core/Button/Button";
import {Link} from 'react-router-dom';
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import TocRoundedIcon from '@material-ui/icons/TocRounded';
import Hidden from "@material-ui/core/Hidden/Hidden";
class ViewYourOrders extends React.Component {
    classes = this.props.classes;
    state= {
        hide:false
    }
    render(){
        let {user} = this.props;
        return (
            <>
                {
                 !this.state.hide ?
                     <AppCard style={{position:"relative"}}>
                         <Grid container alignItems="center" justify="center">
                             <Hidden mdDown>
                             <Grid item xs={12} md={3} lg={2} style={{textAlign:"center"}}>
                                 <AppAnimate animation='transition.fadeIn' delay={500}>
                                     <Link to="/create/product">
                                     <img src="/assets/images/cart.png" style={{maxWidth:150}}  alt="View Orders"/>
                                     </Link>
                                 </AppAnimate>
                             </Grid>
                             </Hidden>
                             <Grid item xs={12} md={6} lg={8}>
                                 <Grid container spacing={1} alignItems="center" justify="center">
                                     <Grid item xs={12}>
                                     <AppAnimate animation='transition.slideDownIn' delay={600}>
                                         <Typography color="primary" variant="h2">{Translate.viewOrders}</Typography>
                                        </AppAnimate>
                                     </Grid>
                                     <Grid item xs={12}>
                                     <AppAnimate animation='transition.slideDownIn' delay={700}>
                                        <Typography variant="subtitle2" >{Translate.viewOrdersDetails}</Typography>
                                    </AppAnimate>
                                     </Grid>
                                 </Grid>
                             </Grid>
                             <Grid item xs={12} md={3} lg={2}>
                                 <Grid container spacing={1} alignItems="center" justify="center">
                                     <Grid item xs={12}>
                                         <AppAnimate animation='transition.slideDownIn' delay={900}>
                                             <Button
                                                 component={Link}
                                                 style={{marginTop:"10px !important"}}
                                                 to="/my-orders"
                                                 color="primary"
                                                 startIcon={<TocRoundedIcon/>}
                                                 variant="outlined"
                                             >
                                                 {Translate.ordersList}
                                             </Button>
                                         </AppAnimate>
                                     </Grid>
                                 </Grid>

                             </Grid>
                         </Grid>
                     </AppCard>
                     : null
                }

            </>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(ViewYourOrders))));